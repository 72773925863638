import { ICheckboxComponentProps } from './CheckboxComponent';

const DEFAULT_STATE: ICheckBoxState = {
	checkProperties: {
		checked: false,
		children: undefined,
		index: undefined,
		label: undefined,
		parentIndex: undefined,
		style: undefined,
		parentLength: 0,
		mainMenuId: undefined
	}
};

export interface ICheckBoxState {
	checkProperties: ICheckboxComponentProps;
}
export const CheckBoxState = {
	...DEFAULT_STATE
};
