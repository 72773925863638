import { ProductVM } from '../../../common/view-model';

const DEFAULT_STATE: IOverviewState = {
	title: 'Product Overview',
	product: undefined,
	emptyIngredientText: 'Refer to images for ingredient information for this product.'
};
interface IOverviewState {
	title: string;
	product: ProductVM | undefined;
	emptyIngredientText: string;
}
export const OverviewState = {
	...DEFAULT_STATE
};
