import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { FilterLevel, FilterType, FilterVM, ISuggestion } from '../common/view-model';
import {
	firstLevelCategoryIdentifier,
	flatLevelCategoryIdentifier,
	parentCategoryIdentifier,
	searchTermIndentifier,
	secondLevelCategoryIdentifier
} from '../helpers/paramsUrlIndetifiers';
import { getParametersKeys } from '../helpers/urlParametersHelpers';

export const useSetUrlParameters = (
	urlCallback: (url: string) => void,
	searchTerm?: ISuggestion,
	selectedFilters?: FilterVM[]
) => {
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const filterTypes = getParametersKeys();

		if (!searchTerm) {
			params.delete(searchTermIndentifier);
		} else {
			const { id, ...rest } = searchTerm;
			params.set(searchTermIndentifier, JSON.stringify(rest));
		}

		if (selectedFilters && selectedFilters.length !== 0) {
			for (const filter of selectedFilters) {
				if (filter.type !== FilterType.CATEGORY) {
					if (
						filter.type === FilterType.NEW_TO_MARKET ||
						filter.type === FilterType.NEW_TO_BEACON ||
						filter.type === FilterType.NEXTY_FINALISTS
					) {
						const key = FilterType[filter.type!].toLowerCase();
						filterTypes[key] = [...filterTypes[key], 'true'];
					} else {
						const key = FilterType[filter.type!].toLowerCase();
						filterTypes[key] = [...filterTypes[key], filter.id!];
					}
				} else {
					if (!filter.level) {
						if (!filterTypes[parentCategoryIdentifier].some(x => x === filter.id!)) {
							filterTypes[parentCategoryIdentifier] = [
								...filterTypes[parentCategoryIdentifier],
								filter.id!
							];
						}
					}
					switch (filter.level) {
						case FilterLevel.SECOND:
							if (!filterTypes[secondLevelCategoryIdentifier].some(x => x === filter.id!)) {
								filterTypes[secondLevelCategoryIdentifier] = [
									...filterTypes[secondLevelCategoryIdentifier],
									filter.id!
								];
							}
							break;
						case FilterLevel.THIRD:
							if (
								!filterTypes[secondLevelCategoryIdentifier].some(x => x === filter.id!) &&
								!filterTypes[firstLevelCategoryIdentifier].some(x => x === filter.id!)
							) {
								filterTypes[firstLevelCategoryIdentifier] = [
									...filterTypes[firstLevelCategoryIdentifier],
									filter.id!
								];
							}
							break;
						case FilterLevel.FOURTH:
							if (!filterTypes[flatLevelCategoryIdentifier].some(x => x === filter.id!)) {
								filterTypes[flatLevelCategoryIdentifier] = [
									...filterTypes[flatLevelCategoryIdentifier],
									filter.id!
								];
							}
							break;
						default:
							break;
					}
				}
			}
			Object.keys(filterTypes).forEach(key => {
				if (filterTypes[key].length > 0) {
					params.set(key, filterTypes[key].join(','));
				}
			});
		}
		Object.keys(filterTypes).forEach(key => {
			if (filterTypes[key].length === 0) {
				params.delete(key);
			}
		});
		const newSearch = params.toString();
		const newUrl = newSearch
			? `${location.pathname}?${newSearch}`
			: `${location.pathname}${location.search}`;
		urlCallback(newUrl);
		window.history.replaceState({}, '', newUrl);
	}, [location, searchTerm, selectedFilters, urlCallback]);
};
