import { Action } from 'redux-actions';
import { AppState } from './AppReducer';
export class AuthActions {
	static readonly LOGOUT = '@@logout/LOGOUT';
	static readonly CLOSE_MODAL_COMPANY_DETAILS = '@@login/CLOSE_MODAL_COMPANY_DETAILS';
	static readonly ON_MODAL_COMPANY_DETAILS_CLOSED = '@@login/ON_MODAL_COMPANY_DETAILS_CLOSED';
	static readonly IS_REFRESHING_TOKEN = '@@auth/IS_REFRESHING_TOKEN';

	static logout(): Action<AppState> {
		return {
			type: AuthActions.LOGOUT,
			payload: {}
		};
	}
	static closeModalCompanyDetails(): Action<AppState> {
		return {
			type: AuthActions.CLOSE_MODAL_COMPANY_DETAILS,
			payload: {}
		};
	}
	static onModalCompanyDetailsClosed(): Action<AppState> {
		return {
			type: AuthActions.ON_MODAL_COMPANY_DETAILS_CLOSED,
			payload: {}
		};
	}
	static isRefreshingToken(isRefreshing: boolean): Action<boolean> {
		return {
			type: AuthActions.IS_REFRESHING_TOKEN,
			payload: isRefreshing
		};
	}
}
