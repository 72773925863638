const DEFAULT_STATE: INotForSaleInUSState = {
	goToFAQText: 'Go to the FAQ page',
	notForSaleInUSTitle: 'Not labeled for sale in the US',
	notForSaleInUSText:
		'This product is international and labeling may not meet US requirements. You can learn more about how this applies to products on Beacon in our FAQ page.'
};

interface INotForSaleInUSState {
	goToFAQText: string;
	notForSaleInUSTitle: string;
	notForSaleInUSText: string;
}
export const NotForSaleInUSState = {
	...DEFAULT_STATE
};
