import './CheckboxComponent.scss';
import { useState, useEffect, useCallback, FC, useRef } from 'react';
import { CheckBoxState } from './model';
import { FilterLevel, FilterType, FilterVM } from '../../../common/view-model';
import { useDispatch, useSelector } from 'react-redux';
import { FilterActions } from '../../../redux/FilterActions';
import { RootState } from '../../../redux/AppReducer';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { v4 as uuidv4 } from 'uuid';
import { eventPublicPathName, publicProductDiscoveryActions } from '../../../common/constants';
import noChecked from '../../../assets/images/no-check.svg';
import hoverChecked from '../../../assets/images/check-hover.svg';
import checkedIcon from '../../../assets/images/check.svg';
import indeterminate from '../../../assets/images/indeternimate.svg';
import { useNavigate } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';

export interface ICheckboxComponentProps {
	checked: boolean;
	label?: string;
	children?: any[];
	parentIndex?: string;
	index?: number;
	style?: string;
	level?: FilterLevel;
	type?: FilterType;
	parentId?: string;
	parentLength: number;
	mainMenuId?: string;
	checkBoxGroupId?: string;
	categoryName?: string;
	carrotIconRef?: string;
	isSuperCategory?: boolean;
	breadcrumText?: string;
	checkBoxDOMRef?: string;
	isLocked?: boolean;
	setLockModalContent?: () => void;
	registerUserClickEvent?: (event: string) => void;
}
export const CheckboxComponent: FC<ICheckboxComponentProps> = ({
	checked,
	label,
	children,
	parentIndex,
	index,
	style,
	level,
	type,
	parentId,
	parentLength,
	mainMenuId,
	checkBoxGroupId,
	categoryName,
	carrotIconRef,
	isSuperCategory,
	breadcrumText,
	checkBoxDOMRef = `checklevel${uuidv4()}`,
	isLocked,
	setLockModalContent,
	registerUserClickEvent
}) => {
	const navigate = useNavigate();
	const [iconUrl, setIconUrl] = useState(checked ? checkedIcon : noChecked);
	const checkBoxContainerRef = useRef<HTMLDivElement>(null);
	const FULL_SIZE_SCREEN_WIDTH = 768;
	const { width } = useWindowSize();
	const isMobile = width < FULL_SIZE_SCREEN_WIDTH;
	const dispatch = useDispatch();
	const selectedFilters = useSelector(
		(state: RootState) => state.appState.filterState?.selectedFilters
	);
	const addFilterStateCallback = useCallback(
		() => dispatch(FilterActions.addFilterState({ parentIndex, state, setState })),
		[dispatch]
	);
	if (selectedFilters) {
		const elementExists =
			selectedFilters.find(x => x.id === parentIndex && x.name === label) !== undefined;
		if (elementExists) {
			checked = elementExists ? elementExists : checked;
		}
	}
	const [state, setState] = useState({
		...CheckBoxState,
		checkProperties: {
			checked,
			label,
			children,
			parentIndex,
			index,
			style,
			level,
			type,
			parentId,
			parentLength,
			mainMenuId,
			checkBoxGroupId,
			categoryName,
			carrotIconRef,
			isSuperCategory,
			breadcrumText,
			checkBoxDOMRef
		}
	});
	const handleDispacth = (event: any) => {
		if (isLocked) {
			setLockModalContent && setLockModalContent();
			registerUserClickEvent &&
				registerUserClickEvent(publicProductDiscoveryActions.CLICK_FILTER_CHECKBOX);
			return;
		}
		const isCheck = event.target.checked;
		const filter: FilterVM = {
			name: label,
			id: parentIndex,
			isChecked: isCheck,
			children,
			level,
			index,
			type,
			state,
			setState,
			parentId,
			parentLength,
			mainMenuId,
			checkBoxGroupId,
			categoryName,
			carrotIconRef,
			isSuperCategory,
			breadcrumText,
			checkBoxDOMRef
		};
		if (isCheck) {
			dispatch(FilterActions.addFilter(filter));
		} else {
			if (selectedFilters?.length === 1) {
				dispatch(FilterActions.removeFilter(filter, isMobile));
				navigate(isCurrentUserAnonymous() ? `${eventPublicPathName}/products` : '/products');
			} else {
				dispatch(FilterActions.removeFilter(filter, isMobile));
			}
		}
	};
	const validateIndeterminate = useCallback(() => {
		let result = false;
		if (level === FilterLevel.THIRD) {
			const existsParent = selectedFilters?.find(x => x.id === parentIndex) !== undefined;
			const elementByParentCount = selectedFilters?.filter(
				x => x.parentId === parentIndex && x.id !== parentIndex
			).length;
			result = elementByParentCount !== 0 && elementByParentCount! < parentLength && !existsParent;
		}
		return result;
	}, [selectedFilters, parentIndex, parentLength, level]);
	useEffect(() => {
		setState({ ...state, checkProperties: { ...state.checkProperties, checked } });
	}, [checked]);
	useEffect(() => {
		addFilterStateCallback();
	}, []);
	useEffect(() => {
		if (validateIndeterminate()) {
			const indeterminateElement = document.getElementById(checkBoxDOMRef) as HTMLInputElement;
			indeterminateElement.indeterminate = true;
		} else {
			const indeterminateElement = document.getElementById(checkBoxDOMRef) as HTMLInputElement;
			indeterminateElement.indeterminate = false;
		}
	}, [selectedFilters]);
	useEffect(() => {
		if (selectedFilters) {
			const lastElement = selectedFilters[selectedFilters?.length - 1];
			if (lastElement && lastElement.isExternalFilter && lastElement.id === parentIndex) {
				const carrotIcon = document.getElementById(carrotIconRef!);
				const checkGroup = document.getElementById(checkBoxGroupId!);
				carrotIcon?.classList.toggle('carrot-icon-open');
				checkGroup?.classList.toggle('filter-active');
			}
		}
	}, [selectedFilters]);
	useEffect(() => {
		if (!checkBoxContainerRef.current) return;

		const checkBoxContainer = checkBoxContainerRef.current;
		const updateIcon = () => {
			if (validateIndeterminate()) {
				setIconUrl(indeterminate);
			} else if (checked) {
				setIconUrl(checkedIcon);
			} else {
				setIconUrl(noChecked);
			}
		};

		const handleMouseOver = () => {
			if (!checkBoxContainerRef.current) return;
			if (validateIndeterminate()) {
				setIconUrl(indeterminate);
			} else if (checked) {
				setIconUrl(checkedIcon);
			} else {
				setIconUrl(hoverChecked);
			}
		};

		const handleMouseLeave = () => {
			if (!checkBoxContainerRef.current) return;
			updateIcon();
		};

		updateIcon();

		checkBoxContainer.addEventListener('mouseover', handleMouseOver);
		checkBoxContainer.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			checkBoxContainer.removeEventListener('mouseover', handleMouseOver);
			checkBoxContainer.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [checked, validateIndeterminate]);

	return (
		<div className='check-container' ref={checkBoxContainerRef}>
			<img
				className='check-icon'
				src={iconUrl}
				alt='check'
				onClick={() =>
					handleDispacth({
						target: {
							checked: !state.checkProperties.checked
						}
					})
				}
			/>
			<input
				data-testid='checkbox-component'
				type='checkbox'
				value={state.checkProperties.label}
				checked={state.checkProperties.checked}
				onChange={handleDispacth}
				id={checkBoxDOMRef}
			/>
			<label
				className={`check-label ${children ? 'parent-label' : ''}${
					state.checkProperties.checked ? ' selected' : ''
				}`}
				htmlFor={checkBoxDOMRef}
			>
				{state.checkProperties.label}
			</label>
		</div>
	);
};
