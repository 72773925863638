import { FC, Fragment, useEffect, useState } from 'react';
import { contactInformationModel as model } from './model';
import './ContactInformationComponent.scss';
import { Broker } from '../../../../common/model';

interface IContactInformationItemComponentProps {
	name?: string;
	email?: string;
	phone?: string;
}
const ContactInformationItemComponent: FC<IContactInformationItemComponentProps> = ({
	name,
	email,
	phone
}) => {
	return (
		<div className='contact-information-item-container'>
			<div className='contact-name-container'>
				<i className='ri-user-line'></i>
				<span className='contact-name'>{name}</span>
			</div>
			<div className='contact-email-container'>
				<i className='ri-mail-line'></i>
				<span className='contact-email'>
					<a href={`mailto:${email}`} target='blank'>
						{email}{' '}
					</a>
				</span>
			</div>
			<div className='contact-phone-container'>
				<i className='ri-phone-line'></i>
				<span className='contact-phone'>{phone}</span>
			</div>
		</div>
	);
};

interface IContactInformationComponentProps {
	brokers: Broker[] | undefined;
	contact: Broker | undefined;
}

export const ContactInformationComponent: FC<IContactInformationComponentProps> = ({
	brokers,
	contact
}) => {
	const [requestSampleContact, setRequestSampleContact] = useState(
		brokers?.find(broker => broker?.name && (broker?.email || broker?.phone)) || {
			name: contact?.name,
			email: contact?.email,
			phone: contact?.phone
		}
	);
	const validateShowContact = (contact: IContactInformationItemComponentProps) =>
		contact?.name && (contact.email || contact.phone);
	useEffect(() => {
		setRequestSampleContact(
			brokers?.find(broker => broker?.name && (broker?.email || broker?.phone)) || {
				name: contact?.name,
				email: contact?.email,
				phone: contact?.phone
			}
		);
	}, [brokers, contact?.email, contact?.name, contact?.phone]);
	return (
		<div className='contact-information-container'>
			{validateShowContact({
				name: contact?.name,
				email: contact?.email,
				phone: contact?.phone
			}) && (
				<div className='brand-contact-container'>
					<div className='title-container'>
						<span className='title'>{model.contactBrandTitle}</span>
					</div>
					<div className='brand-content-container'>
						<ContactInformationItemComponent {...contact} />
					</div>
					<div className='separator'></div>
				</div>
			)}
			{brokers?.length !== 0 && (
				<div className='broker-container'>
					<div className='title-container'>
						<span className='title'>{model.brokerBrandTitle}</span>
					</div>
					<div className='broker-list-container'>
						{brokers?.map((broker, index) => (
							<Fragment key={index}>
								{validateShowContact(broker as IContactInformationItemComponentProps) && (
									<ContactInformationItemComponent {...broker} />
								)}
							</Fragment>
						))}
					</div>
				</div>
			)}
			{validateShowContact(requestSampleContact) && (
				<div className='request-sample-container'>
					<div className='title-container'>
						<span className='title'>{model.requestSampleTitle}</span>
					</div>
					<div className='request-sample-content-container'>
						<ContactInformationItemComponent {...requestSampleContact} />
					</div>
					<div className='separator last-item'></div>
				</div>
			)}
		</div>
	);
};
