const DEFAULT_STATE: IWhatIsNewSectionState = {
	title: 'Find the latest products on Beacon Discovery!',
	newToMarket: {
		title: 'New to the Market',
		content:
			'Stay in the know with these products that have made their debut in the market within the last year.',
		buttonText: 'Discover New To Market'
	},
	newToBeacon: {
		title: 'New to Beacon',
		content:
			'Discover our freshest arrivals - products that have joined the Beacon family within the last 30 days.',
		buttonText: 'Discover New To Beacon'
	}
};

interface IWhatIsNewSectionState {
	title: string;
	newToMarket: IWhatIsNewItem;
	newToBeacon: IWhatIsNewItem;
}
interface IWhatIsNewItem {
	title: string;
	content: string;
	buttonText: string;
}
export const WhatIsNewSectionModel = {
	...DEFAULT_STATE
};
