import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';
import {
	IirisIdentityKey,
	getIirisCookie,
	getLocalStorageValue
} from '../helpers/authenticationHelper';

const graphQLEndpoint = new HttpLink({ uri: `${process.env.REACT_APP_BEACON_API_URL}/graphql` });

const auhtMiddleware = new ApolloLink((operation, forward) => {
	operation.setContext({
		headers: {
			authorization: getIirisCookie() || '',
			email: getLocalStorageValue(IirisIdentityKey.USER_EMAIL) || ''
		}
	});
	return forward(operation);
});

export const apolloClient = new ApolloClient({
	link: concat(auhtMiddleware, graphQLEndpoint),
	cache: new InMemoryCache({ addTypename: false })
});
