import { gql } from 'graphql.macro';

export const GET_PRODUCT = gql`
	query GetProduct($id: String!, $expoEventId: String) {
		product(id: $id, expoEventId: $expoEventId) {
			id
			name
			description
			imageUrl
			sku_upc_gtin
			netContent
			allergens
			launchYear
			manufacturerSuggestedRetailPrice
			marketReady
			shelfLife
			images {
				side
				mainUrl
				thumbnailUrl
			}
			howGoodScore {
				score
				show_howgood_score
				category_average
				source_retail_raiting
			}
			brand {
				id
				name
				contact_name
				contact_email
				contact_phone
				contact_title
				how_its_made
				map_policy
				brand_mission
				brand_story
				regionsBydistributor {
					distributor_id
					distributor_name
					region_id
					region_name
				}
				processingTechniques {
					id
					name
					description
				}
				brokers {
					name
					phone
					email
				}
				current_retail_channels
				boothNumber
				public_url
				url_logo
			}
			ingredientsList
			taxonomy {
				id
				name
			}
			certifications {
				id
				name
				description
				imageUrl
				certifiers {
					id
					name
					description
					learnMore
				}
			}
			dimensions {
				width
				height
				depth
				weight
			}
			spinsMetrics {
				dollars
				productUnits
				distribution
				velocity
			}
			isNextyFinalist
			isNotForSaleInUS
		}
	}
`;
