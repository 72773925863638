import { APP_NAME } from '../../../common/constants';

const DEAFAULT_STATE: IWelcomeComponentState = {
	stepText: 'Step 1 of 2',
	pageTitle: `Welcome to ${APP_NAME}!`,
	meetStandarText: `Every product on ${APP_NAME} meets New Hope Network Ingredient and Expo Standards.`,
	standarsExplanationText: `These Standards help ensure that products presented on ${APP_NAME} or our events meet basic eligibility criteria related to formulation, transparency and ethical practices that help define and safeguard the natural and organic products industry.`
};

interface IWelcomeComponentState {
	stepText: string;
	pageTitle: string;
	meetStandarText: string;
	standarsExplanationText: string;
}
export const WelcomeComponentState = {
	...DEAFAULT_STATE
};
