import { FC, useEffect, useState } from 'react';
import { RootState } from '../../../redux/AppReducer';
import { connect, useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppActions } from '../../../redux/AppActions';
import { DEFAULT_LABELS, DEFAULT_STATE } from './model';
import logoIcon from '../../../assets/images/unsubscribe/beacon-logo.png';
import './UserUnsubscribe.scss';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { UserActions } from '../../../redux/UserActions';
import { useSelector } from 'react-redux';
import BeaconFooter from '../../BeaconFooter';

export interface IUserMenuItemProps {
	setStandAloneLayout: (value: boolean) => void;
	getAnonymousUserToken: () => void;
	setShowBackToTop: (value: boolean) => void;
}

export interface IReasonsUnsubscribe {
	label: string;
	reason: string;
	key: number;
}

const UserUnsubscribe: FC<IUserMenuItemProps> = ({
	setStandAloneLayout,
	getAnonymousUserToken,
	setShowBackToTop
}) => {
	const [reason, setReason] = useState<string>('');
	const [otherText, setOtherText] = useState<string>('');
	const { email } = useParams();
	const { isLoadingData, errorState } = useSelector((state: RootState) => state.appState);
	const subscribeAlertsStatus = useSelector(
		(state: RootState) => state.appState.userState?.subscribeAlertsStatus
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const reasons: Array<IReasonsUnsubscribe> = [
		{
			label: DEFAULT_LABELS.EMAIL_FREQUENTLY.label,
			reason: DEFAULT_LABELS.EMAIL_FREQUENTLY.reason,
			key: 1
		},
		{
			label: DEFAULT_LABELS.NEVER_SIGNUP.label,
			reason: DEFAULT_LABELS.NEVER_SIGNUP.reason,
			key: 2
		},
		{
			label: DEFAULT_LABELS.CONTENT.label,
			reason: DEFAULT_LABELS.CONTENT.reason,
			key: 3
		},
		{
			label: DEFAULT_LABELS.OTHER.label,
			reason: DEFAULT_LABELS.OTHER.reason,
			key: 3
		}
	];

	const checkOtherSelection = () => {
		return reason === DEFAULT_LABELS.OTHER.reason ? false : true;
	};

	const handleChangeReason = (reason: string) => {
		setReason(reason);
		if (reason !== DEFAULT_LABELS.OTHER.reason) {
			setOtherText('');
		}
	};

	const checkReasonSelection = () => {
		if (reason === DEFAULT_LABELS.OTHER.reason) {
			if (otherText === '') {
				return true;
			}
		} else {
			if (reason === '') {
				return true;
			}
			return false;
		}
	};

	const handleUnsubscribe = () => {
		let sendReason = reason === DEFAULT_LABELS.OTHER.reason ? otherText : reason;
		dispatch(
			UserActions.updateUserUnsubscribe({
				email: email,
				reason: sendReason
			})
		);
	};

	const handleSubscribe = () => {
		dispatch(
			UserActions.updateUserSubscribe({
				email: email
			})
		);
	};

	useEffect(() => {
		if (subscribeAlertsStatus === true) {
			setTimeout(() => {
				navigate('/landing');
			}, 1000);
		}
	}, [subscribeAlertsStatus]);

	useEffect(() => {
		setStandAloneLayout(true);
		getAnonymousUserToken();
		setShowBackToTop(false);
	}, []);

	return (
		<>
			<div className='unsubscribe-container'>
				<div className='head-container'>
					<img src={logoIcon} alt='' />
					<h1 className='title-unsubscribe'>{DEFAULT_STATE.header_text}</h1>
				</div>

				<div className='options-container'>
					<h2 className='subheader-text'>{DEFAULT_STATE.subheader_text}</h2>
					{reasons?.map((item, index) =>
						item.reason !== DEFAULT_LABELS.OTHER.reason ? (
							<label className='radio-container' key={index}>
								{item.label}
								<input
									type='radio'
									value={item.reason}
									name='reasons'
									checked={item.reason === reason}
									onClick={() => {
										handleChangeReason(item.reason);
									}}
									readOnly
								/>
								<span className='checkmark'></span>
							</label>
						) : (
							<div className='other-container' key={index}>
								<label className='radio-container'>
									{item.label}
									<input
										type='radio'
										value={item.reason}
										name='reasons'
										checked={item.reason === reason}
										onChange={() => {
											handleChangeReason(item.reason);
										}}
										readOnly
									/>
									<span className='checkmark'></span>
								</label>
								<input
									type='text'
									className='form-control'
									placeholder={DEFAULT_STATE.placeholder_button_active}
									value={otherText}
									disabled={checkOtherSelection()}
									onChange={event => setOtherText(event.currentTarget.value)}
								/>
							</div>
						)
					)}
				</div>

				<div className='actions-container'>
					<ButtonComponent
						text={DEFAULT_STATE.action_button_text}
						onClick={handleUnsubscribe}
						style={'rounded-button primary'}
						disabled={checkReasonSelection()}
					/>
					<p className='footer-text'>
						{DEFAULT_STATE.footer_text_initial}
						<a onClick={handleSubscribe}>{DEFAULT_STATE.link_footer_text}</a>
						{DEFAULT_STATE.footer_text_secondary}
					</p>
				</div>
			</div>
			<div className='wave'></div>
			<BeaconFooter isLoadingData={isLoadingData} hasError={errorState?.hasError} />
		</>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setStandAloneLayout: (isStandAloneLayout: boolean) => {
		dispatch(AppActions.setStandAloneLayout(isStandAloneLayout));
	},
	getAnonymousUserToken: () => dispatch({ type: UserActions.GET_ANONYMOUS_USER_TOKEN }),
	setShowBackToTop: (showButton: boolean) => {
		dispatch(AppActions.updateShowBackToTop(showButton));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserUnsubscribe);
