import { RefObject, useEffect } from 'react';

export const useAnimationEffect = (imageRefs: RefObject<HTMLImageElement>[]) => {
	useEffect(() => {
		const handleAnimationEnd = (index: number) => {
			const nextIndex = (index + 1) % imageRefs.length;
			imageRefs[index].current?.classList.remove('animate');
			imageRefs[nextIndex].current?.classList.add('animate');
		};

		const startAnimation = (index: number) => {
			if (!imageRefs[index].current) return;
			imageRefs[index].current?.classList.add('animate');
			imageRefs[index].current?.addEventListener('animationend', () => handleAnimationEnd(index));
		};

		let timeout = 0;
		for (let i = 0; i < imageRefs.length; i++) {
			setTimeout(() => startAnimation(i), timeout);
			timeout += 1500;
		}

		return () => {
			imageRefs.forEach((ref, index) => {
				if (ref.current) {
					ref.current.removeEventListener('animationend', () => handleAnimationEnd(index));
				}
			});
		};
	}, [imageRefs]);
};
