import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { redirectUrl } from '../common/constants';

interface IPrivateRouteProps {
	isLogged: boolean | undefined;
	hasCompanyDetails?: boolean;
}

export const PrivateRoute: FC<IPrivateRouteProps> = ({ isLogged, hasCompanyDetails }) => {
	if (isLogged) {
		localStorage.removeItem(redirectUrl);
		if (!hasCompanyDetails && window.location.pathname !== '/company') {
			return <Navigate to='/company' />;
		} else {
			return <Outlet />;
		}
	} else {
		localStorage.setItem(redirectUrl, window.location.href);
		return <Navigate to='/' />;
	}
};
