const DEFAULT_STATE: IHideFilterState = {
	filterText: 'Filters',
	hideFilterText: 'Hide Filters',
	mobileFilterText: 'All Filters'
};
interface IHideFilterState {
	filterText: string;
	hideFilterText: string;
	mobileFilterText: string;
}
export const hideFilterModel = {
	...DEFAULT_STATE
};
