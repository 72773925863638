import './index.scss';
import { FooterState } from './model';
import { useState } from 'react';
import BeaconFooter from '../../BeaconFooter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
export default function Footer() {
	const [state] = useState(FooterState);
	const options = state.getOptions(state.baseUrl);
	const isMobileFilterOpen = useSelector(
		(state: RootState) => state.appState.filterState?.isMobileFilterOpen
	);
	const { isLoadingData, errorState } = useSelector((state: RootState) => state.appState);
	return (
		<>
			{!isMobileFilterOpen && (
				<div data-testid='footer' className='footer'>
					<BeaconFooter isLoadingData={isLoadingData} hasError={errorState?.hasError} />
					<footer className='l-footer l-footer-min'>
						<div className='l-corporate-footer'>
							<div className='l-corporate-footer-logo-copyrights-wrapper'>
								<div className='l-corporate-footer-logo-copyrights'>
									<div className='l-corporate-footer-logo'>
										<a href='https://www.informamarkets.com/' target='blank'>
											<img src={state.logoUrl} alt='corporate-logo' />
										</a>
									</div>
									<div className='l-coporate-footer-copyright'>{state.copyright}</div>
								</div>
							</div>
							<div className='l-corporate-footer-links-wrapper'>
								<div className='l-coporate-footer-links'>
									<ul>
										{options.map((option, index) => {
											return (
												<li key={index}>
													<a href={option.href}>{option.label}</a>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</footer>
				</div>
			)}
		</>
	);
}
