import { FC } from 'react';
import './SaveTemplateStickyComponent.scss';
import { IngredientsTemplate } from '../../../common/view-model';
import { SaveTemplateStickyModel as model } from './model';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';

interface ISaveTemplateStickyComponentProps {
	currentTemplate: IngredientsTemplate;
	saveTemplate: () => void;
	show: boolean;
}

export const SaveTemplateStickyComponent: FC<ISaveTemplateStickyComponentProps> = ({
	currentTemplate,
	saveTemplate,
	show
}) => {
	return (
		<div className={`save-template-sticky-container ${!show ? 'hidden' : ''}`}>
			<div className='selected-template-container'>
				<span className='current-template-text'>{currentTemplate.name}</span>
			</div>
			<div className='save-btn-container'>
				<ButtonComponent
					style='rounded-button primary'
					text={model.saveBtnText}
					onClick={saveTemplate}
				/>
			</div>
		</div>
	);
};
