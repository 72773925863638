import { useLocation } from 'react-router-dom';
import { IFiltersUrlParameters, ISuggestion } from '../common/view-model';
import { useEffect } from 'react';
import { searchTermIndentifier } from '../helpers/paramsUrlIndetifiers';
import { getParametersKeys } from '../helpers/urlParametersHelpers';

export const useGetUrlParameters = (
	setSearchTermCallback: (searchTerm: ISuggestion) => void,
	setSelectedFiltersCallback: (selectedFilters: IFiltersUrlParameters) => void,
	hasMappedAllFilters: boolean,
	isBrandLoaded: boolean
) => {
	const location = useLocation();
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const filterTypes = getParametersKeys();
		let selectedFiltersFromUrl: IFiltersUrlParameters = {};

		const searchTerm = params.get(searchTermIndentifier);
		for (const key in filterTypes) {
			if (key) {
				const values = params.get(key);
				if (values) {
					selectedFiltersFromUrl = {
						...selectedFiltersFromUrl,
						[key.toUpperCase()]: values.split(',')
					};
				}
			}
		}
		if (searchTerm) {
			setSearchTermCallback(JSON.parse(searchTerm));
		}

		if (Object.keys(selectedFiltersFromUrl) && hasMappedAllFilters && isBrandLoaded) {
			setSelectedFiltersCallback(selectedFiltersFromUrl);
		}
	}, [
		location,
		setSearchTermCallback,
		setSelectedFiltersCallback,
		hasMappedAllFilters,
		isBrandLoaded
	]);
};
