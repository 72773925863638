import { ITrackingEvent, TrackingEventStatus } from '../common/view-model';
import { v4 as uuidv4 } from 'uuid';
import { IirisIdentityKey, getLocalStorageValue } from './authenticationHelper';

export interface ITrackingMenuEvent {
	type: string;
	user: {
		id: string;
		type: string;
	};
	event: {
		id: string;
		name: string;
		type: string;
	};
}

export const buildTrackingBobPayload = (payload: ITrackingMenuEvent) => {
	const trackingEvent: ITrackingEvent = {
		transactionId: uuidv4(),
		status: TrackingEventStatus.SUCCESS,
		type: payload.type,
		timeStamp: new Date().toISOString(),
		entityId: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
		eventPayload: {
			id: `${payload.event.id}-${new Date().toISOString()}`,
			name: `${payload.event.name}`,
			type: `${payload.event.type}`,
			url: window.location.href,
			tags: [],
			metadata: {
				user_id: payload.user.id,
				user_type: payload.user.type,
				enviroment: process.env.REACT_APP_ENV_NAME
			}
		}
	};
	return trackingEvent;
};
