export const DEFAULT_LABELS = {
	EMAIL_FREQUENTLY: {
		label: 'Emails are sent too frequently.',
		reason: 'Emails are sent too frequently.'
	},
	NEVER_SIGNUP: {
		label: 'I never signed up for this mailing list.',
		reason: 'I never signed up for this mailing list.'
	},
	CONTENT: {
		label: 'The content is not relevant for me.',
		reason: 'The content is not relevant for me.'
	},
	OTHER: {
		label: 'Other:',
		reason: 'OTHER'
	}
};

export const DEFAULT_STATE = {
	header_text: 'You’ve been unsubscribed from Beacon Discovery product alert emails.',
	subheader_text: 'Please tell us why you unsubscribed:',
	action_button_text: 'Submit',
	footer_text_initial: 'If this was a mistake, you can ',
	footer_text_secondary: ' at any time.',
	link_footer_text: 'resubscribe',
	placeholder_button_active: 'Provide your response here.'
};
