import React, { ReactNode, useState } from 'react';
import './LabelForms.scss';
import TooltipFormComponent from '../TooltipForm/TooltipFormComponent';
import TooltipComponent from '../../../UI/TooltipComponent/TooltipComponent';
interface ILabelForms {
	text: string;
	icon?: any;
	iconPosition?: 'start' | 'end';
	toolTipText?: React.ReactElement;
	toolTipPosition?: string;
	classname?: string;
	isMobile?: boolean;
}
const LabelForms: React.FC<ILabelForms> = ({
	text,
	icon,
	iconPosition,
	toolTipText,
	toolTipPosition,
	isMobile,
	classname
}) => {
	return (
		<>
			{iconPosition === 'start' ? (
				<div className='label-container'>
					<label>
						{icon}
						{text}
					</label>
				</div>
			) : (
				<div className='label-container '>
					<label>
						{text}
						{icon && (
							<TooltipFormComponent text={toolTipText || <></>}>{icon}</TooltipFormComponent>
						)}
						{/* <TooltipComponent
							
							text={toolTipText}
							position={isMobile ? 'left' : toolTipPosition}
							classes='tooltip-content-center'>
							{icon}
						</TooltipComponent> */}
					</label>
				</div>
			)}
		</>
	);
};

export default React.memo(LabelForms);
