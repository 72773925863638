import { PROFILE_ROUTES } from '../../common/constants';
import './index.scss';
import { BeaconFooterState, HelpLinks } from './model';
import { useEffect, useState } from 'react';
export default function BeaconFooter(props: any) {
	const { isLoadingData, hasError } = props;
	const [state] = useState(BeaconFooterState);
	const partners = state.getPartners();
	const services = state.getServices();
	const pagesUsingWave = ['brand', PROFILE_ROUTES.PROFILE, PROFILE_ROUTES.FAVORITES, PROFILE_ROUTES.GET_ON_BEACON, PROFILE_ROUTES.ONBOARD_FAQ, PROFILE_ROUTES.INGREDIENTS, PROFILE_ROUTES.PRODUCT_INFO];
	const [showWave, setShowWave] = useState(
		pagesUsingWave.some(page => window.location.pathname.includes(page))
	);
	useEffect(() => {
		setShowWave(pagesUsingWave.some(page => window.location.pathname.includes(page)));
	}, [window.location.pathname]);
	return (
		<>
			{showWave && !isLoadingData && !hasError && <div data-testid='wave' className='wave'></div>}
			<footer data-testid='beacon-footer' className='beacon-footer'>
				<div className='beacon-footer-sub'>
					<div className='partners'>
						<div className='partners-label'>Our Partners</div>
						<div className='partner-wrap'>
							{partners.map((partner, index: number) => (
								<div className='partner' key={index}>
									{partner.label && <div className='partner-label'>{partner.label}</div>}
									<div className='partner-logo'>
										<img
											src={partner.logoUrl}
											style={{ width: partner.logoWidth, height: partner.logoHeight }}
											alt=''
										/>
									</div>

									<div className='partner-text'>
										<a href={partner.href} target='_blank' rel='noreferrer'>
											{partner.text}{' '}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className='services'>
						<div className='services-wrap'>
							<div className='services-label'>Our Services</div>
							{services.map((service, index: number) => (
								<div className='service' key={index}>
									<div className='service-label'></div>
									<div className='service-logo'>
										<img
											src={service.logoUrl}
											alt=''
											style={{ width: service.logoWidth, height: service.logoHeight }}
										/>
									</div>
									<div className='service-text'>
										<a href={service.href} target='_blank' rel='noreferrer'>
											{service.text}{' '}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='footer-help-links'>
					{HelpLinks.map((link, index: number) => (
						<div className={`link-item ${link.className}`} key={index}>
							<a href={`${link.link}`} target='blank'>
								{link.text}
							</a>
						</div>
					))}
				</div>
			</footer>
		</>
	);
}
