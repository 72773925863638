import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GET_BRAND } from '../../graphql/queries/brand';
import { AppState, ErrorState } from '../../redux/AppReducer';
import { BrandVM } from '../../common/view-model';
import { Dispatch, FC, useEffect, useState } from 'react';
import { mapBrandAttributes } from '../../components/brand-profile/model';
import BrandProfileComponent from '../../components/brand-profile/BrandProfileComponent';
import { Action } from 'redux';
import { AppActions } from '../../redux/AppActions';
import { serverError } from '../../common/constants';

const BrandDatail: FC = (props: any) => {
	const { brandId } = useParams();
	const [brand, setBrand] = useState<BrandVM | undefined>();
	const currentEvent = props.appState.storeState?.getCurrentEvent;
	const { data, loading, error } = useQuery<any>(GET_BRAND, {
		variables: { id: brandId, expoEventId: currentEvent?.id },
		skip: currentEvent === undefined
	});
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (!error && !loading && data?.brand) {
			const { __typename, ...rest } = data.brand;
			setBrand(mapBrandAttributes(rest));
		}
		if (error?.message === 'Unauthorized') {
			props.handleUnauthorized();
		}
		if (error) {
			props.handleError(serverError);
		}
	}, [data, error, loading]);
	return <>{brand && <BrandProfileComponent brand={brand} />}</>;
};

const mapStateToProps = (state: AppState) => ({
	...state
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleUnauthorized: () => {
		return dispatch(AppActions.unauthorizedError());
	},
	handleError: (error: ErrorState) => {
		return dispatch(AppActions.setGeneralError(error));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandDatail);
