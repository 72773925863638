import { FC, useCallback } from 'react';
import { Product } from '../../../common/model';
import './FavoriteHeaderComponent.scss';
import { UserActions } from '../../../redux/UserActions';
import { useDispatch } from 'react-redux';
import SortProductsComponent from '../../sortProducts/SortProductsComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { FilterActions } from '../../../redux/FilterActions';
import { ISortByOption } from '../../../common/view-model';

interface IFavoriteHeaderComponentProps {
	products: Product[];
	isLoading?: boolean;
}

export const FavoriteHeaderComponent: FC<IFavoriteHeaderComponentProps> = ({
	products,
	isLoading
}) => {
	const dispatch = useDispatch();
	const downloadFavoritesCsv = () => {
		if (products.length > 0) {
			dispatch({ type: UserActions.DOWNLOAD_FAVORITE_PRODUCTS });
		}
	};
	const { favoriteSortByOptions } = useSelector((state: RootState) => state.appState.filterState!);
	const onSortChange = useCallback(
		(option: ISortByOption) => {
			return dispatch(FilterActions.setFavoriteSortBy(option));
		},
		[dispatch]
	);
	return (
		<>
			<div data-testid='favorite-header-container' className='favorites-container'>
				<span className='title'>Your Favorite Products ({isLoading ? 0 : products.length}) </span>
				<button
					data-testid='export-cvs'
					className={products.length > 0 ? 'export-csv' : 'export-csv disabled'}
					onClick={downloadFavoritesCsv}
				>
					Export as CSV
					<i className='ri-file-line'></i>
				</button>
			</div>
			<div className='favorite-seperator'></div>
			<div className='favorite-filter-sort-container'>
				<div className='favorite-filter-container'></div>
				<div className='favorite-sort-container'>
					<SortProductsComponent
						availableSorts={favoriteSortByOptions}
						isFavoritesPage={true}
						setSortBy={onSortChange}
					/>
				</div>
			</div>
		</>
	);
};
