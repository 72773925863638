import React from 'react';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import './StandardsApplicationComponent.scss';
import { useState } from 'react';
import { DEFAULT_LABELS } from './model';
interface IStandardsApplication {
	brandState: BrandInfo;
}
const StandardsApplicationComponent: React.FC<IStandardsApplication> = ({ brandState }) => {
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	return (
		<>
			<TitleForms
				isVisible={isVisible}
				titleText='Standards Application Information'
				toggleVisibility={toggleVisibility}
				readOnly
			></TitleForms>
			{isVisible && (
				<>
					<p className='description-form'>
						Only the Contact listed here has access to view and edit brand or product information.
						To update this contact, please email our Audience Success Team at
						<a href='mailto:audiencesuccessteam@newhope.com' target='_blank' rel='noreferrer'>
							{' '}
							audiencesuccessteam@newhope.com
						</a>
					</p>
					<ContainerForms
						FormComponent1={
							<div>
								<LabelForms text={DEFAULT_LABELS.accountPrimaryContactName}></LabelForms>{' '}
								<p className={brandState?.submittedContactName ? '' : 'blank-field'}>
									{brandState?.submittedContactName}
								</p>
							</div>
						}
						FormComponent2={
							<div>
								<LabelForms text={DEFAULT_LABELS.accountPrimaryContactEmail}></LabelForms>
								<p className={brandState?.submittedContactEmail ? '' : 'blank-field'}>
									{brandState?.submittedContactEmail}
								</p>
							</div>
						}
					></ContainerForms>
				</>
			)}
		</>
	);
};

export default React.memo(StandardsApplicationComponent);
