import { FC, useState } from 'react';
import './StepperComponent.scss';
import {
	IStepComponentProps,
	IStepperComponentProps,
	IStepperFooterComponentProps,
	IStepperHeaderComponentProps
} from './model';

const StepComponent: FC<IStepComponentProps> = ({
	indicator,
	label,
	index,
	isActive,
	isRightToLeftLanguage,
	currentTabIndex
}) => {
	return (
		<div
			className={`step-indicatior ${isActive ? 'step-active' : ''} ${
				isRightToLeftLanguage ? 'rightToLeft' : ''
			} ${currentTabIndex < index ? 'step-disabled' : 'step-enabled'}`}
		>
			<span className='indicatior-info'>
				{currentTabIndex > index ? (
					<svg
						width='12'
						height='10'
						viewBox='0 0 12 10'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M9.29493 1.79075L5.09986 5.98627L3.00395 3.89036L2.6504 3.53681L2.29684 3.89036L1.59713 4.59007L1.24358 4.94363L1.59713 5.29718L4.74633 8.44638L5.09988 8.79993L5.45343 8.44638L10.7023 3.19755L11.0559 2.84387L10.7021 2.49032L10.0019 1.79061L9.64835 1.43729L9.29493 1.79075Z'
							fill='white'
							stroke='white'
						/>
					</svg>
				) : (
					indicator
				)}
			</span>
			<div className={`stepper-label ${index >= 2 ? 'secondary-color' : 'primary-color'}`}>
				{label}
			</div>
		</div>
	);
};

const StepperHeaderComponent: FC<IStepperHeaderComponentProps> = ({
	stepperContent,
	isRightToLeftLanguage,
	currentTabIndex
}) => {
	return (
		<div className='stepper-head'>
			<div className='stepper-header-wrapper'>
				{stepperContent.map((step, index) => {
					return (
						<StepComponent
							key={index}
							indicator={`${index + 1}`}
							label={step.label}
							index={index}
							isActive={currentTabIndex === index}
							isRightToLeftLanguage={isRightToLeftLanguage}
							currentTabIndex={currentTabIndex}
						/>
					);
				})}
			</div>
		</div>
	);
};
const StepperFooterComponent: FC<IStepperFooterComponentProps> = ({
	previousStepHandler,
	nextStepHandler,
	currentTabIndex,
	stepperContent,
	footerTitle,
	saveContainerRef
}) => {
	return (
		<>
			{stepperContent[currentTabIndex]?.showFooter && (
				<div className={`stepper-footer ${currentTabIndex === 0 ? 'welcome-wrapper' : ''}`}>
					<div className='col'>
						<div className='row'>
							{currentTabIndex >= 1 && <span className='stepper-footer-tittle'>{footerTitle}</span>}
						</div>
						<div className='row'>
							<div className='buttons-section' ref={saveContainerRef}>
								{stepperContent[currentTabIndex]?.previousBtnLabel && (
									<span onClick={previousStepHandler} className='previous-btn'>
										{stepperContent[currentTabIndex].previousBtnLabel}
									</span>
								)}
								<button
									data-testid='stepper-next-btn'
									onClick={() => nextStepHandler()}
									className={`next-btn ${currentTabIndex === 0 ? 'welcome-btn' : ''}`}
								>
									{stepperContent[currentTabIndex]?.continueBtnLabel}
									{stepperContent[currentTabIndex]?.showArrowIcon && (
										<i className='ri-arrow-right-s-line'></i>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export const StepperComponent: FC<IStepperComponentProps> = ({
	stepperContent,
	footerTitle,
	saveContainerRef
}) => {
	const [currentTabIndex, setCurrentTabIndex] = useState(0),
		isLastStep = currentTabIndex === stepperContent.length - 1,
		isPrevBtn = currentTabIndex !== 0;
	const navigateToStepHandler = (index: number) => {
		if (index !== currentTabIndex) {
			setCurrentTabIndex(index);
		}
	};

	const nextStepHandler = () => {
		stepperContent?.[currentTabIndex]?.continueBtnFunction?.();
		setCurrentTabIndex((prev: any) => {
			if (prev !== stepperContent.length - 1) {
				return prev + 1;
			}
		});
	};

	const previousStepHandler = () => {
		setCurrentTabIndex(prev => prev - 1);
	};

	return (
		<div className='stepper-container'>
			<div className='col'>
				<div className='row'>
					<StepperHeaderComponent
						stepperContent={stepperContent}
						navigateToStepHandler={navigateToStepHandler}
						currentTabIndex={currentTabIndex}
						isRightToLeftLanguage={false}
					/>
				</div>
				<div className='content-container'>
					<div className='row'>
						<div className='stepper-body'>
							{stepperContent.map((step, index) => {
								return <div key={index}>{index === currentTabIndex && step.content}</div>;
							})}
						</div>
					</div>
					<div className='row'>
						<StepperFooterComponent
							isPrevBtn={isPrevBtn}
							previousStepHandler={previousStepHandler}
							isLastStep={isLastStep}
							nextStepHandler={nextStepHandler}
							currentTabIndex={currentTabIndex}
							stepperContent={stepperContent}
							footerTitle={footerTitle}
							saveContainerRef={saveContainerRef}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
