import './BrowseByCategoryComponent.scss';
import { FC } from 'react';
import { BrowseByCategoryModel as model } from './model';
import {
	FilterType,
	FilterVM,
	ITrackingEvent,
	TaxonomyVM,
	TrackingEventStatus
} from '../../common/view-model';
import { parentCategoryIdentifier } from '../../helpers/paramsUrlIndetifiers';
import { Link, NavigateFunction } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import { publicHomePageActions } from '../../common/constants';
import { UserState } from '../../redux/AppReducer';
import { v4 as uuidv4 } from 'uuid';
import { handleTracking } from '../../helpers/handelTracking';
interface IBrowseByCategoryItemComponentProps {
	taxonomy: TaxonomyVM;
	navigate: NavigateFunction;
	addFilter: (filter: FilterVM) => void;
	registerUserClickEvent: (event: string) => void;
	userState?: UserState;
}
const BrowseByCategoryItemComponent: FC<IBrowseByCategoryItemComponentProps> = ({
	taxonomy,
	navigate,
	addFilter,
	registerUserClickEvent,
	userState
}) => {
	const productsUrl = '/products';
	const allCategoriesId = 'all-categories';
	const imageName = taxonomy.name?.toLowerCase().replace(/\s/g, '');
	const imageSource = require(`../../assets/images/browse-by-category/${imageName}.png`);
	const navigateToCategory = () => {
		const trackingPayload: ITrackingEvent = {
			transactionId: uuidv4(),
			entityId: taxonomy.id!,
			status: TrackingEventStatus.SUCCESS,
			type: `Home page click on ${taxonomy.name}`,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: taxonomy.id!,
				name: taxonomy.name!,
				type: FilterType[FilterType.CATEGORY],
				url: window.location.href,
				tags: [],
				metadata: {
					user_type: userState?.user?.user_type,
					user_id: userState?.user?.id,
					environment: process.env.REACT_APP_ENV_NAME
				}
			}
		};

		handleTracking(trackingPayload);
		window.scrollTo(0, 0);
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicHomePageActions.CLICK_CATEGORY_BUBBLE);
		}
		if (taxonomy.id === allCategoriesId) {
			navigate(!isCurrentUserAnonymous() ? productsUrl : `.${productsUrl}`);
			return;
		}
		addFilter({ ...taxonomy, type: FilterType.CATEGORY, children: [] } as FilterVM);
		navigate(
			`${!isCurrentUserAnonymous() ? productsUrl : `.${productsUrl}`}?${parentCategoryIdentifier}=${
				taxonomy.id
			}`
		);
	};
	return (
		<div className='category-item' onClick={navigateToCategory}>
			<div className='category-img-container'>
				<img className={`browse-by-img ${imageName}`} src={imageSource} alt='browse-by' />
			</div>
			<div className='category-name-container'>
				<span className='category-name'>{taxonomy.name}</span>
			</div>
		</div>
	);
};
interface IBrowseByCategoryComponentProps {
	taxonomies?: TaxonomyVM[];
	navigate: NavigateFunction;
	addFilter: (filter: FilterVM) => void;
	registerUserClickEvent: (event: string) => void;
	userState?: UserState;
}

export const BrowseByCategoryComponent: FC<IBrowseByCategoryComponentProps> = ({
	taxonomies,
	navigate,
	addFilter,
	registerUserClickEvent,
	userState
}) => {
	const productsUrl = '/products';
	const handleNavigate = () => {
		const trackingPayload: ITrackingEvent = {
			transactionId: uuidv4(),
			entityId: model.title,
			status: TrackingEventStatus.SUCCESS,
			type: `Home page click on ${model.title}`,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: model.title,
				name: model.title,
				type: FilterType[FilterType.CATEGORY],
				url: window.location.href,
				tags: [],
				metadata: {
					user_type: userState?.user?.user_type,
					user_id: userState?.user?.id,
					environment: process.env.REACT_APP_ENV_NAME
				}
			}
		};
		handleTracking(trackingPayload);
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicHomePageActions.CLICK_ALL_CATEGORIES);
		}
		window.scrollTo(0, 0);
		navigate(!isCurrentUserAnonymous() ? productsUrl : `.${productsUrl}`);
	};
	return (
		<div className='browse-by-category'>
			<Link
				to={!isCurrentUserAnonymous() ? productsUrl : `.${productsUrl}`}
				className='title-container'
				onClick={handleNavigate}
			>
				<span className='title'>{model.title}</span>
				<i className='ri-arrow-right-line'></i>
			</Link>
			<div className='categories-container'>
				{taxonomies?.map((taxonomy, index) => (
					<BrowseByCategoryItemComponent
						key={index}
						taxonomy={taxonomy}
						navigate={navigate}
						addFilter={addFilter}
						registerUserClickEvent={registerUserClickEvent}
						userState={userState}
					/>
				))}
			</div>
		</div>
	);
};
