import { gql } from 'graphql.macro';

export const GET_HIGHLIGHTED_PRODUCTS = gql`
	query {
		productsHighlighted {
			products {
				id
				name
				imageUrl
				brand {
					name
					boothNumber
				}
				taxonomy {
					id
					name
				}
				howGoodScore {
					score
					show_howgood_score
					category_average
					source_retail_raiting
				}
			}
		}
	}
`;
