const DEFAULT_STATE: IPackagingState = {
	netContentText: 'Net content:',
	itemTypeText: 'Item type:',
	sizesText: 'Sizes:',
	dimensionsText: 'Dimensions:',
	noDimensionsText: 'No dimensions available.',
	shelfLifeText: 'Shelf Life:',
	notAvailableText: 'Not available'
};
interface IPackagingState {
	netContentText: string;
	itemTypeText: string;
	sizesText: string;
	dimensionsText: string;
	noDimensionsText: string;
	shelfLifeText: string;
	notAvailableText: string;
}
export const packagingState = {
	...DEFAULT_STATE
};
