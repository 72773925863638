import { FC } from 'react';
import './OnboardInfoComponent.scss';
interface IOnboardInfoComponentProps {
	title: string;
	children: JSX.Element;
	bigTitle?: boolean;
}

export const OnboardInfoComponent: FC<IOnboardInfoComponentProps> = ({
	title,
	children,
	bigTitle = false
}) => {
	return (
		<div className='header-container'>
			<div className='icon-container'>
				<i className='ri-error-warning-fill'></i>
			</div>
			<div className='onboard-content-container'>
				<div className='header-title-container'>
					<span className={bigTitle ? 'big-header-title' : 'header-title'}>{title}</span>
				</div>
				<div className='children-container'>{children}</div>
			</div>
		</div>
	);
};
