import { useEffect } from 'react';

export const useLoadTrackingScript = () => {
	useEffect(() => {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.addEventListener('load', function (event) {
			if (window.IIRISTracker) {
				var cfg: any = {};
				cfg.config = {};
				cfg.config.snowplow = {};
				cfg.config.idSource = process.env.REACT_APP_IRIS_TRACKING_ID_SOURCE;
				cfg.config.snowplow.appId = process.env.REACT_APP_IRIS_TRACKING_ID;
				cfg.config.snowplow.cDomain = process.env.REACT_APP_IRIS_TRACKING_CDOMAIN;
				cfg.config.td = {};
				cfg.config.td.lob = process.env.REACT_APP_IRIS_TRACKING_TD_LOB;
				cfg.config.td.domain = process.env.REACT_APP_IRIS_TRACKING_TD_DOMAIN;
				cfg.config.vi = {};
				cfg.config.vi.name = process.env.REACT_APP_IRIS_TRACKING_VI_NAME;
				window.IIRISTracker.initConfig(cfg);
				var irisEvt = new Event('trackerLoaded');
				window.dispatchEvent(irisEvt);
			}
		});
		script.src = process.env.REACT_APP_IRIS_TRACKING_SCRIPT_SRC!;
		document.getElementsByTagName('head')[0].appendChild(script);
		return () => {
			const existingScript = document.querySelector(
				`script[src="${process.env.REACT_APP_IRIS_TRACKING_SCRIPT_SRC!}"]`
			);
			if (existingScript) {
				existingScript.parentNode?.removeChild(existingScript);
			}
		};
	}, []);
};
