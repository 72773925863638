import {
	IngredientByPorpurse,
	IngredientCategory,
	IngredientsTemplate,
	LineTableComponent,
	Purporse
} from '../common/view-model';
export const getIngredientsLength = (
	index: string | number | undefined,
	templates: IngredientsTemplate[] | undefined
): number | undefined => {
	if (!index || !templates) {
		return undefined;
	}
	const selectedTemplate = templates.find(template => template.id === index.toString());
	return selectedTemplate?.ingredients?.length;
};
export const sortIngredientsTableView = (
	mappedPurposes: Purporse[],
	currentFilter?: IngredientCategory
) => {
	let tableIngredients: LineTableComponent[] = [];
	if (mappedPurposes) {
		mappedPurposes.forEach(purpose => {
			purpose.ingredients.forEach((ingredient: IngredientByPorpurse) => {
				if (!tableIngredients.find(x => x.displayName === ingredient.displayName)) {
					tableIngredients.push({
						usages: ingredient.usages,
						displayName: ingredient.displayName!,
						categories: ingredient.categories
					});
				}
			});
		});
	}
	return tableIngredients
		.filter(x => x.categories?.some(y => y.id === currentFilter?.id) || !currentFilter)
		.sort((a: LineTableComponent, b: LineTableComponent) => {
			if (a.displayName?.toLowerCase() < b.displayName?.toLowerCase()) {
				return -1;
			}
			if (a.displayName?.toLowerCase() > b.displayName?.toLowerCase()) {
				return 1;
			}
			return 0;
		});
};
export const mapPurposes = (
	index: number,
	templates: IngredientsTemplate[] | undefined,
	showSpecificToTamplate: boolean,
	viewMode: 'list' | 'table'
): Purporse[] | undefined => {
	let firstTemplateIngredients = templates![0].purpose.reduce((ingredients: any, purpose) => {
		return [...ingredients, ...purpose.ingredients.map(ingredient => ingredient.name)];
	}, []);

	let firstTemplate = templates![0].purpose.map(purpose => {
		return {
			...purpose,
			ingredients: purpose.ingredients.map(ingredient => ({
				...ingredient,
				color: 'blue'
			}))
		} as Purporse;
	});
	switch (index) {
		case 1:
			return firstTemplate;
		case 2:
			if (showSpecificToTamplate && viewMode === 'list') {
				let currentTemplate = templates![1].purpose.map(purpose => {
					return {
						...purpose,
						ingredients: purpose.ingredients
							.filter(ingredient => !firstTemplateIngredients.includes(ingredient.name))
							.map(ingredient => ({ ...ingredient, color: 'green' }))
					};
				});
				return currentTemplate;
			} else {
				let currentTemplate = templates![1].purpose.map(purpose => {
					return {
						...purpose,
						ingredients: purpose.ingredients
							.filter(ingredient => !firstTemplateIngredients.includes(ingredient.name))
							.map(ingredient => ({ ...ingredient, color: 'green' }))
					} as Purporse;
				});
				let result = [...firstTemplate];

				for (let purpose of currentTemplate) {
					let purposeIndex = result.findIndex(
						currentTemplatePurpose => purpose.name === currentTemplatePurpose.name
					);
					if (purposeIndex >= 0) {
						result[purposeIndex].ingredients.push(...purpose.ingredients);
					} else {
						result.push(purpose);
					}
				}

				return result;
			}
		case 3:
			let secondTemplateIngredients = templates![1].purpose.reduce((ingredients: any, purpose) => {
				return [...ingredients, ...purpose.ingredients.map(ingredient => ingredient.name)];
			}, []);

			if (showSpecificToTamplate && viewMode === 'list') {
				let currentTemplate = templates![2].purpose.map(purpose => {
					return {
						...purpose,
						ingredients: purpose.ingredients
							.filter(
								ingredient =>
									![...firstTemplateIngredients, ...secondTemplateIngredients].includes(
										ingredient.name
									)
							)
							.map(ingredient => ({ ...ingredient, color: 'yellow' }))
					};
				});
				return currentTemplate;
			} else {
				let secondTemplate = templates![1].purpose.map(purpose => {
					return {
						...purpose,
						ingredients: purpose.ingredients
							.filter(ingredient => !firstTemplateIngredients.includes(ingredient.name))
							.map(ingredient => ({ ...ingredient, color: 'green' }))
					} as Purporse;
				});

				let currentTemplate = templates![2].purpose.map(purpose => {
					return {
						...purpose,
						ingredients: purpose.ingredients
							.filter(
								ingredient =>
									![...firstTemplateIngredients, ...secondTemplateIngredients].includes(
										ingredient.name
									)
							)
							.map(ingredient => ({ ...ingredient, color: 'yellow' }))
					} as Purporse;
				});
				let result = [...firstTemplate];

				for (let purpose of secondTemplate) {
					let purposeIndex = result.findIndex(
						currentTemplatePurpose => purpose.name === currentTemplatePurpose.name
					);
					if (purposeIndex >= 0) {
						result[purposeIndex].ingredients.push(...purpose.ingredients);
					} else {
						result.push(purpose);
					}
				}

				for (let purpose of currentTemplate) {
					let purposeIndex = result.findIndex(
						currentTemplatePurpose => purpose.name === currentTemplatePurpose.name
					);
					if (purposeIndex >= 0) {
						result[purposeIndex].ingredients.push(...purpose.ingredients);
					} else {
						result.push(purpose);
					}
				}

				return result;
			}

		default:
			break;
	}
};
export const getCategoryDropDownOptions = (currentTab: number, mappedPurposes?: Purporse[]) => {
	const filterTypeMappings: Record<string, IngredientCategory[]> = {};
	mappedPurposes?.forEach(purpose => {
		purpose.ingredients.forEach((ingredient: IngredientByPorpurse) => {
			if (ingredient.categories && ingredient.categories.length > 0) {
				if (!filterTypeMappings[currentTab]) {
					filterTypeMappings[currentTab] = [{ id: 'all', name: 'All' }];
				}
				ingredient.categories.forEach(category => {
					if (!filterTypeMappings[currentTab].find(x => x.id === category.id)) {
						filterTypeMappings[currentTab].push({
							id: category.id!,
							name: category.name!
						});
					}
				});
			}
		});
	});
	return filterTypeMappings;
};

export function toTitleCase(str: string): string {
	return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
}
