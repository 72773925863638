import { RegionsBydistributor } from '../../../../common/model';

const DEAFAULT_STATE: IDistributionState = {
	title:
		'This brand works with these distributors, but availability should be verified for each product.',
	distributorsText: {
		key: 'Distributor',
		content: 'KeHE, UNFI.'
	},
	regionsText: {
		key: 'Region',
		content: 'Northeast, Southeast.'
	},
	channelsText: {
		key: 'Retail distribution channels:',
		content: 'big box stores, independent.'
	},
	tableTitle: 'Distributors and Regions',
	mapValuesForTable: (regionsByDistributors: RegionsBydistributor[] = []) => {
		const keyValueMap = (regionsByDistributors || []).reduce((acc: any, curr) => {
			if (!acc[curr.distributor_name]) {
				acc[curr.distributor_name] = curr.region_name;
			} else {
				acc[curr.distributor_name] = `${acc[curr.distributor_name]}, ${curr.region_name}`;
			}
			return acc;
		}, {});
		return keyValueMap;
	}
};

interface IDistributionText {
	key: string;
	content: string;
}
interface IDistributionState {
	title: string;
	distributorsText: IDistributionText;
	regionsText: IDistributionText;
	channelsText: IDistributionText;
	tableTitle: string;
	mapValuesForTable: (regionsByDistributors?: RegionsBydistributor[]) => any;
}
export const distributionState = {
	...DEAFAULT_STATE
};
