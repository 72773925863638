import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppActions } from '../../redux/AppActions';
import './index.scss';
import { ErrorState } from '../../redux/AppReducer';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import { validateAnonymousPath } from '../../helpers/authenticationHelper';
import { eventPublicPathName } from '../../common/constants';

interface IGeneralErrorComponentProps {
	open: boolean;
	error?: ErrorState;
}

export const GeneralErrorComponent: FC<IGeneralErrorComponentProps> = ({ open, error }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const clearGeneralError = useCallback(() => {
		dispatch(AppActions.setGeneralError({ hasError: false }));
		navigate(validateAnonymousPath(eventPublicPathName) ? `/${eventPublicPathName}` : '/');
	}, [dispatch, navigate]);

	if (!open) return null;
	return (
		<div data-testid='error-container' className='error-container'>
			<div>
				<img src={error?.errorImg} alt='error' className='error-img' />
				<div className='error-title-container'>
					<h3 className='error-title'>{error?.errorTitle}</h3>
				</div>
				<p className='error-content'>{error?.errorContent}</p>
				<p className='error-sub-content'>{error?.errorSubContent}</p>
				<div className='error-btn-continer'>
					<ButtonComponent
						style='rounded-button secondary'
						text='Go To Homepage'
						onClick={clearGeneralError}
					/>
				</div>
			</div>
		</div>
	);
};
