import { Dispatch, FC, Fragment, useEffect, useRef, useState } from 'react';
import { RootState } from '../../../redux/AppReducer';
import { Action } from 'redux';
import { connect } from 'react-redux';
import './OnboardingDetailTemplateComparison.scss';
import { IngredientCategory, IngredientsTemplate, ScreenType } from '../../../common/view-model';
import { IButtonComponentProps, ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
import { getIngredientsLength } from '../../../helpers/ingredientTemplateHelpers';
import { OnboardingDetailTemplateComparisonModel as model } from './model';
import { ViewTemplateComparisonComponent } from '../DetailTemplateComparison/DetailedTemplateComparisonComponent';
import arrowUp from '../../../assets/images/onboarding/arrow-up.svg';
import arrowDown from '../../../assets/images/onboarding/arrow-down.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Swiper as ISwiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useScrollTemplates } from '../../../hooks/useScrollTemplates';
import { IngredientFilterActions } from '../../../redux/ingredientFilter/IngredientFilterActions';
interface IOnboardingDetailTemplateComparisonProps {
	templates: IngredientsTemplate[] | undefined;
	currentTab: number;
	moveToTab: (index: number) => void;
	showDetailedList: boolean;
	setShowDetailedList: (showDetailedList: boolean) => void;
	previewTab: number;
	screenType: ScreenType | undefined;
	setPreviewTab: (index: number) => void;
	mapIngredientFilterOptions: (options: IngredientCategory[]) => void;
}
const OnboardingDetailTemplateComparison: FC<IOnboardingDetailTemplateComparisonProps> = ({
	templates,
	currentTab,
	moveToTab,
	showDetailedList,
	setShowDetailedList,
	previewTab,
	screenType,
	setPreviewTab,
	mapIngredientFilterOptions
}) => {
	const { width } = useWindowSize();
	const mobileLimitWidth = 743;
	const [viewMode, setViewMode] = useState<'table'>('table');
	const [swiperInstance, setSwiperInstance] = useState<ISwiper | null>(null);
	const scrollToRef = useRef<HTMLDivElement>(null);
	useScrollTemplates(
		currentTab,
		previewTab,
		showDetailedList,
		setShowDetailedList,
		scrollToRef.current
	);
	const handleShowDetailedList = () => {
		setShowDetailedList(!showDetailedList);
		setPreviewTab(-1);
	};
	const buttonList: IButtonComponentProps[] = [
		{
			text: `NHN Standards  (${getIngredientsLength(1, templates)})`,
			style: `rounded-button ${currentTab === 1 ? 'active' : ''} blue`,
			onClick: () => {
				moveToTab(1);
			}
		},
		{
			text: `Retailer Standards  (${getIngredientsLength(2, templates)})`,
			style: `rounded-button ${currentTab === 2 ? 'active' : ''} blue`,
			onClick: () => {
				moveToTab(2);
			}
		},
		{
			text: `Retailer Standards Plus  (${getIngredientsLength(3, templates)})`,
			style: `rounded-button ${currentTab === 3 ? 'active' : ''} blue`,
			onClick: () => {
				moveToTab(3);
			}
		}
	];
	useEffect(() => {
		if (swiperInstance) {
			swiperInstance.slideTo(currentTab - 1, 0);
		}
	}, [currentTab, swiperInstance]);
	return (
		<div className='onboardign-template-container'>
			<div className='show-detail-onboarding'>
				<button className='show-detail' type='button' onClick={handleShowDetailedList}>
					{!(screenType === ScreenType.MOBILE || width === mobileLimitWidth) &&
						(!showDetailedList ? model.showDetailedButtonText : model.hideDetailedButtonText)}
					{(screenType === ScreenType.MOBILE || width === mobileLimitWidth) &&
						(!showDetailedList
							? model.showDetailedButtonMobileText
							: model.hideDetailedButtonMobileText)}
					<img src={showDetailedList ? arrowDown : arrowUp} alt='arrow' />
				</button>
			</div>
			<div ref={scrollToRef}></div>
			{showDetailedList && (
				<>
					<div className='onboarding-template-comparison-list'>
						{!(screenType === ScreenType.MOBILE || width === mobileLimitWidth) ? (
							<div className='button-panel-onboarding'>
								{buttonList.map((btn, index) => {
									return (
										<Fragment key={index}>
											<div>
												<ButtonComponent
													onClick={btn.onClick}
													text={btn.text}
													style={btn.style}
													icon={btn.icon}
													iconPosition={btn.iconPosition}
												/>
											</div>
										</Fragment>
									);
								})}
							</div>
						) : (
							<>
								<div className='detail-slider'>
									<Swiper
										onSwiper={setSwiperInstance}
										slidesPerView={'auto'}
										pagination={{
											clickable: true
										}}
										modules={[Pagination]}
										spaceBetween={0}
										onSlideChange={swiper => moveToTab(swiper.activeIndex + 1)}
									>
										{buttonList.map((btn, index) => {
											return (
												<SwiperSlide key={index}>
													<div key={index}>
														<ButtonComponent
															onClick={btn.onClick}
															text={btn.text}
															style={btn.style}
															icon={btn.icon}
															iconPosition={btn.iconPosition}
															id={index === 1 ? 'green' : index === 2 ? 'yellow' : ''}
														/>
													</div>
												</SwiperSlide>
											);
										})}
									</Swiper>
								</div>
							</>
						)}
					</div>
					<div>
						<ViewTemplateComparisonComponent
							viewMode={viewMode}
							templates={templates}
							currentTab={currentTab}
							isMobile={screenType === ScreenType.MOBILE || width === mobileLimitWidth}
							isEdit={true}
							previewTab={previewTab}
							mapIngredientFilterOptions={mapIngredientFilterOptions}
						/>
					</div>
				</>
			)}
			{(screenType === ScreenType.MOBILE || width === mobileLimitWidth) && (
				<div className='separator-container'>
					<div className='separator'></div>
				</div>
			)}
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	screenType: state.deviceScreen
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	mapIngredientFilterOptions: (options: IngredientCategory[]) => {
		return dispatch(IngredientFilterActions.setIngredientFilterOptions(options));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDetailTemplateComparison);
