import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import './ActionsForms.scss';
export interface IActionsForm {
	onUpdate: () => void;
	toggleEditing: () => void;
	onCancel: () => void;
	isValid?: boolean;
}
export const ActionsForms: React.FC<IActionsForm> = ({
	toggleEditing,
	onCancel,
	onUpdate,
	isValid
}) => {
	return (
		<>
			<div className='actions'>
				<ButtonComponent
					onClick={() => {
						toggleEditing();
						onCancel();
					}}
					style='rounded-button secondary'
					text='Undo Changes'
				/>
				<ButtonComponent
					onClick={() => {
						onUpdate();
					}}
					disabled={!isValid}
					style='rounded-button primary'
					text='Save Changes For This Section'
				/>
			</div>
			{isValid ? null : (
				<p className='error-data'>
					Please review and adjust the existing errors to save your changes.
				</p>
			)}
		</>
	);
};
