import { gql } from 'graphql.macro';
export const GET_PRODUCTS = gql`
	query GetProducts(
		$genericFilters: [GenericFilter!]
		$sortAttribute: String
		$sortDirection: [String!]! = []
		$templateId: String!
		$pagination: ProductPagination! = {}
		$favoriteProducts: Boolean = false
		$searchTerm: SearchTerm! = {}
		$expoEventId: String
		$whatsNew: Boolean = false
		$isNewBeacon: Boolean = false
		$isNewProduct: Boolean = false
		$isNextyFinalist: Boolean = false
	) {
		products(
			filter: {
				genericFilters: $genericFilters
				favoriteProducts: $favoriteProducts
				whatsNew: $whatsNew
				isNewBeacon: $isNewBeacon
				isNewProduct: $isNewProduct
				isNextyFinalist: $isNextyFinalist
			}
			sortBy: { attribute: $sortAttribute, order: $sortDirection }
			templateId: $templateId
			pagination: $pagination
			searchTerm: $searchTerm
			expoEventId: $expoEventId
		) {
			totalCount
			products {
				id
				name
				imageUrl
				brand {
					name
					boothNumber
				}
				taxonomy {
					id
					name
				}
				howGoodScore {
					score
					show_howgood_score
					category_average
					source_retail_raiting
				}
			}
		}
	}
`;
