import { Filter } from '../../../common/view-model';

const DEAFAULT_STATE: IMenuItemState = {
	items: [],
	handleMenuClick: (parentIndex?: string, index?: number) => {
		const element = document.getElementById(`main-menu-items-${parentIndex}${index}`);
		element?.classList.toggle('filter-active');
	},
	removeClass: (classNames: string[]) => {
		classNames.forEach(classItem => {
			const items = document.querySelectorAll(`.${classItem}`);
			items.forEach(item => {
				item.classList.remove(classItem);
			});
		});
	}
};

interface IMenuItemState {
	items: Filter[];
	handleMenuClick: (parentIndex?: string, index?: number) => void;
	removeClass: (classNames: string[]) => void;
}

export const MenuItemState = {
	...DEAFAULT_STATE
};
