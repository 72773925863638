import './TopBubblesSectionComponent.scss';
import { FilterVM, ScreenType } from '../../common/view-model';
import { CarouselComponent } from '../UI/CarouselComponent/CarouselComponent';
import { useWindowSize } from '../../hooks/useWindowSize';
import { FC } from 'react';

interface ITopBubblesSectionComponentProps {
	bubbles: FilterVM[] | undefined;
	showFilterPanel: boolean;
	handleOpenPanel: () => void;
	deviceScreen?: ScreenType;
}
export const TopBubblesSectionComponent: FC<ITopBubblesSectionComponentProps> = ({
	bubbles,
	showFilterPanel,
	handleOpenPanel,
	deviceScreen
}) => {
	const FULL_SIZE_SCREEN_WIDTH = 768;
	const { width } = useWindowSize();
	const isMobile = width < FULL_SIZE_SCREEN_WIDTH;
	const getSettings = () => {
		if (isMobile) {
			return {
				dots: false,
				infinite: false,
				speed: 1000,
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true
			};
		} else {
			return {
				dots: false,
				infinite: false,
				speed: 2000,
				slidesToShow: 1,
				slidesToScroll: 4,
				variableWidth: true
			};
		}
	};
	return (
		<div className={`bubbles-contianer ${showFilterPanel ? 'expanded' : 'collapsed'}`}>
			<CarouselComponent
				items={bubbles}
				settings={getSettings()}
				bubbleStyle='regular'
				handleOpenPanel={handleOpenPanel}
				deviceScreen={deviceScreen}
			/>
		</div>
	);
};
