import { useEffect } from 'react';

export const useTokenRefresher = (refreshingCallback: (isRefreshing: boolean) => void) => {
	useEffect(() => {
		window.onload = () => {
			refreshingCallback(true);
		};
		window.addEventListener('refreshEnd', () => refreshingCallback(false));
		return () => {
			window.removeEventListener('refreshEnd', () => refreshingCallback(false));
		};
	}, [refreshingCallback]);
};
