import { FC } from 'react';
import './ButtonComponent.scss';

export interface IButtonComponentProps {
	text: string;
	style: string;
	onClick: () => void;
	icon?: any;
	iconPosition?: 'start' | 'end';
	id?: string;
	disabled?: boolean;
	iconOnly?: boolean;
	type?: 'button' | 'submit' | 'reset';
	preventDefault?: boolean;
}

export const ButtonComponent: FC<IButtonComponentProps> = ({
	text,
	style,
	onClick,
	icon,
	iconPosition,
	id,
	disabled,
	iconOnly,
	type,
	preventDefault = true
}) => {
	const handleClick = (event: any) => {
		if (preventDefault) {
			event.preventDefault();
		}
		if (!disabled && onClick) {
			onClick();
		}
	};
	return (
		<button
			data-testid='button-component'
			type={type || 'button'}
			id={`btn${id}`}
			className={`button ${style} ${iconOnly ? 'icon-only' : ''}`}
			value={text}
			onClick={e => handleClick(e)}
			disabled={disabled}
		>
			{iconPosition === 'start' ? (
				<>
					{icon}
					{!iconOnly && text}
				</>
			) : (
				<>
					{!iconOnly && text}
					{icon}
				</>
			)}
		</button>
	);
};
