const DEFAULT_STATE: IIngredientsTemplateHeaderState = {
	titleText: 'Ingredient personalization',
	stepText: 'Step 2 of 2',
	storeStandarsText:
		'We’ll make sure you never see products with ingredients that don’t align with your store’s standards.',
	chooseTemplateText: 'Choose a template that aligns with the ingredients standards for your store:'
};
interface IIngredientsTemplateHeaderState {
	titleText: string;
	stepText: string;
	storeStandarsText: string;
	chooseTemplateText: string;
}
export const IngredientsTemplateHeaderState = {
	...DEFAULT_STATE
};
