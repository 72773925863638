import { FC, useState } from 'react';
import { ProductVM } from '../../../../common/view-model';
import './HowItsMadeComponent.scss';
import { howItsMadeState } from './model';
interface IHowItsMadeComponentProps {
	product: ProductVM | undefined;
}
export const HowItsMadeComponent: FC<IHowItsMadeComponentProps> = ({ product }) => {
	const [state] = useState({ ...howItsMadeState });
	const invalidTechniqueId = 'None or Unknown';
	return (
		<div data-testid='how-its-made-component' className='how-its-made-container'>
			<div>
				<span className='how-its-made-title'>
					{product?.brand.processingTechniques === null
						? state.nullableTechniquesText
						: product?.brand.processingTechniques &&
						  product?.brand.processingTechniques?.length > 0 &&
						  product?.brand.processingTechniques[0].id?.trim().toLowerCase() !==
								invalidTechniqueId.trim().toLowerCase()
						? state.title
						: state.emptyTechniquesText}
				</span>
			</div>
			{product?.brand.processingTechniques &&
				product?.brand.processingTechniques?.length > 0 &&
				product?.brand.processingTechniques[0].id?.trim().toLowerCase() !==
					invalidTechniqueId.trim().toLowerCase() && (
					<>
						{product?.brand.processingTechniques?.map((element, index) => {
							return (
								<div key={index} className='how-its-made-block'>
									<span className='how-its-made-label'>{element.name}</span>
									<br />
									<span className='how-its-made-title'>{element.description}</span>
								</div>
							);
						})}
					</>
				)}
			<br />
			<span className='how-its-made-title'>{state.disclaimerSentence}</span>
		</div>
	);
};
