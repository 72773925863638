import './GoBackButtonComponent.scss';
import { FC, ReactNode } from 'react';

interface IGoBackButtonComponentProps {
	handelNavigate: () => void;
	text: string;
	icon: ReactNode;
}

export const GoBackButtonComponent: FC<IGoBackButtonComponentProps> = ({
	handelNavigate,
	text,
	icon
}) => {
	return (
		<button className='go-back-btn'>
			<div data-testid='back-button' onClick={handelNavigate}>
				{icon} <span>{text}</span>
			</div>
		</button>
	);
};
