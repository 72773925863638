import { FC, useState } from 'react';
import './TooltipComponent.scss';
import { RootState } from '../../../redux/AppReducer';
import { ScreenType } from '../../../common/view-model';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
export interface IToolTicomponentProps {
	text: string;
	position?: string | 'top';
	children?: any;
	delay?: number;
	classes?: string;
	isMobile?: boolean;
}

const TooltipComponent: FC<IToolTicomponentProps> = ({
	text,
	position,
	children,
	delay,
	classes,
	isMobile
}) => {
	let timeout: any;

	const [active, setActive] = useState(false);
	const showTip = () => {
		if (!isMobile) {
			timeout = setTimeout(() => {
				setActive(true);
			}, delay || 400);
		}
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};
	return (
		<div
			data-testid='tooltip-container'
			className='tooltip-wrapper'
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
		>
			<div className={classes}> {children} </div>
			{active && (
				<div data-testid='active-tooltip' className={`tooltip-content ${position}`}>
					{text}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	isMobile: state.deviceScreen === ScreenType.MOBILE
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TooltipComponent);
