import { ErrorState } from './../../redux/AppReducer';
import { AppActions } from '../../redux/AppActions';
import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
export const GeneralError = () => {
	const dispatch = useDispatch();

	const errorCallback = useCallback(
		(errorDetail: ErrorState) => {
			dispatch(AppActions.setGeneralError(errorDetail));
		},
		[dispatch]
	);

	useEffect(() => {
		const error = {
			hasError: true,
			errorTitle: '',
			errorContent: 'The page you are looking for could not be found.',
			errorSubContent: 'Please try again.',
			errorImg: require('../../assets/images/ErrorState404.png')
		};
		errorCallback(error);
	}, []);
	return null;
};
