import React from 'react';
import { applicationJobTypesMap } from '../../../../mock/roleTypes';
import { UserProfileData } from '../../../authentication/user-profile/model';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import './CompanyFormComponent.scss';
import { useEffect, useState } from 'react';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SelectFormComponent from '../../GenericBaseForms/SelectFormComponent/SelectFormComponent';
const CompanySchema = Yup.object().shape({
	company_name: Yup.string().min(2, 'Company is too short').required('Company is required'),
	company_website: Yup.string()
		.min(2, 'Company Website is too short')
		.required('Company Website is required'),
	job_type: Yup.object()
});
interface ICompanyForm {
	userState: UserProfileData;
	onUpdateProfile: (userState: any) => void;
	onCancel: () => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
}
export const CompanyFormComponent: React.FC<ICompanyForm> = ({
	userState,
	onUpdateProfile,
	onCancel,
	isEditing,
	toggleEditing,
	onChange,
	onValidationChange,
	cleanForm
}) => {
	const ID = 'company-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [jobTypes, setJobTypes] = useState<any[]>([]);
	const {
		errors,
		values,
		handleChange,
		handleReset,
		submitForm,
		setFieldValue,
		isValid,
		setValues
	} = useFormik({
		initialValues: {
			...userState,
			company_name: userState.company_name,
			company_website: userState.company_website,
			job_type: {
				id: userState.job_type,
				name: userState.job_type
			}
		},
		onSubmit: values => {
			onUpdateProfile({
				...userState,
				company_name: values.company_name,
				company_website: values.company_website,
				job_type: values.job_type.name
			});
		},
		validationSchema: CompanySchema
	});
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (userState.user_type) {
			const mappedJobTypes = applicationJobTypesMap[userState.user_type].map(job => ({
				id: job,
				name: job
			}));
			setJobTypes(mappedJobTypes);
		}
	}, [userState]);
	useEffect(() => {
		onChange({
			...userState,
			company_name: values.company_name,
			company_website: values.company_website,
			job_type: values.job_type.name
		});
		onValidationChange(isValid);
	}, [values, isValid, isEditing, cleanForm]);
	useEffect(() => {
		handleReset(null);
	}, [cleanForm]);
	useEffect(() => {
		if (isEditing) {
			setValues({
				...userState,
				company_name: userState.company_name,
				company_website: userState.company_website,
				job_type: {
					id: userState.job_type,
					name: userState.job_type
				}
			});
		}
	}, [userState, isEditing]);
	return (
		<div className='company-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => submitForm()}
				titleText='Company'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
				isValid={isValid}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='Company'></LabelForms>
										<p onClick={() => toggleEditing(ID)}>{userState.company_name}</p>
									</div>
									<div>
										<LabelForms text='Company Website'></LabelForms>
										<p onClick={() => toggleEditing(ID)}>{userState.company_website}</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='I Am A...'></LabelForms>
										<p>{userState.user_type}</p>
									</div>
									<div>
										<LabelForms text='Job Type'></LabelForms>
										<p onClick={() => toggleEditing(ID)}>{userState.job_type}</p>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<ContainerForms
							FormComponent1={
								<>
									<div>
										<LabelForms text='Company'></LabelForms>
										<TextInputComponent
											error={errors.company_name?.length! > 0}
											errorHelperText={<div>{errors.company_name}</div>}
											placeholder='Company'
											name='company_name'
											onChange={handleChange}
											value={values.company_name || ''}
										></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Company Website'></LabelForms>
										<TextInputComponent
											error={errors.company_website?.length! > 0}
											errorHelperText={<div>{errors.company_website}</div>}
											placeholder='Company Website'
											name='company_website'
											onChange={handleChange}
											value={values.company_website || ''}
										></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div>
										<LabelForms text='I Am A...'></LabelForms>
										<TextInputComponent
											disabled
											placeholder='User Type'
											name=''
											value={values.user_type || ''}
										></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Job Type'></LabelForms>
										<SelectFormComponent
											items={jobTypes}
											name='job_type'
											onChange={setFieldValue}
											value={values.job_type}
										></SelectFormComponent>
									</div>
								</>
							}
						></ContainerForms>
						<ActionsForms
							onUpdate={() => submitForm()}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};
