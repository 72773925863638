import { useCallback, useState } from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ErrorState, RootState } from './../../redux/AppReducer';
import { useEffect } from 'react';

//components
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import './index.scss';
import { GeneralErrorComponent } from '../../components/GeneralError/GeneralErrorComponent';
import { PopUpComponent } from '../UI/PopUpComponent/PopUpComponent';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ScreenActions } from '../../redux/ScreenActions';
import {
	Filter,
	FilterType,
	FilterVM,
	ICategoryNavbar,
	IModalContent,
	ScreenType,
	TrackingEventStatus
} from '../../common/view-model';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SearchActions } from '../../redux/search/SearchActions';
import { NavbarCategoryComponent } from '../filter/navbar-category/NavbarCategoryComponent';
import { Events } from '../../common/model';
import { FilterActions } from '../../redux/FilterActions';
import { handleTracking } from '../../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
import { flatSecondLevelFilters, flatFirstLevelFilters } from '../../helpers/urlParametersHelpers';
import {
	getFilterTypeKeyForUrl,
	buildUrlParameters,
	flatThirdLevelChildren
} from '../../helpers/urlParametersHelpers';
import {
	secondLevelCategoryIdentifier,
	firstLevelCategoryIdentifier,
	flatLevelCategoryIdentifier,
	parentCategoryIdentifier
} from '../../helpers/paramsUrlIndetifiers';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import LockOverlayComponent from '../LockOverlay/LockOverlayComponent';
import { AppActions } from '../../redux/AppActions';
import { UserActions } from '../../redux/UserActions';

interface LayoutProps {
	children: any;
	isAuthenticated?: boolean;
	standAloneLayout?: boolean;
	isProfileLayout?: boolean;
	errorState?: ErrorState;
	modalContent?: IModalContent;
	setDeviceScreen: (width: number) => void;
	deviceScreen?: ScreenType;
	onFilterPanelOutsiteClick: Function | undefined;
	completeBrandDetailsLayout?: boolean;
	showNavbar?: boolean;
	isCompanyLoading?: boolean;
	seHasClickedOutsideSearch: (clicked: boolean) => void;
	hasMappedAllFilters?: boolean;
	currentEvent?: Events | null;
	mapCategoryNavbarElements: () => void;
	navbarCategoryElements?: ICategoryNavbar[];
	setCloseOverlay: (setCurrentCategory: () => void) => void;
	removeAllFilters: () => void;
	clearSearchTerm: () => void;
	addFilter: (filter: Filter) => void;
	setCategoryBreadcrumb: (category: string) => void;
	userState?: any;
	clearAllFiltersAndSearchTerm: () => void;
	addParentCategoryFilter: (category: Filter) => void;
	allCategories?: FilterVM[];
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
	hasClickedOutsideBar: boolean;
	homePageLayout?: boolean;
	clearError: () => void;
	screenType?: ScreenType;
}

const Layout = ({
	isAuthenticated,
	standAloneLayout,
	isProfileLayout,
	modalContent,
	errorState,
	setDeviceScreen,
	deviceScreen,
	onFilterPanelOutsiteClick,
	completeBrandDetailsLayout,
	showNavbar,
	isCompanyLoading,
	seHasClickedOutsideSearch,
	hasMappedAllFilters,
	currentEvent,
	mapCategoryNavbarElements,
	navbarCategoryElements,
	setCloseOverlay,
	removeAllFilters,
	clearSearchTerm,
	addFilter,
	userState,
	clearAllFiltersAndSearchTerm,
	allCategories,
	setLockModalContent,
	registerUserClickEvent,
	hasClickedOutsideBar,
	homePageLayout,
	clearError,
	screenType
}: LayoutProps) => {
	const navigate = useNavigate();
	const [categoryTab, setCategoryTab] = useState<ICategoryNavbar | undefined>(undefined);
	const overlayStyle = { backgroundColor: 'rgba(0,0,0,.4)' };
	const { width } = useWindowSize();
	const homePageUrl = 'products';
	const [isOnHomePage, setIsOnHomePage] = useState<boolean>(
		window.location.pathname.includes(homePageUrl) || deviceScreen !== ScreenType.TABLET
	);
	const handleLayoutClick = (e: any) => {
		const targetIdToBeClose = 'popup-overlay';
		const targetClass: string = e.target.className.toString();
		if (targetClass.toLowerCase().trim() === targetIdToBeClose.toLowerCase().trim()) {
			onFilterPanelOutsiteClick && onFilterPanelOutsiteClick();
		}
	};
	const handleAddFilterFromNavbar = useCallback(
		(filters: FilterVM[]) => {
			clearError();
			clearSearchTerm();
			removeAllFilters();
			setCategoryTab(undefined);
			filters.forEach(filter => {
				if (!filter) return;
				addFilter(filter);
				handleTracking({
					transactionId: uuidv4(),
					entityId: filter.id!,
					status: TrackingEventStatus.SUCCESS,
					type: `Nav Click on ${filter.name}`,
					timeStamp: new Date().toISOString(),
					eventPayload: {
						id: filter.id!,
						name: filter.name!,
						type: getFilterTypeKeyForUrl(filter.type!),
						url: window.location.href,
						tags: [],
						metadata: {
							user_id: userState?.id,
							user_type: userState?.user_type,
							environment: process.env.REACT_APP_ENV_NAME,
							filter_id: filter.id,
							filter_name: filter.name
						}
					}
				});
			});
			const urlParameters = buildUrlParameters(filters);
			navigate(`${isCurrentUserAnonymous() ? '.' : ''}/${homePageUrl}?${urlParameters}`);
		},
		[
			addFilter,
			clearSearchTerm,
			navigate,
			removeAllFilters,
			userState?.business_email,
			userState?.user_type
		]
	);
	const handleAddCategoryFilter = useCallback(
		(filter: FilterVM) => {
			const isSuperCategory = filter.isSuperCategory;
			const flattenThirdLevel = flatThirdLevelChildren(allCategories);
			const firstLevelFilters = flatFirstLevelFilters(allCategories);

			const thirdLevelFilters = flattenThirdLevel.flatMap((child: FilterVM) =>
				child.children ? child.children : []
			);

			const tempFilter: FilterVM = {
				...filter,
				children: thirdLevelFilters.find(child => child.id === filter.id)?.children || []
			};

			clearAllFiltersAndSearchTerm();
			setCategoryTab(undefined);

			const eventPayload = {
				id: tempFilter.id!,
				name: tempFilter.name!,
				type: getFilterTypeKeyForUrl(tempFilter.type!),
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: userState?.id,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME,
					filter_id: tempFilter.id,
					filter_name: tempFilter.name
				}
			};

			handleTracking({
				transactionId: uuidv4(),
				entityId: tempFilter.id!,
				status: TrackingEventStatus.SUCCESS,
				type: `Nav Click on ${filter.name}`,
				timeStamp: new Date().toISOString(),
				eventPayload
			});

			const childrenIds = tempFilter.children?.map((child: FilterVM) => child.id) || [];
			addFilter(tempFilter);

			let queryParams = '';
			const secondLevelIdentifier = firstLevelFilters.find(f => f.id === tempFilter.id)?.parentId;
			if (isSuperCategory) {
				queryParams = `?${parentCategoryIdentifier}=${tempFilter.parentId}&${secondLevelCategoryIdentifier}=${tempFilter.id}`;
			} else {
				if (!tempFilter.hasBasedLevelFilters) {
					queryParams = `?${parentCategoryIdentifier}=${
						tempFilter.parentId
					}&${secondLevelCategoryIdentifier}=${secondLevelIdentifier}&${firstLevelCategoryIdentifier}=${
						tempFilter.id
					}${
						childrenIds.length > 0 ? `&${flatLevelCategoryIdentifier}=${childrenIds.join(',')}` : ''
					}`;
				} else {
					const secondLevelIdentifier = firstLevelFilters.find(
						f => f.id === tempFilter.parentId
					)?.parentId;
					queryParams = `?${parentCategoryIdentifier}=${tempFilter.parentFilterId}&${secondLevelCategoryIdentifier}=${secondLevelIdentifier}&${flatLevelCategoryIdentifier}=${tempFilter.id}`;
				}
			}
			clearError();
			navigate(`${isCurrentUserAnonymous() ? '.' : ''}/${homePageUrl}${queryParams}`);
		},
		[
			addFilter,
			allCategories,
			clearAllFiltersAndSearchTerm,
			navigate,
			userState?.business_email,
			userState?.user_type
		]
	);
	useEffect(() => {
		width > 0 && setDeviceScreen(width);
	}, [width]);

	useEffect(() => {
		setIsOnHomePage(
			window.location.pathname.includes(homePageUrl) || deviceScreen !== ScreenType.TABLET
		);
	}, [window.location.pathname, deviceScreen]);
	useEffect(() => {
		if (hasMappedAllFilters && navbarCategoryElements?.length === 0 && currentEvent !== undefined) {
			mapCategoryNavbarElements();
		}
	}, [currentEvent, hasMappedAllFilters, mapCategoryNavbarElements, navbarCategoryElements]);
	return (
		<div data-testid='layout-container' onClick={handleLayoutClick}>
			<div onClick={() => seHasClickedOutsideSearch(true)}>
				<Header />
			</div>
			{isAuthenticated && !completeBrandDetailsLayout ? (
				<>
					{!standAloneLayout ? (
						isProfileLayout ? (
							<>
								<Navbar />
								<div className='standalone-content'>
									{errorState?.hasError || <Outlet />}
									<GeneralErrorComponent open={errorState?.hasError || false} error={errorState} />
								</div>
								<Footer />
							</>
						) : (
							<>
								<Navbar />
								{navbarCategoryElements && navbarCategoryElements.length > 0 && (
									<NavbarCategoryComponent
										handleAddCategoryFilter={handleAddCategoryFilter}
										navbarCategoryElements={navbarCategoryElements}
										categoryTab={categoryTab}
										setCategoryTab={setCategoryTab}
										setCloseOverlay={setCloseOverlay}
										handleAddFilterFromNavbar={handleAddFilterFromNavbar}
										registerUserClickEvent={registerUserClickEvent}
										hasClickedOutsideBar={hasClickedOutsideBar}
										eventName={currentEvent?.name || ''}
										user={userState}
										productsPageUrl={`${isCurrentUserAnonymous() ? '.' : ''}/${homePageUrl}`}
										allCategories={allCategories || []}
									/>
								)}

								<div className='grid-container' onClick={() => seHasClickedOutsideSearch(true)}>
									<div
										className={
											isOnHomePage && deviceScreen !== ScreenType.TABLET
												? !homePageLayout
													? 'main-content'
													: 'main-content-homepage'
												: 'secondary-layout'
										}
									>
										{errorState?.hasError || <Outlet />}
										<GeneralErrorComponent
											open={errorState?.hasError || false}
											error={errorState}
										/>
									</div>
									<Footer />
								</div>
							</>
						)
					) : (
						<>
							{showNavbar && <Navbar />}
							<div className='standalone-content'>
								<Outlet />
							</div>
							<Footer />
						</>
					)}
				</>
			) : (
				<div
					className={`landing-content ${
						completeBrandDetailsLayout ? 'complete-brand-details' : ''
					}`}
				>
					<Outlet />
				</div>
			)}
			{modalContent && (
				<PopUpComponent
					open={modalContent !== undefined}
					content={modalContent.content}
					IsModal={true}
					overlayStyle={overlayStyle}
					shouldLockScroll={!modalContent.canScroll}
					closeOnDocumentClick={modalContent.closeOnDocumentClick}
					showCloseIcon={modalContent.showCloseIcon && !isCompanyLoading}
					isCompanyDetailsModal={modalContent.isCompanyDetailsModal}
				/>
			)}
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	standAloneLayout: state.standAloneLayout,
	isProfileLayout: state.isProfileLayout,
	errorState: state.errorState,
	modalContent: state.modalContent,
	deviceScreen: state.deviceScreen,
	onFilterPanelOutsiteClick: state.filterState?.onFilterPanelOutsiteClick,
	completeBrandDetailsLayout: state.completeBrandDetailsLayout,
	showNavbar: state.showNavbar,
	isCompanyLoading: state.isCompanyLoading,
	hasMappedAllFilters: state.filterState?.hasMappedAllFilters,
	currentEvent: state.storeState?.getCurrentEvent,
	navbarCategoryElements: state.filterState?.categoryNavbarElements,
	userState: state.userState?.user,
	allCategories: state.filterState?.allFilters?.find(filter => filter.type === FilterType.CATEGORY)
		?.filters,
	homePageLayout: state.homePageLayout,
	hasClickedOutsideBar: state.searchState?.hasClickedOutsideBar || false,
	screenType: state.deviceScreen
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	clearError: () => {
		return dispatch(AppActions.setGeneralError({ hasError: false }));
	},
	setDeviceScreen: (screenWidth: number) => {
		return dispatch(ScreenActions.setScreenDevice(screenWidth));
	},
	seHasClickedOutsideSearch: (clicked: boolean) => {
		return dispatch(SearchActions.seHasClickedOutsideSearch(clicked));
	},
	mapCategoryNavbarElements: () => {
		return dispatch(FilterActions.mapNavbarElements());
	},
	setCloseOverlay: (setCurrentCategory: () => void) => {
		return dispatch(FilterActions.addOnMouseLeaveCategoryNavbar(setCurrentCategory));
	},
	setCategoryBreadcrumb: (category: string) => {
		return dispatch(FilterActions.setCategory(category));
	},
	addFilter: (filter: Filter) => {
		return dispatch(FilterActions.addExternalfilter(filter));
	},
	removeAllFilters: () => {
		return dispatch(FilterActions.clearAllFilters());
	},
	clearSearchTerm: () => {
		return dispatch(SearchActions.setSearchTerm(undefined));
	},
	clearAllFiltersAndSearchTerm: () => {
		return dispatch(FilterActions.clearAllFiltersAndSearchTerm());
	},
	addParentCategoryFilter: (category: Filter) => {
		return dispatch(FilterActions.addParentFilter(category));
	},
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
