import { APP_NAME } from '../../../../common/constants';
import { ScreenType } from '../../../../common/view-model';

const DEFAULT_STATE: IIngredientsTemplateCardState = {
	bannedIngredientsLabel: 'banned ingredients',
	allProductsLabel: `All products on ${APP_NAME} `,
	topBannedIngredientsLabel: 'Top banned ingredients:',
	defaultTemplateLabel: 'Default',
	seeFullListLabel: 'See full list'
};
interface IIngredientsTemplateCardState {
	bannedIngredientsLabel: string;
	allProductsLabel: string;
	topBannedIngredientsLabel: string;
	defaultTemplateLabel: string;
	seeFullListLabel: string;
}
export const ingredientsTemplateCardState = { ...DEFAULT_STATE };

export const getStaticTopIngredients = (
	id: string | undefined,
	screenType: ScreenType | undefined
): string[] => {
	const firsTemplateIngregients = [
		'FD&C certified colors',
		'Artificial flavors',
		`Artificial sweeteners ${screenType === ScreenType.TABLET ? '' : '(aspartame, sucralose)'}`,
		'Monosodium glutamate (MSG)',
		'Partially hydrogenated oils (PHOs)'
	];
	const secondTemplateIngregients = [
		'Benzoates',
		'BHA/BHT',
		'Brominated vegetable oil (BVO)',
		'EDTA',
		'Nitrates/Nitrites'
	];
	const thirdTemplateIngregients = [
		'Carrageenan',
		'Reb D and Reb M',
		'Polyethylene glycol',
		'Titanium Dioxide',
		'Slow release caffeine'
	];
	switch (id) {
		case '1':
			return screenType === ScreenType.TABLET
				? firsTemplateIngregients.slice(0, 3)
				: firsTemplateIngregients;
		case '2':
			return screenType === ScreenType.TABLET
				? secondTemplateIngregients.slice(0, 3)
				: secondTemplateIngregients;
		case '3':
			return screenType === ScreenType.TABLET
				? thirdTemplateIngregients.slice(0, 3)
				: thirdTemplateIngregients;
		default:
			return [];
	}
};
