import { Action } from 'redux-actions';
import { IModalContent } from '../common/view-model';
import { AppState, ErrorState, IProfileState } from './AppReducer';

export class AppActions {
	static readonly SET_STATE = '@@set/STATE';
	static readonly SET_ELEMENT_OVERLAY = '@@set/ELEMENT_OVERLAY';
	static readonly SET_APP_TITLE = '@@set/APP_TITLE';
	static readonly SET_IS_LOADING_DATA = '@@set/IS_LOADING_DATA';
	static readonly SET_GENERAL_ERROR = '@@set/GENERAL_ERROR';
	static readonly SET_STAND_ALONE_LAYOUT = '@@set/STAND_ALONE_LAYOUT';
	static readonly SET_PROFILE_LAYOUT = '@@set/PROFILE_LAYOUT';
	static readonly SET_MODAL_CONTENT = '@@set/MODAL_CONTENT';
	static readonly SET_APP_FAVOURITE = '@@set/APP_FAVOURITE';
	static readonly SET_COMPLETE_BRAND_DETAILS_LAYOUT = '@@set/COMPLETE_BRAND_DETAILS_LAYOUT';
	static readonly SET_PRODUCTS_CACHED = '@@set/PRODUCTS_CACHED';
	static readonly SET_PAGE = '@@set/PAGE';
	static readonly SET_USER_RETURNS = '@@set/USER_RETURNS';
	static readonly SHOW_NAVBAR = '@@show/NAVBAR';
	static readonly LOAD_PROFILE_PROGRESS = '@@load/PROFILE_PROGRESS';
	static readonly START_PROGRESS = '@@start/PROGRESS';
	static readonly SET_ISFAVORITING = '@@set/ISFAVORITING';
	static readonly FINISH_PROGRESS = '@@finish/PROGRESS';
	static readonly START_BEACON_PROFILE_PROGRESS = '@@start/BEACON_PROFILE_PROGRESS';
	static readonly FINISH_BEACON_PROFILE_PROGRESS = '@@finish/BEACON_PROFILE_PROGRESS';
	static readonly SET_CREATE_BEACON_PROFILE_PROGRESS = '@@set/CREATE_BEACON_PROFILE_PROGRESS';
	static readonly IS_COMPANY_LOADING = '@@is/COMPANY_LOADING';
	static readonly INTERVAL_CANCELATION = '@@interval/CANCELATION';
	static readonly UNAUTHORIZED_ERROR = '@@unauthorized/ERROR';
	static readonly UNAUTHORIZED_ERROR_RESOLVE = '@@unauthorized/ERROR_RESOLVE';
	static readonly IS_ON_FAVORITES_PAGE = '@@is/ON_FAVORITES_PAGE';
	static readonly SET_IS_ON_HOME_PAGE = '@@set/IS_ON_HOME_PAGE';
	static readonly SET_CONTACT_ACCORDION_OPEN = '@@set/CONTACT_ACCORDION_OPEN';
	static readonly UPDATE_SHOW_BACK_TO_TOP = '@@set/UPDATE_SHOW_BACK_TO_TOP';
	static readonly UPDATE_PROFILE_STATE = '@@set/UPDATE_PROFILE_STATE';

	static updateShowBackToTop(status: boolean): Action<Boolean> {
		return {
			type: AppActions.UPDATE_SHOW_BACK_TO_TOP,
			payload: status
		};
	}

	static setState(state: any): Action<AppState> {
		return {
			type: AppActions.SET_STATE,
			payload: state
		};
	}
	static setProductsCached(products: any): Action<AppState> {
		return {
			type: AppActions.SET_PRODUCTS_CACHED,
			payload: products
		};
	}
	static setPage(page: number): Action<number> {
		return {
			type: AppActions.SET_PAGE,
			payload: page
		};
	}
	static setUserReturns(state: boolean): Action<boolean> {
		return {
			type: AppActions.SET_USER_RETURNS,
			payload: state
		};
	}
	static setAppTitle(title: string | undefined): Action<string | undefined> {
		return {
			type: AppActions.SET_APP_TITLE,
			payload: title
		};
	}
	static setAppFavourite(status: boolean): Action<boolean> {
		return {
			type: AppActions.SET_APP_FAVOURITE,
			payload: status
		};
	}
	static setIsLoadingData(isLoadingData: boolean): Action<boolean> {
		return {
			type: AppActions.SET_IS_LOADING_DATA,
			payload: isLoadingData
		};
	}
	static setGeneralError(payload: ErrorState): Action<ErrorState> {
		return {
			type: AppActions.SET_GENERAL_ERROR,
			payload: payload
		};
	}
	static setStandAloneLayout(isStandAloneLayout: boolean): Action<boolean> {
		return {
			type: AppActions.SET_STAND_ALONE_LAYOUT,
			payload: isStandAloneLayout
		};
	}
	static setProfileLayout(isProfileLayout: boolean): Action<boolean> {
		return {
			type: AppActions.SET_PROFILE_LAYOUT,
			payload: isProfileLayout
		};
	}
	static setModalContent(payload: IModalContent | undefined): Action<any> {
		return {
			type: AppActions.SET_MODAL_CONTENT,
			payload: payload
		};
	}
	static setCompleteBrandDetailsLayout(isCompleteBrandDetailsLayout: boolean): Action<boolean> {
		return {
			type: AppActions.SET_COMPLETE_BRAND_DETAILS_LAYOUT,
			payload: isCompleteBrandDetailsLayout
		};
	}
	static showNavbar(showNavbar: boolean): Action<boolean> {
		return {
			type: AppActions.SHOW_NAVBAR,
			payload: showNavbar
		};
	}
	static setLoadProfileProgress(loadProfileProgress: number): Action<number> {
		return {
			type: AppActions.LOAD_PROFILE_PROGRESS,
			payload: loadProfileProgress
		};
	}
	static startProgress(): Action<any> {
		return {
			type: AppActions.START_PROGRESS,
			payload: undefined
		};
	}
	static setIsFavoriting(isFavoriting: boolean): Action<boolean> {
		return {
			type: AppActions.SET_ISFAVORITING,
			payload: isFavoriting
		};
	}
	static finishProgress(): Action<any> {
		return {
			type: AppActions.FINISH_PROGRESS,
			payload: undefined
		};
	}
	static startBeaconProfileProgress(): Action<any> {
		return {
			type: AppActions.START_BEACON_PROFILE_PROGRESS,
			payload: undefined
		};
	}
	static finishBeaconProfileProgress(): Action<any> {
		return {
			type: AppActions.FINISH_BEACON_PROFILE_PROGRESS,
			payload: undefined
		};
	}
	static setCreateBeaconProfileProgress(createBeaconProfileProgress: number): Action<number> {
		return {
			type: AppActions.SET_CREATE_BEACON_PROFILE_PROGRESS,
			payload: createBeaconProfileProgress
		};
	}
	static isCompanyLoading(isCompanyLoading: boolean): Action<boolean> {
		return {
			type: AppActions.IS_COMPANY_LOADING,
			payload: isCompanyLoading
		};
	}
	static intervalCancelation(): Action<any> {
		return {
			type: AppActions.INTERVAL_CANCELATION,
			payload: undefined
		};
	}
	static unauthorizedError(): Action<any> {
		return {
			type: AppActions.UNAUTHORIZED_ERROR,
			payload: undefined
		};
	}
	static isOnFavoritesPage(isOnFavoritesPage: boolean): Action<boolean> {
		return {
			type: AppActions.IS_ON_FAVORITES_PAGE,
			payload: isOnFavoritesPage
		};
	}
	static setIsOnHomePage(isOnHomePage: boolean): Action<boolean> {
		return {
			type: AppActions.SET_IS_ON_HOME_PAGE,
			payload: isOnHomePage
		};
	}
	static setContactAccordionOpen(contactAccordionOpen: boolean): Action<boolean> {
		return {
			type: AppActions.SET_CONTACT_ACCORDION_OPEN,
			payload: contactAccordionOpen
		};
	}
	static updateProfileState(state: IProfileState) {
		return {
			type: AppActions.UPDATE_PROFILE_STATE,
			payload: state
		};
	}
}
