import { FC, useCallback, useState } from 'react';
import { NotForSaleInUSState } from './model';
import flagUS from '../../../assets/images/icons/flagUS.svg';
import './NotLabeledForSaleInUSComponent.scss';
import { ActionLinkComponent } from '../../UI/ActionLinkComponent/ActionLinkComponent';
import { beaconFaqLink } from '../../../common/constants';
export const NotLabeledForSaleInUSComponent: FC = () => {
	const [state] = useState({ ...NotForSaleInUSState });
	const handleGoFAQClick = useCallback(() => {
		window.open(beaconFaqLink, '_blank', 'rel=noopener noreferrer');
	}, []);
	return (
		<div className='not-for-sale-container'>
			<div className='not-for-sale-content'>
				<span className='not-for-sale-title'>
					{state.notForSaleInUSTitle}
					<img className='us-flag' src={flagUS} alt='us-flag' />
				</span>
				<span className='not-for-sale-text'>{state.notForSaleInUSText}</span>
				<div className='not-for-sale-link'>
					<ActionLinkComponent
						text={state.goToFAQText}
						onClick={handleGoFAQClick}
						underline={false}
						icon='ri-arrow-right-line'
						iconPosition='end'
						iconOnly={false}
						color='light-blue'
						fontWigth='bold'
						size='big'
						to={beaconFaqLink}
						linkToExternal={true}
					/>
				</div>
			</div>
		</div>
	);
};
