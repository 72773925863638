import { RootState } from '../../../redux/AppReducer';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import './BrandAttributesComponent.scss';
import { validateUrl } from '../../../helpers/validateUrl';
import { FC } from 'react';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { publicBrandDetailActions } from '../../../common/constants';
interface IBrandAttibutesComponentProps {
	name: string;
	icon: string;
	content: string;
	isLinkContent: boolean;
	linkText?: string;
	registerUserClickEvent: (event: string) => void;
}

const BrandAttributesComponent: FC<IBrandAttibutesComponentProps> = ({
	name,
	icon,
	content,
	isLinkContent,
	linkText,
	registerUserClickEvent
}) => {
	const handelClick = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicBrandDetailActions.CLICK_BRAND_LINKS);
		}
	};
	return (
		<>
			{content && (
				<div className='brand-attribute-item'>
					<div className='brand-attribute-name-container'>
						{name === 'TikTok' ? (
							<i className='ri-tiktok-line attribute-icon'></i>
						) : (
							<i className={`${icon} attribute-icon`}></i>
						)}
						<span className='brand-attribute-name'>{name}</span>
					</div>
					<div className='brand-attribute-content'>
						{isLinkContent ? (
							<a
								data-testid='link-content'
								className='attribute-link'
								href={validateUrl(content)}
								target='_blank'
								onClick={handelClick}
							>
								{linkText}
							</a>
						) : (
							<span className='text-content'>{content}</span>
						)}
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BrandAttributesComponent);
