const DEFAULT_STATE: HowItsMadeState = {
	title:
		'This product or any ingredients contained in the product was manufactured using these processing techniques:',
	hydrogenotrophy:
		'Uses hydrogenotrophic microbes fermented with a mix of carbon dioxide, oxygen, minerals, water, and nitrogen to produce complex proteins or oils',
	cultivatedAnimalCells:
		'Animal meat, seafood, fat or secreted food products (e.g. milk) produced via growth of animal cells in a controlled and aseptic bioreactor environment (Note: products derived using this method are not currently legal for sale in the U.S.). ',
	emptyTechniquesText:
		'This product was not manufactured with any specialized processing techniques. ',
	nullableTechniquesText:
		'This product may be manufactured with specialized processing techniques, please contact the brand to learn more.',
	disclaimerSentence: 'Note: This information is not currently reviewed by New Hope Network.'
};

interface HowItsMadeState {
	title: string;
	hydrogenotrophy?: string;
	cultivatedAnimalCells?: string;
	emptyTechniquesText?: string;
	nullableTechniquesText: string;
	disclaimerSentence: string;
}
export const howItsMadeState = {
	...DEFAULT_STATE
};
