import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScreenType } from '../../../../common/view-model';

export const useShowMobileSearch = (width: number, deviceScreen?: ScreenType) => {
	const mobileWidthBreakpoint = 743;
	const location = useLocation();
	const pagesNotUsingSearch = useMemo(() => ['/profile'], []);
	const [open, setOpen] = useState<boolean>(false);
	const [showMobileSearch, setShowMobileSearch] = useState<boolean>(
		!pagesNotUsingSearch.includes(location.pathname) &&
			(deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint) &&
			open
	);
	useEffect(() => {
		setShowMobileSearch(
			!pagesNotUsingSearch.includes(location.pathname) &&
				(deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint) &&
				open
		);
	}, [location.pathname, pagesNotUsingSearch, deviceScreen, open, width]);
	return { showMobileSearch, setOpen };
};
