import { BrandVM } from '../../common/view-model';

const DEAFAULT_STATE: IBrandProfileState = {
	backToPageText: 'Back',
	missionStatementTitleText: 'Mission Statement',
	ourStoryTitleText: 'Our Story',
	contactTitleText: 'Contact',
	brandContactCardTitle: 'Brand Contact',
	brokerContactCardTitle: 'Broker Contact',
	contactBrandTitle: 'Contact Information'
};

interface IBrandProfileState {
	backToPageText: string;
	missionStatementTitleText: string;
	ourStoryTitleText: string;
	contactTitleText: string;
	brandContactCardTitle: string;
	brokerContactCardTitle: string;
	contactBrandTitle: string;
}
export const aboutBrandState = {
	...DEAFAULT_STATE
};
const validateNullability = (value: string | null | undefined): boolean => {
	return value !== null && value !== undefined && value !== '';
};
export const mapBrandAttributes = (brand: BrandVM): BrandVM => {
	return {
		...brand,
		hasContactInformation:
			(validateNullability(brand.contact_email) && validateNullability(brand.contact_phone)) ||
			validateNullability(brand.contact_name),
		hasBrokerContactInformation: brand.brokers && brand.brokers.length > 0,
		attributes: [
			{
				name: 'Headquarters',
				icon: 'ri-map-pin-line',
				content: `${brand.hq_city}${brand.hq_state ? ' , ' : ' '}${brand.hq_state}`,
				isLinkContent: false
			},
			{
				name: 'Founded Date',
				icon: 'ri-calendar-2-line',
				content: brand.brand_foundation_year,
				isLinkContent: false
			},
			{
				name: 'Distributors',
				icon: 'ri-truck-line',
				content: brand.distributors,
				isLinkContent: false
			},
			{
				name: 'Holding Company',
				icon: 'ri-organization-chart',
				content: brand.holding_company || 'None',
				isLinkContent: false
			},
			{
				name: 'Parent Company',
				icon: 'ri-parent-line',
				content: brand.parent_company || 'None',
				isLinkContent: false
			},
			{
				name: 'Brand Website',
				icon: 'ri-global-line',
				content: brand.brand_website,
				isLinkContent: true,
				linkText: 'Visit Site'
			},
			{
				name: 'Brand Video',
				icon: 'ri-live-line',
				content: brand.brand_video,
				isLinkContent: true,
				linkText: 'Watch Video'
			},
			{
				name: 'Youtube',
				icon: 'ri-youtube-line',
				content: brand.brand_youtube,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Instagram',
				icon: 'ri-instagram-line',
				content: brand.brand_instagram,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'TikTok',
				icon: 'ri-tiktok',
				content: brand.brand_tiktok,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Twitter',
				icon: 'ri-twitter-line',
				content: brand.brand_twitter,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Facebook',
				icon: 'ri-facebook-circle-line',
				content: brand.brand_facebook,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'LinkedIn',
				icon: 'ri-linkedin-fill',
				content: brand.brand_linkedin,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Private Label Available',
				icon: 'ri-price-tag-3-line',
				content: brand.uses_private_label ? 'Yes' : 'No',
				isLinkContent: false
			}
		]
	};
};
