import { Dispatch, FC, useCallback, useEffect, useRef, useState } from 'react';
import { Action } from 'redux';
import { AppState } from '../../redux/AppReducer';
import { connect } from 'react-redux';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import {
	ISortByOption,
	ITrackingEvent,
	ScreenType,
	TrackingEventStatus,
	sortOptionType
} from '../../common/view-model';
import { cloneDeep } from '@apollo/client/utilities';
import './SortProductsComponent.scss';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import { UserActions } from '../../redux/UserActions';
import { publicProductDiscoveryActions } from '../../common/constants';
import { v4 as uuidv4 } from 'uuid';
import { handleTracking } from '../../helpers/handelTracking';

interface ISortProductsComponentProps {
	appState: AppState;
	setSortBy: (sortBy: ISortByOption | undefined) => void;
	availableSorts: ISortByOption[];
	isFavoritesPage: boolean;
	registerUserClickEvent: (event: string) => void;
}
const SortProductsComponent: FC<ISortProductsComponentProps> = ({
	appState,
	setSortBy,
	availableSorts,
	isFavoritesPage,
	registerUserClickEvent
}) => {
	const currentEventSortOptionId = '3';
	const currentEvent = appState?.storeState?.getCurrentEvent;
	const { sortBySelected, favoriteSortBySelected } = appState.filterState!;
	const [open, setOpen] = useState<boolean>(false);
	const [sortOptions, setSortOptions] = useState<ISortByOption[]>(cloneDeep([...availableSorts]));
	const hasFavorites = appState.userState?.favoriteProducts?.length! > 0;
	const noFavoritesText = '(no products favorited yet)';
	const { deviceScreen } = appState;
	const [currentSortBy, setCurrentSortBy] = useState<ISortByOption | undefined>(
		isFavoritesPage ? favoriteSortBySelected : sortBySelected
	);
	const [leftAling, setLeftAling] = useState<boolean>(
		currentSortBy?.sortType === sortOptionType.FAVORITES ||
			currentSortBy?.sortType === sortOptionType.CURRENT_EVENT ||
			currentSortBy?.sortType === sortOptionType.MOST_RELEVANT
	);
	const handleOptionClick = (option: ISortByOption) => {
		if (!isCurrentUserAnonymous) {
			const trackingPayload: ITrackingEvent = {
				transactionId: uuidv4(),
				status: TrackingEventStatus.SUCCESS,
				type: `Sort By ${option.name} Clicked`,
				timeStamp: new Date().toISOString(),
				entityId: option.id,
				eventPayload: {
					id: option.id,
					name: option.name!,
					type: option.sortType.toString(),
					url: window.location.href,
					tags: [],
					metadata: {
						user_type: appState.userState?.user?.user_type,
						user_id: appState.userState?.user.id,
						environment: process.env.REACT_APP_ENV_NAME
					}
				}
			};
			handleTracking(trackingPayload);
		}
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDiscoveryActions.CLICK_SORT_BY);
		}
		if (
			(option.sortType === sortOptionType.FAVORITES && hasFavorites) ||
			option.sortType !== sortOptionType.FAVORITES
		) {
			setCurrentSortBy(option);
			setSortBy(option);
			setOpen(false);
		}
	};
	const sortButtonRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!hasFavorites) {
			const newSortOptions: ISortByOption[] = cloneDeep([...availableSorts]);
			newSortOptions.forEach(option => {
				if (option.sortType === sortOptionType.FAVORITES) {
					option.name = `${option.name} ${noFavoritesText}`;
				}
			});
			setSortOptions(cloneDeep(newSortOptions!));
		} else {
			setSortOptions(cloneDeep([...availableSorts]));
		}
		if (currentSortBy?.sortType === sortOptionType.FAVORITES && !hasFavorites) {
			setSortBy(availableSorts?.find(option => option.sortType === sortOptionType.ALPHABETICAL));
		}
		if (!currentEvent) {
			setSortOptions(sortOptions.filter(option => option.id !== currentEventSortOptionId));
		} else {
			const newSortOptions: ISortByOption[] = [...availableSorts];
			const currentEventOption = newSortOptions.find(
				option => option.id === currentEventSortOptionId
			);
			if (currentEventOption && currentEvent) {
				currentEventOption.name = currentEvent?.label || currentEvent?.name;
			}
			setSortOptions(newSortOptions);
		}
	}, [
		appState.userState?.favoriteProducts,
		hasFavorites,
		availableSorts,
		currentSortBy,
		setSortBy,
		currentEvent
	]);
	useEffect(() => {
		setLeftAling(
			currentSortBy?.sortType === sortOptionType.FAVORITES ||
				currentSortBy?.sortType === sortOptionType.CURRENT_EVENT ||
				currentSortBy?.sortType === sortOptionType.MOST_RELEVANT
		);
	}, [currentSortBy]);

	useEffect(() => {
		setCurrentSortBy(isFavoritesPage ? favoriteSortBySelected : sortBySelected);
	}, [currentSortBy, favoriteSortBySelected, isFavoritesPage, sortBySelected]);

	useEffect(() => {
		function handleClickSortButton(e: MouseEvent) {
			if (sortButtonRef.current && !e.composedPath().includes(sortButtonRef.current)) {
				setOpen(false);
			} else {
				setOpen(true);
			}
		}

		window.addEventListener('click', handleClickSortButton);
		return () => window.removeEventListener('click', handleClickSortButton);
	}, []);

	return (
		<div data-testid='sort-products-component' className='sort-products-container'>
			<div ref={sortButtonRef} className='sort-button-container'>
				<ButtonComponent
					style={`sort-by-btn ${leftAling ? 'left-align' : ''}`}
					text={
						deviceScreen !== ScreenType.TABLET && deviceScreen !== ScreenType.MOBILE
							? currentSortBy?.name!
							: ''
					}
					id={`sort-by${currentSortBy?.id}`}
					onClick={() => {}}
					icon={<i className={open ? 'icon ri-arrow-up-s-line' : 'icon ri-arrow-down-s-line'}></i>}
					iconPosition='end'
				/>
			</div>

			{open && (
				<div data-testid='sort-by-options' className='sort-by-options-container'>
					{sortOptions?.map((option, index: number) => (
						<span
							key={index}
							className={`sort-option ${option.id === currentSortBy?.id ? 'sort-selected' : ''} ${
								option.sortType === sortOptionType.FAVORITES && !hasFavorites ? 'disabled' : ''
							}`}
							onClick={() => {
								handleOptionClick(option);
							}}
						>
							{option.name}{' '}
							{option.id === currentSortBy?.id && <i className='ri-check-fill selected-icon'></i>}
						</span>
					))}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: AppState) => ({
	...state
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SortProductsComponent);
