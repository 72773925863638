import { ISortByOption, sortOptionType } from '../common/view-model';

export const sortByOptions: ISortByOption[] = [
	{
		id: '1',
		name: 'Newest to Oldest',
		sortType: sortOptionType.FAVORITESDATE,
		productSort: {
			attribute: 'FavoriteProducts',
			order: ['ASC']
		}
	},
	{
		id: '2',
		name: 'Alphabetically (a - z)',
		sortType: sortOptionType.ALPHABETICAL,
		productSort: {
			attribute: 'BrandName',
			order: ['ASC']
		}
	},
	{
		id: '3',
		name: undefined,
		sortType: sortOptionType.CURRENT_EVENT,
		productSort: {
			attribute: 'ExpoEvent',
			order: ['ASC']
		}
	},
	{
		id: '4',
		name: 'Favorites',
		sortType: sortOptionType.FAVORITES,
		productSort: {
			attribute: 'FavoriteProducts',
			order: ['ASC']
		}
	},
	{
		id: '5',
		name: 'HowGood Estimate',
		sortType: sortOptionType.SCORE,
		productSort: {
			attribute: 'HowGoodScore',
			order: ['DES']
		}
	}
];
