import { useState, useEffect } from 'react';

const useInvalidConnectionBrowser = (): boolean => {
	const [invalidConnectionBrowser, setInvalidConnectionBrowser] = useState<boolean>(true);

	useEffect(() => {
		const userAgent: string = navigator.userAgent;
		const isChrome: boolean = /Chrome/.test(userAgent) && !/Edg/.test(userAgent);
		const isEdge: boolean = /Edg/.test(userAgent);

		if ('connection' in navigator) {
			const connection: any = navigator.connection;
			const type: string = connection.effectiveType;
			if ((type === '2g' || type === '3g') && (isChrome || isEdge)) {
				setInvalidConnectionBrowser(false);
			}
		}
	}, []);

	return invalidConnectionBrowser;
};

export default useInvalidConnectionBrowser;
