const DEFAULT_STATE: IMarketingAssetsState = {
	marketingAssetsText: 'Click to view marketing assets provided by the brand.',
	dowloadAssetsText: 'See Marketing Assets',
	noMarketingAssetsText: 'Not Available.'
};

interface IMarketingAssetsState {
	marketingAssetsText: string;
	dowloadAssetsText: string;
	noMarketingAssetsText: string;
}
export const marketingAssetsState = {
	...DEFAULT_STATE
};
