import './SpinsMetricsComponent.scss';
import { RootState } from '../../../../redux/AppReducer';
import { Dispatch, FC, useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ProductVM } from '../../../../common/view-model';
import { spinsMetricsState as model, ISpinsMetricItemsDetail } from './model';

const Separator = () => <div className='spins-separator'></div>;

interface ISpinMetricsItemComponentProps {
	title: string;
	value: string | undefined;
	prevText: string;
	nextText: string;
	icon: string;
	details: ISpinsMetricItemsDetail;
}
const SpinMetricsItemComponent: FC<ISpinMetricsItemComponentProps> = ({
	title,
	value,
	prevText,
	nextText,
	icon,
	details
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const notAvailableValue = 'N/A';
	return (
		<div className='spin-metrics-item-container'>
			<div className='metric-element-container'>
				<div className='icon-container'>
					<i className={`${icon} spin-metrics-icon`}></i>
				</div>
				<div className='metric-text-container'>
					<span className='metric-title'>{title}</span>
					{value && value !== notAvailableValue && (
						<span className='metric-prev-text'>{prevText}</span>
					)}
					<span className='metric-value'>
						{value !== notAvailableValue ? (
							value
						) : (
							<span className='not-available'>{model.metricNotAvailableText}</span>
						)}
					</span>
					{value && value !== notAvailableValue && (
						<span className='metric-next-text'>{nextText}</span>
					)}
					{value && value !== notAvailableValue && (
						<span className='details' onClick={() => setOpen(!open)}>
							{open ? model.lessDetailsText : model.moreDetailsText}
						</span>
					)}
				</div>
			</div>
			{open && (
				<div className='metric-details-container'>
					<span className='detail-title'>{details.title}</span>
					{details.elements.map((element, index) => {
						return (
							<div key={index} className='detail-item'>
								<div className='dot-container'>
									<li className='dot'></li>
								</div>
								<span className='item-text'>{element}</span>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

interface ISpinsMetricsComponentProps {
	product: ProductVM | undefined;
	allMetricsUnavailable: boolean;
}
const SpinsMetricsComponent: FC<ISpinsMetricsComponentProps> = ({
	product,
	allMetricsUnavailable
}) => {
	return (
		<div className='spins-metrics-container'>
			<Separator />
			<div className='metrics-title-container'>
				<span className='metrics-title'>{model.spinsMetricsTitleText}</span>
			</div>
			{!allMetricsUnavailable && (
				<div className='metrics-text-container'>
					<span className='metrics-text'>{model.spinsMetricsText}</span>
				</div>
			)}
			{!allMetricsUnavailable && (
				<div className='median-text-container'>
					<span className='median-text-title'>{model.productsMedianTitleText}</span>
					<span className='median-text'>{model.productsMedianText}</span>
				</div>
			)}
			{allMetricsUnavailable && (
				<div className='metrics-not-available-container'>
					<span className='metrics-not-available-text'>{model.allMetricsNotAvailableText}</span>
				</div>
			)}
			<Separator />
			<div className='metrics-items-container'>
				{model.metricsItems.map((item, index) => {
					const value = Object.entries(product?.spinsMetrics || []).find(
						([key]) => key === item.propertyName
					)?.[1];
					return (
						<div key={index}>
							<SpinMetricsItemComponent
								title={item.title}
								value={value}
								prevText={item.prevText}
								nextText={item.nextText}
								icon={item.icon}
								details={item.details}
							/>
							<Separator />
						</div>
					);
				})}
			</div>
			<div className='based-on-container'>
				<span className='based-on-text'>{model.spinsMetricsBasedOnText}</span>
			</div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SpinsMetricsComponent);
