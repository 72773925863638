import './PackagingComponent.scss';
import { ProductVM } from '../../../../common/view-model';
import { packagingState } from './model';
import { FC, useState } from 'react';
interface IPackagingComponentProps {
	product: ProductVM | undefined;
}
export const PackagingComponent: FC<IPackagingComponentProps> = ({ product }) => {
	const [state] = useState({ ...packagingState });
	return (
		<div data-testid='packaging-component' className='packaging-container'>
			<span className='packaging-title'>
				{state.shelfLifeText}
				<span className='packaging-info'>
					{product?.shelfLife ? `${Math.trunc(product?.shelfLife)} days` : state.notAvailableText}
				</span>
			</span>
			<span className='packaging-title'>
				{state.netContentText}
				<span className='packaging-info'>{product?.netContent}</span>
			</span>
			<span className='packaging-title'>
				{state.itemTypeText}
				<span className='packaging-info'>Unit.</span>
			</span>
			{product?.dimensions ? (
				<>
					<span className='packaging-title'>{state.dimensionsText}</span>
					<div className='packaging-table-container'>
						<table>
							<tbody>
								<tr>
									<td>Height</td>
									<td>{product?.dimensions?.height}</td>
								</tr>
								<tr>
									<td>Width</td>
									<td>{product?.dimensions?.width}</td>
								</tr>
								<tr>
									<td>Depth</td>
									<td>{product?.dimensions?.depth}</td>
								</tr>
								<tr>
									<td>Weight</td>
									<td>{product?.dimensions?.weight}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</>
			) : (
				<span data-testid='no-dimensions' className='packaging-title'>
					{state.dimensionsText} <span className='packaging-info'>{state.noDimensionsText}</span>
				</span>
			)}
		</div>
	);
};
