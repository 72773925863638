const DEFAULT_STATE: ISalesInformationState = {
	salesVelocityText: 'Sales Velocity:',
	aditionalSalesInformationText: 'Additional Sales information:',
	manufacturerSuggestedRetailPriceText: 'MSRP:',
	comingSoonText:
		'Coming Soon: Sales information delivered by SPINS, including growth, distribution, and velocity data will be uploaded for each product. So you can find products that target the right markets, at the right scale.',
	informationNotAvailableText: 'Not Available.',
	productLaucnhYearText: 'Product Launch Year:',
	mapPolicyText: 'Minimum Advertised Pricing (MAP) Policy:'
};
interface ISalesInformationState {
	salesVelocityText: string;
	aditionalSalesInformationText: string;
	manufacturerSuggestedRetailPriceText: string;
	comingSoonText: string;
	informationNotAvailableText: string;
	productLaucnhYearText: string;
	mapPolicyText: string;
}
export const salesInformationState = {
	...DEFAULT_STATE
};
