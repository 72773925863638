export interface UserProfileData {
	id?: string;
	business_email?: string;
	first_name?: string;
	last_name?: string;
	phones?: string[];
	company?: string;
	country?: string;
	country_name?: string;
	state_name?: string;
	state?: string;
	job_title?: string;
	is_social_login?: boolean;
	business_type?: string;
	job_function?: string;
	social_accounts?: any[];
	business_types?: BusinessType[];
	job_functions?: BusinessType[];
	product_categories?: any[];
	metadata?: Metadata;
	subscribed_for_newsletter?: boolean;
	company_name?: string;
	company_website?: string;
	user_type?: string;
	job_type?: string;
}

export interface BusinessType {
	id?: string;
	division?: string;
	brand?: string;
}

export interface Metadata {
	updated_on?: string;
	created_on?: string;
	updated_on_date?: Date;
	created_on_date?: Date;
}

export interface StateType {
	id?: string;
	name?: string;
}

export interface CountryType {
	id: string;
	name: string;
	group: string;
	locale: string;
	states: StateType[];
}

export const jobTypeOptions = [
	'Buyer',
	'Category Manager',
	'Head of Purchasing',
	'Founder/Owner',
	'Marketing',
	'Sales',
	'Associate'
];

const DEFAULT_STATE: IProfileModel = {
	profileTabTitle: 'Account',
	ingredientsTabTitle: 'Ingredients',
	favoritesTabTitle: 'Favorites',
	brandOnboardTabTitle: 'Getting Started'
};
interface IProfileModel {
	profileTabTitle: string;
	ingredientsTabTitle: string;
	favoritesTabTitle: string;
	brandOnboardTabTitle: string;
}
export const profileModel = {
	...DEFAULT_STATE
};
