const DEFAULT_STATE: IHomePageOnboardModel = {
	title: 'Want Your Products on Beacon Discovery?',
	metrics: [

		{
			value: '16,000+',
			name: 'Products Favorited'
		},
		{
			value: '1,200+',
			name: 'Brands'
		},
		{
			value: '500+',
			name: 'Retailers Use Beacon'
		},
	],
	buttonTitle: 'Get Started'
};

interface IHomePageOnboardModel {
	title: string;
	metrics: { name: string; value: string }[];
	buttonTitle: string;
}
export const HomePageOnboardModel = {
	...DEFAULT_STATE
};
