import { useEffect } from 'react';

const useScrollRestoration = () => {
	useEffect(() => {
		const originalScrollRestoration = window.history.scrollRestoration;
		window.history.scrollRestoration = 'manual';
		return () => {
			window.history.scrollRestoration = originalScrollRestoration;
		};
	}, []);
};

export default useScrollRestoration;
