import { Product } from '../common/model';
import { ITrackingEvent, TrackingEventStatus, TrackingEventType } from '../common/view-model';
import { v4 as uuidv4 } from 'uuid';
import { IirisIdentityKey, getLocalStorageValue } from './authenticationHelper';
import { handleTracking } from './handelTracking';
import { AppState } from '../redux/AppReducer';

export const trackProductsResults = (products: Product[], appState: AppState) => {
	const ids = products.map(product => product.id);
	if (
		(appState.searchState?.searchTerm ||
			(appState.filterState?.selectedFilters &&
				appState.filterState?.selectedFilters?.length > 0)) &&
		ids.length > 0
	) {
		const trackingPayload: ITrackingEvent = {
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.PRODUCTS_APPEAR_ON_FILTERED,
			timeStamp: new Date().toISOString(),
			entityId: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
			eventPayload: {
				id: `products-response-${new Date().toISOString()}`,
				name: 'products-response',
				type: 'products',
				url: window.location.href,
				tags: [],
				metadata: {
					user_type: appState?.userState?.user?.user_type,
					user_id: appState?.userState?.user?.user_id,
					environment: process.env.REACT_APP_ENV_NAME,
					products_ids: ids
				}
			}
		};
		handleTracking(trackingPayload);
	}
};
