import { EmptyProductsError } from '../../common/view-model';

const DEFAULT_STATE: IFilterErrorModel = {
	search: {
		errorTitle: 'No Results Found',
		errorContentFirstLine:
			'Please try another search or browse products on the Product Discovery page.',
		errorButtonText: 'Browse Products'
	},
	filter: {
		errorTitle: "Let's Try That Again",
		errorContentFirstLine: 'No products match your search.',
		errorContentSecondLine: 'Try removing some filters and search again.',
		errorButtonText: 'Clear All Filters'
	}
};

interface IFilterErrorState {
	errorTitle: string;
	errorContentFirstLine: string;
	errorContentSecondLine?: string;
	errorButtonText: string;
}
interface IFilterErrorModel {
	[key: string]: IFilterErrorState;
}
const filterErrorModel = {
	...DEFAULT_STATE
};

export const getFilterErrorModel = (type: EmptyProductsError) => filterErrorModel[type];
