import { gql } from 'graphql.macro';

export const GET_ONBOARDING_PRODUCTS = gql`
	query GetOnboardingProducts($email: String!) {
		getProductsFromAdmin(email: $email) {
			id
			status
			name
			imageUrl
			approvedOn
			productCategory
			productDescription
			imagesLastUpdate
			marketReady
			currentYearNextyFinalist
			currentYearNextyWinner

			flagshipProduct
			showHowGood
			howGoodLabel
			howGoodEstimated
			howGoodAvgEstimated
			dimensions {
				height
				width
				depth
				weight
			}
			productMSRP
			launchYear
			productGTIN
			productShelfLife
			certifiedNonGMO
			certifiedOrganic
			certifiedGlutenFree
			violations {
				allergenMissing
				claimsLogoRequiresCertification
				botanicPartsMissing
				directionsForUse
				factPanelMissing
				ingredientsListMissing
				manufacturerAddressMissing
				misuseFDAApprovedHealthClaim
				organicCertifyingAgentMissing
				netQualityContentsMissing
				statementIdentityMissing
				unclarifiedNutrient
				ingredientStandards
				diseaseClaims
				FDALogoMisuse
				nutrientContentClaims
				labelNotUSRequirements
				containsBannedIngredients
			}
		}
	}
`;
