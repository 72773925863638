import { FC, ReactNode, useEffect, useState, useCallback } from 'react';
import './CompanyDetailsLoadingComponent.scss';
import { companyDetailsLoadingModel as model } from './model';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { UserType } from '../../../../common/model';
import { useNavigate } from 'react-router-dom';
import { findUserAccessType } from '../../../../helpers/findUserAccessType';

interface ICheckMarkComponentProps {
	icon: ReactNode;
	title: string;
	text: string;
}
const CheckMarkComponent: FC<ICheckMarkComponentProps> = ({ icon, title, text }) => {
	return (
		<div className='check-mark-element'>
			{icon}
			<div className='check-mark-text-container'>
				<span className='checkmark-text'>
					{<b className='checkmark-title'>{title}</b>}
					{' - '}
					{text}
				</span>
			</div>
		</div>
	);
};
interface CompanyDetailsLoadingComponentProps {
	hasCreatedBeaconProfile: boolean;
	createBeaconProfileProgress: number;
	userType: string;
	onProgressComplete: () => void;
	clearModalContent: () => void;
	setIsCompanyLoading: (isCompanyLoading: boolean) => void;
}
export const CompanyDetailsLoadingComponent: FC<CompanyDetailsLoadingComponentProps> = ({
	hasCreatedBeaconProfile,
	createBeaconProfileProgress,
	onProgressComplete,
	userType,
	clearModalContent,
	setIsCompanyLoading
}) => {
	const isFinishing = createBeaconProfileProgress === model.maxProgressValue;
	const isAlmostFinished = createBeaconProfileProgress >= model.maxProgressValue - 2;
	const progressClassName = `${isFinishing ? 'fade-out' : ''} ${
		isAlmostFinished ? 'progress-rounded' : ''
	}`;
	const [showButton, setShowButton] = useState<boolean>(false);
	const navigateCallback = useCallback(() => {
		const path = findUserAccessType(userType) === UserType.Retailer ? '/onboarding' : '/landing';
		window.location.href = path;
	}, [userType]);
	useEffect(() => {
		if (createBeaconProfileProgress === model.finishingProgressValue) {
			onProgressComplete();
		}
	}, [createBeaconProfileProgress, onProgressComplete]);
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (createBeaconProfileProgress === model.maxProgressValue) {
				setShowButton(true);
			}
		}, model.timerValue);
		return () => {
			clearTimeout(timeout);
		};
	}, [createBeaconProfileProgress]);
	useEffect(() => {
		setIsCompanyLoading(true);
		return () => {
			setIsCompanyLoading(false);
		};
	}, [setIsCompanyLoading]);
	return (
		<div className='company-details-loading-container'>
			<div className='loading-title-container'>
				<span className='title'>{model.modelTitleText}</span>
			</div>
			<div className='congrats-container'>
				<span className='congrats-text'>{model.congratsText}</span>
			</div>
			<div className='checkmarks-container'>
				{model.creationLoadingCheckMarks.map((checkMarkElement, index) => (
					<CheckMarkComponent
						key={index}
						icon={<i className='ri-checkbox-circle-line'></i>}
						title={checkMarkElement.title}
						text={checkMarkElement.text}
					/>
				))}
			</div>
			<div className='progress-container'>
				{!showButton ? (
					<progress
						className={progressClassName}
						max={model.maxProgressValue}
						value={createBeaconProfileProgress}
					></progress>
				) : (
					<ButtonComponent
						style='rounded-button primary btn-fade-in'
						onClick={navigateCallback}
						text={model.buttonText}
						icon={<i className='ri-arrow-right-s-line line-icon'></i>}
						disabled={!hasCreatedBeaconProfile}
					/>
				)}
			</div>
			<div className='separator'></div>
		</div>
	);
};
