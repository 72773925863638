import './BoothNumberComponent.scss';
import { FC } from 'react';
export interface IBoothNumberComponentProps {
	text: string;
	boothStyle: 'primary' | 'secondary';
	icon: string;
	onClick?: () => void;
}

export const BoothNumberComponent: FC<IBoothNumberComponentProps> = ({
	text,
	boothStyle,
	onClick,
	icon
}) => {
	return (
		<div
			data-testid='booth-number-component'
			className={`booth-number ${boothStyle}`}
			onClick={onClick}
		>
			<i className={icon}></i>
			<span className='booth-text'>{text}</span>
		</div>
	);
};
