import { BrandInfo } from '../redux/AppReducer';

export const buildUpdateObjectBrand = (brand: BrandInfo) => {
	const servicesProviders = brand.otherServicesNeeds?.reduce((result, serviceNeed) => {
		result[serviceNeed.id] = serviceNeed.isActive;
		return result;
	}, {} as Record<string, boolean>);
	const currentRetail = brand.currentRetailChannels?.reduce((result, retail) => {
		result[retail.id] = retail.isActive;
		return result;
	}, {} as Record<string, boolean>);
	const currentDist = brand.currentDistributionChannels?.reduce((result, dist) => {
		result[dist.id] = dist.isActive;
		return result;
	}, {} as Record<string, boolean>);

	const keheReg = brand.keheRegion?.reduce((result, region) => {
		result[region.id] = region.isActive;
		return result;
	}, {} as Record<string, boolean>);
	const unfiReg = brand.UNFIRegion?.reduce((result, region) => {
		result[region.id] = region.isActive;
		return result;
	}, {} as Record<string, boolean>);
	const otherReg = brand.otherDistributorRegion?.reduce((result, region) => {
		result[region.id] = region.isActive;
		return result;
	}, {} as Record<string, boolean>);
	const brandBrokers = brand
		.brokers!.filter(broker => broker.name.length > 0)
		.map((broker, index) => ({
			brandBrokerName: broker.name,
			brandBrokerPhone: broker.phone,
			brandBrokerEmail: broker.email,
			brandBrokerId: index.toString()
		}));
	const attributes = brand.brandAttributes
		.filter(attr => attr.isActive)
		.map(attr => attr.id)
		.join(';');
	const processingTech = brand.processingTechniques
		?.filter(technique => technique.isActive)
		.map(tech => {
			return {
				name: tech.name,
				id: tech.id
			};
		});
	return {
		salesforceAccount: brand.salesforceAccount,
		uniqueApplicationSessionId: brand.uniqueApplicationSessionId,
		brandVideo: brand.brandVideo!.length > 0 ? brand.brandVideo : null,
		ownedByParentCompany: brand.ownedByParentCompany,
		ownedByHoldingCompany: brand.ownedByHoldingCompany,
		holdingCompany: brand.ownedByHoldingCompany
			? brand.holdingCompany!.length > 0
				? brand.holdingCompany
				: null
			: null,
		brandHQCity: brand.brandHQCity!.length > 0 ? brand.brandHQCity : null,
		brandHQCountry: brand.brandHQCountry!.length > 0 ? brand.brandHQCountry : null,
		brandHQState: brand.brandHQState!.length > 0 ? brand.brandHQState : null,
		brandYearFound: brand.brandYearFound! > 0 ? brand.brandYearFound : null,
		parentCompany: brand.ownedByParentCompany
			? brand.parentCompany!.length > 0
				? brand.parentCompany
				: null
			: null,
		brandRepEmail: brand.brandRepEmail!.length > 0 ? brand.brandRepEmail : null,
		brandRepName: brand.brandRepName!.length > 0 ? brand.brandRepName : null,
		brandRepPhone: brand.brandRepPhone!.length > 0 ? brand.brandRepPhone : null,
		brandRepTitle: brand.brandRepTitle!.length > 0 ? brand.brandRepTitle : null,
		productRepEmail: brand.productRepEmail!.length > 0 ? brand.productRepEmail : null,
		productRepName: brand.productRepName!.length > 0 ? brand.productRepName : null,
		productRepPhone: brand.productRepPhone!.length > 0 ? brand.productRepPhone : null,
		productRepTitle: brand.productRepTitle!.length > 0 ? brand.productRepTitle : null,
		brandInstagram: brand.brandInstagram!.length > 0 ? brand.brandInstagram : null,
		brandYoutube: brand.brandYoutube!.length > 0 ? brand.brandYoutube : null,
		brandTwitter: brand.brandTwitter!.length > 0 ? brand.brandTwitter : null,
		brandLinkedin: brand.brandLinkedin!.length > 0 ? brand.brandLinkedin : null,
		brandFacebook: brand.brandFacebook!.length > 0 ? brand.brandFacebook : null,
		brandTiktok: brand.brandTiktok!.length > 0 ? brand.brandTiktok : null,
		brandAttributes: attributes.length > 0 ? attributes : null,
		serviceProviderNeeds: servicesProviders,
		processingTechniques: processingTech,
		hasBroker: brand.haveABroker,
		brokers: brandBrokers,
		currentDistributionChannels: currentDist,
		currentRetailChannels: currentRetail,
		keheRegion: keheReg,
		UNFIRegion: unfiReg,
		otherDistributorRegion: otherReg,
		mapPolicy: brand.mapPolicy,
		activelyRaisingCapital: brand.activelyRaisingCapital,
		revenueLastYear: brand.revenueLastYear ? brand.revenueLastYear : 0,
		revenueExpectedThisYear: brand.revenueExpectedThisYear ? brand.revenueExpectedThisYear : 0,
		privateLabeServices: brand.privateLabelServices
	};
};
