import ProductComponent from '../product/ProductComponent';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../redux/AppReducer';
import { Product } from '../../common/model';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useEffect, useRef, useState } from 'react';
import { ScreenType } from '../../common/view-model';
import { Pagination, Navigation, Swiper as ISwiper, Grid } from 'swiper';
import './HighlightedProductsComponent.scss';
import sustainabilityIcon from '../../assets/images/icons/sustainability.svg';
import { HighlightedProductsState } from './model';
interface HighlightedProductsProps {
	productsHighlightedArr: Product[];
}
export const HighlightedProducts = ({ productsHighlightedArr }: HighlightedProductsProps) => {
	const swiperRef = useRef<ISwiper>();
	const [desktopSlidePerView, setDesktopSlidePerView] = useState(6);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const { favoriteProducts } = useSelector((state: RootState) => state.appState?.userState!);
	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setDesktopSlidePerView(6);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setDesktopSlidePerView(5);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setDesktopSlidePerView(4);
		} else if (deviceScreen === ScreenType.TABLET) {
			setDesktopSlidePerView(3);
		} else if (deviceScreen === ScreenType.MOBILE) {
			setDesktopSlidePerView(4);
		}
	}, [deviceScreen]);
	return (
		<div className='highlighted-products'>
			<div className='highlighted-title'>
				<img className='sustainability-icon' src={sustainabilityIcon} alt='sustainability-icon' />
				{HighlightedProductsState.highlightedProductsTitle}
			</div>
			<div className='highlighted-text'>
				{HighlightedProductsState.highlightedProductsText1}{' '}
				<p className='bold-text'>{HighlightedProductsState.highlightedProductsBoldText}</p>{' '}
				{HighlightedProductsState.highlightedProductsText2}
			</div>
			<div className='divider' />

			<div className='products-carousel-container'>
				{deviceScreen !== ScreenType.MOBILE ? (
					<>
						<Swiper
							slidesPerView={desktopSlidePerView}
							spaceBetween={24}
							pagination={{
								clickable: true
							}}
							lazy={true}
							modules={[Pagination, Navigation]}
							onBeforeInit={swiper => {
								swiperRef.current = swiper;
							}}
						>
							{productsHighlightedArr.length > 0 &&
								productsHighlightedArr.map((product: Product, index: number) => (
									<SwiperSlide key={index}>
										<ProductComponent
											product={product}
											isOnBrandPage={true}
											favorite={favoriteProducts?.some(favorite => favorite.pid === product?.id)}
										></ProductComponent>
									</SwiperSlide>
								))}
						</Swiper>
						<div className='nav-buttons'>
							<button
								className='swiper-button-prev'
								onClick={() => swiperRef.current?.slidePrev()}
							></button>
							<button
								className='swiper-button-next'
								onClick={() => swiperRef.current?.slideNext()}
							></button>
						</div>
					</>
				) : (
					<>
						{productsHighlightedArr.length > 0 && (
							<>
								<Swiper
									observeParents={true}
									slidesPerView={2}
									spaceBetween={30}
									grid={{
										rows: 2,
										fill: 'row'
									}}
									navigation={false}
									pagination={{
										el: '.swiper-custom-pagination-highlighted',
										clickable: true,
										dynamicBullets: productsHighlightedArr.length > 20,
										dynamicMainBullets: 5
									}}
									modules={[Grid, Pagination]}
									onBeforeInit={swiper => {
										swiperRef.current = swiper;
									}}
								>
									{productsHighlightedArr.map((product: Product, index: number) => (
										<SwiperSlide key={index}>
											<ProductComponent
												product={product}
												isOnBrandPage={true}
												favorite={favoriteProducts?.some(favorite => favorite.pid === product?.id)}
											/>
										</SwiperSlide>
									))}
								</Swiper>
								{productsHighlightedArr.length > 4 && (
									<>
										<div className='swiper-custom-pagination-highlighted'></div>
										<div className='control-mobile-for-highlighted'>
											<i
												className='bi bi-chevron-left'
												onClick={() => {
													swiperRef.current?.slidePrev();
												}}
											></i>
											<i
												className='bi bi-chevron-right'
												onClick={() => {
													swiperRef.current?.slideNext();
												}}
											></i>
										</div>
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	productsHighlightedArr: state.appState.storeState?.productsHighlighted?.products || []
});

export default connect(mapStateToProps)(HighlightedProducts);
