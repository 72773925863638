import { gql } from 'graphql.macro';

export const GET_CURRENT_EVENTS = gql`
	query {
		getCurrentEvent {
			id
			name
			startDate
			endDate
			floorMapUrl
			exhibitorHubUrl
			agendaUrl
			exhibitorListUrl
		}
	}
`;
