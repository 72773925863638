import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FilterActions } from '../../redux/FilterActions';
import { AppActions } from '../../redux/AppActions';
import './index.scss';
import { SearchActions } from '../../redux/search/SearchActions';
import { EmptyProductsError } from '../../common/view-model';
import filterErrorImg from '../../assets/images/filterError.png';
import searchError from '../../assets/images/searchError.svg';
import { getFilterErrorModel } from './model';
import { useNavigate } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';

export interface FilterErrorComponentProps {
	type: EmptyProductsError;
}

export const FilterErrorComponent: FC<FilterErrorComponentProps> = ({ type }) => {
	const model = getFilterErrorModel(type);
	const imageSource = type === 'search' ? searchError : filterErrorImg;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const productsUrl = '/products';
	const clearAllFilter = useCallback(() => {
		dispatch(FilterActions.clearAllFilters());
		dispatch(AppActions.setGeneralError({ hasError: false }));
		dispatch(SearchActions.setSearchTerm(undefined));
		navigate(`${isCurrentUserAnonymous() ? '..' : ''}${productsUrl} `);
	}, [dispatch]);
	return (
		<div className='filter-error-container'>
			<div className={type}>
				<img src={imageSource} alt='error' className={`error-img ${type}`} />
				<h3 className='error-title'>{model.errorTitle}</h3>
				<p className={`error-content ${type}`}>
					{model.errorContentFirstLine}
					{type === 'filter' && (
						<>
							<br /> {model.errorContentSecondLine}{' '}
						</>
					)}
				</p>
				<button className='error-btn' onClick={clearAllFilter}>
					{model.errorButtonText}
				</button>
			</div>
		</div>
	);
};
