import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: INeedToMakeChangesState = {
	title: 'Get the Support You Need for Your Updates!',
	headerPrevText: 'New Hope Network will complete any updates on your brand’s behalf, just email',
	headerNextText: 'and say, “I want New Hope to update my brand/product data for me.”',
	headerEmailLink: newHopeAudienceEmail,
	firstCardHeaderPrevText: 'Update product data and images in the Product Portal',
	firstCardHeaderTextLink: 'here',
	firstCardHeaderLink: 'https://pi-manager.spins.com/manager/manage',
	firstCardContentText:
		'Upload new products, delete existing ones, or update product information that has changed since you last exhibited. Skip this step with the Support option above!',
	secondCardHeaderText: 'See your products on Beacon Discovery',
	secondCardContentText:
		'Once approved by our Standards team, your brand can be discovered by retailers on Beacon immediately!'
};

interface INeedToMakeChangesState {
	title: string;
	headerPrevText: string;
	headerNextText: string;
	headerEmailLink: string;

	firstCardHeaderPrevText: string;
	firstCardHeaderTextLink: string;
	firstCardHeaderLink: string;
	firstCardContentText: string;

	secondCardHeaderText: string;
	secondCardContentText: string;
}
export const NeedToMakeChangesModel = {
	...DEFAULT_STATE
};
