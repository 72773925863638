import { ofType } from 'redux-observable';
import { Observable, catchError, mergeMap, of } from 'rxjs';
import { EngagementDashboardActions } from './EngagementDashboardActions';
import data from '../../mock/engagementDashboard/data.json';
export class EngagementDashboardEpics {
	static init() {
		return [EngagementDashboardEpics.getEngagementData];
	}
	static getEngagementData(action$: Observable<any>): Observable<any> {
		return action$.pipe(
			ofType(EngagementDashboardActions.START_FETCHING_ENGAGEMENT_DATA),
			mergeMap(_action => {
				return of(EngagementDashboardActions.fetchEngagementDataSuccess(data as any));
			}),
			catchError(error => {
				return of(EngagementDashboardActions.fetchEngagementDataFailure());
			})
		);
	}
}
