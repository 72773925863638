import { Action } from 'redux-actions';
import { EngagementDashboard } from '../../common/view-model';

export class EngagementDashboardActions {
	static readonly START_FETCHING_ENGAGEMENT_DATA =
		'@@engagementDashboard/START_FETCHING_ENGAGEMENT_DATA';
	static readonly FETCH_ENGAGEMENT_DATA_SUCCESS =
		'@@engagementDashboard/FETCH_ENGAGEMENT_DATA_SUCCESS';
	static readonly FETCH_ENGAGEMENT_DATA_FAILURE =
		'@@engagementDashboard/FETCH_ENGAGEMENT_DATA_FAILURE';

	static startFetchingEngagementData = (brandId: string): Action<string> => {
		return {
			type: EngagementDashboardActions.START_FETCHING_ENGAGEMENT_DATA,
			payload: brandId
		};
	};
	static fetchEngagementDataSuccess = (data: EngagementDashboard): Action<EngagementDashboard> => {
		return {
			type: EngagementDashboardActions.FETCH_ENGAGEMENT_DATA_SUCCESS,
			payload: data
		};
	};
	static fetchEngagementDataFailure = (): Action<any> => {
		return {
			type: EngagementDashboardActions.FETCH_ENGAGEMENT_DATA_FAILURE,
			payload: undefined
		};
	};
}
