import { FC } from 'react';
import { ProductVM } from '../../../common/view-model';
import './DetailBreadcrumbComponent.scss';

interface IDetailBreadcrumbComponentProps {
	product: ProductVM | undefined;
}

export const DetailBreadcrumbComponent: FC<IDetailBreadcrumbComponentProps> = ({ product }) => {
	const allProductsText = 'All Products';

	const renderBreadcrumb = (index: number) => {
		const hasMore = product?.taxonomy && index < product.taxonomy.length - 1;
		return (
			<span key={index} className={`breadcrumb-text ${hasMore ? 'has-more' : 'last'}`}>
				{product?.taxonomy && product.taxonomy[index]?.name}
			</span>
		);
	};

	return (
		<div className='detail-breadcrumb-container'>
			<span className={`breadcrumb-text ${product?.taxonomy[0] ? 'has-more' : ''}`}>
				{allProductsText}
			</span>
			{product?.taxonomy && product.taxonomy.map((_, index) => renderBreadcrumb(index))}
		</div>
	);
};
