import { gql } from 'graphql.macro';

export const GET_TAXONOMY_NAVBAR = gql`
	query {
		taxonomyNavbar {
			id
			name
			type
			children {
				id
				name
				type
				children {
					id
					name
					type
				}
			}
		}
	}
`;
