import { FC } from 'react';
import './LoadingIngredientsTemplate.scss';

const LoadingIngredientsTemplateCardComponent = () => {
	return <div className='templates-skeleton loaing-template-card'></div>;
};
const LoadingIngredientPillComponent = () => {
	return <div className='templates-skeleton loading-pill'></div>;
};

interface LoadingIngredientsTemplateComponentProps {
	loadingTopPillsCount: number;
	loadingBottomPillsCount: number;
	loadingCardsCount: number;
	isMobile: boolean;
	profilePage: boolean;
}

export const LoadingIngredientsTemplateComponent: FC<LoadingIngredientsTemplateComponentProps> = ({
	loadingTopPillsCount,
	loadingBottomPillsCount,
	loadingCardsCount,
	isMobile,
	profilePage
}) => {
	const loadingTopPillsIndices = Array.from(
		{ length: loadingTopPillsCount },
		(_, index) => index + 1
	);
	const loadingBottomPillsIndices = Array.from(
		{ length: loadingBottomPillsCount },
		(_, index) => index + 1
	);
	const loadingCardsIndices = Array.from({ length: loadingCardsCount }, (_, index) => index + 1);
	if (isMobile) {
		return (
			<div
				className={`loading-ingredients-template-container ${profilePage ? 'profile-wrapper' : ''}`}
			>
				<div className='loading-ingredients-template-header'>
					{loadingTopPillsIndices.map((index: number) => (
						<LoadingIngredientPillComponent key={index} />
					))}
				</div>
				<div className={`loading-cards-container ${profilePage ? 'profile-cards' : ''}`}>
					{loadingCardsIndices.map((index: number) => (
						<LoadingIngredientsTemplateCardComponent key={index} />
					))}
				</div>
				<div className={`dots-conteiner ${profilePage ? 'profile-dots' : ''}`}>
					{[1, 2, 3].map((index: number) => (
						<div key={index}></div>
					))}
				</div>
				<div className='open-detail-comparison-container'>
					<LoadingIngredientPillComponent />
				</div>
				<div className='seperator-container'>
					<div className='separator'></div>
				</div>
				<div className='loading-ingredients-template-footer'>
					{loadingBottomPillsIndices.map((index: number) => (
						<LoadingIngredientPillComponent key={index} />
					))}
				</div>
			</div>
		);
	}
	return (
		<div
			className={`loading-ingredients-template-container ${profilePage ? 'profile-wrapper' : ''}`}
		>
			{loadingTopPillsIndices.length > 0 && (
				<div className='loading-ingredients-template-header'>
					{loadingTopPillsIndices.map((index: number) => (
						<LoadingIngredientPillComponent key={index} />
					))}
				</div>
			)}

			<div className='loading-cards-container'>
				{loadingCardsIndices.map((index: number) => (
					<LoadingIngredientsTemplateCardComponent key={index} />
				))}
			</div>
			<div className='loading-ingredients-template-footer'>
				{loadingBottomPillsIndices.map((index: number) => (
					<LoadingIngredientPillComponent key={index} />
				))}
			</div>
		</div>
	);
};
