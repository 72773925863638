import { FC, Suspense, useEffect, useState } from 'react';
import './SearchPredictionComponent.scss';
import { ISuggestion } from '../../../common/view-model';
import { SearchPredictionLoadingComponent } from './SearchPredictionLoadingComponent/SearchPredictionLoadingComponent';
import useInvalidConnectionBrowser from '../../../hooks/useInvalidConnectionBrowser';

interface ISearchSuggestionItemComponentProps {
	suggestion: ISuggestion;
	onSuggestionSelected: (suggestion: string, type: string) => void;
	setValueCallback: (value: string) => void;
	value?: string;
	type: string;
}
const SearchSuggestionItemComponent: FC<ISearchSuggestionItemComponentProps> = ({
	suggestion,
	onSuggestionSelected,
	setValueCallback,
	value,
	type
}) => {
	const getStyledSuggestion = (suggestion: string) => {
		if (value) {
			if (!suggestion.toLowerCase().startsWith(value.toLowerCase())) {
				return <span className='default-case'>{suggestion}</span>;
			}
			const userInputLength = value.length;
			return (
				<span className='rest-case'>
					<span className='matching-case'>{suggestion.substring(0, userInputLength)}</span>
					{suggestion.substring(userInputLength)}
				</span>
			);
		}
	};
	const handleClick = () => {
		onSuggestionSelected(suggestion.name, type);
		setValueCallback(suggestion.name);
	};
	return (
		<div className='suggestion-item-container' onClick={handleClick}>
			{getStyledSuggestion(suggestion.name)}
		</div>
	);
};

export interface ISearchPredictionComponentProps {
	suggestions: ISuggestion[];
	onSuggestionSelected: (suggestion: string, type: string) => void;
	value?: string;
	setValueCallback: (value: string) => void;
	showPrediction: boolean;
	loadingSearch: boolean;
}
export const SearchPredictionComponent: FC<ISearchPredictionComponentProps> = ({
	suggestions,
	onSuggestionSelected,
	value,
	setValueCallback,
	showPrediction,
	loadingSearch
}) => {
	const invalidConnectionBrowser = useInvalidConnectionBrowser();
	if ((!suggestions?.length || !value || !showPrediction) && !loadingSearch) return null;
	if (loadingSearch && !invalidConnectionBrowser) {
		return <SearchPredictionLoadingComponent />;
	}
	return (
		<Suspense fallback={<span>Loading...</span>}>
			{suggestions?.length > 0 && (
				<div className='search-prediction-container'>
					{suggestions.map((suggestion, index) => (
						<SearchSuggestionItemComponent
							key={index}
							suggestion={suggestion}
							onSuggestionSelected={onSuggestionSelected}
							setValueCallback={setValueCallback}
							value={value}
							type={suggestion.type}
						/>
					))}
				</div>
			)}
		</Suspense>
	);
};
