import { ofType } from 'redux-observable';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Action } from 'redux-actions';
import { ScreenActions } from './ScreenActions';
import { ScreenType } from '../common/view-model';

const MOBILE_WIDTH = 743;
const TABLET_WIDTH = 992;
const SMALL_DESKTOP_WIDTH = 1280;
const MEDIUM_DESKTOP_WIDTH = 1430;

export class ScreenEpics {
	static init() {
		return [ScreenEpics.setTabletScreenEpic];
	}
	static setTabletScreenEpic = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(ScreenActions.SET_SCREEN_DEVICE),
			map((action: Action<any>) => {
				const width: number = action.payload.width;
				let screenType: ScreenType | undefined;
				if (width < MOBILE_WIDTH) {
					screenType = ScreenType.MOBILE;
				} else if (width >= MOBILE_WIDTH && width <= TABLET_WIDTH) {
					screenType = ScreenType.TABLET;
				} else if (width > TABLET_WIDTH && width <= SMALL_DESKTOP_WIDTH) {
					screenType = ScreenType.SMALL_DESKTOP;
				} else if (width > SMALL_DESKTOP_WIDTH && width <= MEDIUM_DESKTOP_WIDTH) {
					screenType = ScreenType.MEDIUM_DESKTOP;
				} else if (width > MEDIUM_DESKTOP_WIDTH) {
					screenType = ScreenType.LARGE_DESKTOP;
				}

				return ScreenActions.updateCurrentScreen(screenType);
			})
		);
	};
}
