import { Action } from 'redux-actions';
import { ScreenType } from '../common/view-model';
import { AppState } from './AppReducer';

export class ScreenActions {
	static readonly SET_SCREEN_DEVICE = '@@screen/SET_SCREEN_DEVICE';
	static readonly UPDATE_CURRENT_SCREEN = '@@screen/UPDATE_CURRENT_SCREEN';

	static setScreenDevice(width: number): Action<AppState> {
		return Object.assign({
			type: ScreenActions.SET_SCREEN_DEVICE,
			payload: {
				width
			}
		});
	}
	static updateCurrentScreen(payload: ScreenType | undefined): Action<AppState> {
		return Object.assign({
			type: ScreenActions.UPDATE_CURRENT_SCREEN,
			payload: {
				currentScreen: payload
			}
		});
	}
}
