import { FilterVM } from '../common/view-model';
import {
	firstLevelCategoryIdentifier,
	flatLevelCategoryIdentifier,
	parentCategoryIdentifier,
	secondLevelCategoryIdentifier
} from './paramsUrlIndetifiers';
import { flatFirstLevelFilters, flatThirdLevelChildren } from './urlParametersHelpers';

export const getTemplateIngredientsByPurpose = (template: any) => {
	const { ingredients } = template;

	let purposesMap: { [key: string]: Array<any> } = {};

	let purposes = [];

	let i = 1;

	for (const ingredient of ingredients) {
		let simpleIngredient = {
			id: ingredient.id,
			name: ingredient.name,
			categories: ingredient.categories,
			usages: ingredient.usages,
			displayName: ingredient.display_name
		};
		if (!purposesMap[ingredient.purpose]) {
			purposesMap[ingredient.purpose] = [simpleIngredient];
		} else {
			purposesMap[ingredient.purpose].push(simpleIngredient);
		}
	}

	for (const purpose in purposesMap) {
		purposes.push({
			id: i.toString(),
			name: purpose,
			ingredients: purposesMap[purpose]
		});
		i++;
	}

	return purposes;
};

export const getRedirectUrl = (
	productsPageUrl: string,
	filter: FilterVM,
	allCategories: FilterVM[]
) => {
	let result = '';
	if (filter.isSuperCategory) {
		result = `${productsPageUrl}?${parentCategoryIdentifier}=${filter.parentId}&${secondLevelCategoryIdentifier}=${filter.id}`;
	} else {
		const flattenThirdLevel = flatThirdLevelChildren(allCategories);
		const firstLevelFilters = flatFirstLevelFilters(allCategories);
		const thirdLevelFilters = flattenThirdLevel.flatMap((child: FilterVM) =>
			child.children ? child.children : []
		);
		const secondLevelIdentifier = firstLevelFilters.find(
			(f: FilterVM) => f.id === filter.id
		)?.parentId;
		filter = {
			...filter,
			children: thirdLevelFilters.find(child => child.id === filter.id)?.children || []
		};
		let queryParams = '';
		const childrenIds = filter?.children?.map((child: FilterVM) => child.id) || [];
		if (!filter.hasBasedLevelFilters) {
			queryParams = `?${parentCategoryIdentifier}=${
				filter.parentId
			}&${secondLevelCategoryIdentifier}=${secondLevelIdentifier}&${firstLevelCategoryIdentifier}=${
				filter.id
			}${childrenIds.length > 0 ? `&${flatLevelCategoryIdentifier}=${childrenIds.join(',')}` : ''}`;
			result = `${productsPageUrl}${queryParams}`;
		} else {
			const secondLevelIdentifier = firstLevelFilters.find(f => f.id === filter.parentId)?.parentId;
			queryParams = `?${parentCategoryIdentifier}=${filter.parentFilterId}&${secondLevelCategoryIdentifier}=${secondLevelIdentifier}&${flatLevelCategoryIdentifier}=${filter.id}`;
			result = `${productsPageUrl}${queryParams}`;
		}
	}
	return result;
};
