import './MarketingAssetsComponent.scss';
import { ProductVM } from '../../../../common/view-model';
import { marketingAssetsState } from './model';
import { FC, useState } from 'react';
import { validateUrl } from '../../../../helpers/validateUrl';
interface IMarketingAssetsComponentProps {
	product: ProductVM | undefined;
}
export const MarketingAssetsComponent: FC<IMarketingAssetsComponentProps> = ({ product }) => {
	const [state] = useState({ ...marketingAssetsState });
	const handleClick = (event: any) => {
		event.preventDefault();
		window.open(validateUrl(product?.brand.public_url!), '_blank');
	};
	return (
		<div data-testid='marketing-assets-container' className='marketing-container'>
			{product?.brand.public_url ? (
				<>
					<span className='marketing-title'>{state.marketingAssetsText}</span>
					<div
						data-testid='download-marketing-assets'
						className='download-assets'
						onClick={handleClick}
					>
						<span className='marketing-title'>{state.dowloadAssetsText}</span>
					</div>
				</>
			) : (
				<span data-testid='no-marketing-assets' className='marketing-title'>
					<span className='marketing-title'>{state.noMarketingAssetsText}</span>
				</span>
			)}
		</div>
	);
};
