import { useDispatch, useSelector } from 'react-redux';
import { IUserMenuItemProps, UserMenuItemComponent } from '../user-menu-item/userMenuItemComponent';
import './userMenuComponent.scss';
import { FC, useCallback, useState } from 'react';
import { AuthActions } from '../../../redux/AuthActions';
import { UserMenuState } from './model';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/AppReducer';
import { UserType } from '../../../common/model';
import { findUserAccessType } from '../../../helpers/findUserAccessType';
import { AppActions } from '../../../redux/AppActions';
import { PROFILE_ROUTES } from '../../../common/constants';

interface IUserMenuComponent {
	onClose: () => void;
	mobileView?: boolean;
	eventLink?: string;
}

export const UserMenuComponent: FC<IUserMenuComponent> = ({ onClose, mobileView, eventLink }) => {
	const expoMapOption = 'Expo Show Floor Map';
	const dispatch = useDispatch();
	const logout = useCallback(() => dispatch(AuthActions.logout()), [dispatch]);
	const [state] = useState({ ...UserMenuState });
	const navigate = useNavigate();
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const goToPage = (path: string) => {
		dispatch(AppActions.setGeneralError({ hasError: false }));
		navigate(path);
	};
	const [menuItems] = useState<IUserMenuItemProps[]>(
		!mobileView
			? [
					{
						name: `${userState?.first_name} ${userState?.last_name} `,
						subTitle: 'View Your Profile',
						icon: 'ri-account-circle-line',
						onClick: () => {
							goToPage(PROFILE_ROUTES.PROFILE);
						}
					},
					{
						name: 'Get your products on to Beacon Discovery!',
						onClick: () => {
							goToPage(
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
									? PROFILE_ROUTES.GET_ON_BEACON
									: '/profile#5'
							);
						}
					},
					{
						name: 'Your Favorites',
						icon: 'ri-heart-line',
						onClick: () => {
							goToPage(
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
									? PROFILE_ROUTES.FAVORITES
									: '/profile#3'
							);
						}
					},
					{
						name: 'My Beacon Hub',
						onClick: () => {
							goToPage(PROFILE_ROUTES.BRAND_INFO);
						}
					},
					{
						name: 'Ingredients Template',
						icon: 'ri-file-list-2-line',
						onClick: () => {
							goToPage(
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
									? PROFILE_ROUTES.INGREDIENTS
									: '/profile#2'
							);
						}
					},
					{
						name: 'Log Out',
						icon: 'ri-logout-box-line',
						onClick: () => {
							document?.getElementById(state.iirisLogoutButtonId)?.click();
							logout();
						}
					}
			  ]
			: [
					{
						name: `${userState?.first_name} ${userState?.last_name} `,
						subTitle: 'View Your Profile',
						icon: 'ri-account-circle-line',
						onClick: () => {
							goToPage(PROFILE_ROUTES.PROFILE);
						}
					},
					{
						name: 'Get your products on to Beacon Discovery!',
						onClick: () => {
							goToPage(
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
									? PROFILE_ROUTES.GET_ON_BEACON
									: '/profile#5'
							);
						}
					},
					{
						name: 'Your Favorites',
						icon: 'ri-heart-line',
						onClick: () => {
							goToPage(
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
									? PROFILE_ROUTES.FAVORITES
									: '/profile#3'
							);
						}
					},
					{
						name: 'Ingredients Template',
						icon: 'ri-file-list-2-line',
						onClick: () => {
							goToPage(
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
									? PROFILE_ROUTES.INGREDIENTS
									: '/profile#2'
							);
						}
					},
					{
						name: 'My Beacon Hub',
						onClick: () => {
							goToPage(PROFILE_ROUTES.BRAND_INFO);
						}
					},
					{
						name: 'Expo Show Floor Map',
						icon: 'ri-map-2-line',
						onClick: () => {
							if (eventLink) {
								window.open(eventLink, '_blank', 'noopener noreferrer');
							}
						}
					},
					{
						name: 'Log Out',
						icon: 'ri-logout-box-line',
						onClick: () => {
							document?.getElementById(state.iirisLogoutButtonId)?.click();
							logout();
						}
					}
			  ]
	);
	const filteredMenuItems =
		findUserAccessType(userState?.user_type) === UserType.Brand
			? menuItems
					.filter(item => item.name !== 'Ingredients Template')
					.filter(menuItem => {
						if (!userState.isBrandAdmin && menuItem.name === 'My Beacon Hub') {
							return false;
						}
						return true;
					})
			: menuItems;
	return (
		<div className='user-menu' id='user-menu'>
			{filteredMenuItems
				.filter(item => (!eventLink ? item.name !== expoMapOption : item))
				.map((item: IUserMenuItemProps, index: number) => (
					<UserMenuItemComponent
						data-testid='user-menu-item'
						key={index}
						name={item.name}
						onClick={() => {
							item.onClick();
							onClose();
						}}
						icon={item?.icon}
						subTitle={item?.subTitle}
					/>
				))}
		</div>
	);
};
