import { ofType } from 'redux-observable';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Action } from 'redux-actions';
import { ToastActions } from './ToastActions';
export class ToastEpics {
	static init() {
		return [ToastEpics.setToastEpic, ToastEpics.clearToastEpic];
	}
	static setToastEpic = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(ToastActions.SET_TOAST),
			map((action: Action<any>) => {
				return ToastActions.updateToast(action.payload);
			})
		);
	};
	static clearToastEpic = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(ToastActions.CLEAR_TOAST),
			map((action: Action<any>) => {
				return ToastActions.updateToast(action.payload);
			})
		);
	};
}
