import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './TooltipFormComponent.scss';
import ReactDOM from 'react-dom';
import React from 'react';

interface ITooltipProps {
	children: React.ReactElement;
	text: React.ReactElement;
}

const TooltipFormComponent: React.FC<ITooltipProps> = ({ children, text }) => {
	const [show, setShow] = useState(false);
	const childRef = useRef<HTMLDivElement>(null);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [tooltipHeight, setTooltipHeight] = useState(0);
	const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
	const [readyToShow, setReadyToShow] = useState(false);
	const handleOutsideClick = (event: MouseEvent) => {
		if (
			childRef.current &&
			!childRef.current.contains(event.target as Node) &&
			tooltipRef.current &&
			!tooltipRef.current.contains(event.target as Node)
		) {
			setShow(false);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		window.addEventListener('scroll', updateTooltipPosition, true);
		return () => {
			document.addEventListener('mousedown', handleOutsideClick);
			window.removeEventListener('scroll', updateTooltipPosition, true);
		};
	});
	const updateTooltipPosition = () => {
		if (childRef.current) {
			const rect = childRef.current.getBoundingClientRect();
			setTooltipPosition({
				top: rect.top - tooltipHeight,
				left: rect.left + rect.width / 2
			});
			setReadyToShow(true);
		}
	};
	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		updateTooltipPosition();
		setShow(!show);
	};
	useEffect(() => {
		updateTooltipPosition();
	}, [tooltipHeight]);
	useLayoutEffect(() => {
		if (tooltipRef.current) {
			const height = tooltipRef.current.getBoundingClientRect().height;
			setTooltipHeight(height);
		}
	}, [show, tooltipRef, childRef]);
	const tooltip = (
		<div
			ref={tooltipRef}
			className='tooltip'
			style={{
				opacity: readyToShow ? 1 : 0,
				position: 'fixed',
				left: `${tooltipPosition.left + 1}px`,
				top: `${tooltipPosition.top}px`,
				transform: 'translate(-50%)',
				zIndex: 1000,
				maxWidth: 330
			}}
		>
			{text}
			<div
				style={{
					position: 'absolute',
					top: '100%',
					left: '50%',
					marginLeft: '-5px',
					borderWidth: '5px',
					borderStyle: 'solid',
					borderColor:
						tooltipHeight > 0
							? 'rgb(92,102,111) transparent transparent transparent'
							: 'transparent'
				}}
			/>
		</div>
	);

	return (
		<div ref={childRef} onClick={handleClick} className='tooltip-form-container'>
			{React.cloneElement(children)}
			{show && ReactDOM.createPortal(tooltip, document.body)}
		</div>
	);
};

export default React.memo(TooltipFormComponent);
