const DEFAULT_STATE: ICompanyDetailsLoadingModel = {
	modelTitleText: 'Creating Your Account...',
	congratsText:
		'Congrats! You’ve just taken the first step towards being part of a more informed CPG discovery process!',
	creationLoadingCheckMarks: [
		{
			title: 'Speed to Shelf',
			text: 'Save Time, Save Effort'
		},
		{
			title: 'True Sustainability',
			text: 'Evidence Based, Verified Claims'
		},
		{
			title: 'Aligned Values',
			text: 'Let your values lead the conversation'
		},
		{
			title: 'Data to Target Your Market',
			text: 'Find the right fit'
		}
	],
	maxProgressValue: 100,
	buttonText: 'Next',
	finishingProgressValue: 90,
	timerValue: 500
};
interface ICreationLoadingCheckMarks {
	title: string;
	text: string;
}
interface ICompanyDetailsLoadingModel {
	modelTitleText: string;
	congratsText: string;
	creationLoadingCheckMarks: ICreationLoadingCheckMarks[];
	maxProgressValue: number;
	buttonText: string;
	finishingProgressValue: number;
	timerValue: number;
}
export const companyDetailsLoadingModel = {
	...DEFAULT_STATE
};
