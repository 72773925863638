import { FC, useState } from 'react';
import { ProductVM } from '../../../../common/view-model';
import './DistributionComponent.scss';
import { distributionState } from './model';
interface IDistributionComponentProps {
	product: ProductVM | undefined;
}
export const DistributionComponent: FC<IDistributionComponentProps> = ({ product }) => {
	const [state] = useState({ ...distributionState });
	const joinedChannels = product?.brand.current_retail_channels?.split(';').join(',').concat('.');
	const mappedValues = state.mapValuesForTable(product?.brand?.regionsBydistributor);
	return (
		<div data-testid='distribution-component' className='distribution-container'>
			<span>{state.title}</span>
			<div className='distribution-table-container'>
				<span className='distribution-title'>{state.tableTitle}</span>
				{mappedValues && Object.keys(mappedValues).length > 0 && (
					<table>
						<tbody>
							{Object.entries(mappedValues).map(([distributor_name, region_name]: any) => (
								<tr key={distributor_name}>
									<td
										className={distributor_name === 'Not in Distribution' ? 'not-distribution' : ''}
									>
										{distributor_name}
									</td>
									<td>{region_name}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			<div data-testid='distribution-block' className='distribution-block'>
				<span className='distribution-title'>{state.channelsText.key}</span>
				<span>{joinedChannels}</span>
			</div>
		</div>
	);
};
