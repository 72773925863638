import { IToastState } from '../../common/view-model';
import { Action } from 'redux-actions';
import { AppState } from '../AppReducer';
export class ToastActions {
	static readonly SET_TOAST = '@@toast/SET_TOAST';
	static readonly CLEAR_TOAST = '@@toast/CLEAR_TOAST';
	static readonly UPDATE_TOAST = '@@toast/UPDATE_TOAST';

	static setToast(payload: IToastState): Action<AppState> {
		return Object.assign({
			type: ToastActions.SET_TOAST,
			payload
		});
	}
	static clearToast(): Action<AppState> {
		return Object.assign({
			type: ToastActions.CLEAR_TOAST
		});
	}
	static updateToast(payload?: IToastState): Action<AppState> {
		return Object.assign({
			type: ToastActions.UPDATE_TOAST,
			payload
		});
	}
}
