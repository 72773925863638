import React from 'react';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import './FinancialFormComponent.scss';
import { useEffect, useState } from 'react';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { BrandInfo, IProfileState } from '../../../../redux/AppReducer';
import { DEFAULT_LABELS } from './model';
import LoadingBobComponent from '../../GenericBaseForms/LoadingBobComponent/LoadingBobComponent';
interface IFinancialForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (updatebrand: BrandInfo) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
	profileState: IProfileState;
}
const BrandFormSchema = Yup.object().shape({
	activelyRaisingCapital: Yup.boolean(),
	revenueLastYear: Yup.number(),
	revenueExpectedThisYear: Yup.number()
});

const FinancialFormComponent: React.FC<IFinancialForm> = ({
	brandState,
	onUpdateProfileBrand,
	isEditing,
	toggleEditing,
	onCancel,
	onChange,
	onValidationChange,
	cleanForm,
	profileState
}) => {
	const ID = 'financial-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	const { errors, values, handleChange, handleReset, submitForm, isValid, setValues } = useFormik({
		initialValues: {
			...brandState,
			activelyRaisingCapital: brandState.activelyRaisingCapital
				? brandState.activelyRaisingCapital
				: false,
			revenueLastYear: brandState.revenueLastYear ? brandState.revenueLastYear : 0,
			revenueExpectedThisYear: brandState.revenueExpectedThisYear
				? brandState.revenueExpectedThisYear
				: 0
		},
		onSubmit: values => {
			onUpdateProfileBrand({
				...values,
				revenueExpectedThisYear: values.revenueExpectedThisYear,
				revenueLastYear: values.revenueLastYear,
				activelyRaisingCapital: values.activelyRaisingCapital
			});
		},
		validationSchema: BrandFormSchema
	});
	useEffect(() => {
		onChange({
			...values,
			revenueExpectedThisYear: values.revenueExpectedThisYear,
			revenueLastYear: values.revenueLastYear,
			activelyRaisingCapital: values.activelyRaisingCapital
		});
		onValidationChange(isValid);
	}, [values, isValid]);

	useEffect(() => {
		handleReset(null);
	}, [brandState]);
	useEffect(() => {
		if (isEditing) {
			setValues({
				...brandState,
				activelyRaisingCapital: brandState.activelyRaisingCapital
					? brandState.activelyRaisingCapital
					: false,
				revenueLastYear: brandState.revenueLastYear ? brandState.revenueLastYear : 0,
				revenueExpectedThisYear: brandState.revenueExpectedThisYear
					? brandState.revenueExpectedThisYear
					: 0
			});
		}
	}, [brandState, isEditing]);
	return (
		<div className='financial-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				isValid={isValid}
				onUpdate={() => submitForm()}
				titleText='Financial Info'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
				profileState={profileState}
			></TitleForms>
			{ID === profileState.sectionId && profileState.loading ? (
				<LoadingBobComponent></LoadingBobComponent>
			) : (
				<>
					{isVisible &&
						(isEditing !== ID ? (
							<>
								<p className='description-form'>{DEFAULT_LABELS.info_text}</p>
								<ContainerForms
									FormComponent1={
										<>
											<div>
												<LabelForms text={DEFAULT_LABELS.activelyRaisingCapitalC}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='activelyRaisingCapital'
													value={brandState.activelyRaisingCapital!}
													disabled={true}
													onChange={handleChange}
													onClick={() => toggleEditing(ID)}
												></CheckboxFormComponent>
											</div>
										</>
									}
									FormComponent2={
										<>
											<div>
												<LabelForms text={DEFAULT_LABELS.revenueLastYear}></LabelForms>
												<p
													className={brandState?.revenueLastYear ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState.revenueLastYear ? `$${brandState.revenueLastYear}` : ''}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.revenueExpectedThisYear}></LabelForms>
												<p
													className={brandState?.revenueExpectedThisYear ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState.revenueExpectedThisYear
														? `$${brandState.revenueExpectedThisYear}`
														: ''}
												</p>
											</div>
										</>
									}
								></ContainerForms>
							</>
						) : (
							<>
								<p className='description-form'>{DEFAULT_LABELS.info_text}</p>
								<ContainerForms
									FormComponent1={
										<>
											<div>
												<LabelForms text={DEFAULT_LABELS.activelyRaisingCapitalC}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='activelyRaisingCapital'
													value={values.activelyRaisingCapital!}
													disabled={false}
													onChange={handleChange}
												></CheckboxFormComponent>
											</div>
										</>
									}
									FormComponent2={
										<>
											<div>
												<LabelForms text={DEFAULT_LABELS.revenueLastYear}></LabelForms>
												<TextInputComponent
													error={errors.revenueLastYear?.length! > 0}
													errorHelperText={<div>{errors.revenueLastYear}!</div>}
													placeholder={DEFAULT_LABELS.revenueLastYear}
													name='revenueLastYear'
													onChange={handleChange}
													value={values.revenueLastYear!}
													type='number'
												></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.revenueExpectedThisYear}></LabelForms>
												<TextInputComponent
													error={errors.revenueExpectedThisYear?.length! > 0}
													errorHelperText={<div>{errors.revenueExpectedThisYear}!</div>}
													placeholder={DEFAULT_LABELS.revenueExpectedThisYear}
													name='revenueExpectedThisYear'
													onChange={handleChange}
													value={values.revenueExpectedThisYear!}
													type='number'
												></TextInputComponent>
											</div>
										</>
									}
								></ContainerForms>

								<ActionsForms
									onUpdate={() => submitForm()}
									onCancel={() => {
										onCancel();
										handleReset(null);
									}}
									isValid={isValid}
									toggleEditing={() => toggleEditing(null)}
								></ActionsForms>
							</>
						))}
				</>
			)}
		</div>
	);
};

export default React.memo(FinancialFormComponent);
