import { gql } from 'graphql.macro';
export const UPDATE_PROFILE = gql`
	mutation UpdateProfile($ingredientsTemplateId: String, $companyDetails: CompanyDetailsInput) {
		updateProfile(
			updateProfile: {
				ingredientsTemplateId: $ingredientsTemplateId
				companyDetails: $companyDetails
			}
		) {
			ingredientsTemplateId
			favoriteProducts {
				pid
				addDate
			}
			companyDetails {
				userType
				jobType
				website
				name
			}
		}
	}
`;

export const ADD_FAVORITE = gql`
	mutation AddFavorite($productId: String!) {
		addFavoriteProduct(productId: $productId) {
			favoriteProducts {
				pid
				addDate
			}
		}
	}
`;

export const DELETE_FAVORITE = gql`
	mutation DeleteFavorite($productId: String!) {
		deleteFavoriteProduct(productId: $productId) {
			favoriteProducts {
				pid
				addDate
			}
		}
	}
`;

export const CREATE_PROFILE = gql`
	mutation CreateProfile($ingredientsTemplateId: String!) {
		createProfile(createProfileInput: { ingredientsTemplateId: $ingredientsTemplateId }) {
			ingredientsTemplateId
		}
	}
`;
