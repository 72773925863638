import ProductCarouselComponent from '../carousel/ProductCarouselComponent';
import Modal from 'react-bootstrap/Modal';
import InnerImageZoom from 'react-inner-image-zoom';
import { ProductVM, ScreenType } from '../../../common/view-model';
import { OverviewState } from './model';
import { FC, useEffect, useState, useCallback } from 'react';
import { Image } from '../../../common/model';
import { ProductInformationComponent } from '../productInformation/ProductInformationComponent';
import './ProductOverviewComponent.scss';
import { findFactPanelImage } from '../../../helpers/handleFindFactPanelImage';
import { GoBackButtonComponent } from '../../UI/GoBackButtonComponent/GoBackButtonComponent';
import { useNavigate } from 'react-router-dom';
import { DetailBreadcrumbComponent } from '../detailBreadcrumb/DetailBreadcrumbComponent';
import { NotLabeledForSaleInUSComponent } from '../notLabeledForSaleInUS/NotLabeledForSaleinUSComponent';

interface INutritionFactModalComponentProps {
	image: Image | undefined;
	onCloseModal: () => void;
	showModal: boolean;
}
const NutritionFactModalComponent: FC<INutritionFactModalComponentProps> = ({
	image,
	onCloseModal,
	showModal
}) => {
	return (
		<Modal
			show={showModal}
			aria-labelledby='example-custom-modal-styling-title'
			className='nutrition-fact-modal-container'
			onHide={onCloseModal}
		>
			<Modal.Body>
				<div className='modal-item'>
					{image?.mainUrl && <InnerImageZoom src={image?.mainUrl!} />}
				</div>
			</Modal.Body>
			<b className='close_icon' onClick={onCloseModal}>
				&times;
			</b>
			<i className='ri-zoom-in-line zoom-icon'></i>
		</Modal>
	);
};

interface IProductOverviewComponentProps {
	product: ProductVM | undefined;
	deviceScreen?: ScreenType;
	registerUserClickEvent: (event: string) => void;
}

export const ProductOverviewComponent: FC<IProductOverviewComponentProps> = ({
	product,
	deviceScreen,
	registerUserClickEvent
}) => {
	const [state, setState] = useState({ ...OverviewState, product });
	const isDesktop =
		deviceScreen === ScreenType.LARGE_DESKTOP ||
		deviceScreen === ScreenType.MEDIUM_DESKTOP ||
		deviceScreen === ScreenType.SMALL_DESKTOP;
	const [openNutritionFactModal, setOpenNutritionFactModal] = useState(false);
	const [nutritionFactImage, setNutritionFactImage] = useState<Image | undefined>(
		findFactPanelImage(product?.images)
	);
	useEffect(() => {
		if (!state.product) {
			setState({ ...OverviewState, product });
		}
	}, [product, state]);
	useEffect(() => {
		setNutritionFactImage(findFactPanelImage(product?.images));
	}, [product]);
	const navigate = useNavigate();
	const handleNavigateBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);
	return (
		<>
			{isDesktop && (
				<>
					<GoBackButtonComponent
						text='Back'
						icon={<i className='fa fa-arrow-left' aria-hidden='true'></i>}
						handelNavigate={handleNavigateBack}
					/>
					<DetailBreadcrumbComponent product={state.product} />
				</>
			)}
			<div className='detail-container'>
				<div className='product-image-container'>
					<div className='product-image'>
						<ProductCarouselComponent product={state.product} deviceScreen={deviceScreen} />
					</div>
				</div>
				<div>
					<ProductInformationComponent
						product={state.product}
						registerUserClickEvent={registerUserClickEvent}
					/>
				</div>
			</div>
			<div>{product?.isNotForSaleInUS ? <NotLabeledForSaleInUSComponent /> : null}</div>
			<div>
				<div className='ingredients-container'>
					<div className='title'>Ingredients</div>
					<div className='ingredient-line '></div>
					<div className='ingredients'>
						{state.product?.ingredientsList && state.product?.ingredientsList.length > 0
							? state.product?.ingredientsList?.join(', ')
							: state.emptyIngredientText}
					</div>
					<div className='ingredients'>
						<b>Allergens:</b>{' '}
						{product?.allergens && product?.allergens.length > 0
							? product?.allergens.join(', ')
							: 'None'}
					</div>
					{nutritionFactImage && (
						<div
							data-testid='nutrition-fact-link'
							className='view_nutrition'
							onClick={() => setOpenNutritionFactModal(!openNutritionFactModal)}
						>
							View Nutrition Facts
						</div>
					)}
				</div>
			</div>
			<div data-testid='nutrition-fact-modal' className='nutrition-fact-modal'>
				<NutritionFactModalComponent
					image={nutritionFactImage}
					showModal={openNutritionFactModal}
					onCloseModal={() => setOpenNutritionFactModal(false)}
				/>
			</div>
		</>
	);
};
