const DEFAULT_STATE: IBrowseByCategoryState = {
	title: 'Browse by Category'
};

interface IBrowseByCategoryState {
	title: string;
}
export const BrowseByCategoryModel = {
	...DEFAULT_STATE
};
