import './FilterMenuComponent.scss';
import FilterComponent from '../generic-filter/FilterComponent';
import { ScreenType, viewModeType } from '../../../common/view-model';
import { RootState } from '../../../redux/AppReducer';
import { connect } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';
import { Dispatch, FC, useEffect } from 'react';
import { Action } from 'redux';
import HideFilterComponent from '../hide-filter/HideFilterComponent';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { UserActions } from '../../../redux/UserActions';
export interface IFilterMenuComponentProps {
	handelClosePanel?: () => void;
	viewMode: viewModeType;
	deviceScreen?: ScreenType;
	handleClearModalContent: () => void;
	onClearAnimation?: () => void;
	hasClickOnFilter?: boolean;
	setHasClickOnFilter: (hasClicked: boolean) => void;
	mobileFilterPanelRef?: React.RefObject<HTMLDivElement>;
}
const FilterMenuComponent: FC<IFilterMenuComponentProps> = ({
	handelClosePanel,
	viewMode,
	deviceScreen,
	handleClearModalContent,
	onClearAnimation,
	hasClickOnFilter,
	setHasClickOnFilter,
	mobileFilterPanelRef
}) => {
	const handleClose = () => {
		if (deviceScreen == ScreenType.TABLET) {
			handleClearModalContent();
		}
		handelClosePanel && handelClosePanel();
	};
	useEffect(() => {
		return () => {
			if (deviceScreen == ScreenType.TABLET && onClearAnimation) {
				onClearAnimation();
			}
		};
	}, []);
	useEffect(() => {
		setHasClickOnFilter(false);
	}, [setHasClickOnFilter]);
	useEffect(() => {
		if (
			hasClickOnFilter &&
			isCurrentUserAnonymous() &&
			(deviceScreen === ScreenType.TABLET || deviceScreen === ScreenType.MOBILE)
		) {
			handelClosePanel && handelClosePanel();
			setHasClickOnFilter(false);
		}
	}, [
		deviceScreen,
		handelClosePanel,
		handleClearModalContent,
		hasClickOnFilter,
		setHasClickOnFilter
	]);
	return (
		<div
			ref={deviceScreen === ScreenType.MOBILE ? mobileFilterPanelRef : null}
			className={`filter-menu ${deviceScreen == ScreenType.TABLET ? 'tablet-container' : ''}`}
		>
			<HideFilterComponent hideFilterHandler={handleClose} deviceViewMode={viewMode} />
			<FilterComponent />
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	deviceScreen: state.deviceScreen || undefined,
	hasClickOnFilter: state.userState?.hasClickOnFilter
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleClearModalContent: () => {
		dispatch(AppActions.setModalContent(undefined));
	},
	setHasClickOnFilter: (hasClicked: boolean) => {
		dispatch(UserActions.clickOnFilter(hasClicked));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenuComponent);
