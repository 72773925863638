import { UserType } from '../common/model';
import { IRoleType, JobTypeMap } from '../common/view-model';
import { v4 as uuidv4 } from 'uuid';

export const applicationRoleTypes: IRoleType[] = [
	{
		id: uuidv4(),
		name: 'Retailer',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Health Practitioner',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Distributor (Finished Goods)',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Food Service',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Supplier/Raw Ingredient Distributor',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Manufacturer',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Business Services',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Investor',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Media',
		accessType: UserType.Retailer,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Exhibitor',
		accessType: UserType.Brand,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Beacon-Only Brand',
		accessType: UserType.Brand,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Non-Exhibiting Brand',
		accessType: UserType.Brand,
		showInProfile: true
	},
	{
		id: uuidv4(),
		name: 'Brand',
		accessType: UserType.Brand,
		showInProfile: false
	}
];
const jobTypeList: string[] = [
	'Administrator/Assistant',
	'Brand Management',
	'Broker of Finished Products',
	'Buyer/Purchasing',
	'Category Management',
	'Consultant/Advisor',
	'Doctor/PhD',
	'Founder/Owner',
	'Legal/Regulatory/Compliance',
	'Marketing',
	'Product Development',
	'Research/Market Research',
	'Sales',
	'Scientist/Formulator/Technologist/Chemist',
	'Supply Chain'
];
export enum JobTypeKeys {
	Retailer = 'Retailer',
	HealthPractitioner = 'Health Practitioner',
	Distributor = 'Distributor (Finished Goods)',
	FoodService = 'Food Service',
	Supplier = 'Supplier/Raw Ingredient Distributor',
	Manufacturer = 'Manufacturer',
	BusinessServices = 'Business Services',
	Investor = 'Investor',
	Media = 'Media',
	Exhibitor = 'Exhibitor',
	BeaconOnlyBrand = 'Beacon-Only Brand',
	NonExhibitingBrand = 'Non-Exhibiting Brand',
	Brand = 'Brand'
}
const sortedJobTypeList = jobTypeList.sort();
export const applicationJobTypesMap: JobTypeMap = {
	[JobTypeKeys.Retailer]: sortedJobTypeList,
	[JobTypeKeys.HealthPractitioner]: sortedJobTypeList,
	[JobTypeKeys.Distributor]: sortedJobTypeList,
	[JobTypeKeys.FoodService]: sortedJobTypeList,
	[JobTypeKeys.Supplier]: sortedJobTypeList,
	[JobTypeKeys.Manufacturer]: sortedJobTypeList,
	[JobTypeKeys.BusinessServices]: sortedJobTypeList,
	[JobTypeKeys.Investor]: sortedJobTypeList,
	[JobTypeKeys.Media]: [],
	[JobTypeKeys.Exhibitor]: sortedJobTypeList,
	[JobTypeKeys.BeaconOnlyBrand]: sortedJobTypeList,
	[JobTypeKeys.NonExhibitingBrand]: sortedJobTypeList,
	[JobTypeKeys.Brand]: sortedJobTypeList
};
