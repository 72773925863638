import UserUnsubscribe from '../../components/authentication/user-unsubscribe/UserUnsubscribe';

import { AppActions } from '../../redux/AppActions';
import { RootState } from '../../redux/AppReducer';
import { connect, ConnectedProps } from 'react-redux';
import { FC, useEffect } from 'react';

const OnbordingPage: FC<UnsubscribePageProps> = ({ setShowNavbar }) => {
	useEffect(() => {
		setShowNavbar(false);
		return () => {
			setShowNavbar(true);
		};
	}, [setShowNavbar]);
	return <UserUnsubscribe />;
};

const mapStateToProps = ({ appState }: RootState) => ({});
const mapDispatchToProps = {
	setShowNavbar: AppActions.showNavbar
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type UnsubscribePageProps = ConnectedProps<typeof connector>;
export default connector(OnbordingPage);
