export interface ITrackingParams {
	object: any;
	trackingParam: string | null;
}

export const getUrlTrackingParamsObject = (
	trackingParam: string,
	searchParams: URLSearchParams
) => {
	const params = new URLSearchParams(window.location.search);
	const getTrackingParam = params.get(trackingParam);

	let mapUrl = new Map(searchParams);
	let searchParamsObject: any = {};

	mapUrl.forEach((values, keys) => {
		searchParamsObject[keys] = values;
	});

	let returnObject: ITrackingParams = {
		object: searchParamsObject,
		trackingParam: getTrackingParam
	};

	return returnObject;
};
