const DEFAULT_STATE: IAboutBrandState = {
	aboutBandTitle: 'About the brand',
	goToBrandText: 'Go to the Brand Profile page',
	contactTitle: 'Contact Information'
};

interface IAboutBrandState {
	aboutBandTitle: string;
	goToBrandText: string;
	contactTitle: string;
}
export const aboutBrandState = {
	...DEFAULT_STATE
};
