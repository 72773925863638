import { useEffect, useState } from 'react';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import React from 'react';
import './ProductAboutFormComponent.scss';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { ScreenType } from '../../../../common/view-model';
import { ProductInfo, RootState } from '../../../../redux/AppReducer';
interface IProductAboutFormComponent {
	productState: ProductInfo;
}
const LARGE_MOBILE = 744;
const SMALL_MOBILE = 500;
const ProductAboutFormComponent: React.FC<IProductAboutFormComponent> = ({ productState }) => {
	const [state, setState] = useState<ProductInfo>();
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const windowsSize = useWindowSize().width;
	const [charactersShown, setCharactersShown] = useState<number>(0);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	const handleToggle = (type: string) => {
		setDescriptionExpanded(!descriptionExpanded);
	};
	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);

	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.TABLET) {
			setCharactersShown(25);
		} else if (LARGE_MOBILE < windowsSize && windowsSize > SMALL_MOBILE) {
			setCharactersShown(40);
		} else if (SMALL_MOBILE < windowsSize) {
			setCharactersShown(26);
		}
	}, [deviceScreen, windowsSize]);
	return (
		<>
			<div className='product-about-form'>
				<TitleForms
					isVisible={isVisible}
					titleText='About This Product'
					toggleVisibility={toggleVisibility}
					readOnly
				></TitleForms>
				{isVisible && (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='Product Approved on:'></LabelForms>
										<p className={state?.approvedOn ? '' : 'blank'}>
											{state?.approvedOn.split('T')[0]}
										</p>
									</div>
									<div>
										<LabelForms text='Product Category'></LabelForms>
										<p>
											{
												'Food & Beverages > Beverages > Non-Alcoholic Beverages > Non-Alcoholic Wine'
											}
										</p>
									</div>
									<div className='description-container'>
										<LabelForms text='Product Description'></LabelForms>
										<div>
											<p>
												{' '}
												{descriptionExpanded
													? state?.productDescription
													: `${state?.productDescription.substring(0, charactersShown)}...`}
											</p>
											<button onClick={() => handleToggle('mission')}>
												{descriptionExpanded ? '' : 'Expand'}
											</button>
										</div>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms
											text='MarketReady Verified'
											toolTipText={
												<>
													Want to get verified? Visit{' '}
													<a href='https://marketreadyinsights.com/' target='_blank'>
														https://marketreadyinsights.com/
													</a>{' '}
													to learn more.
												</>
											}
											icon={<i className='ri-question-line' />}
										></LabelForms>
										<p className={state?.marketReady ? '' : 'blank'}>
											{state?.marketReady ? 'Yes' : 'No'}
										</p>
									</div>
									<div>
										<LabelForms text='Current Year NEXTY Finalist'></LabelForms>
										<p>{state?.currentYearNextyFinalist ? 'Yes' : 'No'}</p>
									</div>
									<div>
										<LabelForms text='Current Year NEXTY Winner'></LabelForms>
										<p>{state?.currentYearNextyWinner ? 'Yes' : 'No'}</p>
									</div>
								</>
							}
						/>
					</>
				)}
			</div>{' '}
		</>
	);
};

export default React.memo(ProductAboutFormComponent);
