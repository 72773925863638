import { FC, useEffect, useState } from 'react';
import { IngredientsTemplate, IngredientVM } from '../../../../common/view-model';
import { getStaticTopIngredients, ingredientsTemplateCardState } from './model';
import { Swiper as ISwiper } from 'swiper';
import './IngredientsTemplateCardComponent.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { getIngredientsLength } from '../../../../helpers/ingredientTemplateHelpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/AppReducer';
interface IIngredientsCardComponentProps extends IngredientsTemplate {
	onMoveToTab: (index: number) => void;
	index: number;
	templates: IngredientsTemplate[];
	showDetailedList: boolean;
	setShowDetailedList: (showDetailedList: boolean) => void;
	onClick: () => void;
}

export const TemplateCardComponent: FC<IIngredientsCardComponentProps> = ({
	id,
	name,
	purpose,
	description,
	isCurrent,
	templates,
	setShowDetailedList,
	onClick
}) => {
	const [state] = useState({ ...ingredientsTemplateCardState });
	let ingredients: IngredientVM[] = [];
	purpose.forEach(purpose => {
		ingredients = [...ingredients, ...purpose.ingredients];
	});
	const screenType = useSelector((state: RootState) => state.appState.deviceScreen);
	const isDefaultTemplate = id === '1';
	return (
		<div
			className={`template-card ${isCurrent ? 'active' : ''}${
				isDefaultTemplate ? ' default-template-card' : ''
			}`}
			onClick={onClick}
		>
			<div className='col'>
				<div className='row'>
					{isDefaultTemplate && (
						<span className='default-template-label'>{state.defaultTemplateLabel}</span>
					)}
					<div className={`template-card-title ${isDefaultTemplate ? 'default-template' : ''}`}>
						{name}
					</div>
				</div>
				<div className='row'>
					<div className='banned-wrapper'>
						<span className='banned-ingredients-number'>{getIngredientsLength(id, templates)}</span>
						<span className='banned-ingredients-label'>{state.bannedIngredientsLabel}</span>
					</div>
				</div>
				<div className='row'>
					<div className='description'>
						{isDefaultTemplate && (
							<span className='all-products-text'>{state.allProductsLabel}</span>
						)}
						{description}
					</div>
				</div>
				<div className='row'>
					<div className={'ingredients-template-list'}>
						<span className='top-banned-text'>{state.topBannedIngredientsLabel}</span>
						<>
							{getStaticTopIngredients(id, screenType).map((ingredient, index) => (
								<div key={index}>
									<svg
										width='8'
										height='8'
										viewBox='0 0 8 8'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M3.99987 3.29299L6.47487 0.817993L7.18187 1.52499L4.70687 3.99999L7.18187 6.47499L6.47487 7.18199L3.99987 4.70699L1.52487 7.18199L0.817871 6.47499L3.29287 3.99999L0.817871 1.52499L1.52487 0.817993L3.99987 3.29299Z'
											fill='#5C666F'
										/>
									</svg>
									<span key={index} className='ingredient'>
										{ingredient}
									</span>
								</div>
							))}
						</>
					</div>
					<div className='show-more-container'>
						<span className={'show-more'} onClick={() => setShowDetailedList(true)}>
							{state.seeFullListLabel}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
interface IIngredientsTemplateCardComponentProps {
	templates: IngredientsTemplate[] | undefined;
	currentTab: number;
	moveToTab: (index: number) => void;
	showDetailedList: boolean;
	setShowDetailedList: (showDetailedList: boolean) => void;
	isMobile: boolean;
}
export const IngredientsTemplateCardComponent: FC<IIngredientsTemplateCardComponentProps> = ({
	moveToTab,
	templates,
	currentTab,
	showDetailedList,
	setShowDetailedList,
	isMobile
}) => {
	const [swiperInstance] = useState<ISwiper | null>(null);
	useEffect(() => {
		if (swiperInstance && isMobile) {
			swiperInstance.slideTo(currentTab - 1);
		}
	}, [currentTab]);
	return (
		<>
			<div data-testid='ingredients-template-card' className='ingredients-template-card'>
				{templates?.map((card, index: number) => (
					<div key={index}>
						<TemplateCardComponent
							id={card.id}
							name={card.name}
							description={card.description}
							purpose={card.purpose}
							isCurrent={card.id === currentTab.toString()}
							onMoveToTab={moveToTab}
							index={index}
							templates={templates}
							showDetailedList={showDetailedList}
							setShowDetailedList={setShowDetailedList}
							onClick={() => moveToTab(index + 1)}
						/>
					</div>
				))}
			</div>
		</>
	);
};
