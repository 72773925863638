import { FilterLevel, FilterType, FilterVM, IFiltersUrlParameters } from '../common/view-model';
import {
	parentCategoryIdentifier,
	secondLevelCategoryIdentifier,
	firstLevelCategoryIdentifier,
	flatLevelCategoryIdentifier
} from './paramsUrlIndetifiers';

export const getParametersKeys = () => {
	const categoryKey = FilterType[FilterType.CATEGORY];
	const categoryKeys = [
		parentCategoryIdentifier,
		secondLevelCategoryIdentifier,
		firstLevelCategoryIdentifier,
		flatLevelCategoryIdentifier
	];

	return Object.keys(FilterType).reduce(
		(result: IFiltersUrlParameters, key) => {
			if (key && isNaN(Number(key)) && key !== categoryKey) {
				result[key.toLowerCase()] = [];
			}
			return result;
		},
		categoryKeys.reduce((result: IFiltersUrlParameters, key) => {
			result[key] = [];
			return result;
		}, {})
	);
};

export const flatSecondLevelFilters = (allFilters?: FilterVM[]) => {
	const result: FilterVM[] = [];
	function traverse(taxonomy: FilterVM) {
		if (!taxonomy.children) {
			return;
		}

		for (const child of taxonomy.children) {
			if (child && child.children === null) {
				result.push({
					...child,
					categoryName: taxonomy.name,
					level: FilterLevel.SECOND,
					breadcrumText: child.name,
					parentId: taxonomy.id,
					parentLength: taxonomy.children.length
				});
			} else if (child && child.children) {
				traverse(child);
			}
		}
	}

	for (const taxonomy of allFilters!) {
		traverse(taxonomy);
	}
	return result;
};
export const flatFirstLevelFilters = (allFilters?: FilterVM[]) => {
	const secondLevelFilters: FilterVM[] = [];
	allFilters!.forEach((taxonomy: FilterVM) => {
		taxonomy.children?.forEach((child: FilterVM | null, index: number) => {
			if (child?.children) {
				secondLevelFilters.push({
					...child,
					categoryName: taxonomy.name,
					parentId: taxonomy.id,
					index
				});
			}
		});
	});
	const result: FilterVM[] = [];
	secondLevelFilters.forEach((secondLevelFilter: FilterVM) => {
		const children = secondLevelFilter.children || [];
		children.map((child: FilterVM, childIndex: number) => {
			child.categoryName = secondLevelFilter.categoryName;
			child.parentId = secondLevelFilter.id;
			child.breadcrumText = `${secondLevelFilter.name} / ${child.name}`;
			child.carrotIconRef = `carrot-icon${secondLevelFilter.id}${secondLevelFilter.index}`;
			child.checkBoxGroupId = `check-box${secondLevelFilter.id}${secondLevelFilter.index}`;
			child.index = childIndex;
			child.mainMenuId = `main-menu-items-${secondLevelFilter.parentId}`;
			child.level = FilterLevel.THIRD;
			child.parentLength = children.length;
			return child;
		});
		result.push(...children);
	});
	return result;
};
export const flatThirdLevelChildren = (allFilters?: FilterVM[]) => {
	const thirdLevelFilters: FilterVM[] = [];
	allFilters!.forEach((filter: FilterVM) => {
		if (filter.children) {
			filter.children.forEach((child: FilterVM) => {
				if (child.children) {
					thirdLevelFilters.push({
						...child,
						breadcrumText: child.name,
						parentLength: child.children.length,
						parentId: child.id,
						categoryName: filter.name
					});
				}
			});
		}
	});
	return thirdLevelFilters;
};
export const flatBasedLevelFilters = (allFilters: FilterVM[]) => {
	let result: FilterVM[] = [];
	allFilters.forEach((filter: FilterVM) => {
		if (filter.children) {
			filter.children.forEach((child: FilterVM) => {
				child.parentId = filter.id;
			});
			result = [...result, ...filter.children];
		}
	});
	return result;
};
export const getFilterTypeKeyForUrl = (type: FilterType) => FilterType[type]?.toLowerCase();

export const filterTypeMappings: Record<string, FilterType> = {
	[FilterType[FilterType.DISTRIBUTOR]]: FilterType.DISTRIBUTOR,
	[FilterType[FilterType.REGION]]: FilterType.REGION,
	[FilterType[FilterType.CERTIFICATION]]: FilterType.CERTIFICATION,
	[FilterType[FilterType.ALLERGENS]]: FilterType.ALLERGENS,
	[FilterType[FilterType.CONSUMER_DIETS]]: FilterType.CONSUMER_DIETS,
	[FilterType[FilterType.PACKAGING]]: FilterType.PACKAGING,
	[FilterType[FilterType.ATTRIBUTES]]: FilterType.ATTRIBUTES,
	[FilterType[FilterType.CLIMATE]]: FilterType.CLIMATE,
	[FilterType[FilterType.ANIMAL_WELFARE]]: FilterType.ANIMAL_WELFARE,
	[FilterType[FilterType.BUSINESS_PRACTICES]]: FilterType.BUSINESS_PRACTICES,
	[FilterType[FilterType.INGREDIENTS]]: FilterType.INGREDIENTS,
	[FilterType[FilterType.NEW_TO_BEACON]]: FilterType.NEW_TO_BEACON,
	[FilterType[FilterType.NEW_TO_MARKET]]: FilterType.NEW_TO_MARKET,
	[FilterType[FilterType.WHATS_NEW]]: FilterType.WHATS_NEW,
	[FilterType[FilterType.NEXTY_FINALISTS]]: FilterType.NEXTY_FINALISTS
};

export const buildUrlParameters = (filters: FilterVM[]) => {
	let urlParameters = '';
	filters.forEach((filter: FilterVM) => {
		const key = getFilterTypeKeyForUrl(filter.type!);
		if (urlParameters) {
			urlParameters += `&${key}=${filter.id}`;
		} else {
			urlParameters += `${key}=${filter.id}`;
		}
	});
	return urlParameters;
};
