import './EventSliderComponent.scss';
import { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Swiper as ISwiper, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { expoWestLink, newtopiaLink } from '../../common/constants';
import { FilterType, FilterVM, ITrackingEvent, TrackingEventStatus } from '../../common/view-model';
import { NavigateFunction } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import { UserState } from '../../redux/AppReducer';
import { v4 as uuidv4 } from 'uuid';
import { handleTracking } from '../../helpers/handelTracking';

interface IEventSliderComponentProps {
	images: string[];
	addFilter: (filter: FilterVM) => void;
	privateLabelFilter?: FilterVM;
	navigate: NavigateFunction;
	userState?: UserState;
}

export const EventSliderComponent: FC<IEventSliderComponentProps> = ({
	images,
	addFilter,
	privateLabelFilter,
	navigate,
	userState
}) => {
	const swiperRef = useRef<ISwiper>();
	const productsPageUrl = '/products';
	const handleClick = (image: string) => {
		let sliderId: string | undefined = image;
		switch (image) {
			case 'expo-west':
				window.open(expoWestLink, '_blank', 'noopener noreferrer');
				break;
			case 'newtopia':
				window.open(newtopiaLink, '_blank', 'noopener noreferrer');
				break;
			case 'private-label':
				addFilter(privateLabelFilter!);
				const url = `${!isCurrentUserAnonymous() ? '' : '.'}${productsPageUrl}?${[
					FilterType[FilterType.ATTRIBUTES].toLowerCase()
				]}=${privateLabelFilter?.id}`;
				navigate(url);
				break;
			default:
				sliderId = undefined;
				break;
		}
		if (sliderId) {
			const trackingPayload: ITrackingEvent = {
				transactionId: uuidv4(),
				entityId: sliderId,
				status: TrackingEventStatus.SUCCESS,
				type: `Home page carousel click on ${sliderId}`,
				timeStamp: new Date().toISOString(),
				eventPayload: {
					id: sliderId,
					name: sliderId,
					type: sliderId,
					url: window.location.href,
					tags: [],
					metadata: {
						user_type: userState?.user?.user_type,
						user_id: userState?.user?.id,
						environment: process.env.REACT_APP_ENV_NAME
					}
				}
			};
			handleTracking(trackingPayload);
		}
	};
	return (
		<div className='home-swiper-container'>
			<Swiper
				autoplay={{
					delay: 5000,
					disableOnInteraction: false
				}}
				pagination={{
					clickable: true
				}}
				modules={[Autoplay, Pagination, Navigation]}
				loop={true}
				lazy={true}
				onBeforeInit={swiper => {
					swiperRef.current = swiper;
				}}
				className='event-swiper'
			>
				{images.map((image, index) => (
					<SwiperSlide key={index}>
						<div className={image} onClick={() => handleClick(image)}></div>
					</SwiperSlide>
				))}
			</Swiper>
			<>
				<button
					data-testid='swiper-arrow-prev'
					className='swiper-button-prev'
					onClick={() => swiperRef.current?.slidePrev()}
				></button>
				<button
					data-testid='swiper-arrow-next'
					className='swiper-button-next'
					onClick={() => swiperRef.current?.slideNext()}
				></button>
			</>
		</div>
	);
};
