import { FC, useState } from 'react';
import { ToolTip } from '../../../common/model';
import './FilterTooltipComponent.scss';
import { FilterToolTipState } from './model';

interface IFilterTooltipComponentProps {
	id: string;
	toolTip?: ToolTip;
}

export const FilterTooltipComponent: FC<IFilterTooltipComponentProps> = ({ id, toolTip }) => {
	const [state] = useState({ ...FilterToolTipState });
	return (
		<>
			{toolTip?.description && (
				<div data-testid='filter-tooltip' id={`filter-tooltip${id}`} className='tooltip-container'>
					<div>
						<span className='tooltip-description'>
							<span>{toolTip.isPoweredByHowGood && state.tooltipPoweredByText}</span>{' '}
							{toolTip.description}
						</span>
						{toolTip.learnMore && (
							<a className='learn-more-link' href={toolTip.learnMore} target='_blank'>
								{' '}
								<span>{state.learnMoreText} </span>
							</a>
						)}
					</div>
				</div>
			)}
		</>
	);
};
