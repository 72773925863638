import { FC, useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { IngredientsTemplate, ScreenType, TrackingEventType } from '../../common/view-model';
import { GET_INGREDIENT_TEMPLATES } from '../../graphql/queries/ingredientTemplates';
import { getTemplateIngredientsByPurpose } from '../../helpers/transformers';
import { ApolloActions } from '../../redux/ApolloActions';
import { AppActions } from '../../redux/AppActions';
import {
	BrandInfo,
	EngagementDashboardState,
	ProductInfo,
	RootState
} from '../../redux/AppReducer';
import { EngagementDashboardActions } from '../../redux/engagementDashboard/EngagementDashboardActions';
import { UserActions } from '../../redux/UserActions';
import { UserProfileData } from '../authentication/user-profile/model';
import { EngagementDashboardComponent } from '../EngagementDashboard/EngagementDashboardComponent';
import NewProfileIngredientsComponent from './NewProfileIngredients/NewProfileIngredientsComponent';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import { MenuProfileComponent } from '../UI/MenuProfileComponent/MenuProfileComponent';
import { WarningFormOverlayComponent } from '../WarningFormOverlay/WarningFormOverlayComponent';
import './NewProfileComponent.scss';
import FavoritesPage from '../../pages/Favorites/index';
import { NewDetailedTemplateComparisonComponent } from './NewDetailTemplateComparison/NewDetailedTemplateComparisonComponent';
import { BrandFormComponent } from '../forms/BrandForms/BrandForm/BrandFormComponent';
import ContactFormComponent from '../forms/BrandForms/ContactForm/ContactFormComponent';
import { DistributionFormComponent } from '../forms/BrandForms/DistributionForm/DistributionFormComponent';
import SocialMediaFormComponent from '../forms/BrandForms/SocialMediaForm/SocialMediaFormComponent';
import StandardsApplicationComponent from '../forms/BrandForms/StandardsApplication/StandardsApplicationComponent';
import { AccountFormComponent } from '../forms/ProfileForms/AccountForm/AccountFormComponent';
import { CompanyFormComponent } from '../forms/ProfileForms/CompanyForm/CompanyFormComponent';
import ProductDetailsFormComponent from '../forms/ProductForms/ProductDetailsForm/ProductDetailsFormComponent';
import ProductViolationsFormComponent from '../forms/ProductForms/ProductViolationsForm/ProductViolationsFormComponent';
import ProductSustainabilityFormComponent from '../forms/ProductForms/ProductSustainabilityForm/ProductSustainabilityFormComponent';
import ProductCertificationsFormComponent from '../forms/ProductForms/ProductCertificationsForm/ProductCertificationsFormComponent';
import ProductAboutFormComponent from '../forms/ProductForms/ProductAboutForm/ProductAboutFormcomponent';
import { brandStates, productStatusArray, PROFILE_ROUTES } from '../../common/constants';
import { Status, StatusBubbleComponent } from '../UI/StatusBubbleComponent/StatusBubbleComponent';
import FAQComponent from './FAQComponent/FAQComponent';
import FinancialFormComponent from '../forms/BrandForms/FinancialForm/FinancialFormComponent';
import ProductImprovementsFormComponent from '../forms/ProductForms/ProductImprovementsFormComponent/ProductImprovementsFormComponent';
import ReactDOM from 'react-dom';
import { GET_ONBOARDING_PRODUCTS } from '../../graphql/queries/onboardingProducts';
import { useLazyQuery } from '@apollo/client';
import BrandOnboard from '../../pages/BrandOnboard';
import { buildTrackingBobPayload } from '../../helpers/handleBobTracking';
import { handleTracking } from '../../helpers/handelTracking';
import { BOBErrorComponent } from './BOBError/BOBErrorComponent';

export interface INewUserProfileProps {
	setProfileLayout: (value: boolean) => void;
	setIsOnFavoriteTab: (isOnFavoriteTab: boolean) => void;
	getEngagementDashboardData: (brandId: string) => void;
	engagementDashboardState: EngagementDashboardState | undefined;
	ingredientTemplates?: IngredientsTemplate[];
	ingredientsTemplateId?: number;
	getTemplates: () => void;
}
interface IOpenStates extends ProductInfo {
	collapsed: boolean;
	statusProduct: string;
	descriptionStatus: string;
	show: boolean;
}
const NewUserProfile: FC<INewUserProfileProps> = ({
	setProfileLayout,
	setIsOnFavoriteTab,
	engagementDashboardState,
	getEngagementDashboardData,
	getTemplates,
	ingredientTemplates,
	ingredientsTemplateId
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const brandState = useSelector((state: RootState) => state.appState.userState?.brandInfo);
	const profileState = useSelector((state: RootState) => state.appState.profileState);
	const errorBob = useSelector((state: RootState) => state.appState.userState?.errorBob);
	const [toggleState, setToggleState] = useState(location.hash.replace('#', '') || '1');
	const favoritesPageRef = useRef<HTMLDivElement>(null);
	const [detailCurrentTab, setDetailCurrentTab] = useState(ingredientsTemplateId!);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(false);
	const [currentTabIndex, setCurrentTabIndex] = useState(ingredientsTemplateId!);
	const [previewTab, setPreviewTab] = useState(-1);
	const [showDetails, setShowDetails] = useState(false);
	const [ingredientsEditing, setIngredientsEditing] = useState(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const [menuScreenType, setMenuScreenType] = useState<string>('default');
	const [prevIndex, setPrevIndex] = useState<number | undefined>(undefined);
	const [mappedProductStates, setMappedProductStates] = useState<IOpenStates[]>([]);
	const [activeEditing, setActiveEditing] = useState<string | null>(null);
	const [templates, setTemplates] = useState<IngredientsTemplate[]>([]);
	const navigate = useNavigate();
	const currentStatusBrand = brandStates.find(status => status.id === brandState?.status);
	const [activeFormData, setActiveFormData] = useState<
		UserProfileData | BrandInfo | ProductInfo | null
	>(null);
	const [isFormValid, setIsFormValid] = useState<boolean>(true);
	const [cleanForm, setCleanForm] = useState<boolean>(true);
	const [key, setKey] = useState(0);
	const rerender = () => {
		setKey(prevKey => prevKey + 1);
	};

	const [products, setProducts] = useState<ProductInfo[]>([]);
	const [getProducts, { loading: loadingProducts, data: productsData, error: errorProducts }] =
		useLazyQuery<any>(GET_ONBOARDING_PRODUCTS);

	useEffect(() => {
		if (userState?.business_email && toggleState === PROFILE_ROUTES.PRODUCT_INFO) {
			getProducts({ variables: { email: userState?.business_email } });
		}
	}, [userState?.business_email, toggleState]);

	useEffect(() => {
		if (!errorProducts && !loadingProducts && productsData?.getProductsFromAdmin) {
			setProducts(productsData.getProductsFromAdmin);
		}
		if (errorProducts?.message === 'Unauthorized') {
			console.log(errorProducts);
			return;
		}
		if (errorProducts) {
			console.log(errorProducts);
		}
	}, [errorProducts, loadingProducts]);

	const titleCallback = useCallback(
		() => dispatch(AppActions.setAppTitle('User profile')),
		[dispatch]
	);

	useEffect(() => {
		if (ingredientTemplates) {
			ingredientTemplates = ingredientTemplates.map(template => ({
				...template,
				purpose: getTemplateIngredientsByPurpose(template)
			}));

			setTemplates(ingredientTemplates!);
		} else {
			getTemplates();
		}
	}, [ingredientTemplates]);

	useEffect(() => {
		if (products.length > 0) {
			const mappedProducts = products.map((product, index) => {
				const productInfoStatus = productStatusArray.find(status => status.id === product.status);
				let showOnBeacon = true;
				if (productInfoStatus?.id === productStatusArray[0].id) {
					showOnBeacon = false;
				}
				return {
					...product,
					collapsed: false,
					statusProduct: productInfoStatus?.name || '',
					descriptionStatus: productInfoStatus?.description || '',
					show: showOnBeacon
				};
			});
			const filterMappedProduct = mappedProducts.filter(product => product.show !== false);
			setMappedProductStates(filterMappedProduct);
		}
	}, [products]);

	useEffect(() => {
		setProfileLayout(true);
	}, []);
	useEffect(() => {
		const section = location.pathname.replace(/^\/+/, '');
		setToggleState(section);
		if (section === 'favorites') {
			setIsOnFavoriteTab(true);
		} else {
			setIsOnFavoriteTab(false);
		}
	}, [location.pathname]);
	useEffect(() => {
		titleCallback();
		return () => {
			setProfileLayout(false);
		};
	}, []);

	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setMenuScreenType('default');
			setMenuOpen(true);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setMenuScreenType('default');
			setMenuOpen(true);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setMenuScreenType('default');
			setMenuOpen(true);
		} else if (deviceScreen === ScreenType.TABLET) {
			setMenuScreenType('tablet');
			setMenuOpen(false);
		} else if (deviceScreen === ScreenType.MOBILE) {
			setMenuScreenType('mobile');
			setMenuOpen(false);
		}
	}, [deviceScreen]);
	const handleFormChange = (newData: any) => {
		setActiveFormData(newData);
	};
	const toggleTab = (index: any) => {
		if (activeEditing) {
			setShowWarning(true);
			return;
		}
		setToggleState(index);
		if (index === PROFILE_ROUTES.FAVORITES) {
			setIsOnFavoriteTab(true);
		} else {
			setIsOnFavoriteTab(false);
		}
		navigate(`/${index}`, { replace: true });
	};
	const handleSave = () => {
		if (toggleState === PROFILE_ROUTES.PROFILE) {
			updateProfile(activeFormData);
		} else if (toggleState === PROFILE_ROUTES.BRAND_INFO) {
			updateBrand(activeFormData);
		} else if (toggleState === PROFILE_ROUTES.PRODUCT_INFO) {
			updateProduct(activeFormData);
		}
		setActiveEditing(null);
	};
	const updateProfile = (userState: any) => {
		dispatch(
			UserActions.updateUserProfile({
				companyDetails: {
					name: userState?.company_name,
					website: userState?.company_website,
					userType: userState?.user_type,
					jobType: userState?.job_type?.split(' ').join(''),
					isEditing: true
				},
				userProfile: {
					business_email: userState?.business_email,
					business_type: userState?.business_type,
					company: userState?.company,
					country: userState?.country,
					country_name: userState?.country_name,
					first_name: userState?.first_name,
					is_social_login: false,
					job_function: userState?.job_function,
					id: userState?.id,
					job_title: userState?.job_title,
					last_name: userState?.last_name,
					social_accounts: userState?.social_accounts,
					state: userState?.state,
					state_name: userState?.state_name
				}
			})
		);
		setActiveEditing(null);
	};
	const updateBrand = (brandUpdate: any) => {
		const trackingPayload = buildTrackingBobPayload({
			type: TrackingEventType.BOB_TRACKING_BRAND_SAVE,
			user: {
				id: userState?.id || '',
				type: userState.user_type || ''
			},
			event: {
				id: 'bob-tracking-save-brand',
				name: 'bob-tracking-save',
				type: 'bob-tracking'
			}
		});
		handleTracking(trackingPayload);
		dispatch(
			AppActions.updateProfileState({
				loading: true,
				sectionId: activeEditing!
			})
		);
		dispatch(UserActions.updateUserBrand({ ...brandUpdate }));
		setActiveEditing(null);
	};
	const updateProduct = (product: any) => {
		dispatch(UserActions.updateProduct({ ...product }));
		setActiveEditing(null);
	};
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const moveToStepHandler = (index: number) => {
		setPrevIndex(index);
		const targetElement = document.getElementById('target');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		if (ingredientsEditing) {
			setCurrentTabIndex(index);
			setShowDetails(true);
			if (prevIndex === index) {
				setPrevIndex(undefined);
			}
		} else {
			setDetailCurrentTab(index);
			setPreviewTab(index);
			setShowDetails(true);
		}
	};

	const toggleEditing = (formId: string | null) => {
		if (activeEditing !== null && formId !== null) {
			setShowWarning(true);
			return;
		}
		setActiveEditing(formId);
		setCleanForm(false);
	};
	const cancelUpdate = () => {
		setActiveEditing(null);
		setCleanForm(true);
	};

	const toggleOpen = (index: number) => {
		const newOpenStates = mappedProductStates.map((state, indexState) =>
			index === indexState
				? {
						...state,
						collapsed: !state.collapsed
				  }
				: state
		);
		setMappedProductStates(newOpenStates);
	};

	const WarningOverlay = (
		<div className={`overlay  open-warning`} onClick={() => setShowWarning(false)}>
			<WarningFormOverlayComponent
				cancelUpdate={cancelUpdate}
				closeOverlay={() => setShowWarning(false)}
				isValidForm={isFormValid}
				updateData={handleSave}></WarningFormOverlayComponent>
		</div>
	);
	return (
		<div data-testid='profile-container' className={`new-page-container`}>
			<div className='new-profile-container'>
				{showWarning && ReactDOM.createPortal(WarningOverlay, document.body)}
				{menuScreenType !== 'default' && (
					<div className={`overlay ${menuOpen ? 'open' : ''}`}>
						<div className={`menu-container ${menuScreenType}`}>
							<div className='menu-hide-container'>
								<div className={`menu-hide`}>
									<div className='menu-header'>
										<p>Profile Menu</p>
										<div className='hide-buttons'>
											{menuScreenType === 'tablet' && (
												<>
													<ButtonComponent
														onClick={toggleMenu}
														style='rounded-button tertiary'
														text=''
														icon={<i className='ri-arrow-left-line'></i>}
														iconOnly
													/>
													<ButtonComponent
														onClick={toggleMenu}
														style='rounded-button tertiary menu-button'
														text='Hide Menu'
													/>
												</>
											)}

											{menuScreenType === 'mobile' && (
												<ButtonComponent
													onClick={toggleMenu}
													style='rounded-button tertiary menu-button'
													text=''
													icon={<i className='ri-close-line'></i>}
													iconOnly
												/>
											)}
										</div>
									</div>
									<hr className='divider' />
									{menuOpen && (
										<MenuProfileComponent
											selectedItem={toggleState}
											onSelectedItem={toggleTab}></MenuProfileComponent>
									)}
								</div>
							</div>
						</div>
						<div className='overlay-close' onClick={toggleMenu}></div>
					</div>
				)}
				{menuScreenType === 'default' && (
					<div className={`menu-container ${menuScreenType}`}>
						<div className={`menu-hide`}>
							<ButtonComponent
								onClick={toggleMenu}
								style='rounded-button secondary menu-button'
								text='Hide Menu'
								icon={<i className='ri-menu-line'></i>}
								iconPosition='start'
							/>
						</div>
						<div className='menu-default'>
							<MenuProfileComponent
								selectedItem={toggleState}
								onSelectedItem={toggleTab}></MenuProfileComponent>
						</div>
					</div>
				)}
				<div className='main-content profile'>
					<div className='content'>
						<div className='title-content'>
							{toggleState === PROFILE_ROUTES.PROFILE && userState && (
								<>
									<i className='ri-user-line' />
									<h2>Welcome, {userState.first_name}</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.INGREDIENTS && (
								<>
									<i className='ri-file-list-2-line' />
									<h2>Ingredient Templates</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.FAVORITES && (
								<>
									<i className='ri-heart-line' />
									<h2>Favorites</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.GET_ON_BEACON && (
								<>
									<i className='ri-information-line' />
									<h2>Getting Started on Beacon Discovery</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.BRAND_INFO && (
								<>
									<i className='ri-edit-line' />
									<h2>Update Your Brand Info on Beacon Discovery</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.PRODUCT_INFO && (
								<>
									<i className='ri-edit-line' />
									<h2>Update Your Product Info on Beacon Discovery</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.ONBOARD_FAQ && (
								<>
									<i className='ri-question-mark' />
									<h2>Frequently Asked Questions</h2>
								</>
							)}
						</div>
						<div className='header-card'>
							<ButtonComponent
								onClick={toggleMenu}
								style='rounded-button secondary menu-button'
								text='Profile Menu'
								icon={<i className='ri-menu-line'></i>}
								iconPosition='start'
							/>
							{!errorBob && toggleState === PROFILE_ROUTES.PRODUCT_INFO && (
								<p className='count-products'>{mappedProductStates.length} products</p>
							)}
						</div>

						{toggleState === PROFILE_ROUTES.PROFILE && userState && (
							<div className='profile-card'>
								<h2 className='profile-title'>Profile</h2>

								<AccountFormComponent
									onUpdateProfile={updateProfile}
									userState={userState}
									toggleEditing={toggleEditing}
									isEditing={activeEditing}
									onCancel={cancelUpdate}
									onChange={handleFormChange}
									onValidationChange={setIsFormValid}
									cleanForm={cleanForm}
								/>
								<CompanyFormComponent
									onUpdateProfile={updateProfile}
									userState={userState}
									toggleEditing={toggleEditing}
									isEditing={activeEditing}
									onCancel={cancelUpdate}
									onChange={handleFormChange}
									onValidationChange={setIsFormValid}
									cleanForm={cleanForm}
								/>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.INGREDIENTS && (
							<div className='ingredient-card'>
								<NewProfileIngredientsComponent
									toggleState={toggleState}
									showDetails={showDetails}
									setShowDetails={setShowDetails}
									currentTabIndex={currentTabIndex}
									setCurrentTabIndex={setCurrentTabIndex}
									detailCurrentTab={detailCurrentTab}
									setDetailCurrentTab={setDetailCurrentTab}
									isEditing={ingredientsEditing}
									setEditing={setIngredientsEditing}
									moveToStepHandler={moveToStepHandler}
								/>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.FAVORITES && (
							<div ref={favoritesPageRef}>
								<div className='favorite-card'>
									<FavoritesPage isProfilePage={true} />
								</div>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.GET_ON_BEACON && (
							<div className='content-container'>
								{process.env.REACT_APP_SHOW_ENGAGEMENT_DASHBOARD === 'true' && (
									<div>
										<EngagementDashboardComponent
											engagementDashboardState={engagementDashboardState}
											brandId=''
											getEngagementData={getEngagementDashboardData}
											toggleState={toggleState}
										/>
									</div>
								)}
								<div>
									<BrandOnboard toggleState={toggleState} />
								</div>
							</div>
						)}
						{errorBob &&
						(toggleState === PROFILE_ROUTES.BRAND_INFO ||
							toggleState === PROFILE_ROUTES.PRODUCT_INFO) ? (
							<BOBErrorComponent/>
						) : (
							<>
								{toggleState === PROFILE_ROUTES.BRAND_INFO && brandState && profileState && (
									<div className='brand-info-card'>
										<div className='title-container'>
											<StatusBubbleComponent
												status={currentStatusBrand?.name as Status}></StatusBubbleComponent>
											<h3>{brandState?.name}</h3>
										</div>
										<div className='app-status'>
											<span>Application Status: </span>
											<span>{currentStatusBrand?.description}</span>
										</div>
										<div className='divider'></div>
										<div className='questions'>
											<i className='ri-question-mark' />
											<div className='info-questions-container'>
												<span>Questions? Reach out to </span>{' '}
												<span>
													<a
														href='mailto:audiencesuccessteam@newhope.com'
														target='_blank'
														rel='noreferrer'>
														{' '}
														audiencesuccessteam@newhope.com
													</a>
												</span>
											</div>
										</div>
										<StandardsApplicationComponent brandState={brandState} />
										<BrandFormComponent
											key={'brand' + key}
											isEditing={activeEditing}
											onUpdateProfileBrand={updateBrand}
											onCancel={cancelUpdate}
											toggleEditing={toggleEditing}
											brandState={brandState}
											onChange={handleFormChange}
											onValidationChange={setIsFormValid}
											cleanForm={cleanForm}
											updateKeyFc={rerender}
											profileState={profileState}
										/>
										<ContactFormComponent
											isEditing={activeEditing}
											onUpdateProfileBrand={updateBrand}
											onCancel={cancelUpdate}
											toggleEditing={toggleEditing}
											brandState={brandState}
											onChange={handleFormChange}
											onValidationChange={setIsFormValid}
											cleanForm={cleanForm}
											profileState={profileState}
										/>
										<SocialMediaFormComponent
											isEditing={activeEditing}
											onUpdateProfileBrand={updateBrand}
											onCancel={cancelUpdate}
											toggleEditing={toggleEditing}
											brandState={brandState}
											onChange={handleFormChange}
											onValidationChange={setIsFormValid}
											cleanForm={cleanForm}
											profileState={profileState}
										/>
										<DistributionFormComponent
											isEditing={activeEditing}
											key={'distribution-' + key}
											onUpdateProfileBrand={updateBrand}
											onCancel={cancelUpdate}
											toggleEditing={toggleEditing}
											brandState={brandState}
											onChange={handleFormChange}
											onValidationChange={setIsFormValid}
											cleanForm={cleanForm}
											updateKeyFc={rerender}
											profileState={profileState}
										/>

										<FinancialFormComponent
											isEditing={activeEditing}
											onUpdateProfileBrand={updateBrand}
											onCancel={cancelUpdate}
											toggleEditing={toggleEditing}
											brandState={brandState}
											onChange={handleFormChange}
											onValidationChange={setIsFormValid}
											cleanForm={cleanForm}
											profileState={profileState}
										/>
									</div>
								)}{' '}
								{toggleState === PROFILE_ROUTES.PRODUCT_INFO && userState && (
									<>
										{mappedProductStates.length > 0 &&
											mappedProductStates.map((item, index) => {
												return (
													<div className='product-info-card' key={index}>
														<div className='product-main-description-container'>
															<div className='product-description-container'>
																<div className='options-container'>
																	<i
																		className={
																			item.collapsed ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'
																		}
																		onClick={() => toggleOpen(index)}
																	/>
																	<span onClick={() => toggleOpen(index)}>
																		{item.collapsed ? `Show Less` : 'Show More'}
																	</span>
																	<StatusBubbleComponent status={item.statusProduct as Status} />
																</div>
																<div className='product-description'>
																	<h3>{item.name}</h3>
																	<span>
																		{item.descriptionStatus.length > 0
																			? item.descriptionStatus
																			: 'Some information will be seen by retailers when searching and filtering on Beacon Discovery. Please make sure that all information is accurate and up-to-date.'}
																	</span>
																	<div className='questions'>
																		<i className='ri-question-mark' />
																		<span>Questions? Reach out to </span>&nbsp;{' '}
																		<span>
																			<a
																				href='mailto:beacondiscovery@newhope.com'
																				target='_blank'
																				rel='noreferrer'>
																				{' '}
																				beacondiscovery@newhope.com
																			</a>
																		</span>
																	</div>
																</div>
															</div>
															<div className='product-image'>
																<div className='image-container'>
																	<img alt='' src={item?.imageUrl} />
																	<i className='ri-question-line' />
																</div>
															</div>
														</div>
														<div className='questions-tablet'>
															<i className='ri-question-mark' />
															<span>Questions? Reach out to </span>&nbsp;{' '}
															<span>
																<a
																	href='mailto:beacondiscovery@newhope.com'
																	target='_blank'
																	rel='noreferrer'>
																	{' '}
																	beacondiscovery@newhope.com
																</a>
															</span>
														</div>
														<div className='product-main-description-container-mobile'>
															<div className='options-container'>
																<i
																	className={
																		item.collapsed ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'
																	}
																	onClick={() => toggleOpen(index)}
																/>
																<span onClick={() => toggleOpen(index)}>
																	{item.collapsed ? 'Show Less' : 'Show More'}
																</span>
																<StatusBubbleComponent status={item.statusProduct as Status} />
															</div>
															<h3>{item.name}</h3>
															<div className='product-image'>
																<div className='image-container'>
																	<img alt='' src={item?.imageUrl} />
																	<i className='ri-question-line' />
																</div>
															</div>
															<span>
																{item.descriptionStatus.length > 0
																	? item.descriptionStatus
																	: 'Some information will be seen by retailers when searching and filtering on Beacon Discovery. Please make sure that all information is accurate and up-to-date.'}
															</span>
															<div className='questions-mobile'>
																<i className='ri-question-mark' />

																<div className='info-questions-container'>
																	<span>Questions? Reach out to </span>
																	<span>
																		<a
																			href='mailto:beacondiscovery@newhope.com'
																			target='_blank'
																			rel='noreferrer'>
																			{' '}
																			beacondiscovery@newhope.com
																		</a>
																	</span>
																</div>
															</div>
														</div>
														<div className=''>
															{' '}
															{item.collapsed && (
																<>
																	<ProductAboutFormComponent
																		productState={item}></ProductAboutFormComponent>
																	<ProductDetailsFormComponent
																		isEditing={activeEditing}
																		onUpdateProfileProduct={updateProduct}
																		onCancel={cancelUpdate}
																		productState={item}
																		toggleEditing={toggleEditing}
																		onChange={handleFormChange}
																		onValidationChange={setIsFormValid}
																		cleanForm={cleanForm}
																	/>
																	<ProductCertificationsFormComponent productState={item} />
																</>
															)}
															<ProductSustainabilityFormComponent
																isEditing={activeEditing}
																onUpdateProfileProduct={updateProduct}
																onCancel={cancelUpdate}
																productState={item}
																toggleEditing={toggleEditing}
																showHowGoodCard={item.collapsed}
																onChange={handleFormChange}
																onValidationChange={setIsFormValid}
																cleanForm={cleanForm}
															/>
															<ProductViolationsFormComponent productState={item} />
															{item.collapsed && (
																<ProductImprovementsFormComponent
																	productState={item}></ProductImprovementsFormComponent>
															)}
														</div>
														<ButtonComponent
															text={
																item.collapsed
																	? 'Hide Product Information'
																	: 'Show More Product Information'
															}
															onClick={() => toggleOpen(index)}
															iconPosition='start'
															icon={
																<i
																	className={`${
																		item.collapsed ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'
																	}`}
																/>
															}
															style={`button-show`}></ButtonComponent>
													</div>
												);
											})}
									</>
								)}
							</>
						)}
						{toggleState === PROFILE_ROUTES.ONBOARD_FAQ && (
							<>
								<FAQComponent />
							</>
						)}
					</div>
				</div>
			</div>
			<div
				className={`tabs-container ${
					toggleState === '2' || toggleState === '4' ? 'ingredient-background' : ''
				}`}>
				{/* {process.env.REACT_APP_SHOW_ENGAGEMENT_DASHBOARD === 'true' && (
						<>
							{(state?.user_type === JobTypeKeys.Exhibitor ||
								state?.user_type === JobTypeKeys.BeaconOnlyBrand) && (
								<li
									data-testid='charts-tab'
									className={`tabs ${getActiveClass(4, 'active-tabs')}`}
									onClick={() => toggleTab(4)}>
									<i id='profile-chart-icon' className='ri-pie-chart-2-fill'></i>{' '}
								</li>
							)}
						</>
					)} */}
			</div>
			<div>
				{toggleState === PROFILE_ROUTES.INGREDIENTS && templates.length > 0 && (
					<div id='target'>
						<NewDetailedTemplateComparisonComponent
							currentTab={ingredientsEditing ? currentTabIndex : detailCurrentTab}
							templates={templates}
							moveToTab={moveToStepHandler}
							setShowDetails={setShowDetails}
							showDetails={showDetails}
							isMobile={menuScreenType !== 'default'}
							isEdit={ingredientsEditing}
							previewTab={previewTab}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	engagementDashboardState: state?.dashboardState,
	ingredientTemplates: state.storeState?.templates,
	ingredientsTemplateId: state.userState?.ingredientsTemplateId
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setProfileLayout: (isProfileLayout: boolean) => {
		dispatch(AppActions.setProfileLayout(isProfileLayout));
	},
	setIsOnFavoriteTab: (isOnFavoriteTab: boolean) => {
		dispatch(AppActions.isOnFavoritesPage(isOnFavoriteTab));
	},
	getEngagementDashboardData: (brandId: string) => {
		dispatch(EngagementDashboardActions.startFetchingEngagementData(brandId));
	},
	setIngredientsTemplateId: (ingredientsTemplateId: number) =>
		dispatch(UserActions.setIngredientsTemplateId(ingredientsTemplateId)),
	getTemplates: () => {
		return dispatch(ApolloActions.query({ query: GET_INGREDIENT_TEMPLATES }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUserProfile);
