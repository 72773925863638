import { ProductVM } from '../../../common/view-model';

const DEAFAULT_STATE: ProductInformationState = {
	title: 'Product Overview',
	product: undefined
};
interface ProductInformationState {
	title: string;
	product: ProductVM | undefined;
}
export const productInformationState = {
	...DEAFAULT_STATE
};
