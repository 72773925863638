const DEFAULT_STATE: IOnboardingDetailTemplateComparisonProps = {
	showDetailedButtonText: 'Show detailed template comparison',
	hideDetailedButtonText: 'Hide detailed template comparison',
	showDetailedButtonMobileText: 'Show template comparison',
	hideDetailedButtonMobileText: 'Hide template comparison'
};
interface IOnboardingDetailTemplateComparisonProps {
	showDetailedButtonText: string;
	hideDetailedButtonText: string;
	showDetailedButtonMobileText: string;
	hideDetailedButtonMobileText: string;
}

export const OnboardingDetailTemplateComparisonModel = {
	...DEFAULT_STATE
};
