const colors = [
	{ color: '#FEF876', class: 'good-estimate', range: 'Good' },
	{ color: '#6BD6D6', class: 'great-estimate', range: 'Great' },
	{ color: '#C2E950', class: 'best-estimate', range: 'Best' },
	{ color: '#F5F5F5', class: 'none-estimate', range: 'None' }
];
export const getHowGoodColor = (range: string) => {
	switch (range) {
		case 'Good':
			return colors[0];
		case 'Great':
			return colors[1];
		case 'Best':
			return colors[2];
		case 'None':
			return colors[3];
	}
};
