import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, FilterVM } from '../../../common/view-model';
import { RootState } from '../../../redux/AppReducer';
import { FilterActions } from '../../../redux/FilterActions';
import { DropDownMenuItemComponent } from '../DropDownMenuItemComponent/DropDowmMenuItemComponent';
import './MenuItemComponent.scss';
import { MenuItemState } from './model';
interface IMenuItemComponentProps {
	items: Filter[];
	viewMode?: string;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
}
export const MenuItemComponent: FC<IMenuItemComponentProps> = ({
	items,
	viewMode,
	setLockModalContent,
	registerUserClickEvent
}) => {
	const [state] = useState({ ...MenuItemState, items });

	const dispatch = useDispatch();
	const selectedCategory = useSelector(
		(state: RootState) => state?.appState?.filterState?.selectedCategory
	);
	const handleDispacth = (child: FilterVM, index?: number) => {
		const tempItems = items.filter(item => item.id !== child.id);
		state.handleMenuClick(child.id, index);
		if (selectedCategory && selectedCategory === child.name) {
			items.forEach((item: Filter) => {
				const inputElement = document.getElementById(`menu${item.id}`);
				inputElement?.classList.remove('filter-hidden');
			});
			state.removeClass(['filter-hidden', 'filter-active', 'menu-open', 'carrot-icon-open']);
			dispatch(FilterActions.clearAllCategories());
		} else {
			tempItems.forEach((item: Filter) => {
				const inputElement = document.getElementById(`menu${item.id}`);
				inputElement?.classList.add('filter-hidden');
			});
			dispatch(FilterActions.setCategory(child.name));
		}
		state.removeClass(['menu-active']);
		const inputElement = document.getElementById(`menu${child.id}`);
		inputElement?.classList.add('menu-active');
		if (selectedCategory) {
			dispatch(FilterActions.removeParentFilter(child));
		} else {
			dispatch(FilterActions.addParentFilter(child));
		}
	};
	return (
		<>
			{state.items &&
				state.items.map((child: FilterVM, index: number) => {
					return (
						<div key={child.id} id={`menu${child.id}`}>
							<span
								data-testid={`main-menu-name-${index}`}
								className={viewMode === 'mobile' ? 'main-panel-name' : 'main-menu-name'}
								onClick={() => handleDispacth(child, index)}
							>
								{child.name}
								{viewMode === 'mobile' && selectedCategory !== child.name && (
									<i className='ri-arrow-right-s-line icon'></i>
								)}
							</span>
							{child.children && (
								<div
									className={`main-menu-items ${viewMode === 'mobile' ? 'mobile-aling' : ''}`}
									id={`main-menu-items-${child.id}${index}`}
								>
									<DropDownMenuItemComponent
										mainMenuId={`main-menu-items-${child.id}${index}`}
										categoryName={child.name}
										items={child.children}
										viewMode={viewMode}
										setLockModalContent={setLockModalContent}
										registerUserClickEvent={registerUserClickEvent}
									/>
								</div>
							)}
						</div>
					);
				})}
		</>
	);
};
