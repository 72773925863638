const DEFAULT_STATE: IDropDownMenuItemComponentState = {
	handleClickCarrot: (parentIndex?: string, index?: number) => {
		const checkId = `check-box${parentIndex}${index}`;
		const carrotIconId = `carrot-icon${parentIndex}${index}`;
		const checkRefs = document.querySelectorAll('.check-box-children');
		checkRefs.forEach(item => {
			if (item.id !== checkId) {
				item.classList.remove('filter-active');
			}
		});

		const carrotRef = document.querySelectorAll('.carrot-icon-open');
		carrotRef.forEach(item => {
			if (item.id !== carrotIconId) {
				item.classList.remove('carrot-icon-open');
			}
		});
		const checkElement = document.getElementById(checkId);
		const menuElement = document.getElementById(`menu-item${parentIndex}${index}`);
		const iconElement = document.getElementById(carrotIconId);
		iconElement?.classList.toggle('carrot-icon-open');
		checkElement?.classList.toggle('filter-active');
		menuElement?.classList.toggle('menu-open');
	},
	handelArrowClick(parentIndex?: string, index?: number) {
		const checkId = `check-box${parentIndex}${index}`;
		const checkRefs = document.querySelectorAll('.check-box-children');
		const dropRefs = document.querySelectorAll('.drop-menu-item');
		dropRefs.forEach(item => {
			item.classList.add('filter-hidden');
		});
		checkRefs.forEach(item => {
			if (item.id !== checkId) {
				item.classList.remove('filter-active');
			}
		});
		const checkElement = document.getElementById(checkId);
		checkElement?.classList.toggle('filter-active');
	}
};

interface IDropDownMenuItemComponentState {
	handleClickCarrot: (parentIndex?: string, index?: number) => void;
	handelArrowClick: (parentIndex?: string, index?: number) => void;
}
export const DropDownMenuItemComponentState = {
	...DEFAULT_STATE
};
