import { FC } from 'react';
import './ProgressComponent.scss';

interface IProgressComponentProps {
	progress: number;
	max: number;
}

export const ProgressComponent: FC<IProgressComponentProps> = ({ progress, max }) => {
	const style = { width: `${progress > max ? 100 : progress}%` };
	return <div className={`loading-bar ${progress > 90 ? 'fade-out' : ''}`} style={style}></div>;
};
