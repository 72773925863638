import marketReadyImg from '../../../assets/images/marketReady.png';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';
import './MarketReadyOverlay.scss';
import pattern from '../../../assets/images/marketreadypattern.png';
import { marketReadyData } from './model';

export default function MarketReadyOverlay() {
	const dispatch = useDispatch();
	const handleClose = () => {
		dispatch(AppActions.setModalContent(undefined));
	};
	return (
		<div className='market-ready-overlay'>
			<div className='logo'>
				<img src={marketReadyImg} alt='' />
			</div>
			<div className='body'>
				<p>{marketReadyData.description[0]}</p>
				<p>{marketReadyData.description[1]}</p>
			</div>
			<div className='learn-more'>
				<a href={marketReadyData.learnMore.link} target='blank'>
					{marketReadyData.learnMore.title}
				</a>
			</div>
			<i className='fa fa-close market-ready-close' onClick={handleClose}></i>
			<img src={pattern} className='back-pattern' alt='' />
		</div>
	);
}
