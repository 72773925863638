import { Dispatch, FC, ReactNode, useEffect, useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ProductVM } from '../../../common/view-model';
import { HowItsMadeComponent } from './howItsMade/HowItsMadeComponent';
import { DistributionComponent } from './distribution/DistributionComponent';
import { PackagingComponent } from './packaging/PackagingComponent';
import { SalesInformationComponent } from './salesInformation/SalesInformationComponent';
import { MarketingAssetsComponent } from './marketingAssets/MarketingAssetsComponent';
import { RootState } from '../../../redux/AppReducer';
import { useSelector } from 'react-redux';
import './ProductAccordionComponent.scss';
import { findUserAccessType } from '../../../helpers/findUserAccessType';
import { UserType } from '../../../common/model';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { AppActions } from '../../../redux/AppActions';
import LockOverlayComponent from '../../LockOverlay/LockOverlayComponent';
import { UserActions } from '../../../redux/UserActions';
import { publicProductDetailsActions } from '../../../common/constants';
import { ContactInformationComponent } from './contactInformation/ContactInformationComponent';

interface IAccordionItemComponentProps {
	title: string;
	children: ReactNode;
	comingSoon: boolean;
	likelyNewProduct?: boolean;
	setLockModalContent?: () => void;
	registerUserClickEvent?: (event: string) => void;
	contactAccordionOpened?: boolean;
	isContactAccordion?: boolean;
	resetAccordionContactOpened?: () => void;
}
export const AccordionItemComponent: FC<IAccordionItemComponentProps> = ({
	title,
	children,
	comingSoon,
	likelyNewProduct,
	setLockModalContent,
	registerUserClickEvent,
	contactAccordionOpened,
	isContactAccordion,
	resetAccordionContactOpened
}) => {
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const [isOpen, setIsOpen] = useState(false);
	const handleClicked = (event: any) => {
		if (isCurrentUserAnonymous()) {
			setLockModalContent && setLockModalContent();
			registerUserClickEvent &&
				registerUserClickEvent(publicProductDetailsActions.CLICK_PRODUCT_ACCORDION);
			return;
		}
		if (!isOpen) {
			setIsOpen(true);
		} else {
			if (event.target.id === 'accordion-icon') {
				setIsOpen(false);
				if (isContactAccordion) {
					resetAccordionContactOpened && resetAccordionContactOpened();
				}
			}
		}
	};
	useEffect(() => {
		if (contactAccordionOpened) {
			setIsOpen(true);
		}
	}, [contactAccordionOpened]);

	if (findUserAccessType(userState?.user_type) === UserType.Brand && title === 'Marketing Assets')
		return <></>;

	return (
		<div
			data-testid='accordion-component'
			className={`accordion-item ${!isOpen ? 'clickable' : ''}`}
			onClick={handleClicked}
			data-custom-attribute={isContactAccordion ? 'about-brand' : ''}
		>
			<div className='accordion-title'>
				<div className={`accordion-title-text ${isOpen ? 'title-open' : ''}`}>{title}</div>
				{comingSoon && <div className='accordion-title-comingsoon'>Coming Soon</div>}
				{likelyNewProduct && (
					<div className='accordion-title-likely-new-product'>Likely New Product</div>
				)}
				<div className='accordion-title-icon'>
					{isCurrentUserAnonymous() ? (
						<i className='ri-lock-line'></i>
					) : (
						<i
							id='accordion-icon'
							className={`${
								isOpen ? 'main-menu-close ri-subtract-line' : 'main-menu-open ri-add-line'
							}`}
							onClick={handleClicked}
						></i>
					)}
				</div>
			</div>
			{isOpen && <div className='accordion-content'>{children}</div>}
			<div className='separator'></div>
		</div>
	);
};

interface IProductAccordionComponentProps {
	product: ProductVM | undefined;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
	contactAccordionOpened?: boolean;
	resetAccordionContactOpened?: () => void;
}

const ProductAccordionComponent: FC<IProductAccordionComponentProps> = ({
	product,
	setLockModalContent,
	registerUserClickEvent,
	contactAccordionOpened,
	resetAccordionContactOpened
}) => {
	const contactAccordionSectionName = 'Contact Information';
	//Change this to const after we remove the flag from the environment
	let accordionItems: IAccordionItemComponentProps[] = [
		{
			title: 'How It’s Made',
			comingSoon: false,
			children: <HowItsMadeComponent product={product} />
		},
		{
			title: 'Distribution',
			comingSoon: false,
			children: <DistributionComponent product={product} />
		},
		{
			title: 'Product Packaging',
			comingSoon: false,
			children: <PackagingComponent product={product} />
		},
		{
			title: 'Sales Information',
			comingSoon: false,
			children: <SalesInformationComponent product={product} />
		},
		{
			title: 'Marketing Assets',
			comingSoon: false,
			children: <MarketingAssetsComponent product={product} />
		},
		{
			title: contactAccordionSectionName,
			comingSoon: false,
			children: (
				<ContactInformationComponent
					brokers={product?.brand?.brokers}
					contact={{
						name: product?.brand.contact_name,
						email: product?.brand.contact_email,
						phone: product?.brand.contact_phone
					}}
				/>
			),
			contactAccordionOpened
		}
	];

	return (
		<div className='accordion-container'>
			{accordionItems.map((item, index) => (
				<AccordionItemComponent
					key={index}
					title={item.title}
					children={item.children}
					comingSoon={item.comingSoon}
					likelyNewProduct={item.likelyNewProduct}
					setLockModalContent={setLockModalContent}
					registerUserClickEvent={registerUserClickEvent}
					isContactAccordion={item.title === contactAccordionSectionName}
					contactAccordionOpened={
						item.contactAccordionOpened && item.title === contactAccordionSectionName
					}
					resetAccordionContactOpened={resetAccordionContactOpened}
				/>
			))}
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	contactAccordionOpened: state?.contactAccordionOpened
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	},
	resetAccordionContactOpened: () => {
		dispatch(AppActions.setContactAccordionOpen(false));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAccordionComponent);
