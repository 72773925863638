import { BOBErrorState as model } from './model';
import './BOBErrorComponent.scss';
export const BOBErrorComponent = () => {
	return (
		<>
			<span className='bob-error-container'>
				<p className='bob-error-title'>{model.BOBErrorTitle}</p>
				<p className='bob-error-body'>
					{model.BOBErrorBody}
					<a href='mailto:beacondiscovery@newhope.com' target='_blank' rel='noreferrer'>
						{' '}
						beacondiscovery@newhope.com
					</a>
				</p>
			</span>
		</>
	);
};
