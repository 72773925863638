import { FC, useState } from 'react';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
import './FilterControlComponent.scss';
import { FilterControlPanelState } from './model';
import { ISortByOption, ISuggestion, ScreenType, viewModeType } from '../../../common/view-model';
import SortProductsComponent from '../../sortProducts/SortProductsComponent';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { AppActions } from '../../../redux/AppActions';
import { Action, Dispatch } from 'redux';
import { FilterActions } from '../../../redux/FilterActions';
import { SearchActions } from '../../../redux/search/SearchActions';
import { useNavigate } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';

interface IFilterControlComponentProps {
	showAllFiltersHandler: () => void;
	clearAllFiltersHandler: () => void;
	productsLength: number;
	showAllFiltersButton: boolean;
	showClearAllFiltersButton?: boolean;
	deviceViewMode: viewModeType;
	deviceScreen?: ScreenType;
	handleModalContent: () => void;
	sortByOptions?: ISortByOption[];
	setSortBy: (option: ISortByOption) => void;
	searchTerm?: ISuggestion;
	clearFiltersAndSearch: () => void;
}

const FilterControlComponent: FC<IFilterControlComponentProps> = ({
	showAllFiltersHandler,
	clearAllFiltersHandler,
	productsLength,
	showAllFiltersButton,
	showClearAllFiltersButton,
	deviceViewMode,
	deviceScreen,
	handleModalContent,
	sortByOptions,
	setSortBy,
	searchTerm,
	clearFiltersAndSearch
}) => {
	const [state] = useState({ ...FilterControlPanelState });
	const navigate = useNavigate();
	const homePageUrl = 'products';
	const handleShowAll = () => {
		if (deviceScreen === ScreenType.TABLET) {
			handleModalContent();
		}
		showAllFiltersHandler();
	};
	const handleClearAll = () => {
		clearFiltersAndSearch();
		navigate(`${isCurrentUserAnonymous() ? '..' : ''}/${homePageUrl}`);
	};
	return (
		<div
			className={`filter-control-container ${showAllFiltersButton ? 'expanded-wrapper' : ''}${
				searchTerm && deviceScreen === ScreenType.MOBILE ? 'search-wrapper' : ''
			}`}
		>
			{showAllFiltersButton && (
				<ButtonComponent
					style='show-all-filters-btn'
					text={
						deviceViewMode === viewModeType.DESKTOP ? state.showAllFiltersText : state.filtersText
					}
					onClick={handleShowAll}
					icon={<i className='ri-filter-3-line'></i>}
					iconPosition='start'
					id='show-all-filters-btn'
				></ButtonComponent>
			)}
			{deviceScreen !== ScreenType.MOBILE && deviceScreen !== ScreenType.TABLET ? (
				<>
					<span
						className={`items-length ${showAllFiltersButton ? 'length-wrapper' : ''}`}
					>{`${productsLength} ${state.itemsText}`}</span>
					{showClearAllFiltersButton && (
						<span
							className={`clear-all  ${showAllFiltersButton ? 'length-wrapper' : ''}`}
							onClick={handleClearAll}
						>
							{state.clearAllFiltersText}
						</span>
					)}
				</>
			) : (
				<>
					{showClearAllFiltersButton && (
						<span
							className={`clear-all  ${showAllFiltersButton ? 'length-wrapper' : ''}`}
							onClick={handleClearAll}
						>
							{state.clearAllFiltersText}
						</span>
					)}
					<span
						className={`items-length ${
							showAllFiltersButton ? 'length-wrapper' : ''
						} length-alingment`}
					>{`${productsLength} ${state.itemsText}`}</span>
				</>
			)}
			<div className='sort-products'>
				<SortProductsComponent
					availableSorts={sortByOptions}
					deviceViewMode={deviceViewMode}
					isFavoritesPage={false}
					setSortBy={setSortBy}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	deviceScreen: state.deviceScreen || undefined,
	sortByOptions: state?.filterState?.sortByOptions || undefined,
	searchTerm: state?.searchState?.searchTerm || undefined
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <></>,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: false
			})
		);
	},
	setSortBy: (sortBy: ISortByOption | undefined) => {
		return dispatch(FilterActions.setSortBy(sortBy));
	},
	clearSearch: () => {
		dispatch(SearchActions.setSearchTerm(undefined));
	},
	clearFiltersAndSearch: () => {
		dispatch(FilterActions.clearAllFiltersAndSearchTerm());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterControlComponent);
