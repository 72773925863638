const DEFAULT_STATE: IBrandContentModelState = {
	title: 'Getting Started',
	btnText: 'Start Here'
};

interface IBrandContentModelState {
	title: string;
	btnText: string;
}

export const BrandContentModel = {
	...DEFAULT_STATE
};
