import { gql } from 'graphql.macro';
export const UPDATE_BRAND = gql`
	mutation UpdateBrand($brandInfo: UpdateOnboardingBrandInput!) {
		updateBrandSalesforce(
			updateBrand: $brandInfo
		) {
			success
			message
		}
	}
`;
