import { APP_NAME } from '../../../common/constants';

const DEFAULT_STATE: IIngredientsTemplateCardState = {
	bannedIngredientsLabel: 'banned ingredients',
	allProductsLabel: `All products on ${APP_NAME} `,
	topBannedIngredientsLabel: 'Top banned ingredients:',
	defaultTemplateLabel: 'Default',
	seeFullListLabel: 'See full list'
};
interface IIngredientsTemplateCardState {
	bannedIngredientsLabel: string;
	allProductsLabel: string;
	topBannedIngredientsLabel: string;
	defaultTemplateLabel: string;
	seeFullListLabel: string;
}
export const ingredientsTemplateCardState = { ...DEFAULT_STATE };
