import { useEffect } from 'react';
import { eventPublicPathName } from '../common/constants';
import { validateHasAllAuthenticationKeys } from '../helpers/authenticationHelper';

export const useAuthRedirection = () => {
	useEffect(() => {
		if (!validateHasAllAuthenticationKeys()) return;
		const path = window.location.pathname;
		if (path.includes(eventPublicPathName)) {
			const newPath = path.replace(eventPublicPathName, '').slice(1);
			window.location.pathname = newPath;
		}
	}, [window.location.pathname]);
};
