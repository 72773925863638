export interface IMarketReadyData {
	description: string[];
	learnMore: {
		title: string;
		link: string;
	};
}

export const marketReadyData: IMarketReadyData = {
	description: [
		'A MarketReady Verified seal indicates that a product has been reviewed and revised for FDA labeling compliance. MarketReady offers simplified regulatory guidance for CPG brands and ingredient suppliers.',
		'Labeling and marketing reviews help brands achieve market entry and stay compliant through growth efforts, so retailers can feel confident their product choices have been reviewed by industry experts.'
	],

	learnMore: {
		title: 'Learn More',
		link: 'https://beacon.newhope.com/marketready-verified/'
	}
};
