import { FC } from 'react';
import { ProductLoadingCard } from '../ProductListLoadingComponent';
import './ProductListSkeletonComponent.scss';

interface IProductListSkeletonProps {
	viewMode: 'expanded' | 'collapsed';
}

export const ProductListSkeletonComponent: FC<IProductListSkeletonProps> = ({ viewMode }) => {
	return (
		<div data-testid='loading-div' className='product-list-skeleton-container'>
			<div className='p-skeleton-body p-skeleton-body-for-filter'>
				<div className={`p-skeleton-product-list-infinite-scroll ${viewMode}`}>
					{Array.from(Array(12).keys()).map(i => (
						<ProductLoadingCard key={i} />
					))}
				</div>
			</div>
		</div>
	);
};
