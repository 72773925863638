import { HeaderOptions } from '../Header/model';

const DEFAULT_STATE: FooterState = {
	copyright:
		'Copyright © 2022. All rights reserved. Informa Markets, a trading division of Informa PLC.',
	logoUrl: require('../../../assets/images/corporate-logo.png'),
	baseUrl: 'https://www.informamarkets.com/',
	getOptions(url) {
		return [
			{
				label: 'Accessibility',
				href: `${url}accessibility`
			},

			{
				label: 'Privacy Policy',
				href: `${url}privacy-policy`
			},

			{
				label: 'Cookie Policy',
				href: `${url}cookie-policy`
			},

			{
				label: 'Terms of Use',
				href: `${url}terms-of-use`
			},
			{
				label: 'Visitor Terms and Conditions',
				href: `${url}visitor-terms-and-conditions`
			}
		];
	}
};
export interface FooterState {
	copyright: string;
	logoUrl: string;
	baseUrl: string;
	getOptions: (url: string) => FooterOptions[];
}
export const FooterState = {
	...DEFAULT_STATE
};
export interface FooterOptions extends HeaderOptions {}
