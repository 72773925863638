import { Action } from 'redux-actions';
import { ofType, StateObservable } from 'redux-observable';
import { map, mergeMap, of, interval, takeWhile, Subject, takeUntil } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppActions } from './AppActions';
import { AppState } from './AppReducer';
import {
	isCurrentUserAnonymous,
	removeAllAuthenticationKeysFromLocalStorage
} from '../helpers/authenticationHelper';
import { UserActions } from './UserActions';
import { removeCacheUrl } from '../helpers/cacheUrlAnonymousHelper';
import { lastUrlLocalStorageKey } from '../common/constants';

const cancelIntervalSubject = new Subject();

export class AppEpics {
	static init() {
		return [
			AppEpics.setStateEpic,
			AppEpics.setElementOverlayEpic,
			AppEpics.showAppTitleEpic,
			AppEpics.setIsLoadingDataEpic,
			AppEpics.setGeneralErrorEpic,
			AppEpics.setStandAloneLayoutEpic,
			AppEpics.setProfileLayoutEpic,
			AppEpics.setModalContentEpic,
			AppEpics.setCompleteBrandDetailslayoutEpic,
			AppEpics.startProfileProgressEpic,
			AppEpics.finishProgressEpic,
			AppEpics.startCreateBeaconProfileProgressEpic,
			AppEpics.finishCreateBeaconProgressEpic,
			AppEpics.cancellationEpic,
			AppEpics.unauthorizedErrorEpic
		];
	}

	static setStateEpic = (action$: Observable<Action<AppState>>): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_STATE),
			map((action: Action<AppState>) => {
				return { type: 'END_STATE', payload: action.payload };
			})
		);
	};
	static setElementOverlayEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_ELEMENT_OVERLAY),
			map((action: Action<any>) => {
				return { type: 'OVERLAY_STATE', payload: action.payload };
			})
		);
	};
	static showAppTitleEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_APP_TITLE),
			map((action: Action<any>) => {
				return { type: 'APP_TITLE', payload: action.payload };
			})
		);
	};
	static setIsLoadingDataEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_IS_LOADING_DATA),
			map((action: Action<any>) => {
				return { type: 'IS_LOADING_DATA', payload: action.payload };
			})
		);
	};
	static setGeneralErrorEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_GENERAL_ERROR),
			map((action: Action<any>) => {
				return { type: 'GENERAL_ERROR', payload: action.payload };
			})
		);
	};
	static setStandAloneLayoutEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_STAND_ALONE_LAYOUT),
			map((action: Action<any>) => {
				return { type: 'STAND_ALONE_LAYOUT', payload: action.payload };
			})
		);
	};
	static setProfileLayoutEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_PROFILE_LAYOUT),
			map((action: Action<any>) => {
				return { type: 'PROFILE_LAYOUT', payload: action.payload };
			})
		);
	};
	static setModalContentEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_MODAL_CONTENT),
			map((action: Action<any>) => {
				if (!action.payload) {
					document.body.style.overflow = 'auto';
				}
				return { type: 'MODAL_CONTENT', payload: action.payload };
			})
		);
	};
	static setCompleteBrandDetailslayoutEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(AppActions.SET_COMPLETE_BRAND_DETAILS_LAYOUT),
			map((action: Action<any>) => {
				return { type: 'COMPLETE_BRAND_DETAILS_LAYOUT', payload: action.payload };
			})
		);
	};
	static startProfileProgressEpic = (action$: Observable<Action<AppState>>): Observable<any> => {
		return action$.pipe(
			ofType(AppActions.START_PROGRESS),
			mergeMap((action: Action<any>) => {
				const totalSteps = 45;
				const intervalTime = 50;
				const increment = 2;
				return interval(intervalTime).pipe(
					takeWhile(step => step <= totalSteps),
					map(step => step * increment),
					mergeMap(progress => of(AppActions.setLoadProfileProgress(progress))),
					takeUntil(cancelIntervalSubject)
				);
			})
		);
	};
	static finishProgressEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(AppActions.FINISH_PROGRESS),
			mergeMap((action: Action<any>) => {
				const totalSteps = 16;
				const intervalTime = 50;
				const increment = 0.625;
				const startStep = state$.value.appState.loadProfileProgress;
				return interval(intervalTime).pipe(
					takeWhile(step => step <= totalSteps),
					map(step => startStep + step * increment),
					mergeMap(progress => of(AppActions.setLoadProfileProgress(progress))),
					takeUntil(cancelIntervalSubject)
				);
			})
		);
	};
	static startCreateBeaconProfileProgressEpic = (
		action$: Observable<Action<AppState>>
	): Observable<any> => {
		return action$.pipe(
			ofType(AppActions.START_BEACON_PROFILE_PROGRESS),
			mergeMap((action: Action<any>) => {
				const totalSteps = 90;
				const intervalTime = 80;
				const increment = 1;
				return interval(intervalTime).pipe(
					takeWhile(step => step <= totalSteps),
					map(step => step * increment),
					mergeMap(progress => of(AppActions.setCreateBeaconProfileProgress(progress))),
					takeUntil(cancelIntervalSubject)
				);
			})
		);
	};
	static finishCreateBeaconProgressEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(AppActions.FINISH_BEACON_PROFILE_PROGRESS),
			mergeMap((action: Action<any>) => {
				const totalSteps = 10;
				const intervalTime = 80;
				const increment = 1;
				const startStep = state$.value.appState.createBeaconProfileProgress;
				return interval(intervalTime).pipe(
					takeWhile(step => step <= totalSteps),
					map(step => startStep + step * increment),
					mergeMap(progress => of(AppActions.setCreateBeaconProfileProgress(progress))),
					takeUntil(cancelIntervalSubject)
				);
			})
		);
	};
	static cancellationEpic = (action$: Observable<Action<AppState>>): Observable<any> => {
		return action$.pipe(
			ofType(AppActions.INTERVAL_CANCELATION),
			map((action: Action<any>) => {
				cancelIntervalSubject.next(undefined);
				return { type: 'END_STATE', payload: action.payload };
			})
		);
	};
	static unauthorizedErrorEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(AppActions.UNAUTHORIZED_ERROR),
			map((action: Action<any>) => {
				if (isCurrentUserAnonymous()) {
					removeCacheUrl();
					localStorage.setItem(lastUrlLocalStorageKey, window.location.pathname);
					return { type: UserActions.SET_UNAUTHORIZE_ANONYMOUS_USER, payload: action.payload };
				} else {
					removeAllAuthenticationKeysFromLocalStorage();
					return { type: AppActions.UNAUTHORIZED_ERROR_RESOLVE, payload: action.payload };
				}
			})
		);
	};
}
