import { FC } from 'react';
import './ExpoLabelComponent.scss';

export interface IExpoLabelComponentProps {
	text: string;
	labelStyle?: string;
	onClick?: () => void;
	icon?: string;
	showAnimation?: boolean;
}

export const ExpoLabelComponent: FC<IExpoLabelComponentProps> = ({
	text,
	labelStyle,
	onClick,
	icon,
	showAnimation
}) => {
	const handleClick = (event: any) => {
		event.preventDefault();
		//Temporarily commented BP - 1216
		// if (onClick) {
		// 	onClick();
		// }
	};
	return (
		<div
			data-testid='expo-label-component'
			className={`expo-label ${labelStyle} ${showAnimation ? 'animated' : ''}`}
			onClick={(event: any) => {
				handleClick(event);
			}}
		>
			<span id='expo-label-component'>
				{text}
				{icon && <i className={icon}></i>}
			</span>
		</div>
	);
};
