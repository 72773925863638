import { FC, useCallback, useState } from 'react';
import { Filter, FilterLevel, FilterVM } from '../../../common/view-model';
import { CheckboxComponent } from '../CheckboxComponent/CheckboxComponent';
import './DropDownMenuItemComponent.scss';
import { DropDownMenuItemComponentState } from './model';
import { useDispatch } from 'react-redux';
import { FilterActions } from '../../../redux/FilterActions';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
interface IDropDownMenuItemComponentProps {
	items: Filter[];
	mainMenuId?: string;
	categoryName?: string;
	viewMode?: string;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
}
export const DropDownMenuItemComponent: FC<IDropDownMenuItemComponentProps> = ({
	items,
	mainMenuId,
	categoryName,
	viewMode,
	setLockModalContent,
	registerUserClickEvent
}) => {
	const [state] = useState({ ...DropDownMenuItemComponentState });
	const dispatch = useDispatch();
	const carrotIconClickCallback = useCallback(
		(carrotIconOpen: boolean) => {
			dispatch(FilterActions.setCarrotIconClick(carrotIconOpen));
		},
		[dispatch]
	);
	const handleCarrotClick = (filterId?: string, index?: number) => {
		state.handleClickCarrot(filterId, index);
		carrotIconClickCallback(true);
	};
	return (
		<>
			{items &&
				items.map((filter: FilterVM, index: number) => {
					return (
						<div key={filter.id} id={`menu-item${filter.id}${index}`}>
							<div className='drop-menu-item'>
								{filter.children && viewMode !== 'mobile' && (
									<div
										data-testid={`carrot-icon${index}`}
										className='carrot-icon'
										onClick={() => handleCarrotClick(filter.id, index)}
										id={`carrot-icon${filter.id}${index}`}
									></div>
								)}
								<CheckboxComponent
									checked={filter.isChecked}
									label={filter.name}
									children={filter.children}
									parentIndex={filter.id}
									index={index}
									level={FilterLevel.THIRD}
									type={filter.type}
									parentId={filter.id}
									parentLength={filter.children?.length}
									mainMenuId={mainMenuId}
									categoryName={categoryName}
									checkBoxGroupId={`check-box${filter.id}${index}`}
									carrotIconRef={`carrot-icon${filter.id}${index}`}
									isSuperCategory={filter.children !== undefined}
									breadcrumText={`${filter.name}`}
									isLocked={isCurrentUserAnonymous()}
									setLockModalContent={setLockModalContent}
									registerUserClickEvent={registerUserClickEvent}
								/>
								{filter.children && viewMode === 'mobile' && (
									<div
										data-testid={`foward-arrow${index}`}
										className='foward-arrow'
										onClick={() => state.handelArrowClick(filter.id, index)}
										id={`panel-icon${filter.id}${index}`}
									>
										<i className='ri-arrow-right-s-line icon'></i>
									</div>
								)}
							</div>
							{filter.children && (
								<div
									className={`check-box-children ${
										viewMode === 'mobile' ? 'filter-panel-item' : ''
									}`}
									id={`check-box${filter.id}${index}`}
								>
									{filter.children.map((child: FilterVM, childIndex: number) => {
										return (
											<div key={child.id}>
												<CheckboxComponent
													checked={child.isChecked}
													label={child.name}
													parentIndex={child.id}
													index={childIndex}
													level={FilterLevel.FOURTH}
													parentId={filter.id}
													type={child.type}
													parentLength={filter.children.length}
													mainMenuId={mainMenuId}
													checkBoxGroupId={`check-box${filter.id}${index}`}
													categoryName={categoryName}
													carrotIconRef={`carrot-icon${filter.id}${index}`}
													breadcrumText={`${filter.name} / ${child.name}`}
													isLocked={isCurrentUserAnonymous()}
													setLockModalContent={setLockModalContent}
													registerUserClickEvent={registerUserClickEvent}
												/>
											</div>
										);
									})}
								</div>
							)}
						</div>
					);
				})}
		</>
	);
};
