import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import { HowGoodScore } from '../../../product-detail/howGoodScore/HowGoodScoreComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import React from 'react';
import './ProductSustainabilityFormComponent.scss';
import { ProductInfo } from '../../../../redux/AppReducer';
interface IProductDetailsForm {
	productState: ProductInfo;
	onUpdateProfileProduct: (productState: ProductInfo) => void;
	onCancel: () => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	showHowGoodCard?: boolean;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
}
const ProductSustainabilitySchema = Yup.object().shape({
	showHowGood: Yup.boolean()
});
const ProductDetailsFormComponent: React.FC<IProductDetailsForm> = ({
	productState,
	onUpdateProfileProduct,
	onCancel,
	isEditing,
	toggleEditing,
	showHowGoodCard,
	onChange,
	onValidationChange,
	cleanForm
}) => {
	const [ID, setID] = useState<string | null>(null);
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	const { values, handleChange, handleReset, submitForm, isValid, setValues } = useFormik({
		initialValues: {
			...productState,
			showHowGood: productState.showHowGood
		},
		onSubmit: values => {
			onUpdateProfileProduct(values);
		},
		validationSchema: ProductSustainabilitySchema
	});
	useEffect(() => {
		if (productState) {
			setID('product-sustainability-' + productState.id);
		}
	}, [productState]);
	useEffect(() => {
		onChange(values);
		onValidationChange(isValid);
	}, [values, isValid]);
	useEffect(() => {
		handleReset(null);
	}, [cleanForm]);
	useEffect(() => {
		setValues({
			...productState,
			showHowGood: productState.showHowGood
		});
	}, [productState, isEditing]);
	return (
		<div className='product-sustainability-form'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				isValid={isValid}
				onUpdate={() => submitForm()}
				titleText='Sustainability'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						{showHowGoodCard ? (
							<>
								<p className='helper-text'>
									Example of what your HowGood Sustainability Impact Estimate would look like for
									retailers:
								</p>
								<HowGoodScore
									product={{
										...productState,
										allergens: [],
										attributes: [],
										brand: { processingTechniques: [{}], regionsBydistributor: [] },
										certifications: [],
										distributor: {},
										imageUrl: '',
										ingredients: [],
										netContent: '',
										region: {},
										taxonomy: []
									}}
									registerUserClickEvent={() => {}}
									score={30}></HowGoodScore>
							</>
						) : (
							<></>
						)}

						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms
											text='Show HowGood Estimate?
											'
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Toggling this on means the 'Product HowGood Estimate' and 'HowGood Label'
													fields will be visible to users on Beacon Discovery.
												</>
											}></LabelForms>
										<ButtonComponent
											onClick={() => toggleEditing(ID)}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
										/>
									</div>
									<div>
										<LabelForms text='HowGood Label'></LabelForms>
										<p className={productState.howGoodLabel ? '' : 'blank-field'}>
											{productState.howGoodLabel}
										</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Product HowGood Estimate'></LabelForms>
										<p className={productState.howGoodEstimated ? '' : 'blank-field'}>
											{productState.howGoodEstimated}
										</p>
									</div>
									<div>
										<LabelForms text="HowGood Avg Estimate for Product's Category"></LabelForms>
										<p className={productState.howGoodAvgEstimated ? '' : 'blank-field'}>
											{productState.howGoodAvgEstimated}
										</p>
									</div>
								</>
							}></ContainerForms>
					</>
				) : (
					<>
						<ContainerForms
							FormComponent1={
								<>
									<div>
										<LabelForms
											text='Show HowGood Estimate?'
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Toggling this on means the 'Product HowGood Estimate' and 'HowGood Label'
													fields will be visible to users on Beacon Discovery.
												</>
											}></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='showHowGood'
											value={values.showHowGood}
											disabled={false}
											onChange={handleChange}></CheckboxFormComponent>
									</div>
									<div>
										<LabelForms text='HowGood Label'></LabelForms>
										<TextInputComponent
											placeholder='HowGood Label'
											name=''
											value={productState.howGoodLabel ? productState.howGoodLabel : ''}
											disabled={true}></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Product HowGood Estimate'></LabelForms>
										<TextInputComponent
											placeholder='HowGood Estimated'
											name=''
											value={productState.howGoodEstimated ? productState.howGoodEstimated : ''}
											disabled={true}></TextInputComponent>
									</div>
									<div>
										<LabelForms text="HowGood Avg Estimate for Product's Category"></LabelForms>
										<TextInputComponent
											placeholder='HowGood Avg Estimated'
											name=''
											value={
												productState.howGoodAvgEstimated ? productState.howGoodAvgEstimated : ''
											}
											disabled={true}></TextInputComponent>
									</div>
								</>
							}></ContainerForms>
						<ActionsForms
							onUpdate={() => submitForm()}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}
							toggleEditing={() => toggleEditing(null)}></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(ProductDetailsFormComponent);
