import OnbordingComponent from '../../components/onbording/OnbordingComponent';
import { AppActions } from '../../redux/AppActions';
import { RootState } from '../../redux/AppReducer';
import { connect, ConnectedProps } from 'react-redux';
import { FC, useEffect } from 'react';

const OnbordingPage: FC<OnbordingPageProps> = ({ setShowNavbar }) => {
	useEffect(() => {
		setShowNavbar(false);
		return () => {
			setShowNavbar(true);
		};
	}, [setShowNavbar]);
	return <OnbordingComponent />;
};

const mapStateToProps = ({ appState }: RootState) => ({});
const mapDispatchToProps = {
	setShowNavbar: AppActions.showNavbar
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type OnbordingPageProps = ConnectedProps<typeof connector>;
export default connector(OnbordingPage);
