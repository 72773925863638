import { Dispatch, FC, useEffect } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RootState, StoreState, UserState } from '../../redux/AppReducer';
import { FilterActions } from '../../redux/FilterActions';
import { ApolloActions } from '../../redux/ApolloActions';
import { GET_CURRENT_EVENTS } from '../../graphql/queries/events';
import { GET_TAXONOMY_NAVBAR } from '../../graphql/queries/taxonomyNavbar';
import { GET_HIGHLIGHTED_PRODUCTS } from '../../graphql/queries/highlightedProducts';
import { GET_FILTERS } from '../../graphql/queries/filters';
import { eventPublicPathName } from '../../common/constants';
import { validateAnonymousPath } from '../../helpers/authenticationHelper';
import { GET_HIGHLIGHTED_BRANDS } from '../../graphql/queries/highlightedBrands';

interface WithHasDataComponentComponentProps {
	WrappedComponent: FC<any>;
	storeState?: StoreState;
	allFiltersMappedCallback: () => void;
	getEvent: () => void;
	hasAllData?: boolean;
	userState?: UserState;
	getTaxonomyNavbar: () => void;
	getHighlightedProducts: () => void;
	getHighlightedBrands: () => void;
	isRefreshingToken?: boolean;
	getFilters: (variables?: any) => void;
}
const WithHasDataComponentComponent: FC<WithHasDataComponentComponentProps> = ({
	WrappedComponent,
	storeState,
	allFiltersMappedCallback,
	getEvent,
	getTaxonomyNavbar,
	getHighlightedProducts,
	getHighlightedBrands,
	hasAllData,
	userState,
	isRefreshingToken,
	getFilters,
	...props
}) => {
	useEffect(() => {
		if (isRefreshingToken && !validateAnonymousPath(eventPublicPathName)) {
			return;
		}
		if (
			(userState?.hasLoadProfile && window.location.pathname !== '/') ||
			(validateAnonymousPath(eventPublicPathName) && userState?.isAnonymous)
		) {
			if (!storeState?.getCurrentEvent) {
				getEvent();
			}
			if (!storeState?.taxonomyNavbar) {
				getTaxonomyNavbar();
			}
			if (!storeState?.productsHighlighted) {
				getHighlightedProducts();
			}
			if (!storeState?.brandsHighlighted) {
				getHighlightedBrands();
			}
		}
	}, [
		getTaxonomyNavbar,
		getHighlightedProducts,
		storeState?.taxonomyNavbar,
		storeState?.productsHighlighted,
		storeState?.brandsHighlighted,
		userState?.hasLoadProfile,
		userState?.isAnonymous,
		isRefreshingToken,
		storeState?.getCurrentEvent,
		getEvent,
		getHighlightedBrands
	]);
	useEffect(() => {
		if (hasAllData) {
			allFiltersMappedCallback();
		}
	}, [allFiltersMappedCallback, hasAllData]);
	useEffect(() => {
		if (isRefreshingToken && !validateAnonymousPath(eventPublicPathName)) {
			return;
		}
		if (storeState?.filters) {
			return;
		}
		if (userState?.hasLoadProfile) {
			if (window.location.pathname !== '/') {
				getFilters({ templateId: userState?.ingredientsTemplateId?.toString() || '1' });
			}
		} else {
			if (validateAnonymousPath(eventPublicPathName) && userState?.isAnonymous) {
				getFilters({ templateId: '1' });
			}
		}
	}, [
		userState?.hasLoadProfile,
		userState?.ingredientsTemplateId,
		userState?.isAnonymous,
		isRefreshingToken,
		getFilters,
		storeState?.filters
	]);
	return <WrappedComponent {...props} />;
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	storeState: state.storeState,
	isRefreshingToken: state.authState?.isRefreshingToken,
	hasAllData:
		state.storeState?.taxonomyNavbar &&
		state.storeState?.taxonomyNavbar?.length !== 0 &&
		state.storeState.brandsHighlighted?.length !== 0 &&
		state.storeState.productsHighlighted?.products.length !== 0 &&
		state.storeState.filters !== undefined,
	userState: state.userState
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	allFiltersMappedCallback: () => {
		dispatch(FilterActions.mapQueryToFilters());
	},
	getEvent: () => {
		return dispatch(ApolloActions.query({ query: GET_CURRENT_EVENTS }));
	},
	getTaxonomyNavbar: () => {
		return dispatch(ApolloActions.query({ query: GET_TAXONOMY_NAVBAR }));
	},
	getHighlightedProducts: () => {
		return dispatch(ApolloActions.query({ query: GET_HIGHLIGHTED_PRODUCTS }));
	},
	getHighlightedBrands: () => {
		return dispatch(ApolloActions.query({ query: GET_HIGHLIGHTED_BRANDS }));
	},
	getFilters: (variables = null) => {
		return dispatch(ApolloActions.query({ query: GET_FILTERS, variables }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(WithHasDataComponentComponent);
