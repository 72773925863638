import { useState, useEffect } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/AppReducer';

export default function BackToTopComponent() {
	const [showTopBtn, setShowTopBtn] = useState(false);
	const [clicked, setClicked] = useState(false);
	const showBackToTop = useSelector((state: RootState) => state.appState.showBackToTop);
	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.05
	};
	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > window.innerHeight * 2.5) {
				setShowTopBtn(true);
				if (clicked === true) setClicked(false);
			} else {
				setShowTopBtn(false);
			}
			if (document.querySelector('.beacon-footer') !== null) {
				const observer = new IntersectionObserver(callbackObserver, options);
				observer.observe(document.querySelector('.beacon-footer') as Element);
			}
		});
	}, [clicked]);

	const callbackObserver = (
		entries: IntersectionObserverEntry[],
		observer: IntersectionObserver
	): void => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				setShowTopBtn(false);
				entry.target.setAttribute('visible', 'true');
			} else {
				entry.target.setAttribute('visible', 'false');
			}
			observer.unobserve(entry.target);
		});
	};

	return (
		<>
			{showBackToTop && (
				<div
					data-testid='back-top-container'
					onMouseDown={() => setClicked(true)}
					className='top-to-btm'
				>
					{' '}
					{showTopBtn && (
						<>
							<div className='border-gradient-container' onClick={goToTop}>
								<i
									data-testid='go-to-btn'
									className='ri-arrow-up-s-line icon-position icon-style'
								></i>
							</div>
							<span>Back to top</span>
						</>
					)}{' '}
				</div>
			)}
		</>
	);
}
