import { FilterLevel, FilterType, FilterVM } from '../../../common/view-model';
import { addClass, removeClass } from '../../../helpers/handleCssClass';

const DEFAULT_STATE: IBubbleState = {
	handleBubbleClick: (
		filter: FilterVM,
		callback: Function,
		items: FilterVM[],
		panelCallback?: Function
	) => {
		if (!panelCallback) {
			const tempItems = items.filter(item => item.name !== filter.categoryName);
			switch (filter.level) {
				case FilterLevel.FIRST:
					const element = document.getElementById(filter.checkBoxDOMRef!);
					if (element) {
						element.focus();
					}
					break;
				case FilterLevel.SECOND:
					let hiddenFilters = document.querySelectorAll('.filter-hidden');
					hiddenFilters.forEach(item => {
						item.classList.remove('filter-hidden');
					});
					const menuItem = document.getElementById(filter?.mainMenuId!);
					const checkElement = document.getElementById(filter.checkBoxDOMRef!);
					if (!menuItem?.classList.contains('filter-active')) {
						menuItem?.classList.toggle('filter-active');
					}
					tempItems.forEach((item: FilterVM) => {
						const inputElement = document.getElementById(`menu${item.id}`);
						inputElement?.classList.add('filter-hidden');
					});
					checkElement?.focus();
					break;
				case FilterLevel.THIRD:
					setTimeout(() => {
						const checkRefs = document.querySelectorAll('.check-box-children');
						checkRefs.forEach(item => {
							if (item.id !== filter.checkBoxGroupId) {
								item.classList.remove('filter-active');
							}
						});
						const carrotRef = document.querySelectorAll('.carrot-icon-open');
						carrotRef.forEach(item => {
							if (item.id !== filter.carrotIconRef) {
								item.classList.remove('carrot-icon-open');
							}
						});
						const carrotIcon = document.getElementById(filter.carrotIconRef!);
						if (!carrotIcon?.classList.contains('carrot-icon-open')) {
							carrotIcon?.classList.toggle('carrot-icon-open');
						}
						const hidden = document.querySelectorAll('.filter-hidden');
						hidden.forEach(item => {
							item.classList.remove('filter-hidden');
						});
						const menu = document.getElementById(filter?.mainMenuId!);
						const checkLevelElement = document.getElementById(filter.checkBoxDOMRef!);
						const checkGroupElement = document.getElementById(filter.checkBoxGroupId!);
						if (!menu?.classList.contains('filter-active')) {
							menu?.classList.toggle('filter-active');
						}
						if (!checkGroupElement?.classList.contains('filter-active')) {
							checkGroupElement?.classList.toggle('filter-active');
						}
						tempItems.forEach((item: FilterVM) => {
							const inputElement = document.getElementById(`menu${item.id}`);
							inputElement?.classList.add('filter-hidden');
						});
						checkLevelElement?.focus();
					}, 100);
					break;

				default:
					break;
			}
			const activeBubbles = document.querySelectorAll('.bubble-active');
			activeBubbles.forEach(item => {
				item.classList.remove('bubble-active');
			});
			const bubbleRef = document.getElementById(`bubble${filter.id}`);
			if (!bubbleRef?.classList.contains('secondary')) {
				bubbleRef?.classList.toggle('bubble-active');
			}
			callback();
		} else {
			panelCallback();
			const idToRemove = `panel-content${filter.type === FilterType.DISTRIBUTOR ? 0 : 1}`;
			removeClass(idToRemove, 'check-panel');
			const idToAdd = `panel-content${filter.type === FilterType.DISTRIBUTOR ? 1 : 0}`;
			addClass(idToAdd, 'check-panel');
			const elements = document.querySelectorAll('.panel-menu');
			elements.forEach(element => {
				element.classList.add('filter-hidden');
			});
		}
	}
};
export const validateShowBubble = (filter: FilterVM, selectedFilters?: FilterVM[]) => {
	return (
		(filter.level === FilterLevel.THIRD &&
			selectedFilters?.find(f => f.parentId === filter.id && f.isSuperCategory)?.children
				?.length ===
				selectedFilters?.filter(f => f.parentId === filter.id)?.length! - 1) ||
		(filter.level === FilterLevel.THIRD && !filter.children) ||
		(filter.level === FilterLevel.FOURTH &&
			selectedFilters?.filter(f => f.parentId === filter.parentId)?.length! - 1 !==
				filter?.parentLength) ||
		(filter.type !== FilterType.CATEGORY && filter.type !== FilterType.WHATS_NEW)
	);
};
interface IBubbleState {
	handleBubbleClick: (
		filter: FilterVM,
		callback: Function,
		items: FilterVM[],
		panelCallback?: Function
	) => void;
}
export const BubbleState = {
	...DEFAULT_STATE
};
