import { FC } from 'react';
import './ToastComponent.scss';
import { ButtonComponent } from '../ButtonComponent/ButtonComponent';

export interface ToastComponentProps {
	title: string;
	text: string;
	afterAction?: () => void;
	undoAction?: () => void;
	afterActionText?: string;
	undoActionText?: string;
}

const ToastComponent: FC<ToastComponentProps> = ({
	title,
	text,
	afterAction,
	afterActionText,
	undoAction,
	undoActionText
}) => {
	return (
		<div className='toast-body'>
			<h5 className='toast-title'>{title}</h5>
			<div className='toast-text'>{text}</div>
			{(afterAction || undoAction) && (
				<div className='toast-actions'>
					{afterAction && (
						<ButtonComponent
							text={afterActionText || 'Ok'}
							onClick={afterAction}
							style={'rounded-button secondary'}
						/>
					)}
					{undoAction && (
						<ButtonComponent
							text={undoActionText || 'Undo'}
							onClick={undoAction}
							style={'rounded-button tertiary'}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ToastComponent;
