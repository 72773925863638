import { HeaderOptions } from '../Layout/Header/model';

const DEFAULT_STATE: FooterState = {
	getServices: () => {
		return [
			{
				text: 'Verify your products here',
				logoUrl: require('../../assets/images/partnerLogo3.png'),
				href: 'https://store.newhope.com/pages/marketready',
				label: '',
				logoWidth: 'auto',
				logoHeight: '52.5px'
			}
		];
	},
	getPartners: () => {
		return [
			{
				text: 'Start your relationship with us',
				logoUrl: require('../../assets/images/partnerLogo1.png'),
				href: 'https://www.spins.com/',
				label: 'Powered by',
				logoWidth: 'auto',
				logoHeight: '44.6px'
			},
			{
				text: 'Learn about measuring your impact',
				logoUrl: require('../../assets/images/partnerLogo2.png'),
				href: 'https://howgood.com/',
				label: 'Sustainability data by',
				logoWidth: 'auto',
				logoHeight: '72.4px'
			}
		];
	}
};
export interface FooterState {
	getPartners: () => FooterOptions[];
	getServices: () => FooterOptions[];
}
export const BeaconFooterState = {
	...DEFAULT_STATE
};
export interface FooterOptions extends HeaderOptions {
	text: string;
	logoUrl: string;
	logoWidth: string;
	display?: string;
	logoHeight: string;
}

export interface IHelpLinks {
	text: string;
	link: string;
	className: string;
}

export const HelpLinks: IHelpLinks[] = [
	{
		text: 'FAQs',
		link: 'https://beacon.newhope.com/faq/',
		className: ''
	},
	{
		text: 'Newtopia Now',
		link: 'https://solutions.newhope.com/newtopia-now/',
		className: 'newtopia'
	},
	{
		text: 'Expo West',
		link: 'https://www.expowest.com/en/home.html',
		className: 'expo-west'
	},
	{
		text: 'New Hope Network ',
		link: 'https://www.newhope.com/',
		className: ''
	},
	{
		text: 'Contact Us @ beacondiscovery@newhope.com',
		link: 'mailto:beacondiscovery@newhope.com',
		className: ''
	}
];
