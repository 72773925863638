import { APP_NAME } from '../../../common/constants';
const DEFAULT_STATE: IOnboardingTemplateCardsState = {
	bannedIngredientsLabel: 'banned ingredients',
	allProductsLabel: `All products on ${APP_NAME} `,
	topBannedIngredientsLabel: 'Top banned ingredients:',
	defaultTemplateLabel: 'Default',
	seeFullListLabel: 'See full list',
	getStaticTopIngredients: (id: string | undefined): string[] => {
		switch (id) {
			case '1':
				return [
					'FD&C certified colors',
					'Artificial flavors',
					'Artificial sweeteners (aspartame, sucralose)',
					'Monosodium glutamate (MSG)',
					'Partially hydrogenated oils (PHOs)'
				];
			case '2':
				return [
					'Benzoates',
					'BHA/BHT',
					'Brominated vegetable oil (BVO)',
					'EDTA',
					'Nitrates/Nitrites'
				];
			case '3':
				return [
					'Carrageenan',
					'Reb D and Reb M',
					'Polyethylene glycol',
					'Titanium Dioxide',
					'Slow release caffeine'
				];
			default:
				return [];
		}
	}
};

interface IOnboardingTemplateCardsState {
	bannedIngredientsLabel: string;
	allProductsLabel: string;
	topBannedIngredientsLabel: string;
	defaultTemplateLabel: string;
	seeFullListLabel: string;
	getStaticTopIngredients(id: string | undefined): string[];
}
export const OnboardingTemplateCardsState = {
	...DEFAULT_STATE
};
