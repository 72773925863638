import './SalesInformationComponent.scss';
import { ProductVM } from '../../../../common/view-model';
import { salesInformationState } from './model';
import { FC, useState } from 'react';
import SpinsMetricsComponent from '../spinMetrics/SpinsMetricsComponent';
import { validateAllMetricsUnavailable } from '../../../../helpers/validateAllMetricsUnavailable';
interface ISalesInformationComponentProps {
	product: ProductVM | undefined;
}
export const SalesInformationComponent: FC<ISalesInformationComponentProps> = ({ product }) => {
	const [state] = useState({ ...salesInformationState });
	const allMetricsUnavailable = validateAllMetricsUnavailable(product);
	return (
		<div data-testid='sales-information-component' className='sales-information-container'>
			<span className='sales-information-title'>
				{state.mapPolicyText}
				<span className='sales-information-value'>{product?.brand.map_policy ? 'Yes' : 'No'}</span>
			</span>
			<span className='sales-information-title'>
				{state.manufacturerSuggestedRetailPriceText}
				<span className='sales-information-value'>
					{product?.manufacturerSuggestedRetailPrice
						? product?.manufacturerSuggestedRetailPrice
						: state.informationNotAvailableText}
				</span>
			</span>

			<span className='sales-information-title'>
				{state.productLaucnhYearText}
				<span className='sales-information-value'>
					{product?.launchYear ? product?.launchYear : state.informationNotAvailableText}
				</span>
			</span>
			<SpinsMetricsComponent product={product} allMetricsUnavailable={allMetricsUnavailable} />
		</div>
	);
};
