import { Dispatch, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import CompanyDetailsComponent from '../../components/authentication/company-details/CompanyDetailsComponent';
import { AppActions } from '../../redux/AppActions';
import { RootState } from '../../redux/AppReducer';

interface ICompleteBrandDetailsPageProps {
	setOverlay: () => void;
	completeBrandDetailsLayout: (standAloneLayout: boolean) => void;
}

const CompleteBrandDetails: FC<ICompleteBrandDetailsPageProps> = ({
	setOverlay,
	completeBrandDetailsLayout
}) => {
	useEffect(() => {
		setOverlay();
		completeBrandDetailsLayout(true);
		return () => {
			completeBrandDetailsLayout(false);
		};
	}, []);
	return <></>;
};
const mapStateToProps = (state: RootState) => ({
	...state
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	completeBrandDetailsLayout: (isBrandDetailslayout: boolean) =>
		dispatch(AppActions.setCompleteBrandDetailsLayout(isBrandDetailslayout)),
	setOverlay: () =>
		dispatch(
			AppActions.setModalContent({
				content: <CompanyDetailsComponent />,
				showCloseIcon: true,
				canScroll: true,
				isCompanyDetailsModal: true,
				closeOnDocumentClick: false
			})
		)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteBrandDetails);
