import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../../redux/AppReducer';
import { IToastState, ScreenType } from '../../../../common/view-model';
import { FC, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import ToastComponent from '../ToastComponent';
import successIcon from '../../../../assets/images/toast/success.png';
import infoIcon from '../../../../assets/images/toast/info.png';
import warningIcon from '../../../../assets/images/toast/warning.png';
import errorIcon from '../../../../assets/images/toast/error.png';
import { ToastActions } from '../../../../redux/toast/ToastActions';
import 'react-toastify/dist/ReactToastify.css';
const getIconSource = (type: string) => {
	switch (type) {
		case 'success':
			return successIcon;
		case 'info':
			return infoIcon;
		case 'warning':
			return warningIcon;
		case 'error':
			return errorIcon;
		default:
			break;
	}
};
interface ToastContainerComponentProps {
	toastState?: IToastState;
	toastCleanUp?: () => void;
	isMobile?: boolean;
}
const ToastContainerComponent: FC<ToastContainerComponentProps> = ({
	toastState,
	toastCleanUp,
	isMobile
}) => {
	useEffect(() => {
		if (toastState) {
			toast(
				<ToastComponent
					title={toastState.title}
					text={toastState.message}
					afterAction={toastState.afterAction}
					afterActionText={toastState.afterActionText}
					undoAction={toastState.undoAction}
					undoActionText={toastState.undoActionText}
				/>,
				{
					position: toastState.position,
					icon: <img src={getIconSource(toastState.type)} />,
					className: `toast-${toastState.type}`,
					closeOnClick: toastState.closeOnClick,
					autoClose: toastState.autoClose,
					onClose: toastCleanUp
				}
			);
		}
	}, [toastState]);
	return (
		<ToastContainer
			style={{ width: !isMobile ? '450px' : '282px', height: '0px' }}
			autoClose={toastState?.autoClose || 5000}
			progressStyle={{ background: 'unset' }}
		/>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	toastState: state.toastState,
	isMobile: state.deviceScreen === ScreenType.MOBILE
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	toastCleanUp: () => dispatch(ToastActions.clearToast())
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainerComponent);
