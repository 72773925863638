const DEFAULT_STATE: IFilterTooltipState = {
	tooltipPoweredByText: 'Powered By HowGood.',
	learnMoreText: 'Learn more.'
};

interface IFilterTooltipState {
	tooltipPoweredByText: string;
	learnMoreText: string;
}

export const FilterToolTipState = {
	...DEFAULT_STATE
};
