import { useEffect, useState } from 'react';
import './SelectFormComponent.scss';
interface IItem {
	id?: string;
	name?: string;
}

interface ISelectForm {
	items: IItem[];
	onChange: any;
	value: IItem;
	name: string;
	placeholder?: string;
}
const SelectFormComponent: React.FC<ISelectForm> = ({
	onChange,
	value,
	items,
	name,
	placeholder
}) => {
	const [showMenu, setShowMenu] = useState(false);
	const [selectedValue, setSelectedValue] = useState<IItem | null>(null);
	useEffect(() => {
		if (value) {
			setSelectedValue(value);
		}
	}, [value]);
	const handleInputClick = (e: any) => {
		setShowMenu(!showMenu);
	};

	const getDisplay = () => {
		if (!selectedValue) {
			return placeholder;
		}
		return selectedValue.name;
	};

	const onItemClick = (option: IItem) => {
		let newValue;
		newValue = option;
		setSelectedValue(newValue);
		onChange(name, newValue);
		setShowMenu(!showMenu);
	};

	const isSelected = (option: IItem) => {
		if (!selectedValue) {
			return false;
		}
		return selectedValue.name === option.name;
	};
	return (
		<>
			<div className={`${showMenu ? 'active' : ''} select-container`}>
				<div onClick={handleInputClick} className='dropdown-input'>
					<div className={`dropdown-selected-value ${!selectedValue ? 'placeholder' : ''}`}>
						{getDisplay()}
					</div>
					<div className='dropdown-tool-container'>
						<div className='dropdown-tool'>
							{' '}
							<i className={showMenu ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'}></i>
						</div>
					</div>
				</div>

				{showMenu && (
					<div className='dropdown-menu'>
						{items.map(option => (
							<div
								onClick={() => onItemClick(option)}
								key={option.id}
								className={`dropdown-item ${isSelected(option) && 'selected'}`}
							>
								{option.name}
								{isSelected(option) && <i className='ri-check-line'></i>}
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default SelectFormComponent;
