export const ingredientListMapper = (ingredientsList?: string[]): string[] => {
	let result: string[] = [];
	ingredientsList?.forEach((ingredient, index) => {
		if (index === 0) {
			ingredient = ingredient.replace(/^ingredients:\s*/i, '');
			ingredient = ingredient.charAt(0).toUpperCase() + ingredient.slice(1);
		}
		result.push(ingredient);
	});
	return result;
};
