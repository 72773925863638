import { FC } from 'react';
import './WhatsNewFilterComponent.scss';
import { FilterVM } from '../../../../common/view-model';
import { isCurrentUserAnonymous } from '../../../../helpers/authenticationHelper';

interface IWhatsNewFilterComponentProps {
	filter: FilterVM;
	setCurrentCategory: (category?: string) => void;
	addFilter?: (filter: FilterVM) => void;
	handleTracking: (filter: FilterVM) => void;
	setLockModalContent: () => void;
}

export const WhatsNewFilterComponent: FC<IWhatsNewFilterComponentProps> = ({
	filter,
	setCurrentCategory,
	addFilter,
	handleTracking,
	setLockModalContent
}) => {
	const handleClick = () => {
		if (isCurrentUserAnonymous()) {
			setLockModalContent();
			return;
		}
		handleTracking(filter);
		const { children, ...rest } = filter;
		addFilter && addFilter({ ...rest, breadCrumbElements: [{ ...rest }] } as FilterVM);
		setCurrentCategory(filter?.name);
	};
	return (
		<div className='new-filter-container' onClick={handleClick}>
			<span className='filter-name'>{filter.name}</span>
			<i className='ri-arrow-right-s-line'></i>
		</div>
	);
};
