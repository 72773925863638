const DEFAULT_STATE: IBrandOnboardTitleModel = {
	boldText: 'Beacon Discovery is included in exhibitor packages',
	title:
		'or available by subscription and is New Hope Network’s exclusive, year-round product discovery platform.',
	productsFeaturedText: 'See how products are featured on Beacon Discovery',
	brandFeaturedText: 'See how brands are featured on Beacon Discovery',
	productsFeaturedTextMobile: 'See how products are featured on Beacon',
	brandFeaturedTextMobile: 'See how brands are featured on Beacon'
};

interface IBrandOnboardTitleModel {
	boldText: string;
	title: string;
	productsFeaturedText: string;
	brandFeaturedText: string;
	productsFeaturedTextMobile: string;
	brandFeaturedTextMobile: string;
}

export const BrandOnboardTitleModel = {
	...DEFAULT_STATE
};
