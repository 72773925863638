import { Action } from 'redux-actions';
import { ISuggestion } from '../../common/view-model';

export class SearchActions {
	static readonly HAS_CLICKED_OUTSIDE_SEARCH = '@@search/HAS_CLICKED_OUTSIDE_SEARCH';
	static readonly START_SEGGESTION_SEARCH = '@@search/START_SEGGESTION_SEARCH';
	static readonly SEARCH_SUGGESTIONS_SUCCESS = '@@search/SEARCH_SUGGESTIONS_SUCCESS';
	static readonly SEARCH_SUGGESTIONS_FAILURE = '@@search/SEARCH_SUGGESTIONS_FAILURE';
	static readonly CLEAR_SEARCH_SUGGESTIONS = '@@search/CLEAR_SEARCH_SUGGESTIONS';
	static readonly SET_SEARCH_TERM = '@@search/SET_SEARCH_TERM';
	static readonly SET_SEARCH_TERM_ERROR = '@@search/SET_SEARC_TERM_ERROR';
	static readonly SET_SEARCH_TERM_SUCCESS = '@@search/SET_SEARCH_TERM_SUCCESS';

	static seHasClickedOutsideSearch(hasClicked: boolean): Action<boolean> {
		return {
			type: SearchActions.HAS_CLICKED_OUTSIDE_SEARCH,
			payload: hasClicked
		};
	}
	static startSuggestionSearch(searchText: string): Action<string> {
		return {
			type: SearchActions.START_SEGGESTION_SEARCH,
			payload: searchText
		};
	}
	static searchSuggestionsSuccess(suggestions: ISuggestion[]): Action<ISuggestion[]> {
		return {
			type: SearchActions.SEARCH_SUGGESTIONS_SUCCESS,
			payload: suggestions
		};
	}
	static clearSearchSuggestions(): Action<[]> {
		return {
			type: SearchActions.CLEAR_SEARCH_SUGGESTIONS,
			payload: []
		};
	}
	static setSearchTerm(payload: ISuggestion | undefined): Action<ISuggestion | undefined> {
		return {
			type: SearchActions.SET_SEARCH_TERM,
			payload
		};
	}
	static readonly setSearchTermSuccess = (payload: any): Action<any> => {
		return {
			type: SearchActions.SET_SEARCH_TERM_SUCCESS,
			payload
		};
	};
}
