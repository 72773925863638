import { FC, useCallback } from 'react';
import './SearchBarComponent.scss';
import { useCleanUpSearch } from './hooks/useCleanUpSearch';
interface SearchBarPropsComponent {
	handleSearch: () => void;
	handleClose?: () => void;
	placeholder?: string;
	startSuggestionSearch: (term: string) => void;
	value?: string;
	reset: () => void;
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleClearSearch: () => void;
}

export const SearchBarComponent: FC<SearchBarPropsComponent> = ({
	handleSearch,
	placeholder,
	handleClose,
	value,
	reset,
	handleChange,
	handleClearSearch
}) => {
	const defaultPlaceholder = 'Search for products, brands, ingredients and more';
	const closeIconCick = useCallback(() => {
		reset();
		handleClose && handleClose();
		handleClearSearch();
	}, [handleClearSearch, handleClose, reset]);
	const handleKeyUp = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			event.preventDefault();
			if (event.key === 'Enter' && event.currentTarget.value.trim()) {
				handleSearch();
			}
		},
		[handleSearch]
	);
	return (
		<div className='search-bar-container'>
			<input
				type='text'
				className={`search-bar ${value ? 'typing' : ''}`}
				value={value}
				onChange={handleChange}
				placeholder={placeholder || defaultPlaceholder}
				onKeyUp={handleKeyUp}
			/>
			<div className={`icons-container ${!value ? 'empty-wrapper' : ''}`}>
				{value && <i className='ri-close-line clean-icon' onClick={closeIconCick}></i>}
				<i className='ri-search-line search-icon' onClick={handleSearch}></i>
			</div>
		</div>
	);
};
