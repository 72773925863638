import { useWindowSize } from '../../../hooks/useWindowSize';
import { InfoBeaconComponent } from '../InfoBeaconHub/InfoBeaconHubComponent';
import { OnboardInfoComponent } from '../OnboardInfo/OnboardInfoComponent';
import {
	ITimeLineStepComponentProps,
	TimeLineComponent,
	TimeLineStepComponent
} from '../TimeLine/TimeLineComponent';
import './FirstTimeExpoComponent.scss';
import { FirstTimeDesktopSteps } from './TimeLineConfigDesktop';
import { FirstTimeMobileSteps } from './TimeLineConfigMobile';
import { FirstTimeExpoModel as model } from './model';
import { useEffect, useState } from 'react';

const HeaderContent = () => {
	return (
		<div className='first-time-header-text-container'>
			<span className='first-time-header-text'>{model.headerPrevText}</span>{' '}
			<a href={`mailto:${model.headerEmailLink}`} target='_blank' rel='noopener noreferrer'>
				{model.headerEmailLink}
			</a>{' '}
			<span className='first-time-header-text'>{model.headerNextText}</span>
		</div>
	);
};

export const FirstTimeExpoComponent = () => {
	const mobileBreakpoint = 744;
	const { width } = useWindowSize();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [steps, setSteps] = useState<ITimeLineStepComponentProps[]>([]);
	useEffect(() => {
		setIsMobile(width < mobileBreakpoint);
	}, [width]);
	useEffect(() => {
		setSteps(isMobile ? FirstTimeMobileSteps : FirstTimeDesktopSteps);
	}, [isMobile]);
	return (
		<div className='first-time-expo-container'>
			<OnboardInfoComponent title={model.title} children={<HeaderContent />} />
			<div className='first-time-content-container'>
				<TimeLineComponent classes='first-time'>
					{steps.map((step, index) => (
						<div className='timeline-step' key={index}>
							<TimeLineStepComponent {...step} />
						</div>
					))}
				</TimeLineComponent>
			</div>
			<InfoBeaconComponent />
		</div>
	);
};
