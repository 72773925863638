import { useState, useEffect, FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { IngredientsTemplateCardComponent } from '../onbording/IngredientsTemplateCardforProfile/IngredientsTemplateCardComponentforProfile';
import { DetailedTemplateComparisonComponent } from '../onbording/DetailTemplateComparison/DetailedTemplateComparisonComponent';
import { RootState } from '../../redux/AppReducer';
import { useWindowSize } from '../../hooks/useWindowSize';
import { UserActions } from '../../redux/UserActions';
import { IngredientsTemplate, ScreenType } from '../../common/view-model';
import { ApolloActions } from '../../redux/ApolloActions';
import { GET_INGREDIENT_TEMPLATES } from '../../graphql/queries/ingredientTemplates';
import { getTemplateIngredientsByPurpose } from '../../helpers/transformers';
import './ProfileIngredientComponent.scss';
import { LoadingIngredientsTemplateComponent } from '../onbording/LoadingIngredientsTemplate/LoadingIngredientsTemplate';
import { ActionLinkComponent } from '../UI/ActionLinkComponent/ActionLinkComponent';
import { beaconFaqLink } from '../../common/constants';

export interface IIngredientsTemplateComponentProps {
	ingredientTemplates?: IngredientsTemplate[];
	ingredientsTemplateId?: number;
	setIngredientsTemplateId: (ingredientsTemplateId: number) => void;
	getTemplates: () => void;
	saveIngredientsTemplateId: (id: number) => void;
	screenType: ScreenType | undefined;
	toggleState: string | number;
	getUserProfile: () => void;
	saveTemplateLoding?: boolean;
}
const ProfileIngredientComponent: FC<IIngredientsTemplateComponentProps> = ({
	ingredientTemplates,
	ingredientsTemplateId,
	setIngredientsTemplateId,
	getTemplates,
	saveIngredientsTemplateId,
	screenType,
	toggleState,
	getUserProfile,
	saveTemplateLoding
}) => {
	const [templates, setTemplates] = useState<IngredientsTemplate[]>([]);
	const [currentTabIndex, setCurrentTabIndex] = useState(ingredientsTemplateId!);
	const [detailCurrentTab, setDetailCurrentTab] = useState(ingredientsTemplateId!);
	const [showDetailedList, setShowDetailedList] = useState(false);
	const [editmode, setEditmode] = useState(false);
	const [previewTab, setPreviewTab] = useState(-1);
	const [prevIndex, setPrevIndex] = useState<number | undefined>(undefined);
	const FULL_SIZE_SCREEN_WIDTH = 743;
	const { width } = useWindowSize();
	const moveToStepHandler = (index: number) => {
		setPrevIndex(index);
		if (editmode) {
			setCurrentTabIndex(index);
			setShowDetailedList(true);
			if (prevIndex === index) {
				const targetElement = document.getElementById('target');
				if (targetElement) {
					targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
				setPrevIndex(undefined);
			}
		} else {
			setDetailCurrentTab(index);
			const targetElement = document.getElementById('target');
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
			setPreviewTab(index);
			setShowDetailedList(true);
		}
	};
	const save = () => {
		setEditmode(false);
		saveIngredientsTemplateId(currentTabIndex);
		setCurrentTabIndex(ingredientsTemplateId!);
		setDetailCurrentTab(ingredientsTemplateId!);
	};
	const handleLearMoreClick = useCallback(() => {
		window.open(beaconFaqLink, '_blank', 'rel=noopener noreferrer');
	}, []);

	useEffect(() => {
		if (ingredientTemplates) {
			ingredientTemplates = ingredientTemplates.map(template => ({
				...template,
				purpose: getTemplateIngredientsByPurpose(template)
			}));
			setTemplates(ingredientTemplates!);
		} else {
			getTemplates();
		}
	}, [getTemplates, ingredientTemplates]);
	useEffect(() => {
		setEditmode(false);
	}, [toggleState]);
	useEffect(() => {
		if (!ingredientsTemplateId) {
			getUserProfile();
		} else {
			setCurrentTabIndex(ingredientsTemplateId);
			setDetailCurrentTab(ingredientsTemplateId);
		}
	}, [getUserProfile, ingredientsTemplateId]);

	return (
		<>
			{!saveTemplateLoding && ingredientsTemplateId && templates && templates.length > 0 ? (
				<div className='ingredients-template'>
					<div className='col'>
						<div className='row'>
							<div className='template-header'>
								<div className='template-title-show'>
									{!(screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH)
										? 'You selected Ingredient Template:'
										: 'You selected Template:'}{' '}
									<span className='card-name'>{templates[currentTabIndex - 1]?.name}</span>{' '}
								</div>
								<div className='template-profile-learn-more-container'>
									<ActionLinkComponent
										text='Learn More'
										underline={true}
										onClick={handleLearMoreClick}
										icon='ri-arrow-right-line'
										iconPosition='end'
										iconOnly={false}
										color='dark'
										fontWigth='normal'
										size={
											screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH
												? 'small'
												: 'big'
										}
										to={beaconFaqLink}
										linkToExternal={true}
									/>
								</div>
							</div>
						</div>
						<div className='row'>
							<div
								className={`template-cards-profile in-profile ${
									currentTabIndex === 1
										? 'ing-current-blue'
										: currentTabIndex === 2
										? 'ing-current-green'
										: 'ing-current-yellow'
								}`}
							>
								<IngredientsTemplateCardComponent
									currentTab={currentTabIndex}
									templates={templates}
									moveToTab={moveToStepHandler}
									setShowDetailedList={setShowDetailedList}
									showDetailedList={showDetailedList}
									isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
									isEdit={editmode}
								/>

								<div
									className='edit-btn-template'
									style={{ visibility: editmode ? 'hidden' : 'visible' }}
									onClick={() => {
										setEditmode(true);
									}}
								>
									<i className='ri-settings-3-fill gear-icon'></i>
									Edit Details
								</div>
							</div>
						</div>
						<div className='row btn-wrapper'>
							<button
								className={`save-btn ${editmode ? 'show' : 'hide'}`}
								onClick={save}
								style={{ marginBottom: '40px' }}
							>
								Save Changes
							</button>
						</div>
						<div className='row'>
							<div
								className={`detail-comparision ${
									currentTabIndex === 1
										? 'current-blue'
										: currentTabIndex === 2
										? 'current-green'
										: 'current-yellow'
								}`}
							>
								<DetailedTemplateComparisonComponent
									currentTab={editmode ? currentTabIndex : detailCurrentTab}
									templates={templates}
									moveToTab={moveToStepHandler}
									setShowDetailedList={setShowDetailedList}
									showDetailedList={showDetailedList}
									isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
									isEdit={editmode}
									previewTab={previewTab}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoadingIngredientsTemplateComponent
					loadingBottomPillsCount={3}
					loadingCardsCount={3}
					loadingTopPillsCount={
						screenType === ScreenType.TABLET ||
						screenType === ScreenType.MOBILE ||
						width === FULL_SIZE_SCREEN_WIDTH
							? 5
							: 4
					}
					isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
					profilePage={true}
				/>
			)}
		</>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	ingredientTemplates: state.storeState?.templates,
	ingredientsTemplateId: state.userState?.ingredientsTemplateId,
	screenType: state.deviceScreen,
	saveTemplateLoding: state.userState?.saveTemplateLoding
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setIngredientsTemplateId: (ingredientsTemplateId: number) =>
		dispatch(UserActions.setIngredientsTemplateId(ingredientsTemplateId)),
	getTemplates: () => {
		return dispatch(ApolloActions.query({ query: GET_INGREDIENT_TEMPLATES }));
	},
	saveIngredientsTemplateId: (id: number) =>
		dispatch({ type: UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID, payload: id }),
	getUserProfile: () => dispatch({ type: UserActions.GET_USER_PROFILE })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileIngredientComponent);
