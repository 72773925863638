import { Dispatch, FC } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { Events } from '../../common/model';
import { RootState } from '../../redux/AppReducer';
import { ReactComponent as ExpoEastLabel } from '../../assets/images/ExpoEastTags.svg';
import { ReactComponent as ExpoWestLabel } from '../../assets/images/ExpoWestTags.svg';
import { ReactComponent as ExporWestCard } from '../../assets/images/expo-west-card.svg';
import { ReactComponent as ExpoEastCard } from '../../assets/images/ExpoCard.svg';
import { ReactComponent as EastMapDiscover } from '../../assets/images/expo-map1.svg';
import { ReactComponent as WestMapDiscover } from '../../assets/images/expo-west-map-beacon-icon.svg';
import { ReactComponent as ExpoEastMap } from '../../assets/images/DiscoveryMap.svg';
import { ReactComponent as ExpoWestMap } from '../../assets/images/DiscoveryMapWest.svg';

export enum GenericSVGComponentKey {
	LABEL,
	CARDS,
	MAP_DISCOVER,
	MAP
}
interface IGenericSVGPrepComponentProps {
	currentEvent: Events | undefined | null;
	componentKey: GenericSVGComponentKey;
}

const GenericSVGPrepComponent: FC<IGenericSVGPrepComponentProps> = ({
	currentEvent,
	componentKey
}) => {
	const events: {
		[key: string]: (React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null)[];
	} = {
		expoeast: [ExpoEastLabel, ExpoEastCard, EastMapDiscover, ExpoEastMap],
		expowest: [ExpoWestLabel, ExporWestCard, WestMapDiscover, ExpoWestMap]
	};
	const event = currentEvent
		? events[currentEvent.name.replace(/\s+/g, '').toLocaleLowerCase().trim()]
			? events[currentEvent.name.replace(/\s+/g, '').toLocaleLowerCase().trim()]
			: null
		: (null as any);
	const Component = event ? event[componentKey] : null;
	return <>{Component && <Component />}</>;
};

const mapStateToProps = ({ appState }: RootState) => ({
	currentEvent: appState.storeState?.getCurrentEvent
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GenericSVGPrepComponent);
