import { FilterVM, ScreenType } from '../../../common/view-model';
import { BubbleComponent } from '../../TopBubblesSection/Bubble/BubbleComponent';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { FreeMode, Pagination, Navigation, Swiper as ISwiper } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import './CarouselComponent.scss';
import { validateShowBubble } from '../../TopBubblesSection/Bubble/model';

interface ICustomArrowComponentProps {
	arrowDirection: 'prev' | 'next';
	onClick?: () => void;
}
const CustomArrowComponent: FC<ICustomArrowComponentProps> = ({ arrowDirection, onClick }) => {
	const [showNextArrow, setShowNextArrow] = useState(false);
	useEffect(() => {
		const sliderRef = document.querySelector('.slick-track');
		sliderRef?.clientWidth! > 10000 ? setShowNextArrow(true) : setShowNextArrow(false);
	}, [onClick]);
	return (
		<>
			{arrowDirection === 'prev' ? (
				<button
					type='button'
					data-role='none'
					className={`arrow ${arrowDirection}`}
					onClick={onClick}>
					<svg
						width='8'
						height='14'
						viewBox='0 0 8 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M2.828 7.00072L7.778 11.9507L6.364 13.3647L0 7.00072L6.364 0.636719L7.778 2.05072L2.828 7.00072Z'
							fill='#ADB5BD'
						/>
					</svg>
				</button>
			) : (
				<button
					type='button'
					data-role='none'
					className={`arrow ${arrowDirection} ${!showNextArrow ? 'hide-next' : ''}`}
					onClick={onClick}>
					<svg
						width='8'
						height='14'
						viewBox='0 0 8 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M5.172 6.99928L0.222001 2.04928L1.636 0.63528L8 6.99928L1.636 13.3633L0.222 11.9493L5.172 6.99928Z'
							fill='#ADB5BD'
						/>
					</svg>
				</button>
			)}
		</>
	);
};

interface ICarouselComponentProps {
	items: FilterVM[] | undefined;
	bubbleStyle?: 'regular' | 'outline';
	settings: any;
	onBubbleClick?: () => void;
	handleOpenPanel?: () => void;
	deviceScreen?: ScreenType;
}

export const CarouselComponent: FC<ICarouselComponentProps> = ({
	items,
	bubbleStyle,
	settings,
	onBubbleClick,
	handleOpenPanel,
	deviceScreen
}) => {
	const swiperComponentRef = useRef<ISwiper>();
	const [showPrevArrow, setShowPrevArrow] = useState<boolean>(false);
	const [showNextArrow, setShowNextArrow] = useState<boolean>(true);
	const selectedFilters = useSelector(
		(state: RootState) => state.appState.filterState?.selectedFilters
	);
	const FULL_SIZE_SCREEN_WIDTH = 768;
	const { width } = useWindowSize();
	const isMobile = width < FULL_SIZE_SCREEN_WIDTH;
	if (!isMobile) {
		settings = {
			...settings,
			...{
				prevArrow: <CustomArrowComponent arrowDirection='prev' />,
				nextArrow: <CustomArrowComponent arrowDirection='next' />
			}
		};
	}

	const onClickprev = () => {
		let element: HTMLElement = document.getElementsByClassName(
			'swiper-button-prev'
		)[0] as HTMLElement;
		element.click();
		if (swiperComponentRef.current?.isBeginning) {
			setShowPrevArrow(false);
		}
		setShowNextArrow(true);
	};
	const onClicknext = () => {
		let element: HTMLElement = document.getElementsByClassName(
			'swiper-button-next'
		)[0] as HTMLElement;
		element.click();
		if (swiperComponentRef.current?.isEnd) {
			setShowNextArrow(false);
		}
		setShowPrevArrow(true);
	};
	let bubblesToShow = items?.filter(filter => validateShowBubble(filter, selectedFilters));
	const swiperRef = document.querySelectorAll('.swiper-wrapper')[0];
	const swiperContainer = document.getElementsByClassName('bubbles-contianer')[0];

	const [showArrow, setShowArrow] = useState<boolean>(
		swiperRef?.scrollWidth > swiperContainer?.clientWidth && deviceScreen !== ScreenType.MOBILE
	);
	useEffect(() => {
		swiperRef?.scrollWidth > swiperContainer?.clientWidth && deviceScreen !== ScreenType.MOBILE
			? setShowArrow(true)
			: setShowArrow(false);
	}, [swiperRef?.scrollWidth, bubblesToShow, swiperContainer?.clientWidth, deviceScreen]);

	useEffect(() => {
		if (!swiperComponentRef.current?.isEnd) {
			setShowNextArrow(true);
		}
	}, [selectedFilters]);

	return (
		<div
			className={`filter-carousel-wraper ${!showNextArrow ? 'left-wrapper-container' : ''}${
				!showPrevArrow ? 'no-arrow-wrapper' : ''
			}`}>
			{showArrow && showPrevArrow && (
				<i
					className={`bi bi-chevron-left ${!showNextArrow ? 'left-wrapper' : ''}`}
					onClick={onClickprev}></i>
			)}
			<Swiper
				className='carousel-bubble-wrapper'
				slidesPerView={'auto'}
				spaceBetween={0}
				freeMode={true}
				navigation
				pagination={{
					clickable: true
				}}
				onBeforeInit={swiper => {
					swiperComponentRef.current = swiper;
				}}
				modules={[Navigation, FreeMode, Pagination]}
				allowTouchMove={deviceScreen === ScreenType.MOBILE}>
				{items && items.length > 0 && (
					<div
						className={`carousel-wrapper ${!bubbleStyle ? 'category-warapper' : ''}  ${
							!isMobile ? 'show-arrow ' : ''
						}${!isMobile && items.length >= 10 ? 'show-nex' : ''}`}>
						{bubblesToShow!.map((item, index) => {
							return (
								<SwiperSlide key={index}>
									<BubbleComponent
										filter={item}
										style={bubbleStyle}
										onBubbleClick={onBubbleClick}
										disabled={false}
										bubblesToShow={bubblesToShow?.length}
										handleOpenPanel={handleOpenPanel}
									/>
								</SwiperSlide>
							);
						})}
					</div>
				)}
			</Swiper>
			{showArrow && showNextArrow && <i className='bi bi-chevron-right' onClick={onClicknext}></i>}
		</div>
	);
};
