import { FC } from 'react';
import './HomePageOnboardComponent.scss';
import { HomePageOnboardModel as model } from './model';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';

interface IHomeOnboardMetricProps {
	value: string;
	name: string;
}
export const HomeOnboardMetric: FC<IHomeOnboardMetricProps> = ({ value, name }) => {
	return (
		<div className='home-onboard-metric-container'>
			<span className='metric-value'>{value}</span>
			<span className='metric-name'>{name}</span>
		</div>
	);
};

interface IHomePageOnboardComponentProps {
	handelNavigate: () => void;
}

export const HomePageOnboardComponent: FC<IHomePageOnboardComponentProps> = ({
	handelNavigate
}) => {
	return (
		<section className='home-page-onboard-container'>
			<div className='home-onboard-title-container'>
				<span className='onboard-title'>{model.title}</span>
			</div>
			<div className='home-page-metric-container'>
				{model.metrics.map((metric, index) => (
					<HomeOnboardMetric key={index} {...metric} />
				))}
			</div>
			<div className='button-container'>
				<ButtonComponent
					text={model.buttonTitle}
					onClick={handelNavigate}
					style='rounded-button primary'
					icon={<i className='ri-arrow-right-s-line line-icon'></i>}
				/>
			</div>
		</section>
	);
};
