import { Certification } from '../../../common/model';
const DEFAULT_STATE: ICertificationsComponentState = {
	sectionTitle: 'Certifications',
	moreInfoText: 'More info',
	flipText: 'Flip',
	certifierText: 'Certifier',
	validateEdgeCase: (certification: Certification): boolean => {
		const edgeCaseCertificationName = 'Certified Organic';
		return (
			certification.name?.trim().toLowerCase() === edgeCaseCertificationName.trim().toLowerCase() &&
			certification.certifiers?.length === 1
		);
	}
};
interface ICertificationsComponentState {
	sectionTitle: string;
	moreInfoText: string;
	flipText: string;
	certifierText: string;
	validateEdgeCase: (certification: Certification) => boolean;
}
export const CertificationState = {
	...DEFAULT_STATE
};
