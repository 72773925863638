import React, { useRef, useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	Tooltip,
	Legend,
	BarElement,
	BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { verticalChartOptions } from './VerticalBarCharConfig';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	Tooltip,
	Legend,
	BarController
);
export const VerticalBarChartComponent: React.FC<{ data: any }> = ({ data }) => {
	const chartRef = useRef<ChartJS>();
	const [chartData, setChartData] = useState({ datasets: [] });

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}

		const chartData = {
			...data,
			datasets: data.datasets.map((dataset: any) => ({
				...dataset
			}))
		};
		setChartData(chartData);
	}, [data]);

	return <Chart ref={chartRef} options={verticalChartOptions} type='bar' data={chartData} />;
};
