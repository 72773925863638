const DEFAULT_STATE: HeaderState = {
	show: false,
	title: 'Informa',
	baseUrl: 'https://www.informa.com/',
	slogan: 'Beacon is part of the Informa Markets Division of Informa PLC',
	description: `This site is operated by a business or businesses owned by Informa PLC and all
		copyright resides with them. Informa PLC's registered office is 5 Howick Place,
		London SW1P 1WG. Registered in England and Wales. Number 8860726.`,
	true: {
		buttonClass: 'active',
		ribbonClass: 'ribbon-show'
	},
	false: {
		buttonClass: '',
		ribbonClass: 'ribbon-hide'
	},
	getOptions: (url: string) => {
		return [
			{
				label: 'Informa PLC',
				href: url
			},
			{
				label: 'About us',
				href: `${url}About-Us`
			},
			{
				label: 'Investor relations',
				href: `${url}Investors`
			},
			{
				label: 'Talent',
				href: `${url}Talent`
			}
		];
	}
};

export const HeaderState = {
	...DEFAULT_STATE
};

export interface HeaderState {
	show: boolean;
	title: string;
	baseUrl: string;
	slogan: string;
	description: string;
	[key: string]: any;
	getOptions: (url: string) => HeaderOptions[];
}

export interface HeaderOptions {
	label: string;
	href: string;
}
