import { ITrackingEvent } from '../common/view-model';
import { IirisIdentityKey, getLocalStorageValue } from './authenticationHelper';

const getTrackingEventData = (payload: ITrackingEvent) => {
	return {
		transactionId: payload.transactionId,
		entityId: payload.entityId,
		cookieId: payload.cookieId,
		formId: payload.formId,
		status: payload.status,
		type: payload.type,
		ts: new Date().toISOString(),
		userId: getLocalStorageValue(IirisIdentityKey.USER_ID)
	};
};

const getTrackingProductData = (payload: ITrackingEvent) => {
	payload.eventPayload.metadata = {
		...payload.eventPayload.metadata,
		user_id: getLocalStorageValue(IirisIdentityKey.USER_ID)
	};
	return {
		id: payload.eventPayload.id,
		name: payload.eventPayload.name,
		type: payload.eventPayload.type,
		url: payload.eventPayload.url,
		tags: payload.eventPayload.tags.length > 0 ? payload.eventPayload.tags.join(',') : '',
		metadata: JSON.stringify(payload.eventPayload.metadata),
		created: new Date().toISOString(),
		lastUpdated: new Date().toISOString()
	};
};

export const handleTracking = (payload: ITrackingEvent) => {
	const trackFn = () => {
		if (window.IIRISTracker) {
			window.IIRISTracker.trackBusinessEvt(
				getTrackingEventData(payload),
				getTrackingProductData(payload)
			);
		}
	};

	if (window.IIRISTracker) {
		trackFn();
	} else {
		window.addEventListener('trackerLoaded', trackFn);
	}
};
