import { useState } from 'react';
export const useForm = (initialValues: any) => {
	const [values, setValues] = useState(initialValues);
	const reset = () => {
		setValues(initialValues);
	};
	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value
		});
	};
	return [values, handleInputChange, reset];
};
