import React, { ReactNode, useEffect, useState } from 'react';
import TitleForms from '../../forms/GenericBaseForms/TitleForms/TitleForms';
import './FAQComponent.scss';
interface IFAQElement {
	question: string;
	answer: string | ReactNode;
}

const FAQElement: React.FC<IFAQElement> = ({ question, answer }) => {
	const [isOpen, setOpen] = useState<boolean>(false);
	return (
		<div className='faq-container'>
			<div className='title-faq'>
				<div className={`text-title ${isOpen ? 'active' : ''}`}>{question}</div>
				<i
					className={isOpen ? 'ri-subtract-line' : 'ri-add-line'}
					onClick={() => setOpen(!isOpen)}></i>
			</div>
			{isOpen && <div className='description-faq'>{answer}</div>}
		</div>
	);
};

interface IFAQSelected {
	collapsed: boolean;
}
const FAQComponent: React.FC = () => {
	const FAQsArray = [
		{
			sectionTitle: 'Frequently asked questions',
			questions: [
				{
					id: '1',
					question: 'How can I remove a discontinued product or one that I no longer want there?',
					answer: (
						<p>
							To remove a product, please reach out to{' '}
							<a href='mailto:audiencesuccessteam@newhope.com' target='_blank' rel='noreferrer'>
								audiencesuccessteam@newhope.com
							</a>
							.
						</p>
					)
				},
				{
					id: '2',
					question: 'How can I submit new labels for a product that I changed branding for?',
					answer: (
						<p>
							To submit new product labels, please reach out to{' '}
							<a href='mailto:audiencesuccessteam@newhope.com' target='_blank' rel='noreferrer'>
								audiencesuccessteam@newhope.com
							</a>
							.
						</p>
					)
				},
				{
					id: '3',
					question: 'What is the information in the Financial section used for?',
					answer: (
						<p>
							At present, this information is not displayed publicly on Beacon Discovery. Responses
							will be shared only with select registered Expo West/Newtopia Now investors and
							Nutrition Capital Network’s investor network.
						</p>
					)
				}
			]
		}
	];

	const [FAQSelected, setFAQSelected] = useState<IFAQSelected[]>([]);

	const toggleOpen = (index: number) => {
		const newOpenStates = FAQSelected.map((state, indexState) =>
			index === indexState
				? {
						...state,
						collapsed: !state.collapsed
				  }
				: state
		);
		setFAQSelected(newOpenStates);
	};

	useEffect(() => {
		if (FAQsArray) {
			setFAQSelected(
				FAQsArray.map(() => ({
					collapsed: true
				}))
			);
		}
	}, []);
	return (
		<div className='faq-section'>
			{FAQSelected.length > 0 &&
				FAQsArray.map((section, index) => (
					<div key={index} className='faq-section-container'>
						<TitleForms
							isVisible={FAQSelected[index].collapsed}
							titleText={section.sectionTitle}
							toggleVisibility={() => toggleOpen(index)}
							readOnly
							isForm={false}
						/>
						{FAQSelected[index].collapsed &&
							section.questions.map((question: any) => (
								<FAQElement
									answer={question.answer}
									question={question.question}
									key={question.id}></FAQElement>
							))}
					</div>
				))}
		</div>
	);
};

export default FAQComponent;
