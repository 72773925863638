import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { FilterType, FilterVM, TrackingEventStatus } from '../../../common/view-model';
import './CategoryFilterComponent.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	parentCategoryIdentifier,
	secondLevelCategoryIdentifier
} from '../../../helpers/paramsUrlIndetifiers';
import { DropDownMenuItemComponent } from '../../UI/DropDownMenuItemComponent/DropDowmMenuItemComponent';
import { WhatsNewFilterComponent } from '../whats-new/whats-new-filter/WhatsNewFilterComponent';
import { whastNewFilter } from '../../../mock/whatIsNewFilter';
import { WhatsNewFilterElementsComponent } from '../whats-new/whats-new-filter-elements/WhatsNewFilterElementsComponent';
import { handleTracking } from '../../../helpers/handelTracking';
import { getFilterTypeKeyForUrl } from '../../../helpers/urlParametersHelpers';
import { v4 as uuidv4 } from 'uuid';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';

interface ICurrentCategoryFilterComponentProps {
	setCurrentCategory: (category?: string) => void;
	currentCategory?: string;
	currentFilter?: FilterVM;
	handleRemoveParentCategory: () => void;
	productsPageUrl: string;
	thirdLevelChildren?: FilterVM[];
	secondLevelChildren?: FilterVM[];
	handleTracking: (filter: FilterVM) => void;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
	clearAllFilters: () => void;
}
export const CurrentCategoryFilterComponent: FC<ICurrentCategoryFilterComponentProps> = ({
	currentFilter,
	currentCategory,
	setCurrentCategory,
	handleRemoveParentCategory,
	productsPageUrl,
	thirdLevelChildren,
	secondLevelChildren,
	handleTracking,
	setLockModalContent,
	registerUserClickEvent,
	clearAllFilters
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const mergedFilters = useMemo(
		() => [...(thirdLevelChildren || []), ...(secondLevelChildren || [])],
		[secondLevelChildren, thirdLevelChildren]
	);
	const [currentSecondLevelFilter, setCurrentSecondLevelFilter] = useState<FilterVM | undefined>(
		mergedFilters?.find(filter => filter.id === params.get(secondLevelCategoryIdentifier)) ||
			undefined
	);
	useEffect(() => {
		setCurrentSecondLevelFilter(
			mergedFilters?.find(filter => filter.id === params.get(secondLevelCategoryIdentifier)) ||
				undefined
		);
	}, [mergedFilters, params]);
	const handleCurrentClick = () => {
		dispatch(AppActions.setGeneralError({ hasError: false }));
		if (isCurrentUserAnonymous()) {
			return;
		}
		if (!currentSecondLevelFilter) {
			setCurrentCategory(undefined);
			handleRemoveParentCategory();
			clearAllFilters();
		} else {
			const parentUrlParam = params.get(parentCategoryIdentifier);
			const queryParams = `?${parentCategoryIdentifier}=${parentUrlParam}`;
			navigate(`${productsPageUrl}${queryParams}`);
			setCurrentSecondLevelFilter(undefined);
		}
	};
	const handleFilterClick = (filter: FilterVM) => {
		dispatch(AppActions.setGeneralError({ hasError: false }));
		handleTracking(filter);
		setCurrentSecondLevelFilter(filter);
		const parentUrlParam = params.get(parentCategoryIdentifier);
		const queryParams = `?${parentCategoryIdentifier}=${parentUrlParam}&${secondLevelCategoryIdentifier}=${filter.id}`;
		navigate(`${productsPageUrl}${queryParams}`);
	};
	return (
		<div className='current-category-container'>
			<div className='selected-element-container' onClick={handleCurrentClick}>
				<i className='ri-arrow-left-s-line'></i>
				<span className='current-category'>
					{currentSecondLevelFilter?.name || currentCategory}
				</span>
			</div>
			{!currentSecondLevelFilter && (
				<div className='selected-filter-items'>
					{currentFilter?.children?.map((filter: FilterVM) => (
						<div
							className='category-element-container'
							key={filter.id}
							onClick={() => handleFilterClick(filter)}
						>
							<span className='filter-name'>{filter.name}</span>
							<i className='ri-arrow-right-s-line'></i>
						</div>
					))}
				</div>
			)}
			{currentSecondLevelFilter && (
				<div className='drop-element-container'>
					<DropDownMenuItemComponent
						items={currentSecondLevelFilter.children}
						setLockModalContent={setLockModalContent}
						registerUserClickEvent={registerUserClickEvent}
					/>
				</div>
			)}
		</div>
	);
};
interface ICategoryFilterItemComponentProps {
	filter: FilterVM;
	setCurrentFilter: (filter: FilterVM) => void;
	setCurrentCategory: (category?: string) => void;
	productsPageUrl: string;
	handleTracking: (filter: FilterVM) => void;
	setLockModalContent: () => void;
}
export const CategoryFilterItemComponent: FC<ICategoryFilterItemComponentProps> = ({
	filter,
	setCurrentFilter,
	setCurrentCategory,
	productsPageUrl,
	handleTracking,
	setLockModalContent
}) => {
	const navigate = useNavigate();
	const handleClick = () => {
		if (isCurrentUserAnonymous()) {
			setLockModalContent();
			return;
		}
		handleTracking(filter);
		setCurrentCategory(filter.name || '');
		setCurrentFilter(filter);
		const queryParams = `?${parentCategoryIdentifier}=${filter.id}`;
		navigate(`${productsPageUrl}${queryParams}`);
	};
	return (
		<div className='category-element-container' onClick={handleClick}>
			<span className='filter-name'>{filter.name}</span>
			<i className='ri-arrow-right-s-line'></i>
		</div>
	);
};
interface ICategoryFilterComponentProps {
	filters: FilterVM[];
	setCurrentCategory: (category?: string) => void;
	currentCategory?: string;
	parentFilters?: FilterVM[];
	thirdLevelChildren?: FilterVM[];
	secondLevelChildren?: FilterVM[];
	addFilter?: (filter: FilterVM) => void;
	selectedFilters?: FilterVM[];
	userState: any;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
	clearAllFilters: () => void;
}

export const CategoryFilterComponent: FC<ICategoryFilterComponentProps> = ({
	filters,
	currentCategory,
	setCurrentCategory,
	parentFilters,
	thirdLevelChildren,
	secondLevelChildren,
	addFilter,
	selectedFilters,
	userState,
	setLockModalContent,
	registerUserClickEvent,
	clearAllFilters
}) => {
	const whastNewTabName = "What's New?";
	const productsPageUrl = '/products';
	const navigate = useNavigate();
	const location = useLocation();
	const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const [currentFilter, setCurrentFilter] = useState<FilterVM | undefined>(
		parentFilters?.find(filter => filter.id === params.get(parentCategoryIdentifier)) || undefined
	);
	const handleRemoveParentCategory = () => {
		const parentUrlParam = params.get(parentCategoryIdentifier);
		if (parentUrlParam) {
			params.delete(parentCategoryIdentifier);
			navigate(`${productsPageUrl}?${params.toString()}`);
		}
	};
	useEffect(() => {
		if (selectedFilters?.some(f => f.type === FilterType.WHATS_NEW)) {
			setCurrentCategory(whastNewTabName);
		}
	}, [selectedFilters, setCurrentCategory]);
	const handleTrackingCallback = useCallback(
		(filter: FilterVM) => {
			handleTracking({
				transactionId: uuidv4(),
				status: TrackingEventStatus.SUCCESS,
				entityId: filter.id!,
				type: `Click on ${filter.name}`,
				timeStamp: new Date().toISOString(),
				eventPayload: {
					id: filter.id!,
					name: filter.name!,
					type: getFilterTypeKeyForUrl(filter.type!),
					url: window.location.href,
					tags: [],
					metadata: {
						user_id: userState?.id,
						user_type: userState?.user_type,
						environment: process.env.REACT_APP_ENV_NAME,
						category_id: filter.id,
						category_name: filter.name
					}
				}
			});
		},
		[userState?.id, userState?.user_type]
	);
	return (
		<div className={`category-filter-container ${currentCategory ? 'current-category-case' : ''}`}>
			{currentCategory && (
				<>
					{currentCategory !== whastNewTabName ? (
						<div className='selected-category-filter-container'>
							<CurrentCategoryFilterComponent
								currentCategory={currentCategory}
								setCurrentCategory={setCurrentCategory}
								currentFilter={currentFilter}
								handleRemoveParentCategory={handleRemoveParentCategory}
								productsPageUrl={productsPageUrl}
								thirdLevelChildren={thirdLevelChildren}
								secondLevelChildren={secondLevelChildren}
								handleTracking={handleTrackingCallback}
								setLockModalContent={setLockModalContent}
								registerUserClickEvent={registerUserClickEvent}
								clearAllFilters={clearAllFilters}
							/>
						</div>
					) : (
						<WhatsNewFilterElementsComponent
							filters={whastNewFilter.children}
							currentCategory={currentCategory}
							setCurrentCategory={setCurrentCategory}
							productsPageUrl={productsPageUrl}
							selectedFilters={selectedFilters}
							setLockModalContent={setLockModalContent}
							registerUserClickEvent={registerUserClickEvent}
							clearAllFilters={clearAllFilters}
						/>
					)}
				</>
			)}
			{!currentCategory && (
				<>
					<WhatsNewFilterComponent
						filter={whastNewFilter}
						setCurrentCategory={setCurrentCategory}
						addFilter={addFilter}
						handleTracking={handleTrackingCallback}
						setLockModalContent={setLockModalContent}
					/>
					<div className='category-elements-container'>
						{filters.map(filter => (
							<CategoryFilterItemComponent
								key={filter.id}
								filter={filter}
								setCurrentFilter={setCurrentFilter}
								setCurrentCategory={setCurrentCategory}
								productsPageUrl={productsPageUrl}
								handleTracking={handleTrackingCallback}
								setLockModalContent={setLockModalContent}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};
