import './index.scss';
import { FC, useEffect, useState } from 'react';
import {
	ITrackingEvent,
	ProductVM,
	TrackingEmailVariables,
	TrackingEventStatus,
	TrackingEventType
} from '../../common/view-model';
import { ProductOverviewComponent } from './overview/ProductOverviewComponent';
import { Product } from '../../common/model';
import ProductAccordionComponent from './accordion/ProductAccordionComponent';
import { AboutBrandComponent } from './aboutBrand/AboutBrandComponent';
import { ProductCertificationsComponent } from './certifications/ProductCertificationsComponent';
import { ScreenType } from '../../common/view-model';
import { validateAllImagesHaveError } from '../../helpers/validateAllImagesHaveError';
import { ingredientListMapper } from '../../helpers/ingredientListMapper';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/AppReducer';
import { handleTracking } from '../../helpers/handelTracking';
import { getUrlTrackingParamsObject } from '../../helpers/getUrlParamsObject';

interface IProductDetailComponentProps {
	product?: Product;
	deviceScreen?: ScreenType;
	registerUserClickEvent: (event: string) => void;
}

export const ProductDetailComponent: FC<IProductDetailComponentProps> = ({
	product,
	deviceScreen,
	registerUserClickEvent
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const userState = useSelector((state: RootState) => state.appState.userState?.user);

	const mapper = (product?: Product): ProductVM | undefined => {
		if (product) {
			return {
				...product,
				filtered: false,
				hasNavigate: true,
				allImagesHaveError: validateAllImagesHaveError(product.images as any[]),
				ingredientsList: ingredientListMapper(product.ingredientsList)
			};
		}
	};
	const [currentProduct, setCurrentProduct] = useState<ProductVM | undefined>(mapper(product));

	useEffect(() => {
		let searchTrackingObject = getUrlTrackingParamsObject(
			TrackingEmailVariables.UTM_TRACKING,
			searchParams
		);
		if (searchTrackingObject.trackingParam && searchTrackingObject.trackingParam === 'true') {
			const trackingEvent: ITrackingEvent = {
				transactionId: uuidv4(),
				status: TrackingEventStatus.SUCCESS,
				type: TrackingEventType.EMAIL_TRACKING_PRODUCT,
				timeStamp: new Date().toISOString(),
				entityId: product?.id!,
				eventPayload: {
					id: product?.id!,
					name: product?.name!,
					type: product?.taxonomy[0].name!,
					url: window.location.href,
					tags: [],
					metadata: {
						product_brand_name: product!.brand.name,
						user_type: userState?.user_type,
						enviroment: process.env.REACT_APP_ENV_NAME
					}
				}
			};
			handleTracking(trackingEvent);
			delete searchTrackingObject.object[TrackingEmailVariables.UTM_TRACKING];
			setSearchParams(searchTrackingObject.object);
		}
	}, []);

	useEffect(() => {
		setCurrentProduct(mapper(product));
	}, [product]);

	return (
		<div className='product-detail'>
			{product && (
				<div className='col'>
					<div data-testid='product-overview-component'>
						<ProductOverviewComponent
							product={currentProduct}
							deviceScreen={deviceScreen}
							registerUserClickEvent={registerUserClickEvent}
						/>
					</div>
					{currentProduct?.certifications && currentProduct.certifications.length > 0 && (
						<div>
							<ProductCertificationsComponent
								product={currentProduct}
								deviceScreen={deviceScreen}
								registerUserClickEvent={registerUserClickEvent}
							/>
						</div>
					)}
					<div>
						<ProductAccordionComponent product={currentProduct} />
					</div>
					<div>
						<AboutBrandComponent
							product={currentProduct}
							registerUserClickEvent={registerUserClickEvent}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
