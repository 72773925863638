import { FC, useEffect, useRef, useState } from 'react';
import './shareMenuComponent.scss';
import { emailCopy, shareMessages } from './model';
import { eventPublicPathName } from '../../common/constants';
import { RootState } from '../../redux/AppReducer';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ToastActions } from '../../redux/toast/ToastActions';
import { IToastState } from '../../common/view-model';
import { toast } from 'react-toastify';
interface IShareMenuComponentProps {
	setToast: (value: IToastState) => void;
	id: string | number | undefined;
	type: 'brand' | 'product';
}

const ShareMenuComponent: FC<IShareMenuComponentProps> = ({ id, type, setToast }) => {
	const [open, setOpen] = useState<boolean>(false);
	const sortButtonRef = useRef<HTMLDivElement>(null);
	const emailShareClick = () => {
		window.location.href = `mailto:?body=${
			type === 'brand' ? emailCopy.message_brand : emailCopy.message_product
		} ${getShareLink()}`;
		setOpen(false);
	};
	const getShareLink = () => {
		let link = '';
		if (type === 'brand') {
			link = `${window.location.origin}/${eventPublicPathName}/brand/${id}`;
		} else {
			link = `${window.location.origin}/${eventPublicPathName}/detail/${id}`;
		}
		return link;
	};
	const copyShareLink = () => {
		let link = getShareLink();
		navigator.clipboard.writeText(link);
		setToast({
			title: shareMessages.toast_text,
			message: '',
			type: 'success',
			position: toast.POSITION.TOP_RIGHT,
			closeOnClick: false,
			autoClose: 2000
		});
	};

	useEffect(() => {
		function handleClickShareButton(e: MouseEvent) {
			if (sortButtonRef.current && !e.composedPath().includes(sortButtonRef.current)) {
				setOpen(false);
			} else {
				setOpen(true);
			}
		}

		window.addEventListener('click', handleClickShareButton);
		return () => window.removeEventListener('click', handleClickShareButton);
	}, []);

	return (
		<div className='share-menu-container'>
			<div ref={sortButtonRef} className='icon-container'>
				<i className='ri-share-2-line'></i>
			</div>
			{open === true && (
				<div className='share-menu-options'>
					<div className='menu-item' onClick={emailShareClick}>
						<i className='icon ri-mail-line'></i>
						<span className='text'>{shareMessages.email_text}</span>
					</div>
					<div className='menu-item' onClick={copyShareLink}>
						<i className='icon ri-links-line'></i>
						<span className='text'>{shareMessages.copy_text}</span>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setToast: (toastContent: IToastState) => {
		dispatch(ToastActions.updateToast(toastContent));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareMenuComponent);
