import { Dispatch, FC, useEffect, useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RootState, UserState } from '../redux/AppReducer';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { Main } from '../pages/MainPage';
import ProductDetail from '../pages/ProductDetail';
import { PrivateRoute } from './PrivateRoute';
import ProductsPage from '../pages/Products';
import { ProfilePage } from '../pages/Profile/index';
import OnbordingPage from '../pages/Onbording/index';
import BrandDatail from '../pages/BrandDetail/index';
import CompleteBrandDetails from '../pages/CompleteBrandDetails/index';
import LandingPage from '../pages/LandingPage/index';
import { GeneralError } from '../pages/GeneralError/index';
import { UserActions } from '../redux/UserActions';
import { eventPublicPathName, PROFILE_ROUTES } from '../common/constants';
import {
	IirisIdentityKey,
	getLocalStorageValue,
	isCurrentUserAnonymous,
	validateAnonymousPath,
	validateCanLoadAnonymousRoutes,
	validateCompanyDetails
} from '../helpers/authenticationHelper';
import { AnonymousGuard } from './AnonymousGuard';
import { AppActions } from '../redux/AppActions';
import LockOverlayComponent from '../components/LockOverlay/LockOverlayComponent';
import Home from '../pages/HomePage';
import BrandOnboard from '../pages/BrandOnboard';
import UnsubscribePage from '../pages/Unsubscribe';

interface IAppRoutes {
	isAuthenticated?: boolean;
	userState?: UserState;
	eventPath: string;
	getAnonymousUserToken: () => void;
	setLockModalContent: () => void;
}
const AppRoutes: FC<IAppRoutes> = ({
	isAuthenticated,
	userState,
	eventPath,
	getAnonymousUserToken,
	setLockModalContent
}) => {
	const [hasCompanyDetails, setHasCompanyDetails] = useState<boolean>(true);
	useEffect(() => {
		if (userState?.hasLoadProfile) {
			setHasCompanyDetails(validateCompanyDetails(userState.companyDetails));
		}
	}, [userState?.hasLoadProfile]);
	useEffect(() => {
		if (validateAnonymousPath(eventPath) && !getLocalStorageValue(IirisIdentityKey.USER_IDENTITY)) {
			getAnonymousUserToken();
		}
	}, [eventPath, getAnonymousUserToken]);
	useEffect(() => {
		if (isCurrentUserAnonymous() && userState?.hasAnonymousEnded) {
			setLockModalContent();
		}
	}, [setLockModalContent, userState?.hasAnonymousEnded]);

	return (
		<Router>
			<Routes>
				<Route element={<Layout isAuthenticated={isAuthenticated} />}>
					<Route path='/' element={<Main />} />
					<Route
						element={
							<PrivateRoute isLogged={isAuthenticated} hasCompanyDetails={hasCompanyDetails} />
						}
					>
						<Route path='/home' element={<Home />} />
						<Route path='/detail/:id' element={<ProductDetail />} />
						<Route path='/products' element={<ProductsPage />} />
						<Route path={`/${PROFILE_ROUTES.PROFILE}`} element={<ProfilePage />} />
						<Route path='/onboarding' element={<OnbordingPage />} />
						<Route path='/brand/:brandId' element={<BrandDatail />} />
						<Route path='/company' element={<CompleteBrandDetails />} />
						<Route path='/landing' element={<LandingPage />} />
						<Route
							path={`/${PROFILE_ROUTES.GET_ON_BEACON}`}
							element={
								process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true' ? (
									<ProfilePage></ProfilePage>
								) : (
									<Navigate to='/profile#5' />
								)
							}
						/>

						<Route path={`/${PROFILE_ROUTES.BRAND_INFO}`} element={<ProfilePage />} />
						<Route path={`/${PROFILE_ROUTES.PRODUCT_INFO}`} element={<ProfilePage />} />
						<Route path={`/${PROFILE_ROUTES.ONBOARD_FAQ}`} element={<ProfilePage />} />
						<Route path={`/${PROFILE_ROUTES.INGREDIENTS}`} element={<ProfilePage />} />
						<Route path={`/${PROFILE_ROUTES.FAVORITES}`} element={<ProfilePage />} />

						<Route path='*' element={<GeneralError />} />
					</Route>
					{/**Anonymous routes*/}
				</Route>
				<Route element={<AnonymousGuard canLoad={validateCanLoadAnonymousRoutes()} />}>
					<Route
						element={
							userState?.hasLoadAnonymous ? (
								<Layout isAuthenticated={userState?.isAnonymous} />
							) : (
								<></>
							)
						}
						path={`/${eventPath}`}
					>
						<Route index element={<Home />} />
						<Route path={`/${eventPath}/getonbeacon`} element={<BrandOnboard />} />
						<Route path={`/${eventPath}/products`} element={<ProductsPage />} />
						<Route path={`/${eventPath}/detail/:id`} element={<ProductDetail />} />
						<Route path={`/${eventPath}/brand/:brandId`} element={<BrandDatail />} />
						<Route path={`/${eventPath}/anonymous`} element={<></>} />

						<Route path='*' element={<GeneralError />} />
					</Route>
				</Route>
				<Route>
					<Route path='/unsubscribe/:email' element={<UnsubscribePage />} />
					<Route path='*' element={<GeneralError />} />
				</Route>
			</Routes>
		</Router>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	isAuthenticated: state.authState?.isAuthenticated,
	userState: state.userState,
	eventPath: eventPublicPathName
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	getAnonymousUserToken: () => dispatch({ type: UserActions.GET_ANONYMOUS_USER_TOKEN }),
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: false,
				canScroll: false,
				closeOnDocumentClick: false
			})
		);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
