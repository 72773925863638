import { Action } from 'redux-actions';
import { StateObservable, ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { apolloClient } from '../graphql/ApolloClient';
import { ApolloActions, GraphqlAction } from './ApolloActions';
import { AppState } from './AppReducer';
import { AppActions } from './AppActions';
import { serverError } from '../common/constants';
import { trackProductsResults } from '../helpers/trackProductsResultsHelper';

export class ApolloEpics {
	static init() {
		return [ApolloEpics.graphqlEpic, ApolloEpics.queryEpic, ApolloEpics.mutationEpic];
	}

	static graphqlEpic = (action$: Observable<Action<AppState>>): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(ApolloActions.GRAPHQL),
			map((action: GraphqlAction<AppState>) => {
				const result = { ...action };

				if (action.query) {
					result.type = ApolloActions.QUERY;
				} else if (action.mutation) {
					result.type = ApolloActions.MUTATION;
				}

				return result;
			})
		);
	};

	static queryEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(ApolloActions.QUERY),
			mergeMap((action: GraphqlAction<AppState>) => {
				const tempLabelForEvent = 'Expo West';
				const { query, variables } = action;
				const apolloQuery$ = from(
					apolloClient.query({
						query,
						variables,
						fetchPolicy: 'no-cache'
					})
				);
				return apolloQuery$.pipe(
					map((graphqlResult: { data: any }) => {
						if (graphqlResult.data.products) {
							const { products } = graphqlResult.data.products;
							trackProductsResults(products, state$?.value?.appState);
							return {
								type: 'STORE_STATE_PRODUCTS',
								payload: graphqlResult.data
							};
						}
						/**Temp hardcoded  */
						if (
							process.env.REACT_APP_HARDCODED_EVENT_LABEL === 'true' &&
							graphqlResult.data.getCurrentEvent &&
							!graphqlResult.data.getCurrentEvent.label
						) {
							graphqlResult.data.getCurrentEvent = {
								...graphqlResult.data.getCurrentEvent,
								label: tempLabelForEvent
							};
						}
						return { type: 'STORE_STATE', payload: graphqlResult.data };
					}),
					catchError(error => {
						if (error.message === 'Unauthorized') {
							return of(AppActions.unauthorizedError());
						}
						return of(AppActions.setGeneralError(serverError));
					})
				);
			})
		);
	};

	static mutationEpic = (action$: Observable<Action<AppState>>): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(ApolloActions.MUTATION),
			map((action: GraphqlAction<AppState>) => {
				return { type: 'END_STATE', payload: action.payload };
			})
		);
	};
}
