export const removeClasses = (classNames: string[]) => {
	classNames.forEach(classItem => {
		const items = document.querySelectorAll(`.${classItem}`);
		items.forEach(item => {
			item.classList.remove(classItem);
		});
	});
};
export const addClass = (id: string, className: string) => {
	const item = document.getElementById(id);
	item?.classList.add(className);
};
export const removeClass = (id: string, className: string) => {
	const item = document.getElementById(id);
	item?.classList.remove(className);
};
