import { ReactComponent as CurveArrow } from '../../assets/images/curve-arrow.svg';
import { ReactComponent as Cursor } from '../../assets/images/cursor.svg';
import pattern from '../../assets/images/expoEastflower.png';
import './ExpoEastOverlay.scss';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../redux/AppActions';
import GenericSVGPrepComponent from '../PrepExpoDiscovery/GenericSVGPrepComponent';
import { GenericSVGComponentKey } from '../PrepExpoDiscovery/GenericSVGPrepComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/AppReducer';

export const ExpoEastOverlay = () => {
	const dispatch = useDispatch();
	const { storeState } = useSelector((state: RootState) => state.appState);
	const overlayTitle = `This brand will be at ${storeState?.getCurrentEvent?.name}!`;
	const handleClose = () => {
		dispatch(AppActions.setModalContent(undefined));
	};

	return (
		<div className='expo-east-container'>
			<div className='east-title'>{overlayTitle}</div>
			<div className='east-body'>
				<div className='body-title'>
					<i className='fa-regular fa-circle-check'></i>
					<div className='title-text'>
						<span>Find your favorite products on your </span>
						<a href={storeState?.getCurrentEvent?.floorMapUrl} target='blank'>
							<i className='ri-map-2-line'></i> <span>show floor map here!</span>
						</a>
					</div>
				</div>
				<div className='map-container'>
					<div className='cards'>
						<div>
							{Array.from(Array(6).keys()).map((item, index) => (
								<GenericSVGPrepComponent key={index} componentKey={GenericSVGComponentKey.CARDS} />
							))}
						</div>
					</div>
					<GenericSVGPrepComponent componentKey={GenericSVGComponentKey.MAP_DISCOVER} />
					<CurveArrow className='curve-arrow' />
				</div>
			</div>
			<Cursor className='cursor' />
			<i className='fa fa-close expo-close' onClick={handleClose}></i>
			<img src={pattern} className='back-pattern' alt='' />
		</div>
	);
};
