import './BubbleComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FilterActions } from '../../../redux/FilterActions';
import { useCallback, FC, useMemo } from 'react';
import { RootState } from '../../../redux/AppReducer';
import { FilterLevel, FilterType, FilterVM, ScreenType } from '../../../common/view-model';
import { BubbleState as model, validateShowBubble } from './model';
import { useNavigate } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
interface ITopBubblesState {
	filter: FilterVM;
	style?: string;
	onBubbleClick?: () => void;
	disabled: boolean;
	bubblesToShow?: number;
	handleOpenPanel?: () => void;
}

export const BubbleComponent: FC<ITopBubblesState> = ({
	filter,
	style,
	onBubbleClick,
	disabled,
	bubblesToShow,
	handleOpenPanel
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const homePageUrl = 'products';
	const selectedFilters = useSelector(
		(state: RootState) => state.appState.filterState?.selectedFilters
	);
	const screenType = useSelector((state: RootState) => state.appState.deviceScreen);
	const clickFunctions = useSelector(
		(state: RootState) => state.appState.filterState?.bubbleFunctionRef
	);
	const refMemo = useMemo(() => {
		return clickFunctions?.find(f => f.type === filter.type)?.filterRef;
	}, [clickFunctions, filter.type]);
	const bubbleClickCallback = useCallback(() => {
		clickFunctions?.find(f => f.type === filter.type)?.value();
	}, [clickFunctions, filter.type]);
	const showBubble = validateShowBubble(filter, selectedFilters);
	const setCurrentCategory = useCallback(() => {
		dispatch(FilterActions.setCategory(filter.categoryName!));
		const breadCrumb = filter.breadcrumText?.split('/');
		dispatch(
			FilterActions.setBreadcrumbElements(breadCrumb || [filter.categoryName!, filter.name!])
		);
	}, [dispatch, filter]);
	const handleBubbleClick = () => {
		if (
			filter?.type === FilterType.CATEGORY ||
			filter?.type === FilterType.NEW_TO_BEACON ||
			filter?.type === FilterType.NEW_TO_MARKET
		) {
			bubbleClickCallback();
			return;
		}
		if (!disabled) {
			if (screenType === ScreenType.TABLET && filter.type !== FilterType.BRAND) {
				handleOpenPanel!();
				dispatch(FilterActions.setCurrentFilterOpenIndex(filter.type!));
			}
			bubbleClickCallback();
		}
	};
	const handleCloseClick = () => {
		if (!disabled) {
			if (bubblesToShow! <= 1) {
				dispatch(FilterActions.clearAllFilters());
				navigate(`${isCurrentUserAnonymous() ? '..' : ''}/${homePageUrl}`);
			} else {
				dispatch(FilterActions.removeFilter(filter, screenType === ScreenType.MOBILE));
			}
		}
	};
	return (
		<>
			{showBubble && (
				<button
					className={`bubble ${style} ${disabled ? 'disabled' : ''}`}
					id={`bubble${filter.id}`}>
					<span data-testid='bubble-item' className='name' onClick={handleBubbleClick}>
						{filter?.name}
					</span>
					<div data-testid='close-bubble' className={'close'} onClick={handleCloseClick}>
						<i className='ri-close-fill icon'></i>
					</div>
				</button>
			)}
		</>
	);
};
