import { useEffect } from 'react';

export const useScrollTemplates = (
	currentTab: number,
	previewTab: number,
	open: boolean,
	setOpen: Function,
	ref: HTMLDivElement | null
) => {
	useEffect(() => {
		if (!open && previewTab !== -1) {
			setOpen(true);
		}
		if (previewTab !== -1) {
			ref?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		}
	}, [currentTab, open, ref, setOpen, previewTab]);
};
