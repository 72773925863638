import { FC, useCallback, useState } from 'react';
import './IngredientsTemplateHeader.scss';
import { IngredientsTemplateHeaderState } from './model';
import { ActionLinkComponent } from '../../../UI/ActionLinkComponent/ActionLinkComponent';
import { beaconFaqLink } from '../../../../common/constants';

const StandardComponent: FC<{ isMobile: boolean }> = ({ isMobile }) => {
	const handleLearMoreClick = useCallback(() => {
		window.open(beaconFaqLink, '_blank', 'rel=noopener noreferrer');
	}, []);
	return (
		<div className='store-standars'>
			<div>
				{IngredientsTemplateHeaderState.storeStandarsText}
				<div className='template-onboarding-learn-more-container'>
					<ActionLinkComponent
						text='Learn More'
						underline={true}
						onClick={handleLearMoreClick}
						icon='ri-arrow-right-line'
						iconPosition='end'
						iconOnly={false}
						color='dark'
						fontWigth='normal'
						size={isMobile ? 'small' : 'big'}
						to={beaconFaqLink}
						linkToExternal={true}
					/>
				</div>
			</div>
		</div>
	);
};
interface IIngredientsTemplateHeaderProps {
	isMobile: boolean;
}
export const IngredientsTemplateHeader: FC<IIngredientsTemplateHeaderProps> = ({ isMobile }) => {
	const [state] = useState({ ...IngredientsTemplateHeaderState });
	return (
		<div className='ingredients-template-header'>
			<div className='col'>
				<div className='row'>
					<div className='title-wrapper'>
						<span className='personalization-title'>{state.titleText}</span>
						{!isMobile && <span className='step'>{state.stepText}</span>}
					</div>
				</div>
				<div className='row'>
					<StandardComponent isMobile={isMobile} />
				</div>
				<div className='row'>
					<div className='choose-template'>
						<span>{state.chooseTemplateText}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
