import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProductVM } from '../../../common/view-model';
import './AboutBrandComponent.scss';
import { aboutBrandState } from './model';
import {
	isCurrentUserAnonymous,
	validateAnonymousPath
} from '../../../helpers/authenticationHelper';
import { eventPublicPathName, publicProductDetailsActions } from '../../../common/constants';
interface IAboutBrandComponentProps {
	product: ProductVM | undefined;
	registerUserClickEvent: (event: string) => void;
}
export const AboutBrandComponent: FC<IAboutBrandComponentProps> = ({
	product,
	registerUserClickEvent
}) => {
	const navigate = useNavigate();
	const [state] = useState({ ...aboutBrandState });
	const handleNavigate = (event: any) => {
		event.preventDefault();
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_GO_TO_BRAND_PAGE);
		}
		navigate(
			`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/brand/${product?.brand.id}`
		);
	};
	return (
		<div id='about-brand' className='about-brand-container' data-custom-attribute='about-brand'>
			<i className='ri-arrow-line'></i>
			<div className='about-brand-content'>
				<span className='about-brand-title'>{state.aboutBandTitle}</span>
				<div className='brand-information-container'>
					<div className='brand-name-container'>
						<Link
							to={`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/brand/${
								product?.brand.id
							}`}
							data-testid='go-to-brand-link-name'
							className='about-brand-link-name'
							onClick={(event: any) => handleNavigate(event)}
						>
							<span className='brand-name'>{product?.brand?.name}</span>
						</Link>
					</div>
					<div className='brand-info'>
						<Link
							to={`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/brand/${
								product?.brand.id
							}`}
							data-testid='go-to-brand-link-img'
							className='about-brand-link-img'
							onClick={(event: any) => handleNavigate(event)}
						>
							<div className='brand-image'>
								<div className='image-container'>
									<img alt='' src={product?.brand.url_logo} />
								</div>
							</div>
						</Link>
						<div className='brand-story-container'>
							<span className='brand-story'>
								{product?.brand?.brand_story || product?.brand.brand_mission}
							</span>
							<Link
								to={`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/brand/${
									product?.brand.id
								}`}
								data-testid='go-to-brand-link'
								className='about-brand-link'
								onClick={(event: any) => handleNavigate(event)}
							>
								{state.goToBrandText}
								<i className='ri-arrow-right-line icon-arrow'></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
