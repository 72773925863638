const cacheUrlIdentifier = 'redirectPath';
const setCacheUrl = (path: string) => {
	localStorage.setItem(cacheUrlIdentifier, path);
};
const getCacheUrl = () => {
	return localStorage.getItem(cacheUrlIdentifier);
};
const removeCacheUrl = () => {
	localStorage.removeItem(cacheUrlIdentifier);
};

export { setCacheUrl, getCacheUrl, removeCacheUrl };
