import './ProductComponent.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import { getHowGoodColor } from '../../helpers/howGoodColor';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../redux/AppActions';
import { HowGoodScore } from '../product-detail/howGoodScore/HowGoodScoreComponent';
import { RootState } from './../../redux/AppReducer';
import { UserActions } from '../../redux/UserActions';
import { handleTracking } from '../../helpers/handelTracking';
import {
	ITrackingEvent,
	ProductVM,
	TrackingEventStatus,
	TrackingEventType
} from '../../common/view-model';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useId, useState } from 'react';
import { ScreenType } from '../../common/view-model';
import { ExpoLabelComponent } from '../UI/ExpoLabelComponent/ExpoLabelComponent';
import { FavoriteAddDate } from './../../redux/AppReducer';
import { ExpoEastOverlay } from '../ExpoEastOverlay/ExpoEastOverlay';
import { useLocation } from 'react-router-dom';

import { isCurrentUserAnonymous, validateAnonymousPath } from '../../helpers/authenticationHelper';
import { eventPublicPathName, publicProductDiscoveryActions } from '../../common/constants';
import LockOverlayComponent from '../LockOverlay/LockOverlayComponent';
const noImageRef = require('../../assets/images/no-image.png');

export interface IProductComponentProps {
	product: ProductVM | undefined;
	favorite?: boolean;
	favoriteProducts: FavoriteAddDate[];
	toggleFavorite: (productId: string, trackingEvent?: ITrackingEvent) => void;
	setUserReturns: (state: boolean) => void;
	deviceScreen?: ScreenType;
	isOnBrandPage?: boolean;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
	anonymousEventName: string;
}

const ProductComponent = ({
	product,
	favorite,
	toggleFavorite,
	setUserReturns,
	deviceScreen,
	isOnBrandPage,
	setLockModalContent,
	registerUserClickEvent,
	anonymousEventName
}: IProductComponentProps) => {
	const favoriteIconId = useId();
	const expoWestEventName = 'Expo West';
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const [loaded, setLoaded] = useState(false);
	const [imageError, setImageError] = useState(false);
	const location = useLocation();
	const { isFavoriting } = useSelector((state: RootState) => state.appState);
	const currentEvent = useSelector(
		(state: RootState) => state.appState.storeState?.getCurrentEvent
	);

	const handleFavoriteProduct = (e: React.MouseEvent<HTMLDivElement>, id: string | undefined) => {
		e.preventDefault();
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(anonymousEventName);
			setLockModalContent();
			return;
		}
		e.stopPropagation();
		const trackingEvent: ITrackingEvent = {
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.FAVORITES,
			timeStamp: new Date().toISOString(),
			entityId: id!,
			eventPayload: {
				id: product?.id!,
				name: product?.name!,
				type: product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					product_brand_name: product!.brand.name,
					user_type: userState?.user_type,
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		};

		if (id && !isFavoriting) {
			toggleFavorite(id, trackingEvent);
		}
	};
	const handleShow = (score: number) => {
		dispatch(
			AppActions.setModalContent({
				content: (
					<HowGoodScore
						score={score}
						product={product}
						initialOpen={true}
						registerUserClickEvent={() => {}}
					/>
				),
				showCloseIcon: true,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);
	};
	const invalidScoreName = 'standards not met';
	const [showHowGood, setShowHowGood] = useState<boolean | undefined>(
		(product?.howGoodScore?.show_howgood_score &&
			product?.howGoodScore?.source_retail_raiting !== null &&
			product?.howGoodScore?.source_retail_raiting?.trim().toLowerCase() !==
				invalidScoreName.trim().toLowerCase()) ||
			false
	);

	const [showExpoLabel, setShowExpoLabel] = useState<boolean | undefined>(
		!!product?.brand?.boothNumber && currentEvent != null
	);

	const openExpoPopUpCallback = useCallback(() => {
		dispatch(
			AppActions.setModalContent({
				content: <ExpoEastOverlay />,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);

		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.CLICK_ON_SEE_US_EXPO_BUTTON,
			timeStamp: new Date().toISOString(),
			entityId: product?.id!,
			eventPayload: {
				id: product?.id!,
				name: product?.name!,
				type: product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: userState?.id,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME,
					event_id: currentEvent?.id,
					event_name: currentEvent?.name
				}
			}
		});
	}, [dispatch]);
	useEffect(() => {
		if (
			product?.howGoodScore?.show_howgood_score &&
			product?.howGoodScore?.source_retail_raiting !== null &&
			product?.howGoodScore?.source_retail_raiting?.trim().toLowerCase() !==
				invalidScoreName.trim().toLowerCase()
		) {
			setShowHowGood(true);
		} else {
			setShowHowGood(false);
		}

		setShowExpoLabel(!!product?.brand?.boothNumber && !!currentEvent);
	}, [product]);
	const handleCardClickCallback = useCallback(
		(event: any) => {
			event.preventDefault();
			if (isCurrentUserAnonymous()) {
				registerUserClickEvent(publicProductDiscoveryActions.CLICK_PRODUCT);
			}
			const targetId = event.target.id;
			if (
				targetId === 'btnhow-good' ||
				targetId === 'expo-label-component' ||
				targetId === favoriteIconId
			)
				return;
			if (
				(location.pathname.startsWith('/products') ||
					location.pathname.startsWith(`/${eventPublicPathName}`)) &&
				!location.pathname.includes(`${eventPublicPathName}/brand`)
			) {
				const scrollPosition = window.scrollY.toString();
				sessionStorage.setItem('scrollPosition', scrollPosition);
				setUserReturns(true);
			}
			if (isCurrentUserAnonymous()) {
				navigate(`/${eventPublicPathName}/detail/${product?.id}`);
				return;
			}
			navigate(`/detail/${product?.id}`);
		},
		[favoriteIconId, isOnBrandPage, navigate, product?.id]
	);
	return (
		<>
			{product && (
				<Link
					to={`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/detail/${product?.id}`}
					className='product-card'
					data-testid='product-card'
					onClick={handleCardClickCallback}
				>
					<div>
						<div
							data-testid='favorite-button'
							className='product-favorite'
							onClick={event => {
								handleFavoriteProduct(event, product?.id);
							}}
						>
							<i
								id={favoriteIconId}
								className={'fa fa-heart ' + (favorite ? 'red' : ' fa-regular')}
							/>
						</div>
						<div className='product-image'>
							{imageError && (
								<img
									data-testid='error-image'
									src={noImageRef}
									className='error-place-holder'
									alt='error-placeholder'
								/>
							)}

							{!loaded && !imageError && <div className='p-card-skeleton' />}

							<img
								data-testid='product-image'
								src={product?.imageUrl}
								style={loaded && !imageError ? {} : { display: 'none' }}
								className='img-fluid'
								alt={product?.name}
								onLoad={() => {
									setLoaded(true);
								}}
								onError={() => setImageError(true)}
							/>
						</div>

						<div className='product-info'>
							<div className='brand-name-container'>
								<span className='brand'>{product?.brand.name}</span>
							</div>
							<div className='name-wrapper'>
								<span className='name'>{product?.name!}</span>
							</div>
						</div>
					</div>
					<div
						className={`labels-container ${isOnBrandPage ? 'brand-page-wrapper' : ''}${
							showHowGood && showExpoLabel ? 'show-hg-wrapper' : ''
						}${
							(!showExpoLabel && showHowGood) || (!showHowGood && !showExpoLabel)
								? 'expo-label-wrapper'
								: ''
						}${
							showHowGood &&
							currentEvent?.name.trim().toLowerCase() === expoWestEventName.trim().toLowerCase()
								? ' expo-west-wrapper'
								: ''
						}`}
					>
						{showHowGood && (
							<ButtonComponent
								id='how-good'
								text={
									deviceScreen === ScreenType.MOBILE ||
									deviceScreen === ScreenType.TABLET ||
									isOnBrandPage
										? 'HG Estimate'
										: 'HowGood Estimate'
								}
								iconPosition='start'
								style={`how-good-btn ${
									getHowGoodColor(product?.howGoodScore?.source_retail_raiting!)?.class
								}`}
								onClick={() => handleShow(product?.howGoodScore?.score!)}
								icon={
									<>
										{' '}
										<i className='ri-earth-line world-icon'></i>{' '}
										<span
											className={`product-score ${
												product?.howGoodScore?.source_retail_raiting === 'Best'
													? 'best-hg-wrapper'
													: ''
											}`}
										>
											{product?.howGoodScore?.source_retail_raiting!}
										</span>
									</>
								}
							/>
						)}
						{showExpoLabel && (
							<ExpoLabelComponent
								text={currentEvent?.label || currentEvent?.name || ''}
								labelStyle={
									isOnBrandPage
										? `inside-carousel ${currentEvent?.name
												.replace(/\s+/g, '')
												.toLocaleLowerCase()
												.trim()}`
										: currentEvent?.name.replace(/\s+/g, '').toLocaleLowerCase().trim()
								}
								onClick={openExpoPopUpCallback}
							/>
						)}
					</div>
				</Link>
			)}
		</>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	favoriteProducts: state.userState?.favoriteProducts || [],
	deviceScreen: state.deviceScreen
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	toggleFavorite: (productId: string, trackingEvent?: ITrackingEvent) =>
		dispatch(UserActions.toggleFavoriteProduct(productId, trackingEvent)),
	setUserReturns: (state: boolean) => {
		dispatch(AppActions.setUserReturns(state));
	},
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductComponent);
