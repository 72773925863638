import { FC } from 'react';
import './ActionLinkComponent.scss';
import { Link } from 'react-router-dom';
interface IActionLinkComponentProps {
	text: string;
	onClick: () => void;
	underline: boolean;
	icon?: string;
	iconPosition?: 'start' | 'end';
	iconOnly: boolean;
	color: 'dark' | 'light-blue';
	fontWigth: 'bold' | 'normal' | 'semi-bold';
	size: 'bigger' | 'big' | 'regular' | 'small' | 'smaller';
	to: string;
	linkToExternal: boolean;
}

export const ActionLinkComponent: FC<IActionLinkComponentProps> = ({
	text,
	onClick,
	underline,
	icon,
	iconPosition,
	iconOnly,
	color,
	fontWigth,
	size,
	to,
	linkToExternal
}) => {
	const handleClick = (event: any) => {
		event.preventDefault();
		onClick();
	};
	const className = `action-link ${color} ${size} ${fontWigth} ${underline ? 'underline' : ''}`;
	if (linkToExternal) {
		return (
			<a href={to} target='_blank' rel='noopener noreferrer' className='action-link-contianer'>
				{iconOnly && <i className={`${icon} ${color}`}></i>}
				{!iconOnly && iconPosition === 'start' && <i className={`${icon} ${color}`}></i>}
				{!iconOnly && (
					<span className={className} onClick={handleClick}>
						{text}
					</span>
				)}
				{!iconOnly && iconPosition === 'end' && <i className={`${icon} ${color}`}></i>}
			</a>
		);
	}
	return (
		<Link to={to} className='action-link-contianer'>
			{iconOnly && <i className={`${icon} ${color}`}></i>}
			{!iconOnly && iconPosition === 'start' && <i className={`${icon} ${color}`}></i>}
			{!iconOnly && (
				<span className={className} onClick={handleClick}>
					{text}
				</span>
			)}
			{!iconOnly && iconPosition === 'end' && <i className={`${icon} ${color}`}></i>}
		</Link>
	);
};
