import { useEffect, useState } from 'react';
import { ISuggestion } from '../../../../common/view-model';

export const useSearchInputChange = (
	initialValue: string,
	suggestionCallback: (term: string) => void,
	handleShowPredictions: (show: boolean) => void,
	searchTerm?: ISuggestion
) => {
	const [value, setValue] = useState(initialValue);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		suggestionCallback(e.target.value);
		handleShowPredictions(true);
	};
	const reset = () => {
		setValue(initialValue);
		suggestionCallback(initialValue);
	};
	useEffect(() => {
		if (!searchTerm) {
			reset();
		}
	}, [searchTerm]);
	return { value, handleChange, reset, setValue };
};
