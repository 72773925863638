import { FilterType, PintoFilters } from '../common/view-model';
export const mockFilters: PintoFilters[] = [
	{
		id: '1',
		name: 'Categories',
		type: FilterType.CATEGORY,
		filters: []
	},
	{
		id: '2',
		name: 'Distributors',
		type: FilterType.DISTRIBUTOR,
		filters: []
	},
	{
		id: '3',
		name: 'Regions',
		type: FilterType.REGION,
		filters: []
	},
	{
		id: '4',
		name: 'Top Attributes',
		type: FilterType.ATTRIBUTES,
		filters: []
	},
	{
		id: '5',
		name: 'Certifications',
		type: FilterType.CERTIFICATION,
		filters: []
	},
	{
		id: '6',
		name: 'Allergens',
		type: FilterType.ALLERGENS,
		filters: []
	},
	{
		id: '7',
		name: 'Ingredients',
		type: FilterType.INGREDIENTS,
		filters: []
	},
	{
		id: '8',
		name: 'Consumer Diets',
		type: FilterType.CONSUMER_DIETS,
		filters: []
	},
	{
		id: '9',
		name: 'Animal Welfare',
		type: FilterType.ANIMAL_WELFARE,
		filters: []
	},
	{
		id: '10',
		name: 'Business Practices',
		type: FilterType.BUSINESS_PRACTICES,
		filters: []
	},
	{
		id: '11',
		name: 'Climate',
		type: FilterType.CLIMATE,
		filters: []
	},
	{
		id: '12',
		name: 'Packaging',
		type: FilterType.PACKAGING,
		filters: []
	}
];
