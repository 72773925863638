import React from 'react';
import { BrandInfo, IProfileState } from '../../../../redux/AppReducer';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import './ContactFormComponent.scss';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { getIn } from 'formik';
import { validationMessages } from '../../../../common/constants';
import { validateExactLengthMessage } from '../../../../helpers/formFieldValidations';
import { DEFAULT_LABELS } from './model';
import LoadingBobComponent from '../../GenericBaseForms/LoadingBobComponent/LoadingBobComponent';
interface IContactForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (brandState: BrandInfo) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
	profileState: IProfileState;
}

const brokersSchema = Yup.object({
	name: Yup.string().max(150, validationMessages.max_value + '150.'),
	phone: Yup.string().max(10, validationMessages.max_value + '10.'),
	email: Yup.string()
		.email(validationMessages.invalid_email)
		.max(250, validationMessages.max_value + '250.')
});
const ContactFormComponent: React.FC<IContactForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	onChange,
	onValidationChange,
	cleanForm,
	profileState
}) => {
	const ID = 'contact-form';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	const validateBrokers = (values: any) => {
		const errors: any = {};
		if (values.brokers.length > 0) {
			if (!values.brokers[0].name) {
				errors.brokers = errors.brokers || [];
				errors.brokers[0] = errors.brokers[0] || {};
				errors.brokers[0].name = validationMessages.field_required;
			}
			if (!values.brokers[0].phone) {
				errors.brokers = errors.brokers || [];
				errors.brokers[0] = errors.brokers[0] || {};
				errors.brokers[0].phone = validationMessages.field_required;
			}
			if (!values.brokers[0].email) {
				errors.brokers = errors.brokers || [];
				errors.brokers[0] = errors.brokers[0] || {};
				errors.brokers[0].email = validationMessages.field_required;
			}
			if (values.brokers[0].phone.length > 0 && values.brokers[0].phone.length < 10) {
				errors.brokers = errors.brokers || [];
				errors.brokers[0] = errors.brokers[0] || {};
				errors.brokers[0].phone = validateExactLengthMessage('10 digits.');
			}
			for (let i = 0; i < values.brokers.length; i++) {
				const broker = values.brokers[i];
				if (broker.name || broker.phone || broker.email) {
					if (!broker.name) {
						errors.brokers = errors.brokers || [];
						errors.brokers[i] = errors.brokers[i] || {};
						errors.brokers[i].name = validationMessages.field_required;
					}
					if (!broker.phone) {
						errors.brokers = errors.brokers || [];
						errors.brokers[i] = errors.brokers[i] || {};
						errors.brokers[i].phone = validationMessages.field_required;
					}
					if (!broker.email) {
						errors.brokers = errors.brokers || [];
						errors.brokers[i] = errors.brokers[i] || {};
						errors.brokers[i].email = validationMessages.field_required;
					}
					if (broker.phone.length > 0 && broker.phone.length < 10) {
						errors.brokers = errors.brokers || [];
						errors.brokers[i] = errors.brokers[i] || {};
						errors.brokers[i].phone = validateExactLengthMessage('10 digits.');
					}
				}
			}
		}

		return errors;
	};
	const { errors, values, handleChange, handleReset, submitForm, isValid, setValues } = useFormik({
		initialValues: {
			...brandState,
			contact_name: brandState.brandRepName,
			contact_email: brandState.brandRepEmail,
			contact_phone: brandState.brandRepPhone?.replace(/-/g, ''),
			contact_title: brandState.brandRepTitle,
			haveABroker: brandState.haveABroker || false,
			brokers: brandState.brokers,
			sample_name: brandState.productRepName,
			sample_email: brandState.productRepEmail,
			sample_phone: brandState.productRepPhone?.replace(/-/g, ''),
			sample_title: brandState.productRepTitle
		},
		validate: validateBrokers,
		onSubmit: values => {
			onUpdateProfileBrand({
				...brandState,
				brandRepEmail: values.contact_email,
				brandRepName: values.contact_name,
				brandRepPhone: values.contact_phone,
				brandRepTitle: values.contact_title,
				haveABroker: values.haveABroker,
				brokers: values.brokers,
				productRepEmail: values.sample_email,
				productRepName: values.sample_name,
				productRepPhone: values.sample_phone,
				productRepTitle: values.sample_title
			});
		},
		validationSchema: Yup.object().shape({
			contact_name: Yup.string()
				.required(validationMessages.field_required)
				.max(150, validationMessages.max_value +'150.'),
			contact_email: Yup.string()
				.email(validationMessages.invalid_email)
				.required(validationMessages.field_required)
				.max(250, validationMessages.max_value),
			contact_phone: Yup.string().test('length', validateExactLengthMessage('10 digits.'), val => {
				return val !== undefined && val?.toString().replace(/-/g, '').length === 10;
			}),
			contact_title: Yup.string()
				.required(validationMessages.field_required)
				.max(70, validationMessages.max_value + '70.'),
			haveABroker: Yup.boolean(),
			brokers: Yup.array().of(brokersSchema),
			sample_name: Yup.string()
				.required(validationMessages.field_required)
				.max(150, validationMessages.max_value + '150.'),
			sample_email: Yup.string()
				.email(validationMessages.invalid_email)
				.required(validationMessages.field_required)
				.max(250, validationMessages.max_value + '250.'),
			sample_phone: Yup.string().test('length', validateExactLengthMessage('10 digits.'), val => {
				return val !== undefined && val?.toString().replace(/-/g, '').length === 10;
			}),
			sample_title: Yup.string()
				.required(validationMessages.field_required)
				.max(70, validationMessages.max_value + '70.')
		})
	});
	useEffect(() => {
		onChange({
			...brandState,
			brandRepEmail: values.contact_email,
			brandRepName: values.contact_name,
			brandRepPhone: values.contact_phone,
			brandRepTitle: values.contact_title,
			haveABroker: values.haveABroker,
			brokers: values.brokers,
			productRepEmail: values.sample_email,
			productRepName: values.sample_name,
			productRepPhone: values.sample_phone,
			productRepTitle: values.sample_title
		});
		onValidationChange(isValid);
	}, [values, isValid]);

	useEffect(() => {
		handleReset(null);
	}, [brandState]);
	useEffect(() => {
		if (values.haveABroker) {
			const allCurrentBrokers = brandState.brokers!;
			const maxBrokers = 3;
			const defaultBroker = { name: '', phone: '', email: '' };
			const brokersArray = [
				...allCurrentBrokers,
				...Array(Math.max(0, maxBrokers - allCurrentBrokers.length)).fill(defaultBroker)
			];
			setValues({
				...values,
				brokers: brokersArray
			});
		} else {
			setValues({
				...values,
				brokers: []
			});
		}
	}, [values.haveABroker]);
	useEffect(() => {
		if (isEditing) {
			let brokers: any = [];
			if (brandState.haveABroker) {
				const allCurrentBrokers = brandState.brokers!;
				const maxBrokers = 3;
				const defaultBroker = { name: '', phone: '', email: '' };
				brokers = [
					...allCurrentBrokers,
					...Array(Math.max(0, maxBrokers - allCurrentBrokers.length)).fill(defaultBroker)
				];
			}
			setValues({
				...brandState,
				contact_name: brandState.brandRepName,
				contact_email: brandState.brandRepEmail,
				contact_phone: brandState.brandRepPhone?.replace(/-/g, ''),
				contact_title: brandState.brandRepTitle,
				haveABroker: brandState.haveABroker || false,
				brokers: brokers,
				sample_name: brandState.productRepName,
				sample_email: brandState.productRepEmail,
				sample_phone: brandState.productRepPhone?.replace(/-/g, ''),
				sample_title: brandState.productRepTitle
			});
		}
	}, [brandState, isEditing]);
	return (
		<div className='contact-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				isValid={isValid}
				onUpdate={() => submitForm()}
				titleText='Contact Information for Retailers'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
				profileState={profileState}></TitleForms>
			{ID === profileState.sectionId && profileState.loading ? (
				<LoadingBobComponent></LoadingBobComponent>
			) : (
				<>
					{isVisible &&
						(isEditing !== ID ? (
							<>
								<ContainerForms
									FormComponent1={
										<>
											{' '}
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepName}></LabelForms>
												<p
													className={brandState?.brandRepName ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandRepName}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepEmail}></LabelForms>
												<p
													className={brandState?.brandRepEmail ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandRepEmail}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepPhone}></LabelForms>
												<p
													className={brandState?.brandRepPhone ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandRepPhone}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepTitle}></LabelForms>
												<p
													className={brandState?.brandRepTitle ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandRepTitle}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepName}></LabelForms>
												<p
													className={brandState?.productRepName ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.productRepName}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepEmail}></LabelForms>
												<p
													className={brandState?.productRepEmail ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.productRepEmail}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepPhone}></LabelForms>
												<p
													className={brandState?.productRepPhone ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.productRepPhone}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepTitle}></LabelForms>
												<p
													className={brandState?.productRepTitle ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.productRepTitle}
												</p>
											</div>
										</>
									}
									classColumn2='brokers'
									FormComponent2={
										<>
											{' '}
											<div>
												<LabelForms text={DEFAULT_LABELS.haveABroker}></LabelForms>

												<ButtonComponent
													onClick={() => toggleEditing(ID)}
													style='rounded-button tertiary toggle-button'
													text=''
													icon={
														<i
															className={
																brandState.haveABroker ? 'ri-toggle-fill' : 'ri-toggle-line'
															}
														/>
													}
												/>
											</div>
											{brandState.haveABroker ? (
												brandState.brokers?.map((element, index) => (
													<div className='broker-fields' key={index}>
														<div>
															<LabelForms text={`Broker ${index + 1} Name`}></LabelForms>
															<p onClick={() => toggleEditing(ID)}>{element.name}</p>
														</div>
														<div>
															<LabelForms text={`Broker ${index + 1} Phone Number`}></LabelForms>
															<p onClick={() => toggleEditing(ID)}>{element.phone}</p>
														</div>
														<div>
															<LabelForms text={`Broker ${index + 1} Email`}></LabelForms>
															<p onClick={() => toggleEditing(ID)}>{element.email}</p>
														</div>
													</div>
												))
											) : (
												<></>
											)}
										</>
									}></ContainerForms>
							</>
						) : (
							<>
								<ContainerForms
									FormComponent1={
										<>
											{' '}
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepName}></LabelForms>
												<TextInputComponent
													error={errors.contact_name?.length! > 0}
													errorHelperText={<div>{errors.contact_name}</div>}
													placeholder='Brand Contact Name'
													name='contact_name'
													onChange={handleChange}
													value={values.contact_name!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepEmail}></LabelForms>
												<TextInputComponent
													error={errors.contact_email?.length! > 0}
													errorHelperText={<div>{errors.contact_email}</div>}
													placeholder={DEFAULT_LABELS.brandRepEmail}
													name='contact_email'
													onChange={handleChange}
													value={values.contact_email!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepPhone}></LabelForms>
												<TextInputComponent
													error={errors.contact_phone?.length! > 0}
													errorHelperText={<div>{errors.contact_phone}</div>}
													placeholder={DEFAULT_LABELS.brandRepPhone}
													name='contact_phone'
													onChange={handleChange}
													value={values.contact_phone!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandRepTitle}></LabelForms>
												<TextInputComponent
													error={errors.contact_title?.length! > 0}
													errorHelperText={<div>{errors.contact_title}</div>}
													placeholder={DEFAULT_LABELS.brandRepTitle}
													name='contact_title'
													onChange={handleChange}
													value={values.contact_title!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepName}></LabelForms>
												<TextInputComponent
													error={errors.sample_name?.length! > 0}
													errorHelperText={<div>{errors.sample_name}</div>}
													placeholder={DEFAULT_LABELS.productRepName}
													name='sample_name'
													onChange={handleChange}
													value={values.sample_name!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepEmail}></LabelForms>
												<TextInputComponent
													error={errors.sample_email?.length! > 0}
													errorHelperText={<div>{errors.sample_email}</div>}
													placeholder={DEFAULT_LABELS.productRepEmail}
													name='sample_email'
													onChange={handleChange}
													value={values.sample_email!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepPhone}></LabelForms>
												<TextInputComponent
													error={errors.sample_phone?.length! > 0}
													errorHelperText={<div>{errors.sample_phone}</div>}
													placeholder={DEFAULT_LABELS.productRepPhone}
													name='sample_phone'
													onChange={handleChange}
													value={values.sample_phone!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.productRepTitle}></LabelForms>
												<TextInputComponent
													error={errors.sample_title?.length! > 0}
													errorHelperText={<div>{errors.sample_title}</div>}
													placeholder={DEFAULT_LABELS.productRepTitle}
													name='sample_title'
													onChange={handleChange}
													value={values.sample_title!}></TextInputComponent>
											</div>
										</>
									}
									classColumn2='brokers'
									FormComponent2={
										<>
											{' '}
											<div>
												<LabelForms text={DEFAULT_LABELS.haveABroker}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='haveABroker'
													value={values.haveABroker}
													disabled={false}
													onChange={handleChange}></CheckboxFormComponent>
											</div>
											{values.haveABroker ? (
												values.brokers!.map((element, index) => (
													<div className='broker-fields' key={index}>
														<div>
															<LabelForms text={`Broker ${index + 1} Name`}></LabelForms>
															<TextInputComponent
																error={getIn(errors, `brokers[${index}].name`) !== undefined}
																errorHelperText={
																	<div>{getIn(errors, `brokers[${index}].name`)}</div>
																}
																placeholder={index === 0 ? 'Full Name' : `Optional`}
																name={`brokers.${index}.name`}
																onChange={handleChange}
																value={element.name}></TextInputComponent>
														</div>
														<div>
															<LabelForms text={`Broker ${index + 1} Phone Number`}></LabelForms>
															<TextInputComponent
																error={getIn(errors, `brokers[${index}].phone`) !== undefined}
																errorHelperText={
																	<div>{getIn(errors, `brokers[${index}].phone`)}</div>
																}
																placeholder={index === 0 ? '888-123-4567' : `Optional`}
																name={`brokers.${index}.phone`}
																onChange={handleChange}
																value={element.phone}></TextInputComponent>
														</div>
														<div>
															<LabelForms text={`Broker ${index + 1} Email`}></LabelForms>
															<TextInputComponent
																error={getIn(errors, `brokers[${index}].email`) !== undefined}
																errorHelperText={
																	<div>{getIn(errors, `brokers[${index}].email`)}</div>
																}
																placeholder={index === 0 ? 'example@example.com' : `Optional`}
																name={`brokers.${index}.email`}
																onChange={handleChange}
																value={element.email}></TextInputComponent>
														</div>
													</div>
												))
											) : (
												<></>
											)}
										</>
									}></ContainerForms>
								<>
									<ActionsForms
										onUpdate={() => submitForm()}
										onCancel={() => {
											onCancel();
											handleReset(null);
										}}
										isValid={isValid}
										toggleEditing={() => toggleEditing(null)}></ActionsForms>
								</>
							</>
						))}
				</>
			)}
		</div>
	);
};

export default React.memo(ContactFormComponent);
