import { useEffect, useState } from 'react';
import { ScreenType } from '../../../../common/view-model';

export const useCertificationsPerScreen = (initial: number, screenType?: ScreenType) => {
	const [certificationsPerScreen, setCertificationsPerScreen] = useState<number>(initial);
	useEffect(() => {
		switch (screenType) {
			case ScreenType.LARGE_DESKTOP:
				window.innerWidth > 1750 ? setCertificationsPerScreen(5) : setCertificationsPerScreen(4);
				break;
			case ScreenType.MEDIUM_DESKTOP:
				window.innerWidth > 1395 ? setCertificationsPerScreen(4) : setCertificationsPerScreen(3);
				break;
			case ScreenType.SMALL_DESKTOP:
				setCertificationsPerScreen(3);
				break;
			default:
				setCertificationsPerScreen(initial);
				break;
		}
	}, [initial, screenType, window.innerWidth]);
	return { certificationsPerScreen };
};
