import { FC, useEffect, useState } from 'react';
import './BrandOnboardTitle.scss';
import { BrandOnboardTitleModel as model } from './model';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
import { useWindowSize } from '../../../hooks/useWindowSize';

interface IBrandOnboardTitleProps {
	featuredProductLink: string;
	featuredBrandLink: string;
}
export const BrandOnboardTitle: FC<IBrandOnboardTitleProps> = ({
	featuredBrandLink,
	featuredProductLink
}) => {
	const mobileBreakpoint = 744;
	const { width } = useWindowSize();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	useEffect(() => {
		setIsMobile(width < mobileBreakpoint);
	}, [width]);
	return (
		<div className='onboard-title-container'>
			<div
				className={
					process.env.REACT_APP_USE_NEW_PROFILE_VIEW === 'true'
						? 'new-title-container'
						: 'title-container'
				}>
				<span className='bold-title'>{model.boldText}</span>{' '}
				<span className='title'>{model.title}</span>
			</div>
			<div className='buttons-container'>
				<a href={featuredProductLink} target='_blank'>
					<ButtonComponent
						style='rounded-button primary'
						text={isMobile ? model.productsFeaturedTextMobile : model.productsFeaturedText}
						onClick={() => {}}
						icon={<i className='ri-external-link-line'></i>}
						preventDefault={false}
					/>
				</a>
				<a href={featuredBrandLink} target='_blank'>
					<ButtonComponent
						style='rounded-button primary'
						text={isMobile ? model.brandFeaturedTextMobile : model.brandFeaturedText}
						onClick={() => {}}
						icon={<i className='ri-external-link-line'></i>}
						preventDefault={false}
					/>
				</a>
			</div>
		</div>
	);
};
