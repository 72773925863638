import { gql } from 'graphql.macro';

export const GET_HIGHLIGHTED_BRANDS = gql`
	query {
		brandsHighlighted {
			id
			brand_story
			name
			url_logo
		}
	}
`;
