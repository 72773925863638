export interface Base {
	id?: string;
	name?: string;
	description?: string;
	isActive?: string;
	createdOn?: string;
	updatedOn?: string;
	toolTip?: ToolTip;
}

export interface Brand extends Base {
	boothNumber?: string;
	brand_attributes?: string;
	brand_country?: string;
	brand_facebook?: string;
	brand_foundation_year?: string;
	brand_instagram?: string;
	brand_linkedin?: string;
	brand_mission?: string;
	brand_state?: string;
	brand_story?: string;
	brand_tiktok?: string;
	brand_twitter?: string;
	brand_video?: string;
	brand_website?: string;
	brand_youtube?: string;
	brand_zip?: string;
	brokers?: Broker[];
	contact_email?: string;
	contact_name?: string;
	contact_phone?: string;
	contact_title?: string;
	current_retail_channels?: string;
	distributors?: string;
	holding_company?: string;
	how_its_made?: string;
	hq_city?: string;
	hq_country?: string;
	hq_state?: string;
	map_policy?: boolean;
	parent_company?: string;
	processingTechniques: ProcessingTechnique[];
	products?: Product[];
	public_url?: string;
	regionsBydistributor: RegionsBydistributor[];
	taxonomy?: Taxonomy;
	url_logo?: string;
	uses_private_label?: boolean;
}

export interface Dimensions {
	height?: string;
	width?: string;
	depth?: string;
	weight?: string;
}

export interface Product extends Base {
	sku?: number;
	upc?: number;
	gtin?: number;
	imageUrl: string;
	images?: Image[];
	netContent: string;
	distributor: Distributor;
	brand: Brand;
	howGoodScore?: HowGoodScore;
	taxonomy: Taxonomy[];
	ingredients: Ingredient[];
	attributes: Attribute[];
	region: Region;
	allergens: string[];
	sku_upc_gtin?: string;
	dimensions?: Dimensions;
	certifications: Certification[];
	ingredientsList?: string[];
	manufacturerSuggestedRetailPrice?: string;
	launchYear?: string;
	marketReady?: boolean;
	shelfLife?: number;
	spinsMetrics?: SpinMetric;
	isNextyFinalist?: boolean;
	isNotForSaleInUS?: boolean;
}

export interface Distributor extends Base {}

export interface Taxonomy extends Base {
	parent?: Taxonomy;
}

export interface Ingredient extends Base {
	taxonomy?: Taxonomy;
}

export interface Attribute extends Base {
	climateFriendly?: boolean;
	regenerative?: boolean;
	plantBasedMinimally?: boolean;
	processed?: boolean;
	cleanLabelGrassFed?: boolean;
	noSyntheticIngredients?: boolean;
	noArtificialFlavors?: boolean;
	isTopAttribute?: boolean;
}
export interface Region extends Base {}

export interface Certification extends Base {
	imageUrl: string;
	certifiers: Certifier[];
}
export interface Allergen extends Base {}
export interface ConsumerDiet extends Base {}
export interface Packaging extends Base {}
export interface AnimalWelfare extends Base {}
export interface Climate extends Base {}
export interface BusinessPractice extends Base {}
export interface ClaimIngredients extends Base {}
export interface ToolTip {
	description: string;
	learnMore?: string;
	isPoweredByHowGood: boolean;
}
export interface Image {
	side: string;
	mainUrl: string;
	thumbnailUrl: string;
}

export enum SortKeys {
	FavoriteProducts = 'favoriteProducts',
	HowGoodScore = 'howGoodScore',
	ASC = 'asc',
	DES = 'des'
}

export interface Certifier extends Base {
	learnMore: string;
}

export interface CompanyDetails {
	name: string;
	website: string;
	userType: string;
	jobType: string;
	isEditing: boolean;
}

export interface HowGoodScore {
	score: number;
	show_howgood_score: boolean;
	category_average?: number;
	source_retail_raiting?: string;
}

export enum UserType {
	Brand = 'Brand',
	Retailer = 'Retailer'
}
export interface RegionsBydistributor {
	distributor_id: string;
	distributor_name: string;
	region_id: string;
	region_name: string;
}
export interface ProcessingTechnique extends Base {}

export enum howGoodScoreRetailRaiting {
	Best = 'Best',
	Great = 'Great',
	Good = 'Good',
	StandardsNotMet = 'Standards Not Met'
}
export interface Broker extends Base {
	phone?: string;
	email?: string;
}
export interface SpinMetric {
	id: string;
	productId: string;
	dollars: string;
	dollarsPctChg: string;
	dollarSales: string;
	unitSales: string;
	productUnits: string;
	unitsPctChg: string;
	maxAcv: string;
	distribution: string;
	velocity: string;
	subcategoryMedian: number;
	rawScore: number;
}

export interface Events {
	id: string;
	name: string;
	startDate: string;
	endDate: string;
	floorMapUrl: string;
	exhibitorHubUrl: string;
	agendaUrl: string;
	exhibitorListUrl: string;
	label: string;
}
