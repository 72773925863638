import { OnboardInfoComponent } from '../OnboardInfo/OnboardInfoComponent';
import { DEFAULT_INFO_STATE as model } from './model';
import './InfoBeaconHub.scss';
const InfoContent = () => {
	return (
		<div className='info-text-container'>
			<span className='info-text'>{model.infoText}</span>{' '}
		</div>
	);
};

export const InfoBeaconComponent = () => {
	return <OnboardInfoComponent bigTitle title={model.infoTitle} children={<InfoContent />} />;
};
