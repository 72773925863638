const DEFAULT_STATE: IHighlightedProductsState = {
	highlightedProductsTitle: 'Sustainability Stand-Outs',
	highlightedProductsText1: 'These products have some of the highest',
	highlightedProductsText2: 'on Beacon Discovery.',
	highlightedProductsBoldText: 'HowGood Sustainability Impact Estimates'
};

interface IHighlightedProductsState {
	highlightedProductsTitle: string;
	highlightedProductsText1: string;
	highlightedProductsText2: string;
	highlightedProductsBoldText: string;
}
export const HighlightedProductsState = {
	...DEFAULT_STATE
};
