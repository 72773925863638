import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import './OnbordingComponent.scss';
import { StepperComponent } from '../UI/StepperComponent/StepperComponent';
import { WelcomeComponent } from './Welcome/WelcomeComponent';
import { StepperContent } from '../UI/StepperComponent/model';
import { AppActions } from '../../redux/AppActions';
import { FC, useEffect, useRef } from 'react';
import IngredientsTemplateComponent from './IngredientsTemplate/IngredientsTemplateComponent';
import { UserActions } from '../../redux/UserActions';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../hooks/useWindowSize';
import { RootState } from '../../redux/AppReducer';
import { ScreenType } from '../../common/view-model';
export interface IOnbordingComponentProps {
	setStandAloneLayout: (value: boolean) => void;
	saveUserIngredientsTemplateId: () => void;
	hasLoadIngredientsTemplates: boolean;
	screenType: ScreenType | undefined;
}
const OnbordingComponent: FC<IOnbordingComponentProps> = ({
	setStandAloneLayout,
	saveUserIngredientsTemplateId,
	hasLoadIngredientsTemplates,
	screenType
}) => {
	const saveContainerRef = useRef<HTMLDivElement>(null);
	const FULL_SIZE_SCREEN_WIDTH = 743;
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const footerTitle = 'You can always change this configuration in your profile later.';
	const onBordingSteps: StepperContent[] = [
		{
			label: 'Welcome',
			content: (
				<WelcomeComponent
					isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
				/>
			),
			continueBtnLabel: 'Get Started',
			showFooter: true,
			showArrowIcon: screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH
		},
		{
			label: 'Ingredients',
			content: <IngredientsTemplateComponent saveContainerRef={saveContainerRef} />,
			continueBtnLabel: 'Save & Continue',
			previousBtnLabel: 'Back',
			continueBtnFunction: () => {
				saveUserIngredientsTemplateId();
				navigate('/landing');
			},
			showFooter: hasLoadIngredientsTemplates
		}
	];
	useEffect(() => {
		setStandAloneLayout(true);
	}, []);
	return (
		<div data-testid='onbording-component' className='stepper-container'>
			<StepperComponent
				stepperContent={onBordingSteps}
				footerTitle={footerTitle}
				saveContainerRef={saveContainerRef}
			/>
		</div>
	);
};

const mapStateToProps = ({ appState }: RootState) => ({
	hasLoadIngredientsTemplates:
		(appState?.storeState?.templates && appState?.storeState?.templates.length > 0) || false,
	screenType: appState.deviceScreen
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setStandAloneLayout: (standAloneLayout: boolean) =>
		dispatch(AppActions.setStandAloneLayout(standAloneLayout)),
	saveUserIngredientsTemplateId: () =>
		dispatch({ type: UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID })
});

export default connect(mapStateToProps, mapDispatchToProps)(OnbordingComponent);
