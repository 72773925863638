import { Dispatch, FC, Fragment, useState, useEffect } from 'react';
import { RootState } from '../../../redux/AppReducer';
import './OnboardingTemplateCards.scss';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { IngredientVM, IngredientsTemplate, ScreenType } from '../../../common/view-model';
import { OnboardingTemplateCardsState as model } from './model';
import { TemplateCardComponent } from '../IngredientsTemplate/IngredientsTemplateCard/IngredientsTemplateCardComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Swiper as ISwiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getIngredientsLength } from '../../../helpers/ingredientTemplateHelpers';
import exIcon from '../../../assets/images/onboarding/ex-icon.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
interface IOnboardingTemplateCardProps extends IngredientsTemplate {
	onMoveToTab: (index: number) => void;
	index: number;
	templates: IngredientsTemplate[];
	showDetailedList: boolean;
	setShowDetailedList: (showDetailedList: boolean) => void;
	onClick: () => void;
}
const OnboardingTemplateCard: FC<IOnboardingTemplateCardProps> = ({
	id,
	name,
	purpose,
	color,
	description,
	isCurrent,
	templates,
	setShowDetailedList,
	onClick
}) => {
	let ingredients: IngredientVM[] = [];
	purpose.forEach(purpose => {
		ingredients = [...ingredients, ...purpose.ingredients];
	});
	const isDefaultTemplate = id === '1';
	return (
		<div
			className={`onboarding-card ${isCurrent ? 'active blue' : ''}${
				isDefaultTemplate ? ' default-card' : ''
			}`}
			onClick={onClick}
		>
			{isDefaultTemplate && (
				<div className='default-template-label-container'>
					<span className='default-template-label'>{model.defaultTemplateLabel}</span>
				</div>
			)}
			<div className='card-title-container'>
				<div className={`template-card-title ${isCurrent ? 'blue' : ''}`}>{name}</div>
			</div>
			<div className='banned-container'>
				<span className='banned-ingredients-number'>{getIngredientsLength(id, templates)}</span>
				<span className='banned-ingredients-label'>{model.bannedIngredientsLabel}</span>
			</div>
			<div className='description-container'>
				{isDefaultTemplate ? (
					<span className='all-products-text'>
						{model.allProductsLabel}
						<span className='description'>{description}</span>
					</span>
				) : (
					<span className='description'>{description}</span>
				)}
			</div>
			<div className='template-list-container'>
				<div className='top-banned-container'>
					<span className='top-banned-text'>{model.topBannedIngredientsLabel}</span>
				</div>
				<div className='list-container'>
					{model.getStaticTopIngredients(id).map((ingredient, index) => {
						return (
							<Fragment key={index}>
								<div className='list-item'>
									<img className='ex-icon' src={exIcon} alt='ex' />
									<span className='ingredient'>{ingredient}</span>
								</div>
							</Fragment>
						);
					})}
				</div>
				<div className='show-more-container'>
					<span
						className={`show-more ${isCurrent ? 'blue' : ''}`}
						onClick={() => setShowDetailedList(true)}
					>
						{model.seeFullListLabel}
					</span>
				</div>
			</div>
		</div>
	);
};
interface IOnboardingTemplateCardsProps {
	templates: IngredientsTemplate[] | undefined;
	currentTab: number;
	moveToTab: (index: number) => void;
	showDetailedList: boolean;
	setShowDetailedList: (showDetailedList: boolean) => void;
	screenType: ScreenType | undefined;
}

const OnboardingTemplateCards: FC<IOnboardingTemplateCardsProps> = ({
	moveToTab,
	templates,
	currentTab,
	showDetailedList,
	setShowDetailedList,
	screenType
}) => {
	const [swiperInstance, setSwiperInstance] = useState<ISwiper | null>(null);
	const { width } = useWindowSize();
	const mobileLimitWidth = 743;
	useEffect(() => {
		if (swiperInstance) {
			swiperInstance.slideTo(currentTab - 1, 0);
		}
	}, [currentTab, swiperInstance]);
	if (screenType === ScreenType.MOBILE || width === mobileLimitWidth) {
		return (
			<div className='slider-template-cards'>
				<Swiper
					onSwiper={setSwiperInstance}
					onSlideChange={swiper => moveToTab(swiper.activeIndex + 1)}
					pagination={{
						clickable: true
					}}
					slidesPerView={'auto'}
					spaceBetween={24}
					modules={[Pagination]}
				>
					{templates?.map((card, index: number) => (
						<SwiperSlide key={index}>
							<OnboardingTemplateCard
								id={card.id}
								name={card.name}
								description={card.description}
								purpose={card.purpose}
								isCurrent={card.id === currentTab.toString()}
								onMoveToTab={moveToTab}
								index={index}
								templates={templates}
								showDetailedList={showDetailedList}
								setShowDetailedList={setShowDetailedList}
								onClick={() => moveToTab(index + 1)}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		);
	}
	return (
		<div className='onboarding-template-cards-contianer'>
			{templates?.map((card, index: number) => (
				<div key={index}>
					<TemplateCardComponent
						id={card.id}
						name={card.name}
						description={card.description}
						purpose={card.purpose}
						isCurrent={card.id === currentTab.toString()}
						onMoveToTab={moveToTab}
						index={index}
						templates={templates}
						showDetailedList={showDetailedList}
						setShowDetailedList={setShowDetailedList}
						onClick={() => moveToTab(index + 1)}
					/>
				</div>
			))}
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	screenType: state.deviceScreen
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTemplateCards);
