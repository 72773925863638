import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: IBeaconOnlyBrandState = {
	title: 'Get the Support You Need for Onboarding!',
	headerEmailLink: newHopeAudienceEmail,
	headerPrevText:
		'Allow us to upload your product data for you by giving consent in the Standards Form in Step 1 or emailing',
	headerNextText:
		'with "I’d like assistance with uploading my product data" after booking your booth.',
	firstCardHeaderText: 'Complete the Standards form',
	firstCardHeaderTextLink: 'here',
	firstCardHeaderLink: 'https://standards.newhope.com/beacon-only/',
	firstCardContentText:
		'Check ‘Yes’ on the consent page to get product upload support for your onboarding.',

	thirdCardPrevText:
		'Create an account for New Hope Network’s Product Portal offered by Pinto/SPINS',
	thirdCardLinkText: 'here',
	thirdCardContentText:
		'Once your account is created, you will receive your login credentials via email.',
	thirdCardLink: 'https://www.spins.com/pi-manager/signup-request/?partnerName=new-hope-network ',

	fourthCardPrevText: 'Upload product data into Product Portal for review',
	fourthCardLinkText: 'here',
	fourthCardLink:
		'https://standards.newhope.com/wp-content/uploads/2024/09/NHN_Guide_Updated_SEPT_2024-4.pdf',
	fourthCardSecondLink:
		'https://survey.alchemer.com/s3/7555625/NHN-Consents-to-Onboard-with-SPINS-Pinto',
	fourthCardContentBoldText: 'If you would like to try the upload yourself,',
	fourthCardContentBoldTextAfter: 'are the instructions.',
	fourthCardContentPrev: 'Give consent in the Standards form',
	fourthCardEmail: newHopeAudienceEmail,
	fourthCardContentFormAfterText: 'then email',
	fourthCardContentPostText: 'and say “I want New Hope to upload product data for me.”',
	fourthCardContentProductsUploadText: 'Let us upload your product data for you!  ',

	fiveCardHeaderText: 'See your products on Beacon Discovery',
	fiveCardContentText:
		'Once approved by our Standards team, your brand can be discovered by retailers on Beacon immediately!'
};

interface IBeaconOnlyBrandState {
	title: string;
	headerPrevText: string;
	headerNextText: string;
	headerEmailLink: string;

	firstCardHeaderText: string;
	firstCardHeaderTextLink: string;
	firstCardHeaderLink: string;
	firstCardContentText: string;

	thirdCardPrevText: string;
	thirdCardLinkText: string;
	thirdCardLink: string;
	thirdCardContentText: string;

	fourthCardPrevText: string;
	fourthCardLinkText: string;
	fourthCardLink: string;
	fourthCardSecondLink: string;
	fourthCardContentPrev: string;
	fourthCardContentPostText: string;
	fourthCardContentBoldText: string;
	fourthCardContentBoldTextAfter: string;
	fourthCardContentProductsUploadText: string;
	fourthCardContentFormAfterText: string;
	fourthCardEmail: string;

	fiveCardHeaderText: string;
	fiveCardContentText: string;
}

export const BeaconOnlyBrandModel = {
	...DEFAULT_STATE
};
