const DEFAULT_STATE: IHowGood = {
	good: {
		title: 'Good',
		text: '= better than 70% of food products assessed by HowGood'
	},
	great: {
		title: 'Great',
		text: '= better than 85% of food products assessed by HowGood'
	},
	best: {
		title: 'Best',
		text: '= better than 95% of food products assessed by HowGood'
	},
	getPercentage: (range: string): number => {
		switch (range) {
			case 'Good':
				return 70;
			case 'Great':
				return 85;
			case 'Best':
				return 95;
			default:
				return 0;
		}
	},
	getHgText: (percentage: number): string => {
		return percentage > 0
			? `better than ${percentage}% of food products`
			: 'Estimates are based on products in the HowGood database.';
	},
	hgPrevText: 'This product has an environmental and social impact ',
	hgNextText: 'assessed by HowGood. Estimates are based on products in the HowGood database.',
	estimateText: 'Product Estimate',
	categoryAverageText: 'Category Average',
	versusText: 'VS'
};

interface ITitleText {
	title: string;
	text: string;
}

export interface IHowGood {
	good: ITitleText;
	great: ITitleText;
	best: ITitleText;
	getPercentage: (range: string) => number;
	getHgText: (percentage: number) => string;
	hgPrevText: string;
	hgNextText: string;
	estimateText: string;
	categoryAverageText: string;
	versusText: string;
}

export const howGoodState = {
	...DEFAULT_STATE
};
