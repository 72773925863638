import { gql } from 'graphql.macro';

export const GET_FILTERS = gql`
	query GetFilters($templateId: String) {
		filters(templateId: $templateId) {
			climates {
				id
				name
				isTopAttribute
				toolTip {
					description
					learnMore
					isPoweredByHowGood
				}
			}
			regions {
				id
				name
			}
			distributors {
				id
				name
			}
			packagings {
				id
				name
				isTopAttribute
				toolTip {
					description
					learnMore
					isPoweredByHowGood
				}
			}
			taxonomyFilters {
				id
				name
				isChecked
				type
				children {
					id
					name
					isChecked
					type
					children {
						id
						name
						isChecked
						type
						children {
							id
							name
							isChecked
							type
						}
					}
				}
			}
			certifications {
				id
				name
				isTopAttribute
				toolTip {
					description
					learnMore
					isPoweredByHowGood
				}
			}
			claimingIngredients {
				id
				name
				isTopAttribute
				toolTip {
					description
					learnMore
					isPoweredByHowGood
				}
			}
			animalWalfares {
				id
				name
				isTopAttribute
				toolTip {
					description
					learnMore
					isPoweredByHowGood
				}
			}
			businessPractices {
				id
				name
				isTopAttribute
				toolTip {
					learnMore
					description
					isPoweredByHowGood
				}
			}
			consumerDiets {
				id
				name
				isTopAttribute
				toolTip {
					description
					learnMore
					isPoweredByHowGood
				}
			}
			allergens {
				id
				name
				isTopAttribute
				toolTip {
					learnMore
					description
					isPoweredByHowGood
				}
			}
			climates {
				id
				name
				isTopAttribute
				toolTip {
					description
					isPoweredByHowGood
					learnMore
				}
			}
			topAttributes {
				id
				name
				isTopAttribute
				toolTip {
					isPoweredByHowGood
					learnMore
					description
				}
			}
		}
	}
`;
