import { APP_NAME } from '../../../common/constants';

export interface IDropDowmCompanyOption {
	value: string;
	label: string;
}
const DEAFULT_STATE: ICompanyDetailsState = {
	modalTitleText: 'Company Account Profile',
	welcomeMessageText: `Welcome! To get the most out of your experience with ${APP_NAME}, please provide this information to create your Beacon profile.`,
	companyNameText: 'Company Name',
	companyWebSiteText: 'Company Website',
	companyPositionText: 'I Am A...',
	companyJobTypeText: 'Job Type',
	poweredByText: 'Powered By',
	irisText: 'IIRIS',
	buttonText: 'Next',
	jobTypeOptions: [
		{
			value: 'Buyer',
			label: 'Buyer'
		},
		{
			value: 'CategoryManager',
			label: 'Category Manager'
		},
		{
			value: 'HeadOfPurchasing',
			label: 'Head Of Purchasing'
		},
		{
			value: 'Founder/Owner',
			label: 'Founder/Owner'
		},
		{
			value: 'Marketing',
			label: 'Marketing'
		},
		{
			value: 'Sales',
			label: 'Sales'
		},
		{
			value: 'Associate',
			label: 'Associate'
		}
	]
};
interface ICompanyDetailsState {
	modalTitleText: string;
	welcomeMessageText: string;
	companyNameText: string;
	companyWebSiteText: string;
	companyPositionText: string;
	companyJobTypeText: string;
	poweredByText: string;
	irisText: string;
	buttonText: string;
	jobTypeOptions: IDropDowmCompanyOption[];
}
export const CompanyDetailsState = {
	...DEAFULT_STATE
};
