import { FC, useState } from 'react';
import { WelcomeComponentState } from './model';
import './WelcomeComponent.scss';
const welcomeImage = require('../../../assets/images/welcome-page.png');
interface IWelcomeComponentProps {
	isMobile: boolean;
}
export const WelcomeComponent: FC<IWelcomeComponentProps> = ({ isMobile }) => {
	const [state] = useState({ ...WelcomeComponentState });
	return (
		<div className='welcome-container'>
			<div className='welcome'>
				<span className='welcome-title'>{state.pageTitle}</span>
				<p className='meet-standars'>{state.meetStandarText}</p>
				<p className='standars-explanation'>{state.standarsExplanationText}</p>
			</div>
			<div className='welcome-img-container'>
				{!isMobile && <span className='step-text'>{state.stepText}</span>}
				<div className='img-container'>
					<img src={welcomeImage} alt='welcome' />
				</div>
			</div>
		</div>
	);
};
