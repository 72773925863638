import { Image as ProductImage } from '../common/model';

const validateImage = (imageUrl: string) => {
	return new Promise(resolve => {
		const img = new Image();
		img.onload = () => {
			resolve(true);
		};
		img.onerror = () => {
			resolve(false);
		};
		img.src = imageUrl;
	});
};
export async function validateImageArray(images: ProductImage[]) {
	const validatedArray = await Promise.all(
		images.map(async image => {
			const canLoad = await validateImage(image.thumbnailUrl);
			return { ...image, canLoad };
		})
	);
	return validatedArray;
}
