import { FilterLevel, FilterType, FilterVM } from '../common/view-model';
import { whastNewFilter } from '../mock/whatIsNewFilter';
import {
	flatBasedLevelFilters,
	flatFirstLevelFilters,
	flatSecondLevelFilters,
	flatThirdLevelChildren
} from './urlParametersHelpers';

export const buildBreadCrumb = (elements?: any[]) => {
	return elements?.reduce((acc, item) => {
		const levels = Object.keys(item);
		const subKey = levels[0];
		acc[subKey] = { ...item[subKey] };
		return acc;
	}, {});
};
export const buildBreadCrumbForDeepFilter = (filter: FilterVM, categories: FilterVM[]) => {
	const secondLevelFilters = flatSecondLevelFilters(categories);
	const firstLevelFilters = flatFirstLevelFilters(categories);
	const thirdLevelFilters = flatThirdLevelChildren(categories);
	const flatElements = flatBasedLevelFilters(firstLevelFilters);
	if (
		filter.type === FilterType.CATEGORY ||
		filter.type === FilterType.WHATS_NEW ||
		filter.type === FilterType.NEW_TO_BEACON ||
		filter.type === FilterType.NEW_TO_MARKET ||
		filter.type === FilterType.NEXTY_FINALISTS
	) {
		if (filter.type === FilterType.WHATS_NEW) {
			return [{ firstLevel: { ...filter } }];
		}
		const { children, ...rest } = whastNewFilter;
		if (
			filter.type === FilterType.NEW_TO_BEACON ||
			filter.type === FilterType.NEW_TO_MARKET ||
			filter.type === FilterType.NEXTY_FINALISTS
		) {
			return [{ firstLevel: { ...rest } }, { secondLevel: { ...filter } }];
		}
		switch (filter.level) {
			case FilterLevel.THIRD:
				const firstLevelCategory = firstLevelFilters.find((x: FilterVM) => x.id === filter.id);
				const secondLevelFilter = thirdLevelFilters.find(
					(x: FilterVM) => x.id === firstLevelCategory?.parentId
				);
				let firstLevel = {};
				if (firstLevelCategory) {
					categories.flatMap((child: FilterVM) => {
						child.children?.forEach((subChild: FilterVM) => {
							if (subChild.id === secondLevelFilter?.parentId) {
								firstLevel = child;
							}
						});
					});
				}
				return [
					{ firstLevel },
					{ secondLevel: secondLevelFilter },
					{ thirdLevel: firstLevelCategory }
				];
			case FilterLevel.FOURTH:
				const flatLevelCategory = flatElements.find((x: FilterVM) => x.id === filter.id);
				if (flatLevelCategory) {
					const parentCategory = firstLevelFilters.find(x => x.id === flatLevelCategory?.parentId);
					const firstLevelCategory = firstLevelFilters.find(
						(x: FilterVM) => x.id === flatLevelCategory.parentId
					);
					const secondLevel = thirdLevelFilters.find(x => x.id === firstLevelCategory?.parentId);
					let firstLevel = {};
					if (secondLevel) {
						categories.flatMap((child: FilterVM) => {
							child.children?.forEach((subChild: FilterVM) => {
								if (subChild.id === secondLevel?.parentId) {
									firstLevel = child;
								}
							});
						});
					}
					return [
						{ firstLevel: firstLevel },
						{ secondLevel: secondLevel },
						{ thirdLevel: { ...parentCategory } },
						{ fourthLevel: flatLevelCategory }
					];
				}
				break;
			default:
				return [];
		}
	} else {
		return [];
	}
};
