import {
	FilterType,
	PintoFilters,
	FilterVM,
	FilterQueryVM,
	GenericFilterKey,
	FilterLevel
} from '../../../common/view-model';
import { Product } from '../../../common/model';
import { ICheckBoxState } from '../../UI/CheckboxComponent/model';
import { mockFilters } from '../../../mock/filters';

const DEFAULT_STATE: IFilterState = {
	multifilterItems: [],
	multiFilterHelper: (items, item) => {
		items.push(item);
	}
};

interface IFilterState {
	multifilterItems: PintoFilters[];
	multiFilterHelper: (items: PintoFilters[], item: PintoFilters) => void;
}
export const FilterState = {
	...DEFAULT_STATE
};
export interface ISelectedFilterState {
	parentIndex?: string;
	state?: ICheckBoxState;
	setState?: Function;
}
export const mapFiltersToQuery = (filters: FilterVM[]): FilterQueryVM[] => {
	let queryFilters: FilterQueryVM[] = [
		{
			key: GenericFilterKey.Regions,
			ids: []
		},
		{
			key: GenericFilterKey.Distributor,
			ids: []
		},
		{
			key: GenericFilterKey.Taxonomy,
			ids: []
		},
		{
			key: GenericFilterKey.Certifications,
			ids: []
		},
		{
			key: GenericFilterKey.Allergens,
			ids: []
		},
		{
			key: GenericFilterKey.ConsumerDiet,
			ids: []
		},
		{
			key: GenericFilterKey.Packaging,
			ids: []
		},
		{
			key: GenericFilterKey.Climate,
			ids: []
		},
		{
			key: GenericFilterKey.AnimalWelfare,
			ids: []
		},
		{
			key: GenericFilterKey.BussinessPractices,
			ids: []
		},
		{
			key: GenericFilterKey.Attributes,
			ids: []
		},
		{
			key: GenericFilterKey.Ingredients,
			ids: []
		},
		{
			key: GenericFilterKey.Brand,
			ids: []
		}
	];
	if (filters && filters.length > 0) {
		filters.forEach((filter: FilterVM) => {
			switch (filter.type) {
				case FilterType.DISTRIBUTOR:
					queryFilters.find(f => f.key === GenericFilterKey.Distributor)!.ids.push(filter.id!);
					break;
				case FilterType.REGION:
					queryFilters.find(f => f.key === GenericFilterKey.Regions)!.ids.push(filter.id!);
					break;
				case FilterType.CERTIFICATION:
					queryFilters.find(f => f.key === GenericFilterKey.Certifications)!.ids.push(filter.id!);
					break;
				case FilterType.ALLERGENS:
					queryFilters.find(f => f.key === GenericFilterKey.Allergens)!.ids.push(filter.id!);
					break;
				case FilterType.CONSUMER_DIETS:
					queryFilters.find(f => f.key === GenericFilterKey.ConsumerDiet)!.ids.push(filter.id!);
					break;
				case FilterType.PACKAGING:
					queryFilters.find(f => f.key === GenericFilterKey.Packaging)!.ids.push(filter.id!);
					break;
				case FilterType.CLIMATE:
					queryFilters.find(f => f.key === GenericFilterKey.Climate)!.ids.push(filter.id!);
					break;
				case FilterType.ANIMAL_WELFARE:
					queryFilters.find(f => f.key === GenericFilterKey.AnimalWelfare)!.ids.push(filter.id!);
					break;
				case FilterType.BUSINESS_PRACTICES:
					queryFilters
						.find(f => f.key === GenericFilterKey.BussinessPractices)!
						.ids.push(filter.id!);
					break;
				case FilterType.ATTRIBUTES:
					queryFilters.find(f => f.key === GenericFilterKey.Attributes)!.ids.push(filter.id!);
					break;
				case FilterType.INGREDIENTS:
					queryFilters.find(f => f.key === GenericFilterKey.Ingredients)!.ids.push(filter.id!);
					break;
				case FilterType.CATEGORY:
					if (!filter.isSuperLevelFilter) {
						const superCategory = filters.find(f => f.isSuperLevelFilter);
						if (superCategory) {
							queryFilters.find(f => f.key === GenericFilterKey.Taxonomy)!.ids = queryFilters
								.find(f => f.key === GenericFilterKey.Taxonomy)!
								.ids.filter(x => x !== superCategory.id);
						}
					}
					if (filter.level === FilterLevel.THIRD) {
						const secondLevelFilter = filters.filter(f => f.level === FilterLevel.SECOND);
						queryFilters.find(f => f.key === GenericFilterKey.Taxonomy)!.ids = queryFilters
							.find(f => f.key === GenericFilterKey.Taxonomy)!
							.ids.filter(x => !secondLevelFilter.map(f => f.id).includes(x));
					}
					if (filter.level === FilterLevel.FOURTH) {
						const secondLevelFilter = filters.filter(f => f.level === FilterLevel.SECOND);
						queryFilters.find(f => f.key === GenericFilterKey.Taxonomy)!.ids = queryFilters
							.find(f => f.key === GenericFilterKey.Taxonomy)!
							.ids.filter(x => !secondLevelFilter.map(f => f.id).includes(x));

						const thirdLevelFilter = filters.filter(f => f.level === FilterLevel.THIRD);
						queryFilters.find(f => f.key === GenericFilterKey.Taxonomy)!.ids = queryFilters
							.find(f => f.key === GenericFilterKey.Taxonomy)!
							.ids.filter(x => !thirdLevelFilter.map(f => f.id).includes(x));
					}

					const ids = queryFilters.find(f => f.key === GenericFilterKey.Taxonomy)!.ids;
					if (!ids.includes(filter.id!)) {
						ids.push(filter.id!);
					}
					break;
				case FilterType.BRAND:
					queryFilters.find(f => f.key === GenericFilterKey.Brand)!.ids.push(filter.id!);
					break;
				default:
					break;
			}
		});
	}
	return queryFilters;
};
export const validateScroll = (id: string, open: boolean) => {
	const element = document.getElementById(id);
	return open && element && element.scrollHeight > 520;
};
export const handleToolTipClick = (id: string, iconId: string) => {
	const iconElement = document.getElementById(iconId);
	const element = document.getElementById(`filter-tooltip${id}`);
	if (element) {
		if (element.classList.contains('show-tooltip')) {
			element.classList.remove('show-tooltip');
		} else {
			document.querySelectorAll('.show-tooltip').forEach(el => {
				el.classList.remove('show-tooltip');
			});
			element.classList.add('show-tooltip');
		}
	}
	if (iconElement) {
		if (iconElement.classList.contains('active')) {
			iconElement.classList.remove('active');
		} else {
			document.querySelectorAll('.tooltip-icon').forEach(el => {
				el.classList.remove('active');
			});
			iconElement.classList.add('active');
		}
	}
};
export const orderFiltersAlphabetically = (filters?: FilterVM[]) => {
	return filters?.sort((a: FilterVM, b: FilterVM) => {
		if (a?.name?.toLowerCase()! < b?.name?.toLowerCase()!) {
			return -1;
		}
		if (a?.name?.toLowerCase()! > b?.name?.toLowerCase()!) {
			return 1;
		}
		return 0;
	});
};
