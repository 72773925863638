import './index.scss';
import { RootState } from '../../redux/AppReducer';
import { connect, ConnectedProps } from 'react-redux';
import { FC, useEffect } from 'react';
import { ProgressComponent } from '../../components/UI/ProgressComponent/ProgressComponent';
import { AppActions } from '../../redux/AppActions';
import { UserActions } from '../../redux/UserActions';

const LandingPage: FC<LandingPageProps> = ({
	setStandAloneLayout,
	loadProfileProgress,
	startLoadingProgress,
	setLoadProfileProgress,
	cancellInterval,
	showCurrentSessionToast
}) => {
	useEffect(() => {
		startLoadingProgress();
		setStandAloneLayout(true);
		return () => {
			setStandAloneLayout(false);
			setLoadProfileProgress(0);
			cancellInterval();
		};
	}, [setStandAloneLayout, startLoadingProgress, setLoadProfileProgress, cancellInterval]);
	return (
		<div className='loader-container'>
			<ProgressComponent progress={loadProfileProgress || 0} max={100} />
		</div>
	);
};
const mapStateToProps = ({ appState }: RootState) => ({
	loadProfileProgress: appState.loadProfileProgress
});
const mapDispatchToProps = {
	setStandAloneLayout: AppActions.setStandAloneLayout,
	startLoadingProgress: AppActions.startProgress,
	setLoadProfileProgress: AppActions.setLoadProfileProgress,
	cancellInterval: AppActions.intervalCancelation,
	showCurrentSessionToast: UserActions.currentSessionDetected
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type LandingPageProps = ConnectedProps<typeof connector>;
export default connector(LandingPage);
