import { ProductDetailComponent } from '../../components/product-detail';
import { useParams } from 'react-router-dom';
import { Product } from '../../common/model';
import { connect } from 'react-redux';
import { AppState, ErrorState } from './../../redux/AppReducer';
import { Dispatch, FC, useEffect, useState } from 'react';
import { GET_PRODUCT } from '../../graphql/queries/product';
import { useQuery } from '@apollo/client';
import { validateImageArray } from '../../helpers/validateImagesCanLoad';
import { Action } from 'redux';
import { AppActions } from '../../redux/AppActions';
import { serverError } from '../../common/constants';
import { UserActions } from '../../redux/UserActions';
import { SearchActions } from '../../redux/search/SearchActions';

const ProductDetail: FC = (props: any) => {
	const { registerUserClickEvent, seHasClickedOutsideSearch } = props;
	const { id } = useParams();
	const [product, setProduct] = useState<Product | undefined>();
	const currentEvent = props.appState.storeState?.getCurrentEvent;
	const { data, loading, error } = useQuery<any>(GET_PRODUCT, {
		variables: { id, expoEventId: currentEvent?.id },
		skip: currentEvent === undefined
	});
	const [finishValidation, setFinishValidation] = useState(false);
	useEffect(() => {
		seHasClickedOutsideSearch(true);
		setTimeout(() => {
			seHasClickedOutsideSearch(false);
		}, 50);
	}, [seHasClickedOutsideSearch]);

	useEffect(() => {
		if (!error && !loading && data?.product) {
			const { __typename, ...rest } = data.product;
			setProduct(rest);
		}
		if (error?.message === 'Unauthorized') {
			props.handleUnauthorized();
			return;
		}
		if (error) {
			props.handleError(serverError);
		}
	}, [data, error, loading]);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		async function validateImages() {
			if (product && product.images && !finishValidation) {
				await validateImageArray(product.images).then(res => {
					setProduct({ ...product, images: res });
					setFinishValidation(true);
				});
			}
		}
		validateImages();
	}, [product, finishValidation]);

	if (loading || !finishValidation) {
		return null;
	}

	return (
		<ProductDetailComponent
			product={product}
			deviceScreen={props.appState.deviceScreen}
			registerUserClickEvent={registerUserClickEvent}
		/>
	);
};

const mapStateToProps = (state: AppState) => ({
	...state
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleUnauthorized: () => {
		return dispatch(AppActions.unauthorizedError());
	},
	handleError: (error: ErrorState) => {
		return dispatch(AppActions.setGeneralError(error));
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	},
	seHasClickedOutsideSearch: (clicked: boolean) => {
		return dispatch(SearchActions.seHasClickedOutsideSearch(clicked));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
