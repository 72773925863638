import './SearchPredictionLoadingComponent.scss';
export const SearchPredictionLoadingComponent = () => {
	return (
		<div className='skeleton-wrapper'>
			<span className='skeleton-header'>Loading...</span>
			<span className='skeleton-line long'></span>
			<span className='skeleton-line medium'></span>
			<span className='skeleton-line short'></span>
			<span className='skeleton-line medium'></span>
		</div>
	);
};
