import React, { FC } from 'react';
import './TimeLineComponent.scss';

interface ITimeLineComponentProps {
	children: JSX.Element | JSX.Element[];
	classes: string;
}

const TimeLineComponent: FC<ITimeLineComponentProps> = ({ children, classes }) => {
	return (
		<div className={`timeline-container ${classes}`}>
			{React.Children.map(children, (child, index) => (
				<div className='timeline-step' key={index}>
					{React.cloneElement(child, { key: index })}
				</div>
			))}
		</div>
	);
};

export interface ITimeLineStepComponentProps {
	stepIcon: JSX.Element;
	leftContent: JSX.Element;
	rightContent: JSX.Element;
}

const TimeLineStepComponent: FC<ITimeLineStepComponentProps> = ({
	leftContent,
	stepIcon,
	rightContent
}) => {
	return (
		<div className='time-container'>
			<div className='time-heading-container'>
				<div className='timeline-content left'>{leftContent}</div>
				<div className='timeline-icon'>{stepIcon}</div>
				<div className='timeline-content right'>{rightContent}</div>
			</div>
		</div>
	);
};

export { TimeLineComponent, TimeLineStepComponent };
