import { Action } from 'redux-actions';
import { ofType, StateObservable } from 'redux-observable';
import { map, of, catchError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
	FilterType,
	FilterVM,
	FilterLevel,
	PintoFilters,
	IFiltersUrlParameters,
	sortOptionType,
	ISortByOption,
	ICategoryNavbar,
	ICategoryNavbarFilter,
	NavbarElementType,
	NavbarSubElementType,
	ITrackingEvent,
	TrackingEventStatus
} from '../common/view-model';
import { ISelectedFilterState } from '../components/filter/generic-filter/model';
import { AppState, RootState } from './AppReducer';
import { FilterActions } from './FilterActions';
import { cloneDeep } from '@apollo/client/utilities';
import {
	Distributor,
	Region,
	Certification,
	Allergen,
	ConsumerDiet,
	Packaging,
	Attribute,
	Climate,
	AnimalWelfare,
	BusinessPractice,
	ClaimIngredients
} from '../common/model';
import {
	firstLevelCategoryIdentifier,
	flatLevelCategoryIdentifier,
	parentCategoryIdentifier,
	secondLevelCategoryIdentifier
} from '../helpers/paramsUrlIndetifiers';
import {
	flatSecondLevelFilters,
	flatFirstLevelFilters,
	flatThirdLevelChildren,
	filterTypeMappings,
	flatBasedLevelFilters
} from '../helpers/urlParametersHelpers';
import { whastNewFilter } from '../mock/whatIsNewFilter';
import { buildBreadCrumbForDeepFilter } from '../helpers/buildBreadCrumbHelper';
import { handleTracking } from '../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
export class FilterEpics {
	static init() {
		return [
			FilterEpics.addFilterEpic,
			FilterEpics.setCategoryEpic,
			FilterEpics.removeFilterEpic,
			FilterEpics.clearAllFiltersEpic,
			FilterEpics.filterStateEpic,
			FilterEpics.addParentEpic,
			FilterEpics.removeParentEpic,
			FilterEpics.clearAllCategoriesEpic,
			FilterEpics.mapEntityToFilterEpic,
			FilterEpics.addExternalFiltersEpic,
			FilterEpics.addOpenFilterFunctionRef,
			FilterEpics.addMouseLeaveCategoryNavbar,
			FilterEpics.addCloseFilterFunctionRefEpic,
			FilterEpics.removeAllAndAddFilterEpic,
			FilterEpics.removeAllAndLeaveOneFilterEpic,
			FilterEpics.mapFiltersFromUrl,
			FilterEpics.clearAllFiltersAndSearchTermEpic,
			FilterEpics.mapNavbarElementsEpic
		];
	}

	static addFilterEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.ADD_FILTER),
			map((action: Action<any>) => {
				const { payload } = action;
				const { filter } = payload;
				const trackingPayload: ITrackingEvent = {
					transactionId: uuidv4(),
					status: TrackingEventStatus.SUCCESS,
					type: `Click on ${filter.name} filter`,
					timeStamp: new Date().toISOString(),
					entityId: filter.id,
					eventPayload: {
						id: filter?.id!,
						name: filter?.name!,
						type: FilterType[filter?.type],
						url: window.location.href,
						tags: [],
						metadata: {
							user_type: state$.value?.appState?.userState?.user?.user_type,
							user_id: state$.value?.appState?.userState.user?.user_id,
							enviroment: process.env.REACT_APP_ENV_NAME
						}
					}
				};
				handleTracking(trackingPayload);
				const appFilters = state$.value.appState.filterState.allFilters;
				const categories = appFilters.find((x: FilterVM) => x.type === FilterType.CATEGORY).filters;
				filter.breadCrumbElements = buildBreadCrumbForDeepFilter(filter, categories);
				const {
					state,
					setState,
					children,
					type,
					level,
					parentId,
					parentLength,
					categoryName,
					mainMenuId,
					checkBoxGroupId,
					carrotIconRef,
					checkBoxDOMRef
				} = filter;
				let filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				const allFilters = state$.value.appState.filterState.allFilters;
				const states = state$.value.appState.filterState.filterStates;
				let newFilters: FilterVM[] = [];
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				let isParent = false;
				if (
					type === FilterType.CATEGORY &&
					parentId &&
					parentLength !== 0 &&
					level === FilterLevel.FOURTH
				) {
					const selectedFilters = state$.value.appState.filterState.selectedFilters;
					const parentElement = document.getElementById(checkBoxDOMRef) as HTMLInputElement;
					const elementsByParent = selectedFilters.filter((f: FilterVM) => f.parentId === parentId);
					const categories = flatThirdLevelChildren(
						allFilters.find((x: FilterVM) => x.type === FilterType.CATEGORY).filters
					);
					let parentCategory: FilterVM | undefined;
					categories.forEach((cat: FilterVM) => {
						if (cat.children && cat.children.length > 0) {
							cat.children.forEach((category: FilterVM) => {
								if (category.id === parentId) {
									parentCategory = category;
								}
							});
						}
					});
					if (
						elementsByParent.length < parentLength - 1 &&
						parentCategory?.children &&
						parentCategory.children.length > 0
					) {
						if (parentElement) {
							parentElement.indeterminate = true;
						}
					} else {
						if (parentElement) {
							parentElement.indeterminate = false;
						}
						const parentState = states.find(
							(s: ISelectedFilterState) => s.parentIndex === parentId
						);
						const selectedByParent = parentCategory?.children?.length === parentLength;
						if (parentState && selectedByParent) {
							const { state, setState } = parentState;
							setState({ ...state, checkProperties: { ...state.checkProperties, checked: true } });
							filters = [
								...filters,
								{ ...parentCategory!, level: FilterLevel.THIRD, isSuperCategory: true, parentId }
							];
						}
					}
				}
				const valueExists = filters.indexOf(action.payload) > -1;
				if (!valueExists && !isParent && !children) {
					newFilters = [...filters, filter];
				}
				if (children) {
					newFilters = [...filters];
					const existsParent = newFilters.find(x => x.id === filter.id) !== undefined;
					if (!existsParent) {
						newFilters = [...newFilters, filter];
					}
					const { id } = filter;
					children.forEach((child: FilterVM, index: number) => {
						if (!newFilters.find((f: FilterVM) => f.id === child.id)) {
							newFilters = [
								...newFilters,
								{
									...child,
									parentId: id,
									index,
									parentLength: children.length,
									level: FilterLevel.FOURTH,
									categoryName,
									mainMenuId,
									checkBoxGroupId,
									carrotIconRef,
									breadCrumbElements: buildBreadCrumbForDeepFilter(filter, categories)
								}
							];
							const childState = states.find(
								(s: ISelectedFilterState) => s.parentIndex === child.id
							);
							const { state, setState } = childState;
							setState({ ...state, checkProperties: { ...state.checkProperties, checked: true } });
						}
					});
				}
				setState &&
					setState({ ...state, checkProperties: { ...state.checkProperties, checked: true } });
				return {
					type: 'FILTER_STATE',
					payload: {
						newFilters,
						selectedCategory,
						breadCrumbElements: state$.value.appState.filterState.breadCrumbElements
					}
				};
			})
		);
	};
	static removeFilterEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.REMOVE_FILTER),
			map((action: Action<any>) => {
				let {
					state,
					setState,
					children,
					parentId,
					type,
					id,
					level,
					parentLength,
					checkBoxDOMRef,
					isExternalFilter
				} = action.payload.filter;
				const { clearCategory } = action.payload;
				const states = state$.value.appState.filterState.filterStates;
				let filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				let isLastFilterByParent = false;
				if (isExternalFilter) {
					let newFilters: FilterVM[] = [];
					const index = filters.findIndex((f: FilterVM) => f.id === id && f.type === type);
					filters.splice(index, 1);
					newFilters = [...filters];
					return {
						type: 'FILTER_STATE',
						payload: {
							newFilters,
							selectedCategory,
							lastDeletedFilter: action.payload.filter,
							breadCrumbElements:
								type === FilterType.CATEGORY
									? undefined
									: state$.value.appState.filterState.breadCrumbElements
						}
					};
				}
				if (
					type === FilterType.CATEGORY &&
					parentId &&
					parentLength !== 0 &&
					level === FilterLevel.FOURTH
				) {
					const selectedFilters = state$.value.appState.filterState.selectedFilters;
					const parentElement = document.getElementById(checkBoxDOMRef) as HTMLInputElement;
					const elementsByParent = selectedFilters.filter(
						(f: FilterVM) => f.parentId === parentId
					).length;
					if (elementsByParent - 1 <= parentLength && !(elementsByParent <= 1)) {
						if (parentElement) {
							parentElement.indeterminate = true;
						}
					} else {
						if (parentElement) {
							parentElement.indeterminate = false;
						}
					}
					const selectedByParent =
						filters.filter((x: FilterVM) => x.parentId === parentId)?.length - 1;
					if (selectedByParent <= parentLength) {
						filters = filters.filter((x: FilterVM) => x.id !== parentId);
					}
					if (selectedByParent === 0) {
						isLastFilterByParent = true;
					}
				}
				if (!state) {
					const childState = states.find(
						(s: ISelectedFilterState) => s.parentIndex === action.payload.filter.id
					);
					state = childState?.state;
					setState = childState?.setState;
				}
				setState &&
					setState({ ...state, checkProperties: { ...state.checkProperties, checked: false } });
				let newFilters: FilterVM[] = [];

				if (children && children.length > 0) {
					const parentElement = document.getElementById(checkBoxDOMRef) as HTMLInputElement;
					if (parentElement) {
						parentElement.indeterminate = false;
					}
					children.forEach((child: FilterVM) => {
						const index = filters.findIndex((f: FilterVM) => f.id === child.id);
						const childState = states.find((s: ISelectedFilterState) => s.parentIndex === child.id);
						const { state, setState } = childState;
						if (index > -1) {
							filters.splice(index, 1);
						}
						setState({ ...state, checkProperties: { ...state.checkProperties, checked: false } });
					});
					newFilters = [...filters.filter((f: FilterVM) => f.id !== id)];
				} else {
					if (type === FilterType.CATEGORY) {
						newFilters = filters.filter((f: FilterVM) => f.id !== id);
					} else {
						const filterToRemoved = filters.find((f: FilterVM) => f.id === id && f.type === type);
						if (filterToRemoved) {
							const index = filters.findIndex((f: FilterVM) => f.id === id && f.type === type);
							filters.splice(index, 1);
							newFilters = [...filters];
						}
					}
				}
				if (parentId) {
					const parentState = states.find((s: ISelectedFilterState) => s.parentIndex === parentId);
					if (parentState) {
						const { state, setState } = parentState;
						setState({ ...state, checkProperties: { ...state.checkProperties, checked: false } });
					}
				}
				if (
					!clearCategory &&
					newFilters.filter(
						(f: FilterVM) =>
							f.type === FilterType.CATEGORY ||
							f.type === FilterType.NEW_TO_BEACON ||
							f.type === FilterType.NEW_TO_MARKET
					).length === 0
				) {
					selectedCategory = undefined;
					const carrotRef = document.querySelectorAll('.carrot-icon-open');
					carrotRef.forEach(item => {
						item.classList.remove('carrot-icon-open');
					});
				}
				return {
					type: 'FILTER_STATE',
					payload: {
						newFilters,
						selectedCategory,
						lastDeletedFilter: {
							...action.payload.filter,
							isLastFilterByParent: isLastFilterByParent
						},
						breadCrumbElements:
							type === FilterType.CATEGORY
								? undefined
								: state$.value.appState.filterState.breadCrumbElements
					}
				};
			})
		);
	};
	static setCategoryEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<AppState>> => {
		return action$.pipe(
			ofType(FilterActions.SET_CATEGORY),
			map((action: Action<AppState>) => {
				return { type: 'FILTER_CATEGORY_STATE', payload: action.payload };
			})
		);
	};
	static clearAllFiltersEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	) => {
		return action$.pipe(
			ofType(FilterActions.CLEAR_ALL_FILTERS),
			map((action: Action<any>) => {
				const filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				const states = state$.value.appState.filterState.filterStates;
				filters.forEach((filter: FilterVM) => {
					const { state, setState } = filter;
					if (state && setState) {
						setState({ ...state, checkProperties: { ...state.checkProperties, checked: false } });
					} else {
						const childState = states.find(
							(s: ISelectedFilterState) => s.parentIndex === filter.id
						);
						if (childState) {
							childState.setState({
								...state,
								checkProperties: { ...childState.state.checkProperties, checked: false }
							});
						}
					}
				});
				const parentCheckStates: ISelectedFilterState[] = states.filter(
					(s: ISelectedFilterState) =>
						s.parentIndex === 'AllDistributors' || s.parentIndex === 'AllRegions'
				);
				parentCheckStates.forEach((selectedFilterState: ISelectedFilterState) => {
					const { state, setState } = selectedFilterState;
					setState!({ ...state, checkProperties: { ...state!.checkProperties, checked: false } });
				});
				const inputElements = document.getElementsByTagName('input');
				for (let i = 0; i < inputElements.length; i++) {
					const element = inputElements[i];
					if (element && element.type === 'checkbox') {
						element.indeterminate = false;
					}
				}
				return { type: 'CLEAR_ALL_FILTERS', payload: [] };
			})
		);
	};
	static filterStateEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.FILTER_STATE),
			map((action: Action<any>) => {
				let states: ISelectedFilterState[] = state$.value.appState.filterState.filterStates;
				const { parentIndex } = action.payload.state;
				if (
					states?.filter((state: ISelectedFilterState) => state.parentIndex === parentIndex)
						.length === 0
				) {
					states = [...states, action.payload.state];
				}
				return { type: 'UPDATE_FILTER_STATE', payload: states };
			})
		);
	};
	static addParentEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.ADD_PARENT_FILTER),
			map((action: Action<any>) => {
				let { filter } = action.payload;
				filter = {
					...filter,
					isSuperLevelFilter: true,
					isExternalFilter: true
				};
				let filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				let cleanUpFilters = filters.filter(f => !f.isSuperLevelFilter);
				let newFilters: FilterVM[] = [...cleanUpFilters, filter];
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				return {
					type: 'FILTER_STATE',
					payload: { newFilters, selectedCategory }
				};
			})
		);
	};
	static removeParentEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.REMOVE_PARENT_FILTER),
			map((action: Action<any>) => {
				const { filter } = action.payload;
				let filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				let newFilters: FilterVM[] = [...filters];
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				newFilters = filters.filter((f: FilterVM) => f.id !== filter.id);
				return {
					type: 'FILTER_STATE',
					payload: { newFilters, selectedCategory }
				};
			})
		);
	};
	static clearAllCategoriesEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.CLEAR_ALL_CATEGORIES),
			map((action: Action<any>) => {
				let filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				let newFilters: FilterVM[] = [...filters];
				let selectedCategory = undefined;
				newFilters = filters.filter((f: FilterVM) => f.type !== FilterType.CATEGORY);
				return {
					type: 'FILTER_STATE',
					payload: { newFilters, selectedCategory }
				};
			})
		);
	};
	static mapEntityToFilterEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.MAP_FILTERS_TO_QUERY),
			map((action: Action<any>) => {
				const allFilters = state$.value.appState.filterState.allFilters;
				const storeState = state$.value.appState.storeState;
				const distributors: FilterVM[] | undefined = storeState?.filters?.distributors?.map(
					(distributor: Distributor) => {
						return {
							id: distributor.id,
							name: distributor.name,
							isChecked: false,
							type: FilterType.DISTRIBUTOR,
							children: [],
							toolTip: { ...distributor.toolTip }
						};
					}
				);
				const regions: FilterVM[] | undefined = storeState?.filters?.regions?.map(
					(region: Region) => {
						return {
							id: region.id,
							name: region.name,
							isChecked: false,
							type: FilterType.REGION,
							children: [],
							toolTip: { ...region.toolTip }
						};
					}
				);
				const taxonomies: FilterVM[] = storeState?.filters?.taxonomyFilters?.map(
					(taxonomyFilter: FilterVM) => {
						return {
							...taxonomyFilter
						};
					}
				);
				const certifications: FilterVM[] | undefined = storeState?.filters?.certifications?.map(
					(certification: Certification) => {
						return {
							id: certification.id,
							name: certification.name,
							isChecked: false,
							type: FilterType.CERTIFICATION,
							children: [],
							toolTip: { ...certification.toolTip }
						};
					}
				);
				const allergens: FilterVM[] | undefined = storeState?.filters?.allergens?.map(
					(allergen: Allergen) => {
						return {
							id: allergen.id,
							name: allergen.name,
							isChecked: false,
							type: FilterType.ALLERGENS,
							children: [],
							toolTip: { ...allergen.toolTip }
						};
					}
				);
				const consumerDiets: FilterVM[] | undefined = storeState?.filters?.consumerDiets?.map(
					(consumerDiet: ConsumerDiet) => {
						return {
							id: consumerDiet.id,
							name: consumerDiet.name,
							isChecked: false,
							type: FilterType.CONSUMER_DIETS,
							children: [],
							toolTip: { ...consumerDiet.toolTip }
						};
					}
				);
				const packaging: FilterVM[] | undefined = storeState?.filters?.packagings?.map(
					(packaging: Packaging) => {
						return {
							id: packaging.id,
							name: packaging.name,
							isChecked: false,
							type: FilterType.PACKAGING,
							children: [],
							toolTip: { ...packaging.toolTip }
						};
					}
				);
				const atributes: FilterVM[] | undefined = storeState?.filters?.topAttributes?.map(
					(attribute: Attribute) => {
						return {
							id: attribute.id,
							name: attribute.name,
							isChecked: false,
							type: FilterType.ATTRIBUTES,
							children: [],
							toolTip: { ...attribute.toolTip }
						};
					}
				);
				const climates: FilterVM[] | undefined = storeState?.filters?.climates?.map(
					(climate: Climate) => {
						return {
							id: climate.id,
							name: climate.name,
							isChecked: false,
							type: FilterType.CLIMATE,
							children: [],
							toolTip: { ...climate.toolTip }
						};
					}
				);
				const animalWelfare: FilterVM[] | undefined = storeState?.filters?.animalWalfares?.map(
					(animalWelfare: AnimalWelfare) => {
						return {
							id: animalWelfare.id,
							name: animalWelfare.name,
							isChecked: false,
							type: FilterType.ANIMAL_WELFARE,
							children: [],
							toolTip: { ...animalWelfare.toolTip }
						};
					}
				);
				const businessPractices: FilterVM[] | undefined =
					storeState?.filters?.businessPractices?.map((businessPractice: BusinessPractice) => {
						return {
							id: businessPractice.id,
							name: businessPractice.name,
							isChecked: false,
							type: FilterType.BUSINESS_PRACTICES,
							children: [],
							toolTip: businessPractice.toolTip
						};
					});
				const ingredients: FilterVM[] | undefined = storeState?.filters?.claimingIngredients?.map(
					(claimIngredient: ClaimIngredients) => {
						return {
							id: claimIngredient.id,
							name: claimIngredient.name,
							isChecked: false,
							type: FilterType.INGREDIENTS,
							children: [],
							toolTip: { ...claimIngredient.toolTip }
						};
					}
				);
				allFilters.map((filter: PintoFilters) => {
					switch (filter.type) {
						case FilterType.CATEGORY:
							filter.filters = taxonomies!;
							break;
						case FilterType.DISTRIBUTOR:
							filter.filters = distributors!;
							break;
						case FilterType.REGION:
							filter.filters = regions!;
							break;
						case FilterType.CERTIFICATION:
							filter.filters = certifications!;
							break;
						case FilterType.ALLERGENS:
							filter.filters = allergens!;
							break;
						case FilterType.CONSUMER_DIETS:
							filter.filters = consumerDiets!;
							break;
						case FilterType.PACKAGING:
							filter.filters = packaging!;
							break;
						case FilterType.ATTRIBUTES:
							filter.filters = atributes!;
							break;
						case FilterType.CLIMATE:
							filter.filters = climates!;
							break;
						case FilterType.ANIMAL_WELFARE:
							filter.filters = animalWelfare!;
							break;
						case FilterType.BUSINESS_PRACTICES:
							filter.filters = businessPractices!;
							break;
						case FilterType.INGREDIENTS:
							filter.filters = ingredients!;
							break;
						default:
							break;
					}
				});

				return {
					type: 'ALL_FILTERS_MAPPED',
					payload: [...allFilters]
				};
			})
		);
	};
	static addExternalFiltersEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.ADD_EXTERNAL_FILTER),
			map((action: Action<any>) => {
				const appFilters = state$.value.appState.filterState.allFilters;
				const categories = appFilters.find((x: FilterVM) => x.type === FilterType.CATEGORY).filters;
				let filters: FilterVM[] = state$.value.appState.filterState.selectedFilters;
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				let newFilters: FilterVM[] = cloneDeep(filters);
				const valueExists = filters.find(
					(filter: FilterVM) => filter.id === action.payload.filter.id
				);
				if (!valueExists) {
					newFilters = [
						...filters,
						{
							...action.payload.filter,
							breadCrumbElements: buildBreadCrumbForDeepFilter(action.payload.filter, categories)
						}
					];
				} else {
					newFilters = filters;
				}
				return {
					type: 'FILTER_STATE',
					payload: { newFilters, selectedCategory }
				};
			})
		);
	};
	static addOpenFilterFunctionRef = (action$: Observable<Action<AppState>>): Observable<any> => {
		return action$.pipe(
			ofType(FilterActions.ADD_OPEN_FILTER_FUNCTION_REF),
			map((action: Action<any>) => {
				return FilterActions.setOpenFilterFunctionRef(action.payload);
			})
		);
	};
	static addMouseLeaveCategoryNavbar(
		action$: Observable<Action<AppState>>
	): Observable<Action<any>> {
		return action$.pipe(
			ofType(FilterActions.ADD_ON_MOUSE_LEAVE_CATEGORY_NAVBAR),
			map((action: Action<any>) => {
				return FilterActions.setOnMouseLeaveCategoryNavbar(action.payload);
			})
		);
	}
	static addCloseFilterFunctionRefEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.ADD_CLOSE_FILTER_FUNCTION_REF),
			map((action: Action<any>) => {
				return FilterActions.setCloseFilterFunctionRef(action.payload);
			})
		);
	};
	static removeAllAndAddFilterEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.REMOVE_ALL_AND_ADD_FILTER),
			map((action: Action<any>) => {
				const { children, id } = action.payload;
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				const states = state$.value.appState.filterState.filterStates;
				let newFilters: FilterVM[] = [...[], action.payload];
				if (children) {
					children.forEach((child: FilterVM, index: number) => {
						if (!newFilters.find((f: FilterVM) => f.id === child.id)) {
							newFilters = [
								...newFilters,
								{
									...child,
									parentId: id,
									index,
									parentLength: children.length,
									level: FilterLevel.THIRD
								}
							];
							const childState = states.find(
								(s: ISelectedFilterState) => s.parentIndex === child.id
							);
							const { state, setState } = childState;
							setState({ ...state, checkProperties: { ...state.checkProperties, checked: true } });
						}
					});
				}

				return {
					type: 'FILTER_STATE',
					payload: { newFilters, selectedCategory }
				};
			})
		);
	};
	static removeAllAndLeaveOneFilterEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.REMOVE_ALL_AND_LEAVE_ONE_FILTER),
			map((action: Action<any>) => {
				let filters = [...state$.value.appState.filterState.selectedFilters];
				let selectedCategory = state$.value.appState.filterState.selectedCategory;
				let newFilters = filters.filter(f => f.id === action.payload.id);
				return {
					type: 'FILTER_STATE',
					payload: { newFilters, selectedCategory }
				};
			})
		);
	};
	static mapFiltersFromUrl = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<any>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(FilterActions.MAP_FILTERS_FROM_URL),
			map((action: Action<any>) => {
				const { payload } = action;
				const allFilters = state$.value.appState.filterState.allFilters;
				const selectedFilters = state$.value.appState.filterState.selectedFilters;
				const { brand } = state$.value?.appState?.storeState;
				const categories = allFilters.find((x: FilterVM) => x.type === FilterType.CATEGORY).filters;
				const secondLevelFilters = flatSecondLevelFilters(categories);
				const firstLevelFilters = flatFirstLevelFilters(categories);
				const thirdLevelFilters = flatThirdLevelChildren(categories);
				let selectedCategory: string | undefined;
				let filtersFromUrl: IFiltersUrlParameters = payload;

				const result = Object.entries(filtersFromUrl).reduce((acc: FilterVM[], [key, value]) => {
					const filterType = filterTypeMappings[key];
					const filterList = filterType
						? allFilters.find((x: FilterVM) => x.type === filterType)?.filters || []
						: [];

					if (filterType) {
						const { children, ...rest } = whastNewFilter;
						switch (filterType) {
							case FilterType.WHATS_NEW:
								acc.push({
									...(rest as FilterVM),
									isChecked: true,
									breadCrumbElements: [{ firstLevel: { ...rest } }]
								});
								break;
							case FilterType.NEW_TO_BEACON:
								const newToBeaconFilter = whastNewFilter.children?.find(
									(x: FilterVM) => x.type === FilterType.NEW_TO_BEACON
								);
								if (newToBeaconFilter) {
									acc.push({
										...newToBeaconFilter,
										isChecked: true,
										breadCrumbElements: [
											{ firstLevel: { ...rest } },
											{ secondLevel: { ...newToBeaconFilter } }
										]
									});
								}
								break;
							case FilterType.NEXTY_FINALISTS:
								const nextyFinalistsFilter = whastNewFilter.children?.find(
									(x: FilterVM) => x.type === FilterType.NEXTY_FINALISTS
								);
								if (nextyFinalistsFilter) {
									acc.push({
										...nextyFinalistsFilter,
										isChecked: true,
										breadCrumbElements: [
											{ firstLevel: { ...rest } },
											{ secondLevel: { ...nextyFinalistsFilter } }
										]
									});
								}
								break;
							case FilterType.NEW_TO_MARKET:
								const newToMarketFilter = whastNewFilter.children?.find(
									(x: FilterVM) => x.type === FilterType.NEW_TO_MARKET
								);
								if (newToMarketFilter) {
									acc.push({
										...newToMarketFilter,
										isChecked: true,
										breadCrumbElements: [
											{ firstLevel: { ...rest } },
											{ secondLevel: { ...newToMarketFilter } }
										]
									});
								}
								break;
							default:
								value.forEach((id: string) => {
									const filter = filterList.find((x: FilterVM) => x.id === id);
									if (filter) {
										acc.push(filter);
									}
								});
								break;
						}
					} else {
						switch (key) {
							case parentCategoryIdentifier.toUpperCase():
								const category = categories.find((x: FilterVM) => x.id === value[0]);
								if (category) {
									selectedCategory = category.name;
									acc.push({
										...category,
										isSuperLevelFilter: true,
										breadCrumbElements: [{ firstLevel: category }]
									});
								}
								break;
							case secondLevelCategoryIdentifier.toUpperCase():
								value.forEach((id: string) => {
									const secondLevelCategory = secondLevelFilters.find((x: FilterVM) => x.id === id);
									if (secondLevelCategory) {
										const firstLevel = categories.find(
											(x: FilterVM) => x.id === secondLevelCategory.parentId
										);
										acc.push({
											...secondLevelCategory,
											isChecked: true,
											level: FilterLevel.SECOND,
											children: undefined,
											isExternalFilter: true,
											isSuperCategory: false,
											breadcrumText: secondLevelCategory.name,
											breadCrumbElements: [
												{ firstLevel },
												{ secondLevel: { ...secondLevelCategory } }
											]
										});
										selectedCategory = secondLevelCategory?.name;
									} else {
										const thirdLevelCategory = thirdLevelFilters.find((x: FilterVM) => x.id === id);
										if (thirdLevelCategory) {
											let firstLevel = {};
											categories.flatMap((child: FilterVM) => {
												child.children?.forEach((subChild: FilterVM) => {
													if (subChild.id === thirdLevelCategory.parentId) {
														firstLevel = child;
													}
												});
											});
											acc.push({
												...thirdLevelCategory,
												isChecked: true,
												level: FilterLevel.SECOND,
												isSuperCategory: true,
												breadCrumbElements: [{ firstLevel }, { secondLevel: thirdLevelCategory }]
											});
											selectedCategory = thirdLevelCategory?.name;
										}
									}
								});
								break;
							case firstLevelCategoryIdentifier.toUpperCase():
								let parentCategories: string[] = [];
								value.forEach((id: string) => {
									const firstLevelCategory = firstLevelFilters.find((x: FilterVM) => x.id === id);
									const secondLevelFilter = thirdLevelFilters.find(
										(x: FilterVM) => x.id === firstLevelCategory?.parentId
									);
									if (firstLevelCategory) {
										let firstLevel = {};
										categories.flatMap((child: FilterVM) => {
											child.children?.forEach((subChild: FilterVM) => {
												if (subChild.id === secondLevelFilter?.parentId) {
													firstLevel = child;
												}
											});
										});
										acc.push({
											...firstLevelCategory,
											isChecked: true,
											children: undefined,
											level: FilterLevel.THIRD,
											isExternalFilter: true,
											isSuperCategory: false,
											breadcrumText: `${secondLevelFilter?.name}/${firstLevelCategory.name}`,
											breadCrumbElements: [
												{ firstLevel },
												{ secondLevel: secondLevelFilter },
												{ thirdLevel: firstLevelCategory }
											]
										});
										parentCategories.push(secondLevelFilter?.categoryName || '');
									}
								});
								selectedCategory = parentCategories.pop();
								break;
							case FilterType[FilterType.BRAND]:
								if (brand) {
									acc.push({
										...brand,
										type: FilterType.BRAND,
										children: undefined,
										isChecked: true
									});
								}
								break;
							case flatLevelCategoryIdentifier.toUpperCase():
								let parentCategoryElement: string[] = [];
								const flatElements = flatBasedLevelFilters(firstLevelFilters);
								value.forEach((id: string) => {
									const flatLevelCategory = flatElements.find((x: FilterVM) => x.id === id);
									if (flatLevelCategory) {
										const parentCategory = firstLevelFilters.find(
											x => x.id === flatLevelCategory?.parentId
										);
										const firstLevelCategory = firstLevelFilters.find(
											(x: FilterVM) => x.id === flatLevelCategory.parentId
										);
										const secondLevel = thirdLevelFilters.find(
											x => x.id === firstLevelCategory?.parentId
										);
										let firstLevel = {};
										if (secondLevel) {
											categories.flatMap((child: FilterVM) => {
												child.children?.forEach((subChild: FilterVM) => {
													if (subChild.id === secondLevel?.parentId) {
														firstLevel = child;
													}
												});
											});
										}
										const allSelectedByParent = selectedFilters.find(
											(x: FilterVM) => x.id === flatLevelCategory.parentId
										)?.children?.length;
										const hideFourthLevel =
											allSelectedByParent === parentCategory?.children?.length;

										acc.push({
											...flatLevelCategory,
											isChecked: true,
											level: FilterLevel.FOURTH,
											breadcrumText: firstLevelCategory?.breadcrumText,
											children: undefined,
											isExternalFilter: true,
											isSuperCategory: false,
											parentLength: parentCategory?.children?.length - 1,
											breadCrumbElements: [
												{ firstLevel: firstLevel },
												{ secondLevel: secondLevel },
												{ thirdLevel: { ...parentCategory } },
												!hideFourthLevel ? { fourthLevel: flatLevelCategory } : {}
											]
										});
										parentCategoryElement.push(firstLevelCategory?.categoryName || '');
									}
								});
								selectedCategory = parentCategoryElement.pop();
								break;
							default:
								break;
						}
					}

					return acc;
				}, []);

				return {
					type: FilterActions.SET_SELECTED_FILTERS,
					payload: { filters: result, selectedCategory }
				};
			}),
			catchError((error: any) => {
				console.error(error);
				return of({
					type: FilterActions.SET_SELECTED_FILTERS,
					payload: { filters: [], selectedCategory: undefined }
				});
			})
		);
	};

	static clearAllFiltersAndSearchTermEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<RootState>
	) => {
		return action$.pipe(
			ofType(FilterActions.CLEAR_ALL_FILTERS_AND_SEARCH_TERM),
			map((action: Action<any>) => {
				const { sortByOptions, sortBySelected } = state$.value.appState.filterState!;
				let sortBy =
					sortBySelected?.sortType === sortOptionType.MOST_RELEVANT
						? sortByOptions?.find(x => x.sortType === sortOptionType.ALPHABETICAL)
						: sortBySelected;
				const mostRelevantOption = sortByOptions?.find(
					(option: ISortByOption) => option.sortType === sortOptionType.MOST_RELEVANT
				);
				if (mostRelevantOption) {
					sortByOptions?.shift();
				}
				return {
					type: FilterActions.SET_CLEAR_ALL_FILTERS_AND_SEARCH_TERM,
					payload: {
						sortBy,
						sortByOptions
					}
				};
			}),
			catchError((error: any) => {
				return of({
					type: FilterActions.SET_CLEAR_ALL_FILTERS_AND_SEARCH_TERM,
					payload: undefined
				});
			})
		);
	};
	static mapNavbarElementsEpic = (
		action$: Observable<Action<AppState>>,
		state$: StateObservable<RootState>
	) => {
		return action$.pipe(
			ofType(FilterActions.MAP_NAVBAR_ELEMENTS),
			map((action: Action<any>) => {
				const beveragesId = '99999992';
				const pantryId = '99999991';
				const taxonomyNavbar = state$.value.appState.storeState?.taxonomyNavbar;
				const allFilters = state$.value.appState.filterState?.allFilters;
				const categories = allFilters?.find(x => x.type === FilterType.CATEGORY)?.filters;
				const firstLevelFilters = flatFirstLevelFilters(categories);
				const regenerativeCertificationFilterId = 'certified-regenerative';
				const womanOwnedBussinesFilterId = 'woman-owned-business';
				const { children, ...currentNewFilter } = whastNewFilter;
				const newToMarketFilter = whastNewFilter.children.find(
					(f: FilterVM) => f.type === FilterType.NEW_TO_MARKET
				);
				const newToBeaconFilter = whastNewFilter.children.find(
					(f: FilterVM) => f.type === FilterType.NEW_TO_BEACON
				);
				const certifications = allFilters?.find(
					filter => filter.type === FilterType.CERTIFICATION
				)?.filters;
				const bussinesPractice = allFilters?.find(
					filter => filter.type === FilterType.BUSINESS_PRACTICES
				)?.filters;
				const regenerativeFilter = certifications?.find(
					certification => certification.id === regenerativeCertificationFilterId
				);
				const womanOwnedBussinesFilter = bussinesPractice?.find(
					bussinesPractice => bussinesPractice.id === womanOwnedBussinesFilterId
				);
				const nextyFinalistFilter = whastNewFilter.children.find(
					(f: FilterVM) => f.type === FilterType.NEXTY_FINALISTS
				);
				const currentEvent = state$.value.appState.storeState?.getCurrentEvent;
				const navElements: ICategoryNavbar[] = action.payload;
				const flatLevelFilters = flatBasedLevelFilters(firstLevelFilters);
				let result = navElements.map((navbarElement: ICategoryNavbar) => {
					switch (navbarElement.id) {
						case NavbarElementType.EVENT:
							return {
								...navbarElement,
								elements: navbarElement.elements?.map((subElement: ICategoryNavbarFilter) => {
									switch (subElement.id) {
										case NavbarSubElementType.MAP_EVENT:
											return {
												...subElement,
												linkUrl: currentEvent?.floorMapUrl
											};
										case NavbarSubElementType.AGENDA:
											return {
												...subElement,
												linkUrl: currentEvent?.agendaUrl
											};
										case NavbarSubElementType.EXHIBITOR_LIST:
											return {
												...subElement,
												linkUrl: currentEvent?.exhibitorListUrl
											};
										case NavbarSubElementType.HUB:
											return {
												...subElement,
												linkUrl: currentEvent?.exhibitorHubUrl
											};

										default:
											return subElement;
									}
								})
							};
						case NavbarElementType.NEW:
							return {
								...navbarElement,
								elements: navbarElement.elements?.map((subElement: ICategoryNavbarFilter) => {
									switch (subElement.id) {
										case NavbarSubElementType.WOMEN_OWNED:
											return {
												...subElement,
												filters: [{ ...womanOwnedBussinesFilter }]
											};
										case NavbarSubElementType.REGENERATIVE:
											return {
												...subElement,
												filters: [{ ...regenerativeFilter }]
											};
										case NavbarSubElementType.NEW_TO_MARKET:
											return {
												...subElement,
												filters: [currentNewFilter, newToMarketFilter]
											};
										case NavbarSubElementType.NEW_TO_BEACON:
											return {
												...subElement,
												filters: [currentNewFilter, newToBeaconFilter]
											};
										case NavbarSubElementType.NEXTY_FINALISTS:
											return {
												...subElement,
												filters: [currentNewFilter, nextyFinalistFilter]
											};
										default:
											return subElement;
									}
								})
							};
						default:
							return {
								...navbarElement,
								elements: navbarElement.elements?.map((subElement: ICategoryNavbarFilter) => {
									const filters = taxonomyNavbar
										?.find((tax: FilterVM) => tax.id === subElement.id)
										?.children.map((child: FilterVM) => {
											return {
												...child,
												isSuperCategory: subElement.id !== beveragesId,
												parentId: subElement.panelFilterId,
												children: child.children
													?.map((subChild: FilterVM) => ({
														...subChild,
														parentId: child.id,
														level: FilterLevel.SECOND,
														isSuperCategory: false,
														isExternalFilter: true,
														breadcrumText: child.name,
														parentLength: child.children.length,
														categoryName: child.name,
														hasBasedLevelFilters: flatLevelFilters.some(x => x.id === subChild.id),
														parentFilterId: subElement.panelFilterId
													}))
													.sort((a: FilterVM, b: FilterVM) => {
														if (a?.name?.toLowerCase()! < b?.name?.toLowerCase()!) {
															return -1;
														}
														if (a?.name?.toLowerCase()! > b?.name?.toLowerCase()!) {
															return 1;
														}
														return 0;
													})
											};
										});
									const sortedFilters = [...filters].sort(
										(a: ICategoryNavbarFilter, b: ICategoryNavbarFilter) => {
											if (a?.name?.toLowerCase()! < b?.name?.toLowerCase()!) {
												return -1;
											}
											if (a?.name?.toLowerCase()! > b?.name?.toLowerCase()!) {
												return 1;
											}
											return 0;
										}
									);
									return {
										...subElement,
										//Elements for Pantry are sorted alphabetically, since they are not sorted in the taxonomy
										filters: subElement.id === pantryId ? sortedFilters : filters
									};
								})
							};
					}
				});

				return {
					type: FilterActions.MAP_NAVBAR_ELEMENTS_SUCCESS,
					payload: result
				};
			}),
			catchError((error: any) => {
				console.error(error);
				return of({
					type: FilterActions.MAP_NAVBAR_ELEMENTS_FAILURE,
					payload: []
				});
			})
		);
	};
}
