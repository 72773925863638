import { OnboardCardComponent, underLinePosition } from '../OnboardCard/OnboardCardComponent';
import { ITimeLineStepComponentProps } from '../TimeLine/TimeLineComponent';
import { NeedToMakeChangesModel as model } from './model';
import spins from '../../../assets/images/brand-onboard/time-line/spins.svg';
import favorites from '../../../assets/images/brand-onboard/time-line/favorites.svg';

export const NeedChangesDesktopSteps: ITimeLineStepComponentProps[] = [
	{
		stepIcon: <span className='number'>1</span>,
		leftContent: <></>,
		rightContent: (
			<OnboardCardComponent
				image={<img src={spins} alt='spins' className='spins-img' />}
				header={
					<div className='first-card-header-container'>
						<span className='card-text-header'>{model.firstCardHeaderPrevText}</span>{' '}
						<a
							className='card-link-header'
							href={model.firstCardHeaderLink}
							target='_blank'
							rel='noopener noreferrer'
						>
							{model.firstCardHeaderTextLink}
						</a>{' '}
					</div>
				}
				content={
					<div className='first-card-content-container'>
						<span className='card-text-content'>{model.firstCardContentText}</span>
					</div>
				}
				underlinePosition={underLinePosition.BOTTOM_LEFT}
			/>
		)
	},
	{
		leftContent: <></>,
		stepIcon: <i className='ri-checkbox-circle-line circle-check'></i>,
		rightContent: (
			<OnboardCardComponent
				header={
					<div className='second-card-header-container'>
						<span className='card-text-header card-mt-2'>{model.secondCardHeaderText}</span>
					</div>
				}
				content={
					<div className='second-card-content-container'>
						<span className='card-text-content'>{model.secondCardContentText}</span>
					</div>
				}
				image={<img src={favorites} className='favorites-img' alt='favorites'></img>}
				underlinePosition={underLinePosition.BOTTOM_LEFT}
				className='need-changes-negative-margin-wrapper'
			/>
		)
	}
];
