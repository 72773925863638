import { useEffect, useState, useRef, FC } from 'react';
import { connect, useSelector } from 'react-redux';
import { UserActions } from '../../../redux/UserActions';
import { RootState } from './../../../redux/AppReducer';
import { ProductVM } from '../../../common/view-model';
import { Image as ProductImages } from '../../../common/model';
import { Pagination, Swiper as ISwiper, Thumbs, FreeMode, Navigation } from 'swiper';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import TooltipComponent from '../../UI/TooltipComponent/TooltipComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Action, Dispatch } from 'redux';
import { ITrackingEvent, TrackingEventStatus, TrackingEventType } from '../../../common/view-model';
import { v4 as uuidv4 } from 'uuid';
import { ScreenType } from '../../../common/view-model';
import { sortProductImages } from '../../../helpers/sortProductImages';
import Modal from 'react-bootstrap/Modal';
import Slider from 'react-slick';
import InnerImageZoom from 'react-inner-image-zoom';
import { FavoriteAddDate } from './../../../redux/AppReducer';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './ProductCarouselComponent.scss';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { AppActions } from '../../../redux/AppActions';
import LockOverlayComponent from '../../LockOverlay/LockOverlayComponent';
import { publicProductDetailsActions } from '../../../common/constants';
import ShareMenuComponent from '../../shareMenu/shareMenuComponent';
const noImageRef = require('../../../assets/images/no-image.png');

interface ICarouselImageItemComponentProps {
	alt?: string;
	src?: string;
	onClick?: () => void;
	showSigleErrorImage?: boolean;
	registerUserClickEvent: (event: string) => void;
}
export const CarouselImageItemComponent: FC<ICarouselImageItemComponentProps> = ({
	alt,
	src,
	onClick,
	showSigleErrorImage,
	registerUserClickEvent
}) => {
	const [loaded, setLoaded] = useState(false);
	const [imageError, setImageError] = useState(false);
	if (showSigleErrorImage) {
		return <img src={noImageRef} className='single-no-image-place-holder'></img>;
	}
	const handelClick = () => {
		if (onClick) {
			if (isCurrentUserAnonymous()) {
				registerUserClickEvent(publicProductDetailsActions.CLICK_PRODUCT_IMAGE_CAROUSEL);
			}
			onClick();
		}
	};
	return (
		<>
			{imageError && <img src={noImageRef} className='no-image-place-holder'></img>}
			{!loaded && !imageError && <div className='spinner'></div>}

			<img
				data-testid='carousel-image-item-component'
				src={src}
				style={loaded && !imageError ? {} : { display: 'none' }}
				alt={alt}
				onLoad={() => setLoaded(true)}
				onError={() => setImageError(true)}
				onClick={handelClick}
			></img>
		</>
	);
};

interface IProductCarouselComponentProps {
	product: ProductVM | undefined;
	favoriteProducts: FavoriteAddDate[];
	toggleFavorite: (productId: string, trackingEvent?: ITrackingEvent) => void;
	deviceScreen: ScreenType | undefined;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
}
const ProductCarouselComponent: FC<IProductCarouselComponentProps> = ({
	product,
	favoriteProducts,
	toggleFavorite,
	deviceScreen,
	setLockModalContent,
	registerUserClickEvent
}) => {
	const [favorite, setFavorite] = useState<boolean>(false);
	const [thumbsSwiper, setThumbsSwiper] = useState<ISwiper | null>(null);
	const [preview, setPreview] = useState<number>(0);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const [images, setImages] = useState<ProductImages[]>(sortProductImages(product?.images || []));
	const swiperRefMobileModal = useRef<ISwiper>();
	const { isFavoriting } = useSelector((state: RootState) => state.appState);
	const isDesktop =
		deviceScreen === ScreenType.LARGE_DESKTOP ||
		deviceScreen === ScreenType.MEDIUM_DESKTOP ||
		deviceScreen === ScreenType.SMALL_DESKTOP;
	const isTablet = deviceScreen === ScreenType.TABLET;
	const numberSlides = isTablet ? 3 : 5;
	const handleFavoriteProduct = (id: string | undefined) => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_HEART_ICON);
			setLockModalContent();
			return;
		}

		const trackingEvent: ITrackingEvent = {
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.FAVORITES,
			timeStamp: new Date().toISOString(),
			entityId: id!,
			eventPayload: {
				id: product?.id!,
				name: product?.name!,
				type: product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					product_brand_name: product!.brand.name,
					user_type: userState?.user_type,
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		};

		if (id && !isFavoriting) {
			toggleFavorite(id, trackingEvent);
		}
	};

	const detailView = (index: number) => {
		setPreview(index);
		setOpenModal(true);
	};
	const settings = {
		customPaging: function (i: number) {
			if (images !== undefined && (images[i] as any).canLoad)
				return (
					<div>
						<img className='preview_image' src={images[i]?.thumbnailUrl} alt='Preview' />
					</div>
				);
			else return <></>;
		},

		dots: true,
		dotsClass: 'slick-dots slick-thumb',
		infinite: true,
		display: 'block',
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: preview
	};

	const onClickLeft = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_PRODUCT_IMAGE_CAROUSEL);
		}
		let element: HTMLElement = document.getElementsByClassName('slick-prev')[0] as HTMLElement;
		element.click();
	};

	const onClickRight = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_PRODUCT_IMAGE_CAROUSEL);
		}
		let element: HTMLElement = document.getElementsByClassName('slick-next')[0] as HTMLElement;
		element.click();
	};
	useEffect(() => {
		const zoomIcon = document.getElementsByClassName('iiz__hint_visible')[0] as HTMLElement;
		if (zoomIcon) {
			zoomIcon.style.visibility = 'visible';
			const timeout = setTimeout(() => {
				zoomIcon.style.visibility = 'hidden';
			}, 3000);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [openModal]);
	useEffect(() => {
		setImages(sortProductImages(product?.images || []));
	}, [product]);
	useEffect(() => {
		setFavorite(favoriteProducts?.some(favorite => favorite.pid === product?.id));
	}, [favoriteProducts, product]);
	const handleSlideChangeValidate = (swiper: ISwiper) => {
		if (isCurrentUserAnonymous() && swiper.previousIndex !== 0) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_PRODUCT_IMAGE_CAROUSEL);
		}
	};
	if (product?.allImagesHaveError) {
		return (
			<div data-testid='all-images-have-error' className='product-carousel-container'>
				<div
					data-testid='product-favorite-all-images-have-error'
					className='dual-container-actions'
					onClick={event => handleFavoriteProduct(product?.id)}
				>
					<ShareMenuComponent id={product?.id} type='product'></ShareMenuComponent>
					<TooltipComponent
						text={favorite ? 'Product was added to favorites' : 'Add to favorites'}
						position='bottom'
						classes='tooltip-content-center'
					>
						<i className={favorite ? 'ri-heart-fill red' : 'ri-heart-line'} />
					</TooltipComponent>
				</div>

				<div className='mySwiper2'>
					<CarouselImageItemComponent
						showSigleErrorImage={true}
						registerUserClickEvent={registerUserClickEvent}
					/>
				</div>
			</div>
		);
	}
	return (
		<>
			{images?.length > 0 && (
				<div data-testid='product-carousel-component' className='product-carousel-container'>
					<div className='dual-container-actions'>
						<ShareMenuComponent id={product?.id} type='product'></ShareMenuComponent>
						<TooltipComponent
							text={favorite ? 'Product was added to favorites' : 'Add to favorites'}
							position='bottom'
							classes='tooltip-content-center'
						>
							<i
								className={favorite ? 'ri-heart-fill red' : 'ri-heart-line'}
								onClick={event => handleFavoriteProduct(product?.id)}
								data-testid='product-favorite'
							/>
						</TooltipComponent>
					</div>
					{isDesktop || isTablet ? (
						<>
							<Swiper
								spaceBetween={10}
								navigation={true}
								thumbs={{ swiper: thumbsSwiper }}
								modules={[FreeMode, Navigation, Thumbs]}
								className='mySwiper2'
								loop={true}
								onSlideChange={swiper => handleSlideChangeValidate(swiper)}
							>
								{images?.map((el: any, index: number) => {
									if (el.canLoad) {
										return (
											<SwiperSlide key={index}>
												<CarouselImageItemComponent
													data-testid='carousel-image-item'
													src={el.thumbnailUrl}
													alt={el.side}
													onClick={() => {
														detailView(index as number);
													}}
													registerUserClickEvent={registerUserClickEvent}
												/>
											</SwiperSlide>
										);
									}
									return null;
								})}
							</Swiper>
							<div className='myswiper2-dumb'>
								<Swiper
									initialSlide={0}
									onSwiper={setThumbsSwiper}
									spaceBetween={5}
									navigation={false}
									slidesPerView={numberSlides}
									freeMode={true}
									watchSlidesProgress={true}
									modules={[FreeMode, Navigation, Thumbs]}
									className='mySwiper'
									onBeforeInit={swiper => {
										swiperRefMobileModal.current = swiper;
									}}
								>
									{images?.map((el: any, index: number) => {
										return el.canLoad ? (
											<SwiperSlide key={index}>
												<CarouselImageItemComponent
													src={el.thumbnailUrl}
													alt={el.side}
													registerUserClickEvent={registerUserClickEvent}
												/>
											</SwiperSlide>
										) : null;
									})}
								</Swiper>
								{images.length > numberSlides ? (
									<>
										<i
											data-testid='mobile-left-arrow'
											className='bi bi-chevron-left'
											onClick={() => swiperRefMobileModal.current?.slidePrev()}
										></i>
										<i
											data-testid='mobile-right-arrow'
											className='bi bi-chevron-right'
											onClick={() => swiperRefMobileModal.current?.slideNext()}
										></i>
									</>
								) : null}
							</div>
						</>
					) : (
						<>
							<Swiper
								spaceBetween={10}
								navigation={true}
								modules={[Pagination]}
								pagination={{
									clickable: true,
									el: '.swiper-custom-pagination'
								}}
								className='mySwiper2'
								onSlideChange={swiper => handleSlideChangeValidate(swiper)}
							>
								{images?.map((el: any, index: number) => {
									return el.canLoad ? (
										<SwiperSlide key={index}>
											<CarouselImageItemComponent
												src={el.thumbnailUrl}
												alt={el.side}
												onClick={() => detailView(index as number)}
												registerUserClickEvent={registerUserClickEvent}
											/>
										</SwiperSlide>
									) : null;
								})}
							</Swiper>
							<div className='swiper-custom-pagination'></div>
						</>
					)}
					{isDesktop || isTablet ? (
						<Modal
							data-testid='product-carousel-modal'
							show={openModal}
							onHide={() => setOpenModal(false)}
							aria-labelledby='example-custom-modal-styling-title'
							className='desktop-modal-container'
						>
							<Modal.Body>
								<Slider {...settings}>
									{images?.map((el: any, index: number) => {
										return el.canLoad ? (
											<div className='modal_item' style={{ display: 'block' }} key={index}>
												{el.mainUrl && isTablet && (
													<TransformWrapper>
														<TransformComponent>
															<img src={el?.mainUrl} alt='Cooming soon' />
														</TransformComponent>
													</TransformWrapper>
												)}
												{el.mainUrl && isDesktop && <InnerImageZoom src={el?.mainUrl} />}
											</div>
										) : null;
									})}
								</Slider>

								<i
									data-testid='left-arrow'
									className='bi bi-chevron-left'
									onClick={() => onClickLeft()}
								></i>
								<i
									data-testid='right-arrow'
									className='bi bi-chevron-right'
									onClick={() => onClickRight()}
								></i>

								<b
									data-testid='close-icon'
									className='close_icon'
									onClick={() => setOpenModal(false)}
								>
									&times;
								</b>
							</Modal.Body>
						</Modal>
					) : (
						<Modal
							show={openModal}
							onHide={() => setOpenModal(false)}
							aria-labelledby='example-custom-modal-styling-title'
							className='mobile-modal-container'
						>
							<Modal.Body>
								<Swiper
									spaceBetween={10}
									navigation={true}
									modules={[Pagination, Navigation]}
									pagination={{
										clickable: true,
										el: '.swiper-custom-pagination-for-modal'
									}}
									className='mySwiper3'
									onBeforeInit={swiper => {
										swiperRefMobileModal.current = swiper;
									}}
									initialSlide={preview}
								>
									{images?.map((el: any, index: number) => {
										return el.canLoad ? (
											<SwiperSlide key={index}>
												{el.mainUrl && (
													<TransformWrapper>
														<TransformComponent>
															<img src={el?.mainUrl} alt='Cooming soon' />
														</TransformComponent>
													</TransformWrapper>
												)}
											</SwiperSlide>
										) : null;
									})}
								</Swiper>
								<div className='swiper-custom-pagination-for-modal'></div>
								<span className='iiz__hint_visible'></span>
								<div className='control-mobile'>
									<i
										className='bi bi-chevron-left'
										onClick={() => {
											swiperRefMobileModal.current?.slidePrev();
										}}
									></i>
									<i
										className='bi bi-chevron-right'
										onClick={() => {
											swiperRefMobileModal.current?.slideNext();
										}}
									></i>
								</div>

								<b className='close_icon-mobile' onClick={() => setOpenModal(false)}>
									&times;
								</b>
							</Modal.Body>
						</Modal>
					)}
				</div>
			)}
		</>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	favoriteProducts: state.userState?.favoriteProducts || []
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	toggleFavorite: (productId: string, trackingEvent?: ITrackingEvent) =>
		dispatch(UserActions.toggleFavoriteProduct(productId, trackingEvent)),
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCarouselComponent);
