const DEFAULT_STATE: IIrisAuthenticationState = {
	loginButtonText: 'Login',
	registerButtonText: 'Sign Up',
	logOutButtonText: 'Logout',
	iirisModalId: 'iiris-modal-close-btn',
	iirisRegisterButtonId: 'irisRegisterBtn',
	iirisLoginButtonId: 'irisLoginBtn',
	iirisLogoutButtonId: 'irisLogoutBtn',
	showMenu: false,
	ctaButtonText: 'CPG Brand? Get your products on Beacon',

	handleRegister() {
		document?.getElementById(this.iirisRegisterButtonId)?.click();
	},
	handleLogin() {
		document?.getElementById(this.iirisLoginButtonId)?.click();
	}
};

interface IIrisAuthenticationState {
	iirisModalId: string;
	loginButtonText: string;
	registerButtonText: string;
	logOutButtonText: string;
	iirisRegisterButtonId: string;
	iirisLoginButtonId: string;
	iirisLogoutButtonId: string;
	showMenu: boolean;
	handleRegister: () => void;
	handleLogin: () => void;
	ctaButtonText: string;
}
export const IrisAuthenticationState = {
	...DEFAULT_STATE
};
