export const DEFAULT_LABELS = {
	brandRepName: 'Brand Contact Name',
	brandRepEmail: 'Brand Contact Email',
	brandRepPhone: 'Brand Contact Phone Number',
	brandRepTitle: 'Brand Contact Title',
	productRepName: 'Request Sample Contact Name',
	productRepEmail: 'Request Sample Contact Email',
	productRepPhone: 'Request Sample Contact Phone Number',
	productRepTitle: 'Request Sample Contact Title',
	haveABroker: 'Do you have a broker?'
};
