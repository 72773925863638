import { useState } from 'react';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import { SecondaryLabelForm } from '../../GenericBaseForms/SecondaryLabelForm/SecondaryLabelForm';
import React from 'react';
import './ProductImprovementsFormComponent.scss';
import { useWindowSize } from '../../../../hooks/useWindowSize';

interface IProductViolationsForm {
	productState: any;
}
interface IImprovements {
	change: string;
	description: string | React.ReactElement;
	action: string;
	id: string;
}

const ProductImprovementsFormComponent: React.FC<IProductViolationsForm> = ({ productState }) => {
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	const { width } = useWindowSize();
	const FULL_SIZE_SCREEN_WIDTH = 768;
	const isMobile = width < FULL_SIZE_SCREEN_WIDTH;

	const ImprovementsArray: IImprovements[] = [
		{
			change: 'Allergen Warning Missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Product contains an ingredient that requires an allergen declaration which	is not present. Allergen declaration is required.',
			id: 'allergenMissing'
		},
		{
			change: 'Claims or TM logo requires certification',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Product label indicates the product is certified organic, certified gluten free and/or non-GMO verified/certified. The supporting certificatedocumentation is required or the label may require appropriate revisions.',
			id: 'claimsLogoRequiresCertification'
		},
		{
			change: 'Botanic plant parts missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Dietary supplement products containing botanical substances are required to include the plant parts used within the ingredient declaration.',
			id: 'botanicPartsMissing'
		},
		{
			change: 'Ingredient standards ',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description: (
				<>
					Product contains at least one ingredient that is prohibited by New Hope Network
					Ingredients Standards & Guidelines. See{' '}
					<a
						href='https://standards.newhope.com/ingredient-standards-guidelines/'
						target='_blank'
						rel='noreferrer'
					>
						{' '}
						https://standards.newhope.com/ingredient-standards-guidelines/
					</a>
					&nbsp; for details.
				</>
			),
			id: 'ingredientStandards'
		},
		{
			change: 'Organic certifying agent missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.',
			description:
				'Product label includes organic claims that require the product be Certified Organic with the certifying agent identified on the information panel. The certyfiing agent does not appear on this product label and it is required. If the product is not Certified Organic, the organic claim(s) need to be removed.',
			id: 'organicCertifyingAgentMissing'
		}
	];
	const improvements: IImprovements[] = ImprovementsArray.filter(
		improvement => productState.violations[improvement.id]
	);
	if (improvements.length > 0) {
		return (
			<div className='product-violations-form'>
				<TitleForms
					isVisible={isVisible}
					titleText='Improvements For Next Show'
					toggleVisibility={toggleVisibility}
					readOnly
				></TitleForms>
				{!isMobile && isVisible ? (
					<>
						<p className='helper-text'>
							To provide additional information, please contact your Standards Specialist at
							<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
								{' '}
								standards@newhope.com.
							</a>{' '}
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<SecondaryLabelForm text='Change'></SecondaryLabelForm>
									</th>
									<th>
										<SecondaryLabelForm text='Description'></SecondaryLabelForm>
									</th>
									<th>
										<SecondaryLabelForm text='Action'></SecondaryLabelForm>
									</th>
								</tr>
							</thead>
							<tbody>
								{improvements.map((violation, index) => (
									<tr key={index + productState.id}>
										<td>{violation.change}</td>
										<td>{violation.description}</td>
										<td>{violation.action}</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				) : (
					<div className='improvements-container'>
						<p className='helper-text'>
							To provide additional information, please contact your Standards Specialist at
							<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
								{' '}
								standards@newhope.com.
							</a>{' '}
						</p>
						{improvements.map((violations, index) => (
							<div key={index + productState.id} className='improvement-container'>
								<div className='improvement-element'>
									<SecondaryLabelForm text='Change'></SecondaryLabelForm>
									<p> {violations.change}</p>
								</div>

								<div className='improvement-element'>
									<SecondaryLabelForm text='Description'></SecondaryLabelForm>
									<p> {violations.description}</p>
								</div>

								<div className='improvement-element'>
									<SecondaryLabelForm text='Action'></SecondaryLabelForm>
									<p> {violations.action}</p>
								</div>
								<div className='separator'></div>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
	return null;
};

export default React.memo(ProductImprovementsFormComponent);
