import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import './BrandComponent.scss';
interface IBrandComponent {
	name: string;
	description: string;
	onClick: () => void;
	brandImage: string;
}

export const BrandComponent = ({ name, description, brandImage, onClick }: IBrandComponent) => {
	return (
		<div className='brand-card-container'>
			<div className='image'>
				<img src={brandImage} alt='brand'></img>
			</div>
			<div className='info'>
				<p className='brand-highlighted-name'>{name}</p>
				<p className='brand-highlighted-description'>{description}</p>
				<ButtonComponent
					style='rounded-button secondary'
					icon={<i className='ri-arrow-right-s-line line-icon'></i>}
					onClick={() => onClick()}
					text='Learn More'
				/>
			</div>
		</div>
	);
};
