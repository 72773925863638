import { useState } from 'react';
import './index.scss';
import { HeaderState } from './model';

export default function Header() {
	const [state, setState] = useState(HeaderState);
	const headerState: HeaderState = state[state.show.toString()];
	const options = state.getOptions(state.baseUrl);
	const toggleHeader = () => {
		const val = !state.show;
		setState({ ...state, show: val });
	};
	return (
		<>
			<header data-testid='header' className='header'>
				<section className='informa-bar'>
					<div id='iribbon-container'>
						<button
							data-testid='iribbon-title'
							id='iribbon-title'
							title='show/hide'
							className={headerState.buttonClass}
							onClick={toggleHeader}
						>
							{state.title}
						</button>
						<div id='iribbon-detail' className={headerState.ribbonClass}>
							<div id='iribbon-left'>
								<p> {state.slogan} </p>
								<ul>
									{options.map((option: any, index: number) => {
										return (
											<li key={index} className={index === 0 ? 'iribbon-mainlink' : ''}>
												<a href={option.href}>{option.label}</a>
											</li>
										);
									})}
								</ul>
							</div>
							<div id='iribbon-right'>
								<p>{state.description}</p>
							</div>
						</div>
					</div>
				</section>
			</header>
		</>
	);
}
