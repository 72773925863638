import { FC } from 'react';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import './WarningFormOverlayComponent.scss';
interface WarningOverLayComponentProps {
	closeOverlay: () => void;
	updateData: () => void;
	cancelUpdate: () => void;
	isValidForm: boolean;
}
export const WarningFormOverlayComponent: FC<WarningOverLayComponentProps> = ({
	cancelUpdate,
	closeOverlay,
	updateData,
	isValidForm
}) => {
	const handleContainerClick = (e: { stopPropagation: () => void }) => {
		e.stopPropagation();
	};
	return (
		<div className='warning-overlay-container' onClick={handleContainerClick}>
			<div className='warning-title-container'>
				<p>Save Your Changes?</p>
				<ButtonComponent
					onClick={closeOverlay}
					style='rounded-button tertiary menu-button'
					text=''
					icon={<i className='ri-close-line'></i>}
					iconOnly
				/>
			</div>
			<div className='warning-body-container'>
				<div className='warning-description-container'>
					<p className='description-title'>You are currently editing another section.</p>
					<p className='description-body'>
						Do you want to save your changes before editing the next section?
					</p>
				</div>
				<div className='warning-buttons-container'>
					<ButtonComponent
						style='rounded-button secondary'
						text='Undo Changes'
						onClick={() => {
							cancelUpdate();
							closeOverlay();
						}}
					/>
					<ButtonComponent
						style='rounded-button primary'
						text='Save Changes For This Section'
						onClick={() => {
							updateData();
							closeOverlay();
						}}
						disabled={!isValidForm}
					/>
				</div>
			</div>
		</div>
	);
};
