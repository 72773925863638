import { APP_NAME } from '../../../../common/constants';
const DEFAULT_STATE: ISpinsMetricsState = {
	spinsMetricsTitleText: 'SPINS Point of Sale (POS) Metrics',
	spinsMetricsText:
		'Metrics are based on the past 52-weeks and are provided to help retailers identify products that are a good fit for their store, they are not intended to be performance evaluations.',
	productsMedianTitleText: 'All product median',
	productsMedianText: ` = comparison of this product with the midpoint of all products on the ${APP_NAME} platform.`,
	spinsMetricsBasedOnText: `*POS metrics are based on comparisons to all products, across regions on the ${APP_NAME} platform. SPINS data presented is for the combined channels of US Natural Enhanced Channel and Regional & Independent Grocery.`,
	moreDetailsText: 'More Details',
	lessDetailsText: 'Less Details',
	metricNotAvailableText: 'Metric not available',
	allMetricsNotAvailableText:
		'Metrics for this product are not available, this may indicate the product and/or brand is new to market or not yet in distribution.',
	metricsItems: [
		{
			title: 'Dollar Sales: ',
			prevText: 'This product has dollar sales that are ',
			nextText: ' than the median product.',
			icon: 'ri-money-dollar-circle-line',
			details: {
				title:
					'Total Past 52-week dollar and unit sales metrics helps you understand how new or established a product is:',
				elements: [
					'No SPINS sales data may indicate that this product is very new.',
					'Scores below the median may indicate a less established product.',
					'Scores higher than the median may indicate a product is more established.'
				]
			},
			propertyName: 'dollars'
		},
		{
			title: 'Unit Sales: ',
			prevText: 'This product has unit sales that are ',
			nextText: ' than the median product.',
			icon: 'ri-bar-chart-box-line',
			details: {
				title:
					'Total Past 52-week dollar and unit sales metrics helps you understand how new or established a product is:',
				elements: [
					'No SPINS sales data may indicate that this product is very new.',
					'Scores below the median may indicate a less established product.',
					'Scores higher than the median may indicate a product is more established.'
				]
			},
			propertyName: 'productUnits'
		},
		{
			title: 'Distribution ',
			prevText: '(Max ACV): This product is ',
			nextText: ' distributed than the median product.',
			icon: 'ri-pin-distance-line',
			details: {
				title:
					'Total Past 52-week distribution helps you understand how widely a product is distributed and how unique it may be to carry this product in your store:',
				elements: [
					'We take the product’s total distribution and weight it by the store size. A product’s cumulative (or penetration) % of distribution weighted by the store size (ACV) across all weeks over a 52-week period.',
					'No SPINS distribution data may indicate that this product is very new.',
					'Scores below the median may indicate this product is less likely to be currently carried by your competitors.',
					'Scores above the median may indicate this product is established, with a proven track record of sales.'
				]
			},
			propertyName: 'distribution'
		},
		{
			title: 'Velocity: ',
			prevText: 'This product has ',
			nextText: ' velocity than the median product.',
			icon: 'ri-bar-chart-2-line',
			details: {
				title:
					'Total Past 52-week average velocity score helps you understand if the product has a demonstrated track record of generating sales velocity:',
				elements: [
					'This metric helps compare the sales rates of products with varying levels of distribution and numbers of weeks selling. A product’s combined Channel Dollars divided by its cross-channel max ACV.',
					'No SPINS distribution data may indicate that this product is very new.',
					'Scores below the median may indicate this product that will need additional marketing or merchandizing support to drive velocity.',
					'Scores above the median may indicate an established product that will require less support to generate sales.'
				]
			},
			propertyName: 'velocity'
		}
	]
};
export interface ISpinsMetricItemsDetail {
	title: string;
	elements: string[];
}
interface ISpinsMetricsItems {
	title: string;
	prevText: string;
	nextText: string;
	icon: string;
	details: ISpinsMetricItemsDetail;
	propertyName: string;
}

interface ISpinsMetricsState {
	spinsMetricsTitleText: string;
	spinsMetricsText: string;
	productsMedianText: string;
	spinsMetricsBasedOnText: string;
	productsMedianTitleText: string;
	moreDetailsText: string;
	lessDetailsText: string;
	metricsItems: ISpinsMetricsItems[];
	metricNotAvailableText: string;
	allMetricsNotAvailableText: string;
}

export const spinsMetricsState = {
	...DEFAULT_STATE
};
