import './HideFilterComponent.scss';
import { FC, useState } from 'react';
import { hideFilterModel } from './model';
import { ScreenType, viewModeType } from '../../../common/view-model';
import { RootState } from '../../../redux/AppReducer';
import { connect } from 'react-redux';

interface IHideFilterComponentProps {
	hideFilterHandler?: () => void;
	deviceViewMode: viewModeType;
	deviceScreen?: ScreenType;
}
const HideFilterComponent: FC<IHideFilterComponentProps> = ({
	hideFilterHandler,
	deviceViewMode,
	deviceScreen
}) => {
	const [state] = useState({ ...hideFilterModel });
	const handleClose = () => {
		if (deviceScreen === ScreenType.TABLET) {
			const bodyRef = document.getElementsByTagName('body')[0] as HTMLElement;
			bodyRef!.style.overflow = 'auto';
		}
		hideFilterHandler && hideFilterHandler();
	};
	return (
		<div className='hide-filter-container'>
			<span className='filter-text'>
				{deviceViewMode === viewModeType.DESKTOP ? state.filterText : state.mobileFilterText}
			</span>
			{deviceViewMode !== viewModeType.MOBILE ? (
				<div className='hide-filter' onClick={handleClose}>
					<i className='back-icon ri-arrow-left-line'></i>
					<span className='hide-filter-text'>{state.hideFilterText}</span>
				</div>
			) : (
				<>
					<span className='close-filter' onClick={handleClose}>
						<i className='ri-close-line icon'></i>
					</span>
				</>
			)}
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	deviceScreen: state.deviceScreen || undefined
});

export default connect(mapStateToProps)(HideFilterComponent);
