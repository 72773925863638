import './BrandOnboardFooter.scss';
import { BrandOnboardFooterModel as model } from './model';
export const BrandOnboardFooter = () => {
	return (
		<div className='brand-onboard-footer'>
			<div className='onboard-footer-title-container'>
				<span className='footer-title'>{model.footerTitle}</span>
			</div>
			<div className='links-container'>
				<ul className='list-container'>
					<li>
						{model.viewText}{' '}
						<a href={model.onboardingGuideLink} target='_blank' rel='noopener noreferrer'>
							{model.onboardingGuideText}
						</a>{' '}
					</li>
					<li>
						{model.learnMoreAboutText}{' '}
						<a href={model.newHopeNetworkLink} target='_blank' rel='noopener noreferrer'>
							{model.newHopeStandardsText}
						</a>
					</li>
					<li>
						{model.reachOutText}{' '}
						<a href={`mailto:${model.audienceLink}`} target='_blank' rel='noopener noreferrer'>
							{model.audienceText}
						</a>{' '}
						{model.onboardingConcernText}
					</li>
				</ul>
			</div>
		</div>
	);
};
