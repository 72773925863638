export const shareMessages = {
	copy_text: 'Copy Link',
	email_text: 'Share Via Email',
	toast_text: 'Link Copied!'
};

export const emailCopy = {
	message_brand: 'I found a brand on Beacon Discovery that you may be interested in!',
	message_product: 'I found a product on Beacon Discovery that you may be interested in!'
};
