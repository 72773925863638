import { FC, Fragment, useEffect } from 'react';
import {
	FilterVM,
	ICategoryNavbar,
	ICategoryNavbarFilter,
	ITrackingEvent,
	TrackingEventStatus
} from '../../../common/view-model';
import './NavbarOverlayComponent.scss';
import { ActionLinkComponent } from '../../UI/ActionLinkComponent/ActionLinkComponent';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { publicProductDiscoveryActions } from '../../../common/constants';
import { v4 as uuidv4 } from 'uuid';
import { handleTracking } from '../../../helpers/handelTracking';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';
import { buildUrlParameters } from '../../../helpers/urlParametersHelpers';
import { getRedirectUrl } from '../../../helpers/transformers';
interface ICategoryNavabarItemComponentProps {
	filter: FilterVM;
	handleAddCategoryFilter: (filter: FilterVM) => void;
	registerUserClickEvent: (event: string) => void;
	showName: boolean;
	productsPageUrl: string;
	allCategories: FilterVM[];
}
export const CategoryNavabarItemComponent: FC<ICategoryNavabarItemComponentProps> = ({
	filter,
	handleAddCategoryFilter,
	registerUserClickEvent,
	showName,
	productsPageUrl,
	allCategories
}) => {
	const dispatch = useDispatch();
	const handleClick = (filter: FilterVM) => {
		dispatch(AppActions.setGeneralError({ hasError: false }));
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDiscoveryActions.CLICK_INSIDE_TOP_NAVBAR);
		}
		handleAddCategoryFilter(filter);
	};
	return (
		<div className={`category-navbar-item-container ${!showName ? 'no-underline' : ''}`}>
			{showName && (
				<ActionLinkComponent
					text={filter.name || ''}
					onClick={() => handleClick(filter)}
					underline={true}
					icon='ri-arrow-right-line'
					iconPosition='end'
					iconOnly={false}
					color='dark'
					fontWigth='semi-bold'
					size='big'
					to={getRedirectUrl(productsPageUrl, filter, allCategories)}
					linkToExternal={false}
				/>
			)}
			{filter?.children?.map((child: FilterVM) => (
				<ActionLinkComponent
					text={child.name || ''}
					onClick={() => handleClick(child)}
					underline={false}
					iconOnly={false}
					color='dark'
					fontWigth='normal'
					size='big'
					key={child.id}
					to={getRedirectUrl(productsPageUrl, child, allCategories)}
					linkToExternal={false}
				/>
			))}
		</div>
	);
};

interface ICategoryNavbarCardItemComponentProps {
	filters?: FilterVM[];
	handleAddCategoryFilter: (filter: FilterVM) => void;
	categoryTab?: ICategoryNavbar;
	registerUserClickEvent: (event: string) => void;
	productsPageUrl: string;
	allCategories: FilterVM[];
}
export const CategoryNavbarCardItemComponent: FC<ICategoryNavbarCardItemComponentProps> = ({
	filters,
	handleAddCategoryFilter,
	categoryTab,
	registerUserClickEvent,
	productsPageUrl,
	allCategories
}) => {
	const noNeedWrappingIds = ['991180'];
	return (
		<div className={`category-navbar-card-item ${categoryTab?.id}-case`}>
			{filters?.map((filter: FilterVM, index: number) => {
				const firstFiveChildren = noNeedWrappingIds.includes(filter.id!)
					? filter?.children?.slice(0, 5)
					: filter?.children?.slice(0, 5);
				const remainingChildren = noNeedWrappingIds.includes(filter.id!)
					? filter?.children?.slice(5, 11)
					: filter?.children?.slice(5);
				const lastChildren = noNeedWrappingIds.includes(filter.id!)
					? filter?.children?.slice(-3)
					: [];

				return (
					<Fragment key={`${filter.id}-${index}`}>
						<CategoryNavabarItemComponent
							handleAddCategoryFilter={handleAddCategoryFilter}
							key={`${filter.id}-first`}
							filter={{ ...filter, children: firstFiveChildren }}
							registerUserClickEvent={registerUserClickEvent}
							showName={true}
							productsPageUrl={productsPageUrl}
							allCategories={allCategories}
						/>
						{remainingChildren?.length > 0 && (
							<CategoryNavabarItemComponent
								handleAddCategoryFilter={handleAddCategoryFilter}
								key={`${filter.id}-remaining`}
								filter={{ ...filter, children: remainingChildren }}
								registerUserClickEvent={registerUserClickEvent}
								showName={false}
								productsPageUrl={productsPageUrl}
								allCategories={allCategories}
							/>
						)}
						{lastChildren?.length > 0 && (
							<CategoryNavabarItemComponent
								handleAddCategoryFilter={handleAddCategoryFilter}
								key={`${filter.id}-last`}
								filter={{ ...filter, children: lastChildren }}
								registerUserClickEvent={registerUserClickEvent}
								showName={false}
								productsPageUrl={productsPageUrl}
								allCategories={allCategories}
							/>
						)}
					</Fragment>
				);
			})}
		</div>
	);
};
interface ICardNavbarElementsItemProps {
	cardContent: ICategoryNavbarFilter;
	handleAddFilterFromNavbar: (filter: FilterVM[]) => void;
	registerUserClickEvent: (event: string) => void;
	setCategoryTab: (category?: ICategoryNavbar) => void;
	eventName: string;
	user: any;
	productsPageUrl: string;
}
const CardNavbarElementsItem: FC<ICardNavbarElementsItemProps> = ({
	cardContent,
	handleAddFilterFromNavbar,
	registerUserClickEvent,
	setCategoryTab,
	eventName,
	user,
	productsPageUrl
}) => {
	const handleClick = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDiscoveryActions.CLICK_INSIDE_TOP_NAVBAR);
		}
		if (
			cardContent.filters &&
			cardContent.filters.length > 0 &&
			cardContent.filters.every(f => f?.id)
		) {
			handleAddFilterFromNavbar(cardContent.filters);
		} else if (cardContent.linkUrl) {
			const trakckingPayload: ITrackingEvent = {
				transactionId: uuidv4(),
				entityId: cardContent.name!,
				status: TrackingEventStatus.SUCCESS,
				type: `Nav Click on ${eventName} - ${cardContent.name}`,
				timeStamp: new Date().toISOString(),
				eventPayload: {
					id: cardContent.id!,
					name: cardContent.name!,
					type: cardContent.name!,
					url: window.location.href,
					tags: [],
					metadata: {
						user_type: user?.user_type,
						user_id: user?.id,
						environment: process.env.REACT_APP_ENV_NAME
					}
				}
			};
			handleTracking(trakckingPayload);
			window.open(cardContent.linkUrl, '_blank', 'noopener,noreferrer');
			setCategoryTab(undefined);
		}
	};
	return (
		<div className='card-container' onClick={handleClick}>
			<div className='image-container'>
				<img className='card-image' src={cardContent.imagePath} alt={cardContent.name} />
			</div>
			<div className='link-container'>
				<ActionLinkComponent
					text={cardContent.name || ''}
					onClick={() => {}}
					underline={true}
					icon='ri-arrow-right-line'
					iconPosition='end'
					iconOnly={false}
					color='dark'
					fontWigth='semi-bold'
					size='big'
					to={
						cardContent?.filters && cardContent?.filters?.length > 0
							? `${productsPageUrl}?${buildUrlParameters(cardContent?.filters)}`
							: cardContent.linkUrl || ''
					}
					linkToExternal={(cardContent?.filters && cardContent?.filters?.length > 0) || true}
				/>
			</div>
			<div className='description-container'>
				<p className='description'>{cardContent.description}</p>
			</div>
		</div>
	);
};
interface INavbarOverlayComponentProps {
	handleAddCategoryFilter: (filter: FilterVM) => void;
	categoryTab?: ICategoryNavbar;
	setCategoryTab: (category?: ICategoryNavbar) => void;
	handleAddFilterFromNavbar: (filter: FilterVM[]) => void;
	registerUserClickEvent: (event: string) => void;
	hasClickedOutsideBar: boolean;
	eventName: string;
	user: any;
	productsPageUrl: string;
	allCategories: FilterVM[];
}

export const NavbarOverlayComponent: FC<INavbarOverlayComponentProps> = ({
	categoryTab,
	setCategoryTab,
	handleAddFilterFromNavbar,
	handleAddCategoryFilter,
	registerUserClickEvent,
	hasClickedOutsideBar,
	eventName,
	user,
	productsPageUrl,
	allCategories
}) => {
	const eventSectionId = 'event';
	const newSectionId = 'new';
	const useCardElements = categoryTab?.id === eventSectionId || categoryTab?.id === newSectionId;
	useEffect(() => {
		if (hasClickedOutsideBar) {
			setCategoryTab(undefined);
		}
	}, [hasClickedOutsideBar, setCategoryTab]);
	return (
		<div
			className={`category-nav-overlay ${useCardElements ? 'cards-case' : ''} ${
				process.env.REACT_APP_USE_NEW_BEACON === 'true' ? '' : 'nexty-case'
			} ${categoryTab?.id === 'new' ? 'large-blue' : ''}`}
			onMouseLeave={() => setCategoryTab(undefined)}
		>
			{useCardElements ? (
				<div className='navbar-card-elements-container'>
					{categoryTab.elements.map((element: ICategoryNavbarFilter) => (
						<CardNavbarElementsItem
							cardContent={element}
							key={element.id}
							handleAddFilterFromNavbar={handleAddFilterFromNavbar}
							registerUserClickEvent={registerUserClickEvent}
							setCategoryTab={setCategoryTab}
							eventName={eventName}
							user={user}
							productsPageUrl={productsPageUrl}
						/>
					))}
				</div>
			) : (
				<div className='category-navbar-container'>
					{categoryTab?.elements.map((element: ICategoryNavbarFilter) => (
						<CategoryNavbarCardItemComponent
							key={element.id}
							filters={element?.filters}
							handleAddCategoryFilter={handleAddCategoryFilter}
							categoryTab={categoryTab}
							registerUserClickEvent={registerUserClickEvent}
							productsPageUrl={productsPageUrl}
							allCategories={allCategories}
						/>
					))}
				</div>
			)}
			<div className='overlay-footer'></div>
		</div>
	);
};
