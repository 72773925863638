import { FC, useEffect, useRef, useState } from 'react';
import ProductListComponent from '../../components/product-list/ProductListComponent';
import { connect, useSelector, useDispatch } from 'react-redux';
import { AppState, RootState } from '../../redux/AppReducer';
import { ApolloActions } from '../../redux/ApolloActions';
import { Action, Dispatch } from 'redux';
import { mapFiltersToQuery } from '../../components/filter/generic-filter/model';
import { GET_PRODUCTS } from '../../graphql/queries/products';
import {
	FilterQueryVM,
	FilterType,
	IFiltersUrlParameters,
	ISuggestion,
	ITrackingEvent,
	TrackingEmailVariables,
	TrackingEventStatus,
	TrackingEventType
} from '../../common/view-model';
import { useCallback } from 'react';
import { AppActions } from '../../redux/AppActions';
import { UserType } from '../../common/model';
import { ProductPagination } from '../../common/view-model';
import { useSetUrlParameters } from '../../hooks/useSetUrlParameters';
import { SearchActions } from '../../redux/search/SearchActions';
import { FilterActions } from '../../redux/FilterActions';
import { useGetUrlParameters } from '../../hooks/useGetUrlParameters';
import { GET_BRAND } from '../../graphql/queries/brand';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getFilterTypeKeyForUrl } from '../../helpers/urlParametersHelpers';
import { getUrlTrackingParamsObject } from '../../helpers/getUrlParamsObject';
import { handleTracking } from '../../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
import { IirisIdentityKey, getLocalStorageValue } from '../../helpers/authenticationHelper';

const ProductsPage: FC<RootState> = (props: any) => {
	const { setSearchTerm, setSelectedFilters, setPage, setProductsCached, setUserReturns } = props;
	const mobileFilterPanelRef = useRef<HTMLDivElement>(null);
	const [viewMode] = useState('grid');
	const [products, setProducts] = useState<any>([]);
	const [totalCount, setTotalCount] = useState<number>();
	const [initLoading, setInitLoading] = useState(true);
	const [isStartGetProduct, setIsStartGetProduct] = useState(false);
	const [userType, setUserType] = useState<UserType | undefined>();
	const dispatch = useDispatch();
	const [offset, setOffset] = useState(0);
	const limit = 24;
	const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
	const { storeState } = props.appState;
	const hasMappedAllFilters = props.appState.filterState?.hasMappedAllFilters;
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const brandId = params.get(getFilterTypeKeyForUrl(FilterType.BRAND));
	const [isBrandLoaded, setIsBrandLoaded] = useState((brandId && storeState?.brand) || !brandId);
	const [ingredientsTemplateId, setIngredientsTemplateId] = useState<number | undefined>(
		props.appState?.userState?.ingredientsTemplateId
	);
	const selectedFilters = useSelector(
		(state: RootState) => state.appState.filterState?.selectedFilters
	);
	const searchTerm = useSelector((state: RootState) => state.appState.searchState?.searchTerm);
	const isLoading = useSelector((state: RootState) => state.appState.isLoadingData);
	const expoEvent = useSelector((state: RootState) => state.appState.storeState?.getCurrentEvent);
	const selectedSortBy = useSelector(
		(state: RootState) => state.appState.filterState?.sortBySelected
	);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const [searchParams, setSearchParams] = useSearchParams();
	const isLoadingCallback = useCallback(
		() => dispatch(AppActions.setIsLoadingData(true)),
		[dispatch]
	);

	useEffect(() => {
		setIngredientsTemplateId(props.appState?.userState?.ingredientsTemplateId);
		setUserType(props.appState?.userState?.user?.user_type);
	}, [props.appState?.userState]);

	useEffect(() => {
		const savedPosition = parseInt('' + sessionStorage.getItem('scrollPosition'), 10);
		if (mobileFilterPanelRef.current) return;
		if (expoEvent === undefined) return;
		if (savedPosition > 0) return;
		const urlParams = new URLSearchParams(window.location.search);
		if (window.location.search && selectedFilters?.length === 0 && !urlParams.get('searchTerm'))
			return;
		if (!isInfiniteLoading && !storeState.userReturns) {
			setPage(0);
			setOffset(0);
			isLoadingCallback();
		}
		let variable: FilterQueryVM[] = [];
		if (selectedFilters && selectedFilters.length > 0) {
			const queryFilers = mapFiltersToQuery(selectedFilters);
			variable = queryFilers;
			const brandObject = variable.find(item => item.key === 'Brand');
			if (brandId !== null && brandObject!.ids.length > 0) {
				const isBrandIdPresent = brandObject && brandObject.ids.includes(brandId);
				if (!isBrandIdPresent) return;
			}
		}
		let pagination: ProductPagination = { offset: offset, limit: limit };
		if (storeState.userReturns) return;
		setIsStartGetProduct(true);
		props.getProducts({
			genericFilters: variable,
			sortAttribute: selectedSortBy?.productSort.attribute,
			sortDirection: selectedSortBy?.productSort.order,
			templateId: String(ingredientsTemplateId || '1'),
			pagination: pagination,
			searchTerm: { type: searchTerm?.type || null, term: searchTerm?.name },
			expoEventId: expoEvent?.id || null,
			whatsNew:
				selectedFilters?.some(f => f.type === FilterType.WHATS_NEW) &&
				!selectedFilters?.some(f => f.type === FilterType.NEW_TO_BEACON) &&
				!selectedFilters?.some(f => f.type === FilterType.NEW_TO_MARKET) &&
				!selectedFilters?.some(f => f.type === FilterType.NEXTY_FINALISTS),
			isNewBeacon: selectedFilters?.some(f => f.type === FilterType.NEW_TO_BEACON),
			isNewProduct: selectedFilters?.some(f => f.type === FilterType.NEW_TO_MARKET),
			isNextyFinalist: selectedFilters?.some(f => f.type === FilterType.NEXTY_FINALISTS)
		});
	}, [
		selectedFilters,
		selectedSortBy,
		ingredientsTemplateId,
		userType,
		offset,
		limit,
		expoEvent?.id,
		searchTerm,
		mobileFilterPanelRef.current
	]);

	useEffect(() => {
		if (isStartGetProduct && !isLoading) {
			setInitLoading(false);
		}
	}, [isStartGetProduct, isLoading]);

	useEffect(() => {
		if (isInfiniteLoading) {
			setProducts([...products, ...props.appState?.storeState?.products]);
			setIsInfiniteLoading(false);
		} else setProducts(props.appState?.storeState?.products);
		setTotalCount(props.appState?.storeState?.totalCount);
	}, [props.appState?.storeState?.products]);

	useEffect(() => {
		props.setProfileLayout(false);
		props.setStandAloneLayout(false);
		let searchTrackingObject = getUrlTrackingParamsObject(
			TrackingEmailVariables.UTM_TRACKING,
			searchParams
		);
		if (searchTrackingObject.trackingParam && searchTrackingObject.trackingParam === 'true') {
			const trackingEvent: ITrackingEvent = {
				transactionId: uuidv4(),
				status: TrackingEventStatus.SUCCESS,
				type: TrackingEventType.EMAIL_TRACKING_DISCOVERY,
				timeStamp: new Date().toISOString(),
				entityId: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
				eventPayload: {
					id: `email-tracking-discovery-${new Date().toISOString()}`,
					name: 'email-tracking-discovery',
					type: 'email-tracking',
					url: window.location.href,
					tags: [],
					metadata: {
						user_id: props.appState?.userState?.user?.user_id,
						user_type: props.appState?.userState?.user_type,
						enviroment: process.env.REACT_APP_ENV_NAME
					}
				}
			};
			handleTracking(trackingEvent);
			delete searchTrackingObject.object[TrackingEmailVariables.UTM_TRACKING];
			setSearchParams(searchTrackingObject.object);
		}
	}, []);

	useEffect(() => {
		setUserReturns(false);
		const savedPosition = parseInt('' + sessionStorage.getItem('scrollPosition'), 10);
		if (storeState.userReturns || savedPosition > 0) {
			setInitLoading(false);
			return;
		}
		setOffset(0);
		setPage(0);
	}, [selectedFilters, selectedSortBy]);

	useEffect(() => {
		const savedPosition = parseInt('' + sessionStorage.getItem('scrollPosition'), 10);
		if (savedPosition > 0) return;
		if (!storeState.userReturns) {
			setProductsCached([...storeState.productsCached, ...props.appState?.storeState?.products]);
		}
		if (storeState.page === 0) {
			setProductsCached([...props.appState?.storeState?.products]);
		}
		setTotalCount(props.appState?.storeState?.totalCount);
		setInitLoading(false);
	}, [products, setProductsCached]);

	const fetchMoreData = useCallback(() => {
		setUserReturns(false);
		const prevOffset = offset;
		const pNum = storeState.page + 1;
		if (prevOffset + limit > totalCount!) {
			setOffset(prevOffset);
			setIsInfiniteLoading(false);
		} else {
			setOffset(pNum * limit);
			setIsInfiniteLoading(true);
		}
		setPage(pNum);
	}, [offset, totalCount, props.appState.deviceScreen]);

	useEffect(() => {
		if (brandId) {
			props.getBrandById({ id: brandId });
			window.scrollTo(0, 0);
		}
	}, [brandId]);

	useEffect(() => {
		setIsBrandLoaded((brandId && storeState?.brand) || !brandId);
	}, [brandId, storeState?.brand]);
	useGetUrlParameters(setSearchTerm, setSelectedFilters, hasMappedAllFilters, isBrandLoaded);
	useSetUrlParameters(props.setFilterUrl, searchTerm, selectedFilters);
	return (
		<>
			<ProductListComponent
				products={storeState.productsCached}
				totalCount={totalCount}
				viewMode={viewMode}
				initLoading={initLoading}
				infiniteLoading={isInfiniteLoading}
				fetchMoreData={fetchMoreData}
				mobileFilterPanelRef={mobileFilterPanelRef}
			/>
		</>
	);
};

const mapStateToProps = (state: AppState) => ({
	...state
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	getProducts: (variables = null) => {
		return dispatch(ApolloActions.query({ query: GET_PRODUCTS, variables }));
	},
	setStandAloneLayout: (standAloneLayout: boolean) =>
		dispatch(AppActions.setStandAloneLayout(standAloneLayout)),
	setProfileLayout: (isProfileLayout: boolean) =>
		dispatch(AppActions.setProfileLayout(isProfileLayout)),
	getBrandById: (variables = null) => {
		return dispatch(ApolloActions.query({ query: GET_BRAND, variables }));
	},
	setProductsCached: (products: any) => {
		dispatch(AppActions.setProductsCached(products));
	},
	setPage: (page: number) => {
		dispatch(AppActions.setPage(page));
	},
	setUserReturns: (state: boolean) => {
		dispatch(AppActions.setUserReturns(state));
	},
	setSearchTerm: (searchTerm: ISuggestion) => dispatch(SearchActions.setSearchTerm(searchTerm)),
	setSelectedFilters: (selectedFilters: IFiltersUrlParameters) => {
		dispatch(FilterActions.setSelectedFiltersFromUrl(selectedFilters));
	},
	setFilterUrl: (url: string) => {
		dispatch({ type: FilterActions.MAP_BROWSER_URL, payload: url });
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
