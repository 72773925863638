const DEFAULT_STATE: IContactInformationModel = {
	contactBrandTitle: 'Brand Contact',
	brokerBrandTitle: 'Broker',
	requestSampleTitle: 'Request A Sample'
};
interface IContactInformationModel {
	contactBrandTitle: string;
	brokerBrandTitle: string;
	requestSampleTitle: string;
}
export const contactInformationModel = {
	...DEFAULT_STATE
};
