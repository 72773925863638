import './SocialMediaFormComponent.scss';
import { useEffect, useState } from 'react';
import { BrandInfo, IProfileState } from '../../../../redux/AppReducer';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { validationMessages, validationRegex } from '../../../../common/constants';
import { DEFAULT_LABELS } from './model';
import LoadingBobComponent from '../../GenericBaseForms/LoadingBobComponent/LoadingBobComponent';

interface ISocialMediaForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (brandState: BrandInfo) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
	profileState: IProfileState;
}

const BrandSocialMediaSchema = Yup.object().shape({
	brandYoutube: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(120, validationMessages.max_value + '120.'),
	brandInstagram: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value + '100.'),
	brandTiktok: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value + '100.'),
	brandTwitter: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(75, validationMessages.max_value + '75.'),
	brandFacebook: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value + '100.'),
	brandLinkedin: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value + '100.')
});

const SocialMediaFormComponent: React.FC<ISocialMediaForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	onChange,
	onValidationChange,
	cleanForm,
	profileState
}) => {
	const ID = 'social-media-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	const { errors, values, handleChange, handleReset, submitForm, isValid, setValues } = useFormik({
		initialValues: {
			...brandState,
			brandYoutube: brandState.brandYoutube ? brandState.brandYoutube : '',
			brandInstagram: brandState.brandInstagram ? brandState.brandInstagram : '',
			brandTiktok: brandState.brandTiktok ? brandState.brandTiktok : '',
			brandTwitter: brandState.brandTwitter ? brandState.brandTwitter : '',
			brandFacebook: brandState.brandFacebook ? brandState.brandFacebook : '',
			brandLinkedin: brandState.brandLinkedin ? brandState.brandLinkedin : ''
		},
		onSubmit: values => {
			onUpdateProfileBrand({
				...values
			});
		},
		validationSchema: BrandSocialMediaSchema
	});
	useEffect(() => {
		onChange(values);
		onValidationChange(isValid);
	}, [values, isValid]);

	useEffect(() => {
		handleReset(null);
	}, [cleanForm]);
	useEffect(() => {
		if (isEditing) {
			setValues({
				...brandState,
				brandYoutube: brandState.brandYoutube ? brandState.brandYoutube : '',
				brandInstagram: brandState.brandInstagram ? brandState.brandInstagram : '',
				brandTiktok: brandState.brandTiktok ? brandState.brandTiktok : '',
				brandTwitter: brandState.brandTwitter ? brandState.brandTwitter : '',
				brandFacebook: brandState.brandFacebook ? brandState.brandFacebook : '',
				brandLinkedin: brandState.brandLinkedin ? brandState.brandLinkedin : ''
			});
		}
	}, [brandState, isEditing]);
	return (
		<div className='social-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				isValid={isValid}
				onUpdate={() => submitForm()}
				titleText='Social Media'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
				profileState={profileState}></TitleForms>
			{ID === profileState.sectionId && profileState.loading ? (
				<LoadingBobComponent></LoadingBobComponent>
			) : (
				<>
					{isVisible &&
						(isEditing !== ID ? (
							<>
								<ContainerForms
									FormComponent1={
										<>
											{' '}
											<div>
												<LabelForms text={DEFAULT_LABELS.brandYoutube}></LabelForms>
												<p
													className={brandState?.brandYoutube ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandYoutube}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandInstagram}></LabelForms>
												<p
													className={brandState?.brandInstagram ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandInstagram}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandTiktok}></LabelForms>

												<p
													className={brandState?.brandTiktok ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandTiktok}
												</p>
											</div>
										</>
									}
									FormComponent2={
										<>
											{' '}
											<div>
												<LabelForms text={DEFAULT_LABELS.brandTwitter}></LabelForms>
												<p
													className={brandState?.brandTwitter ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandTwitter}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandFacebook}></LabelForms>
												<p
													className={brandState?.brandFacebook ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandFacebook}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandLinkedin}></LabelForms>
												<p
													className={brandState?.brandLinkedin ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}>
													{brandState?.brandLinkedin}
												</p>
											</div>
										</>
									}
								/>
							</>
						) : (
							<>
								<ContainerForms
									FormComponent1={
										<>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandYoutube}></LabelForms>
												<TextInputComponent
													error={errors.brandYoutube?.length! > 0}
													errorHelperText={<div>{errors.brandYoutube}</div>}
													placeholder={DEFAULT_LABELS.brandYoutube}
													name='brandYoutube'
													onChange={handleChange}
													value={values.brandYoutube!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandInstagram}></LabelForms>
												<TextInputComponent
													error={errors.brandInstagram?.length! > 0}
													errorHelperText={<div>{errors.brandInstagram}</div>}
													placeholder={DEFAULT_LABELS.brandInstagram}
													name='brandInstagram'
													onChange={handleChange}
													value={values.brandInstagram!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text='TikTok'></LabelForms>
												<TextInputComponent
													error={errors.brandTiktok?.length! > 0}
													errorHelperText={<div>{errors.brandTiktok}</div>}
													placeholder='TikTok'
													name='brandTiktok'
													onChange={handleChange}
													value={values.brandTiktok!}></TextInputComponent>
											</div>
										</>
									}
									FormComponent2={
										<>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandTwitter}></LabelForms>
												<TextInputComponent
													error={errors.brandTwitter?.length! > 0}
													errorHelperText={<div>{errors.brandTwitter}</div>}
													placeholder={DEFAULT_LABELS.brandTwitter}
													name='brandTwitter'
													onChange={handleChange}
													value={values.brandTwitter!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandFacebook}></LabelForms>
												<TextInputComponent
													error={errors.brandFacebook?.length! > 0}
													errorHelperText={<div>{errors.brandFacebook}</div>}
													placeholder={DEFAULT_LABELS.brandFacebook}
													name='brandFacebook'
													onChange={handleChange}
													value={values.brandFacebook!}></TextInputComponent>
											</div>
											<div>
												<LabelForms text='LinkedIn'></LabelForms>
												<TextInputComponent
													error={errors.brandLinkedin?.length! > 0}
													errorHelperText={<div>{errors.brandLinkedin}</div>}
													placeholder='LinkedIn'
													name='brandLinkedin'
													onChange={handleChange}
													value={values.brandLinkedin!}></TextInputComponent>
											</div>
										</>
									}></ContainerForms>
								<ActionsForms
									onUpdate={() => submitForm()}
									onCancel={() => {
										onCancel();
										handleReset(null);
									}}
									isValid={isValid}
									toggleEditing={() => toggleEditing(null)}></ActionsForms>
							</>
						))}
				</>
			)}
		</div>
	);
};

export default React.memo(SocialMediaFormComponent);
