import { useEffect, useState } from 'react';
import Swiper from 'swiper';

export const useBeginingAndEnd = (
	swiperRef: React.MutableRefObject<Swiper | undefined>,
	hasSlideChange: boolean,
	sethasSlideChange: (hasChange: boolean) => void
) => {
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	useEffect(() => {
		setIsBeginning(swiperRef.current?.isBeginning!);
		setIsEnd(swiperRef.current?.isEnd!);
		return () => {
			sethasSlideChange(false);
		};
	}, [hasSlideChange, swiperRef, sethasSlideChange, window.innerWidth]);
	return { isBeginning, isEnd };
};
