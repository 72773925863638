import React from 'react';
import './LoadingBobComponent.scss';

export default function LoadingBobComponent() {
	return (
		<div className='loading-container'>
			<div className='templates-skeleton'></div>
			<div className='templates-skeleton'></div>
		</div>
	);
}
