import { Dispatch, FC } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { IngredientCategory } from '../../../common/view-model';
import { IngredientFilterActions } from '../../../redux/ingredientFilter/IngredientFilterActions';
import './FilterIngredientTemplateComponent.scss';

interface IFilterIngredientTemplateItemComponentProps {
	item: IngredientCategory;
	onClick: (item: IngredientCategory) => void;
	selectedFilter: IngredientCategory | undefined;
	allCategoriesId: string;
}
const FilterIngredientTemplateItemComponent: FC<IFilterIngredientTemplateItemComponentProps> = ({
	item,
	onClick,
	selectedFilter,
	allCategoriesId
}) => {
	const isCurrentFilter =
		selectedFilter?.id === item.id || (!selectedFilter && item.id === allCategoriesId);
	return (
		<div
			className={`filter-template-item-container ${isCurrentFilter ? 'current' : ''}`}
			onClick={() => onClick(item)}
		>
			{' '}
			<span className='filter-item-text'>{item.name}</span>
			{isCurrentFilter && <i className='ri-check-line'></i>}
		</div>
	);
};

interface IFilterIngredientTemplateComponentProps {
	selectedFilter: IngredientCategory | undefined;
	ingredientFilterOptions: IngredientCategory[] | undefined;
	setSelectedFilter: (selectedFilter?: IngredientCategory) => void;
	onSelectFilter: () => void;
}
const FilterIngredientTemplateComponent: FC<IFilterIngredientTemplateComponentProps> = ({
	selectedFilter,
	ingredientFilterOptions,
	setSelectedFilter,
	onSelectFilter
}) => {
	const allCategoriesId = 'all';
	const handleFilterClick = (item: IngredientCategory) => {
		if (item.id === allCategoriesId) {
			setSelectedFilter(undefined);
		} else {
			setSelectedFilter(item);
		}
		onSelectFilter();
	};
	if (!ingredientFilterOptions || ingredientFilterOptions?.length === 0) {
		return null;
	}
	return (
		<div className='template-filter-container' onMouseLeave={onSelectFilter}>
			{ingredientFilterOptions.map((item: IngredientCategory) => {
				return (
					<FilterIngredientTemplateItemComponent
						key={item.id}
						item={item}
						onClick={handleFilterClick}
						selectedFilter={selectedFilter}
						allCategoriesId={allCategoriesId}
					/>
				);
			})}
		</div>
	);
};
const mapStateToProps = ({ appState }: RootState) => ({
	selectedFilter: appState.ingredientFilterState?.selectedFilter,
	ingredientFilterOptions: appState.ingredientFilterState?.filterOptions
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setSelectedFilter: (selectedFilter?: IngredientCategory) => {
		return dispatch(IngredientFilterActions.setIngredientFilter(selectedFilter));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterIngredientTemplateComponent);
