import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { AppActions } from '../../../redux/AppActions';
import { AuthActions } from '../../../redux/AuthActions';
import Popup from 'reactjs-popup';
import './PopUpComponent.scss';

interface IProductComponentProps {
	open: boolean;
	IsModal: boolean;
	shouldLockScroll: boolean;
	overlayStyle: any;
	content: any;
	closeOnDocumentClick: boolean;
	showCloseIcon?: boolean;
	isCompanyDetailsModal?: boolean;
}

export const PopUpComponent: FC<IProductComponentProps> = ({
	open,
	IsModal,
	shouldLockScroll,
	overlayStyle,
	content,
	closeOnDocumentClick,
	showCloseIcon,
	isCompanyDetailsModal
}) => {
	const dispatch = useDispatch();
	const closePopUpCallback = useCallback(
		() => dispatch(AppActions.setModalContent(undefined)),
		[dispatch]
	);
	const companyDetailsCloseCallback = useCallback(
		() => dispatch(AuthActions.closeModalCompanyDetails()),
		[dispatch]
	);
	const handleClosePopUp = () => {
		if (isCompanyDetailsModal) {
			companyDetailsCloseCallback();
		}
		closePopUpCallback();
	};
	useEffect(() => {
		const popUpRef = document.getElementById('popup-content');
		popUpRef && popUpRef.scrollTo(0, 0);
	}, []);
	return (
		<Popup
			open={open}
			modal={IsModal}
			lockScroll={shouldLockScroll}
			overlayStyle={overlayStyle}
			closeOnDocumentClick={closeOnDocumentClick}
			onClose={closePopUpCallback}
		>
			<div
				data-testid='close-icon'
				id='popup-content'
				className={!isCompanyDetailsModal ? 'score-wrapper' : ''}
			>
				{showCloseIcon && (
					<span data-testid='close-pop-up' id='close-popup' onClick={handleClosePopUp}>
						<i className='ri-close-line icon'></i>
					</span>
				)}
				{content}
			</div>
		</Popup>
	);
};
