export const DEFAULT_INFO_STATE: ModelInfo = {
	infoTitle:
		'NEW! Check your application status or update your brand information in the Beacon Hub!',
	infoText:
		'Log in with the email address used in the Standards form and select ‘Brand Info’ in your profile.'
};

interface ModelInfo {
	infoTitle: string;
	infoText: string;
}
