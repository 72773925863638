import ProductComponent from '../product/ProductComponent';
import FilterControlComponent from '../filter/filter-control/FilterControlComponent';
import FilterMenuComponent from '../filter/filter-menu/FilterMenuComponent';
import { Dispatch, useEffect, useState } from 'react';
import { Events, Product } from '../../common/model';
import { connect } from 'react-redux';
import { TopBubblesSectionComponent } from '../TopBubblesSection/TopBubblesSectionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FavoriteAddDate, RootState } from '../../redux/AppReducer';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useCallback } from 'react';
import { AppActions } from '../../redux/AppActions';
import { FilterErrorComponent } from '../FilterError/FilterErrorComponent';
import { FilterActions } from '../../redux/FilterActions';
import {
	FilterType,
	FilterVM,
	ISuggestion,
	ScreenType,
	viewModeType
} from '../../common/view-model';
import { Action } from 'redux';
import './ProductListComponent.scss';
import ProductListLoadingComponent from './productListLoading/ProductListLoadingComponent';
import { ProductListSkeletonComponent } from './productListLoading/ProductListSkeletonComponent/ProductListSkeletonComponent';
import { PrepExpoDiscovery } from '../PrepExpoDiscovery/PrepExpoDiscovery';
import { CategoryBreadcrumbComponent } from '../filter/category-breadcrumb/CategoryBreadcrumbComponent';
import { publicProductDiscoveryActions } from '../../common/constants';

interface IProductListComponentProps {
	products: Product[];
	viewMode: string;
	initLoading: Boolean;
	infiniteLoading: Boolean;
	favoriteProducts: FavoriteAddDate[];
	deviceScreen?: ScreenType;
	totalCount: number;
	setFilterPanelClickOutside: (value: () => void) => void;
	clearModalContent: () => void;
	setShowFilterMobilePanel: (value: boolean) => void;
	fetchMoreData: () => void;
	hasLoadProfile: boolean;
	setCurrentFilterOpenIndex?: (index?: number) => void;
	currentEvent: Events | undefined | null;
	searchTerm?: ISuggestion;
	showEventModal: () => void;
	setProductsCached: (products: Product[]) => void;
	mobileFilterPanelRef?: React.RefObject<HTMLDivElement>;
}

const ProductListComponent = ({
	products,
	viewMode,
	initLoading,
	infiniteLoading,
	favoriteProducts,
	deviceScreen,
	totalCount,
	setFilterPanelClickOutside,
	clearModalContent,
	setShowFilterMobilePanel,
	fetchMoreData,
	hasLoadProfile,
	setCurrentFilterOpenIndex,
	currentEvent,
	searchTerm,
	showEventModal,
	mobileFilterPanelRef
}: IProductListComponentProps) => {
	const searchTermText = `Results for '${searchTerm?.name}'`;
	const allCateogriesText = 'All Categories';
	const dispatch = useDispatch();
	const size = useWindowSize();
	const [productList, setProductList] = useState(products);
	const [showFilterPanel, setShowFilterPanel] = useState<boolean>(true);
	const initialProductsLength = viewMode === 'grid' ? 12 : 16;
	const mobileScrollThreshold = 1500;
	const otherScreenScrollThreshold = 0;
	const [productLength, setProductLength] = useState<number>(initialProductsLength);
	const [loadableProducts, setLoadableProducts] = useState<Product[]>(
		products.slice(0, initialProductsLength)
	);
	const [deviceViewMode, setDeviceViewMode] = useState<viewModeType>(
		size.width > 768 ? viewModeType.DESKTOP : viewModeType.MOBILE
	);
	const isLoading = useSelector((state: RootState) => state.appState.isLoadingData);
	const storeState = useSelector((state: RootState) => state.appState.storeState);
	const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
	const selectedCategory = useSelector(
		(state: RootState) => state.appState.filterState?.selectedCategory
	);
	const selectedFilters = useSelector(
		(state: RootState) => state.appState.filterState?.selectedFilters
	);
	const [lastCategoryFilter, setLastCategoryFilter] = useState<FilterVM | undefined>(
		selectedFilters
			?.filter(
				item =>
					item.type === FilterType.CATEGORY ||
					item?.type === FilterType.NEW_TO_BEACON ||
					item?.type === FilterType.WHATS_NEW ||
					item?.type === FilterType.NEW_TO_MARKET ||
					item?.type === FilterType.NEXTY_FINALISTS
			)
			.pop()
	);
	const showPageTitleCallback = useCallback(
		() => dispatch(AppActions.setAppTitle(undefined)),
		[dispatch]
	);

	window.onbeforeunload = function () {
		window.scrollTo(0, 0);
	};
	const clearAllFiltersCallback = useCallback(
		() => dispatch(FilterActions.clearAllFilters()),
		[dispatch]
	);

	const hasData =
		storeState?.totalCount !== undefined && !initLoading && storeState.filters !== undefined;

	const [showMobileFilterPanel, setShowMobileFilterPanel] = useState<boolean>(
		deviceViewMode === viewModeType.MOBILE && !showFilterPanel
	);
	const onFilterPanelOutsiteClick = async () => {
		const bodyRef = document.getElementsByTagName('body')[0] as HTMLElement;
		bodyRef!.style.overflow = 'auto';
		setAnimation('animated-hide');
		await new Promise(r => setTimeout(r, 500));
		setShowFilterPanel(false);
		clearModalContent();
	};
	const [animation, setAnimation] = useState<string | undefined>('animated-open');
	const handleClosePanel = async (miliseconds: number) => {
		if (deviceScreen === ScreenType.TABLET) {
			setAnimation('animated-hide');
			await new Promise(r => setTimeout(r, miliseconds));
			setShowFilterPanel(false);
		} else {
			setAnimation(undefined);
			setShowFilterPanel(false);
		}
		setCurrentFilterOpenIndex && setCurrentFilterOpenIndex(undefined);
	};
	const handleOpenPanel = () => {
		setAnimation('animated-open');
		setShowFilterPanel(true);
	};

	useEffect(() => {
		const savedPosition = parseInt('' + sessionStorage.getItem('scrollPosition'), 10);
		if (savedPosition > 0) {
			setProductList(products);
			setTimeout(() => {
				window.scrollTo(0, savedPosition);
				sessionStorage.removeItem('scrollPosition');
			}, 200);
			return;
		}
		setProductList(products);
	}, [products]);

	useEffect(() => {
		setProductLength(viewMode === 'grid' ? 12 : 16);
	}, [viewMode]);

	useEffect(() => {
		setLoadableProducts(products.slice(0, productLength));
	}, [products, productLength]);

	useEffect(() => {
		if (size.width < 768) {
			setDeviceViewMode(viewModeType.MOBILE);
		} else {
			setDeviceViewMode(viewModeType.DESKTOP);
		}
	}, [size]);
	useEffect(() => {
		showPageTitleCallback();
	}, []);
	useEffect(() => {
		window.onpopstate = () => {
			setProductLength(12);
			setLoadableProducts(products.slice(0, productLength));
		};
	}, [loadableProducts, productLength, products]);

	useEffect(() => {
		if (deviceScreen === ScreenType.TABLET) {
			setShowFilterPanel(false);
			setFilterPanelClickOutside(onFilterPanelOutsiteClick);
		} else {
			setShowFilterPanel(true);
			setAnimation('animated-open');
		}
	}, [deviceScreen]);
	useEffect(() => {
		setShowFilterMobilePanel(showMobileFilterPanel);
	}, [showMobileFilterPanel]);
	const handleScroll = useCallback(() => {
		if (
			window.innerHeight + document.documentElement.scrollTop <=
				document.documentElement.offsetHeight -
					(deviceScreen === ScreenType.MOBILE
						? mobileScrollThreshold
						: otherScreenScrollThreshold) ||
			infiniteLoading
		) {
			return;
		}
		canLoadMore && fetchMoreData();
	}, [canLoadMore, fetchMoreData, infiniteLoading]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [infiniteLoading, handleScroll]);

	useEffect(() => {
		const validateCanLoad =
			storeState?.products &&
			storeState.totalCount &&
			storeState?.products.length < storeState?.totalCount;
		if (validateCanLoad) {
			setCanLoadMore(true);
		} else {
			setCanLoadMore(false);
		}
	}, [storeState?.products, storeState?.totalCount]);
	//Temporarily commented this BP - 1216
	// useEffect(() => {
	// 	if (window.localStorage.getItem('discover') !== 'done' && currentEvent) {
	// 		showEventModal();
	// 	}
	// }, [currentEvent, showEventModal]);
	useEffect(() => {
		setLastCategoryFilter(
			selectedFilters
				?.filter(
					item =>
						item.type === FilterType.CATEGORY ||
						item?.type === FilterType.NEW_TO_BEACON ||
						item?.type === FilterType.WHATS_NEW ||
						item?.type === FilterType.NEW_TO_MARKET ||
						item?.type === FilterType.NEXTY_FINALISTS
				)
				.pop()
		);
	}, [selectedFilters]);

	return (
		<>
			{hasData ? (
				<>
					{showMobileFilterPanel ? (
						<FilterMenuComponent
							handelClosePanel={() => setShowMobileFilterPanel(false)}
							viewMode={deviceViewMode}
							mobileFilterPanelRef={mobileFilterPanelRef}
						/>
					) : (
						<>
							<div className='category-title-container'>
								<span className='category-title'>
									{searchTerm ? searchTermText : selectedCategory || allCateogriesText}
								</span>
							</div>
							{(!searchTerm || (searchTerm && selectedFilters?.length !== 0)) && (
								<CategoryBreadcrumbComponent elements={lastCategoryFilter?.breadCrumbElements} />
							)}
							<div
								className={`${showFilterPanel ? 'products-wrapper ' : ''}${
									searchTerm ? 'search-wrapper' : ''
								}`}
							>
								{deviceViewMode === viewModeType.DESKTOP && showFilterPanel && (
									<div className={animation}>
										<FilterMenuComponent
											handelClosePanel={() => handleClosePanel(500)}
											viewMode={deviceViewMode}
											oonClearAnimation={() => setAnimation(undefined)}
										/>
									</div>
								)}
								<div className='col'>
									<div className='row'>
										<FilterControlComponent
											showAllFiltersButton={
												!showFilterPanel || deviceViewMode === viewModeType.MOBILE
											}
											productsLength={totalCount}
											showAllFiltersHandler={
												deviceViewMode === viewModeType.MOBILE
													? () => setShowMobileFilterPanel(true)
													: () => handleOpenPanel()
											}
											clearAllFiltersHandler={clearAllFiltersCallback}
											showClearAllFiltersButton={
												(selectedFilters && selectedFilters.length > 0) || searchTerm
											}
											deviceViewMode={deviceViewMode}
										/>
										{selectedFilters && selectedFilters.length > 0 && (
											<TopBubblesSectionComponent
												bubbles={selectedFilters}
												showFilterPanel={showFilterPanel}
												handleOpenPanel={handleOpenPanel}
												deviceScreen={deviceScreen}
											/>
										)}
									</div>
									<div data-testid='product-list-component' className='row'>
										{!isLoading && hasData ? (
											productList.length !== 0 ? (
												<div
													className={`product-list ${showFilterPanel ? 'expanded' : 'collapsed'} `}
												>
													<div>
														{productList.map((product, index) => {
															return (
																<ProductComponent
																	key={index}
																	product={product}
																	favorite={favoriteProducts?.some(
																		favorite => favorite.pid === product?.id
																	)}
																	anonymousEventName={
																		publicProductDiscoveryActions.CLICK_HEART_ICON
																	}
																/>
															);
														})}
													</div>
												</div>
											) : (
												<FilterErrorComponent type={searchTerm ? 'search' : 'filter'} />
											)
										) : (
											<ProductListSkeletonComponent
												viewMode={showFilterPanel ? 'expanded' : 'collapsed'}
											/>
										)}
										{infiniteLoading && (
											<ProductListSkeletonComponent
												viewMode={showFilterPanel ? 'expanded' : 'collapsed'}
											/>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</>
			) : (
				<ProductListLoadingComponent
					data-testid='loading-state'
					deviceScreen={deviceScreen}
					initLoading={true}
				/>
			)}
		</>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	favoriteProducts: state.userState?.favoriteProducts || [],
	deviceScreen: state.deviceScreen || undefined,
	hasLoadProfile: state.userState?.hasLoadProfile || false,
	currentEvent: state.storeState?.getCurrentEvent,
	searchTerm: state.searchState?.searchTerm
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setFilterPanelClickOutside: (onClickOutside: Function) => {
		return dispatch(FilterActions.addCloseFilterFunctionRef(onClickOutside));
	},
	clearModalContent: () => {
		return dispatch(AppActions.setModalContent(undefined));
	},
	setShowFilterMobilePanel: (show: boolean) => {
		return dispatch(FilterActions.setIsMobileFilterOpen(show));
	},
	setCurrentFilterOpenIndex: (index?: number) => {
		return dispatch(FilterActions.setCurrentFilterOpenIndex(index));
	},
	showEventModal: () => {
		return dispatch(
			AppActions.setModalContent({
				content: <PrepExpoDiscovery />,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListComponent);
