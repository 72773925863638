import './FavoriteSkeletonComponent.scss';
import { ProductLoadingCard } from '../../../product-list/productListLoading/ProductListLoadingComponent';
import { useEffect, useState } from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';

export const FavoriteSkeletonComponent = () => {
	const [cardCount, setCardCount] = useState<number>(10);
	const { width } = useWindowSize();
	useEffect(() => {
		if (width >= 1500) {
			setCardCount(10);
		} else if (width >= 1200 && width < 1500) {
			setCardCount(8);
		} else {
			setCardCount(6);
		}
	}, [width]);
	return (
		<div data-testid='loading-div' className='product-list-skeleton-container'>
			<div className='p-skeleton-body p-skeleton-body-for-filter'>
				<div className='p-skeleton-product-favorite-list'>
					{Array.from(Array(cardCount).keys()).map(i => (
						<ProductLoadingCard key={i} />
					))}
				</div>
			</div>
		</div>
	);
};
