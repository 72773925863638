import { ScreenType, viewModeType } from '../../../common/view-model';
import './ProductListLoadingComponent.scss';

interface IProductListLoadingComponent {
	deviceScreen?: ScreenType;
	initLoading: boolean;
}

export function ProductLoadingCard() {
	return (
		<div className='p-skeleton p-skeleton-product-card'>
			<div className='p-skeleton-product-card-title' />
		</div>
	);
}

export default function ProductListLoadingComponent({
	initLoading,
	deviceScreen
}: IProductListLoadingComponent) {
	return (
		<div className='product-list-skeleton-container'>
			<div className='p-skeleton p-skeleton-title' />
			{deviceScreen !== ScreenType.MOBILE && deviceScreen !== ScreenType.TABLET && (
				<div className='p-skeleton p-skeleton-breadcrumb' />
			)}
			<div className='p-skeleton-body'>
				{deviceScreen !== ScreenType.MOBILE && deviceScreen !== ScreenType.TABLET && (
					<div className='p-skeleton p-skeleton-filter' />
				)}
				<div className='col'>
					<div className='p-skeleton-filter-bubble'>
						{(deviceScreen === ScreenType.MOBILE || deviceScreen === ScreenType.TABLET) && (
							<div className='p-skeleton p-skeleton-filter-m' />
						)}

						<div className='p-skeleton p-skeleton-sort' />
					</div>
					<div className={`p-skeleton-product-list ${initLoading ? 'first-group' : ''}`}>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
							<ProductLoadingCard key={i} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
