const DEFAULT_STATE: IHighlightedBrandsState = {
	highlightedBrandsTitle: 'NEXTYs on Beacon',
	highlightedBrandsText:
		'The NEXTY Awards recognize the most progressive, innovative, inspiring and trustworthy products in the natural products industry. Check out some of the brands on Beacon!'
};

interface IHighlightedBrandsState {
	highlightedBrandsTitle: string;
	highlightedBrandsText: string;
}
export const HighlightedBrandsState = {
	...DEFAULT_STATE
};
