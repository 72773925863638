import { gql } from 'graphql.macro';

export const GET_INGREDIENT_TEMPLATES = gql`
	query GetIngredientTemplates {
		templates {
			id
			name
			description
			ingredients {
				id
				name
				display_name
				usages {
					id
					name
				}
				categories {
					id
					name
				}
			}
		}
	}
`;
