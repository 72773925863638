import { FC, useEffect } from 'react';
import { Product } from '../../../common/model';
import ProductComponent from '../../product/ProductComponent';
import './ProductListComponent.scss';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { AppActions } from '../../../redux/AppActions';
import { FavoriteSkeletonComponent } from './FavoriteSkeletonComponent/FavoriteSkeletonComponent';

interface IProductListComponentProps {
	products: Product[];
	viewMode: string;
	isProfilePage?: boolean;
	isLoading?: boolean;
}

export const FavoriteProductListComponent: FC<IProductListComponentProps> = ({
	products,
	isProfilePage,
	isLoading
}) => {
	const dispatch = useDispatch();
	const showPageTitleCallback = useCallback(
		() => dispatch(AppActions.setAppTitle(undefined)),
		[dispatch]
	);
	useEffect(() => {
		showPageTitleCallback();
	}, []);
	const cleanIsOnFavorites = useCallback(
		() => dispatch(AppActions.isOnFavoritesPage(false)),
		[dispatch]
	);
	useEffect(() => {
		return () => {
			cleanIsOnFavorites();
		};
	}, [cleanIsOnFavorites]);
	return (
		<div data-testid='favorite-list' className='favorite-list'>
			{!isLoading ? (
				<>
					<div className='products-wrapper favorite-products-wrapper'>
						{products.length !== 0 ? (
							<div className={`product-list ${!isProfilePage ? 'favorites-wrapper' : ''}`}>
								<div>
									{products.map((product, index) => {
										return <ProductComponent favorite={true} key={index} product={product} />;
									})}
								</div>
							</div>
						) : null}
					</div>
				</>
			) : (
				<FavoriteSkeletonComponent />
			)}
		</div>
	);
};
