import './WhatIsNewSectionComponent.scss';
import { FC, useRef } from 'react';
import { WhatIsNewSectionModel as model } from './model';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import benvoleo from '../../assets/images/home-page-what-is-new/benvoleo.png';
import byheart from '../../assets/images/home-page-what-is-new/byheart.png';
import davidstea from '../../assets/images/home-page-what-is-new/davidstea.png';
import ritter from '../../assets/images/home-page-what-is-new/ritter.png';
import tostrose from '../../assets/images/home-page-what-is-new/tostrose.png';
import vistaHermosa from '../../assets/images/home-page-what-is-new/vistaHermosa.png';
import { useAnimationEffect } from './WhatIsNewSectionComponent.hooks';
import { NavigateFunction } from 'react-router-dom';
import { FilterVM, ITrackingEvent, TrackingEventStatus, FilterType } from '../../common/view-model';
import { whastNewFilter } from '../../mock/whatIsNewFilter';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import { publicHomePageActions } from '../../common/constants';
import { UserState } from '../../redux/AppReducer';
import { handleTracking } from '../../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
interface IWhatIsNewSectionComponentProps {
	navigate: NavigateFunction;
	addFilter: (filter: FilterVM) => void;
	registerUserClickEvent: (event: string) => void;
	userState?: UserState;
}

export const WhatIsNewSectionComponent: FC<IWhatIsNewSectionComponentProps> = ({
	navigate,
	addFilter,
	registerUserClickEvent,
	userState
}) => {
	const newToMarketUrl = `${
		!isCurrentUserAnonymous() ? '' : '.'
	}/products?whats_new=new&new_to_market=true`;
	const newToBeaconUrl = `${
		!isCurrentUserAnonymous() ? '' : '.'
	}/products?whats_new=new&new_to_beacon=true`;
	const benvoleoImgRef = useRef<HTMLImageElement>(null);
	const byheartImgRef = useRef<HTMLImageElement>(null);
	const vistaHermosaImgRef = useRef<HTMLImageElement>(null);
	const davidsteaImgRef = useRef<HTMLImageElement>(null);
	const ritterImgRef = useRef<HTMLImageElement>(null);
	const tostroseImgRef = useRef<HTMLImageElement>(null);
	useAnimationEffect([
		benvoleoImgRef,
		byheartImgRef,
		vistaHermosaImgRef,
		davidsteaImgRef,
		ritterImgRef,
		tostroseImgRef
	]);
	const handleNavigate = (url: string, filter: FilterVM) => {
		if (isCurrentUserAnonymous() && filter.id === 'new-to-market') {
			registerUserClickEvent(publicHomePageActions.CLICK_NEW_TO_MARKET);
		}
		if (isCurrentUserAnonymous() && filter.id === 'new-to-beacon') {
			registerUserClickEvent(publicHomePageActions.CLICK_NEW_TO_BEACON);
		}
		const trackingPayload: ITrackingEvent = {
			transactionId: uuidv4(),
			entityId: filter.id!,
			status: TrackingEventStatus.SUCCESS,
			type: `Home page click on ${filter.name}`,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: filter.id!,
				name: filter.name!,
				type: FilterType[filter.type!],
				url: window.location.href,
				tags: [],
				metadata: {
					user_type: userState?.user?.user_type,
					user_id: userState?.user?.id,
					environment: process.env.REACT_APP_ENV_NAME
				}
			}
		};
		handleTracking(trackingPayload);
		window.scrollTo(0, 0);
		addFilter(filter);
		navigate(url);
	};

	return (
		<div className='what-is-new-container'>
			<div className='title-container'>
				<span className='what-is-new-title'>{model.title}</span>
			</div>
			<div className='what-is-new-content-container'>
				<div className='new-to-market-container'>
					<div className='new-to-title-container'>
						<span className='new-to-title'>{model.newToMarket.title}</span>
					</div>
					<div className='new-to-content-container'>
						<span className='new-to-content'>{model.newToMarket.content}</span>
					</div>
					<div className='new-to-button-container'>
						<ButtonComponent
							style='rounded-button primary'
							text={model.newToMarket.buttonText}
							icon={<i className='ri-arrow-right-s-line line-icon'></i>}
							onClick={() => handleNavigate(newToMarketUrl, whastNewFilter.children[0])}
						/>
					</div>
				</div>
				<div className='images-container'>
					<img id='benvoleo' src={benvoleo} alt='benvoleo' ref={benvoleoImgRef} />
					<img id='byheart' src={byheart} alt='byheart' ref={byheartImgRef} />
					<img id='vista-hermosa' src={vistaHermosa} alt='vista-hermosa' ref={vistaHermosaImgRef} />
					<img id='davidstea' src={davidstea} alt='davidstea' ref={davidsteaImgRef} />
					<img id='ritter' src={ritter} alt='ritter' ref={ritterImgRef} />
					<img id='tostrose' src={tostrose} alt='tostrose' ref={tostroseImgRef} />
				</div>
				{process.env.REACT_APP_USE_NEW_BEACON === 'true' ? (
					<div className='new-to-beacon-container'>
						<div className='new-to-title-container'>
							<span className='new-to-title'>{model.newToBeacon.title}</span>
						</div>
						<div className='new-to-content-container'>
							<span className='new-to-content'>{model.newToBeacon.content}</span>
						</div>
						<div className='new-to-button-container'>
							<ButtonComponent
								style='rounded-button secondary'
								text={model.newToBeacon.buttonText}
								icon={<i className='ri-arrow-right-s-line line-icon'></i>}
								onClick={() => handleNavigate(newToBeaconUrl, whastNewFilter.children[1])}
							/>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
