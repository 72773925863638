import { APP_NAME } from '../../common/constants';
const DEFAULT_STATE: ILandingPageState = {
	logoText: 'Beacon',
	welcomeText: `Welcome to ${APP_NAME}!`,
	loginMessage: 'Log in or create an account to get started',
	loginButtonText: 'Log In'
};

interface ILandingPageState {
	logoText: string;
	welcomeText: string;
	loginMessage: string;
	loginButtonText: string;
}
export const LandingPageState = {
	...DEFAULT_STATE
};
