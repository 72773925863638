import {
	ProductVM,
	ScreenType,
	TrackingEventStatus,
	TrackingEventType
} from '../../../common/view-model';
import './ProductInformationComponent.scss';
import { FC, useCallback, useEffect, useState } from 'react';
import { productInformationState } from './model';
import { HowGoodScore } from '../howGoodScore/HowGoodScoreComponent';
import { v4 as uuidv4 } from 'uuid';
import { handleTracking } from '../../../helpers/handelTracking';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { UserType } from '../../../common/model';
import { Link, useNavigate } from 'react-router-dom';
import { BoothNumberComponent } from '../../UI/BoothNumberComponent/BoothNumberComponent';
import { ExpoLabelComponent } from '../../UI/ExpoLabelComponent/ExpoLabelComponent';
import marketReady from '../../../assets/images/marketReady.png';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';
import MarketReadyOverlay from '../marketReadyOverlay/MarketReadyOverlay';
import { ExpoEastOverlay } from '../../ExpoEastOverlay/ExpoEastOverlay';
import { findUserAccessType } from '../../../helpers/findUserAccessType';
import {
	isCurrentUserAnonymous,
	validateAnonymousPath
} from '../../../helpers/authenticationHelper';
import { eventPublicPathName, publicProductDetailsActions } from '../../../common/constants';
import { ProductBadgesComponent } from '../badges/ProductBadgesComponent';
interface IProductInformationComponentProps {
	product: ProductVM | undefined;
	registerUserClickEvent: (event: string) => void;
}
export const ProductInformationComponent: FC<IProductInformationComponentProps> = ({
	product,
	registerUserClickEvent
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState({ ...productInformationState, product });
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const deviceScreen = useSelector((state: RootState) => state.appState.deviceScreen);
	const currentEvent = useSelector(
		(state: RootState) => state.appState.storeState?.getCurrentEvent
	);

	const handleGoToBrand = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_CONTACT_BRAND);
		}
		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.CONTACT_BRAND,
			timeStamp: new Date().toISOString(),
			entityId: state.product?.id!,
			eventPayload: {
				id: state.product?.id!,
				name: state.product?.name!,
				type: state.product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					product_brand_name: state.product?.brand.name,
					user_type: userState?.user_type,
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		});
		handleFocusAboutBrand();
	};
	const handleRequestSample = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_REQUEST_SAMPLE);
		}
		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.REQUEST_SAMPLE,
			timeStamp: new Date().toISOString(),
			entityId: state.product?.id!,
			eventPayload: {
				id: state.product?.id!,
				name: state.product?.name!,
				type: state.product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					product_brand_name: state.product?.brand.name,
					user_type: userState?.user_type,
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		});
		handleFocusAboutBrand();
	};
	const handleFocusAboutBrand = () => {
		const elements = document.querySelectorAll('[data-custom-attribute="about-brand"]');
		const element = Array.from(elements).find(
			element =>
				element instanceof HTMLElement && window.getComputedStyle(element).display !== 'none'
		);
		element?.scrollIntoView({ behavior: 'smooth' });
		dispatch(AppActions.setContactAccordionOpen(true));
	};
	const handleNavigateToBrand = (event: any) => {
		event.preventDefault();
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_GO_TO_BRAND_PAGE);
		}
		navigate(
			`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/brand/${product?.brand.id}`
		);
	};

	const openExpoPopUpCallback = useCallback(() => {
		dispatch(
			AppActions.setModalContent({
				content: <ExpoEastOverlay />,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);

		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.CLICK_ON_SEE_US_EXPO_BUTTON,
			timeStamp: new Date().toISOString(),
			entityId: product?.id!,
			eventPayload: {
				id: product?.id!,
				name: product?.name!,
				type: product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: userState?.id,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME,
					event_id: currentEvent?.id,
					event_name: currentEvent?.name
				}
			}
		});
	}, [dispatch]);

	useEffect(() => {
		setState({ ...productInformationState, product });
	}, [product]);

	const handleClickMarketReady = useCallback(() => {
		dispatch(
			AppActions.setModalContent({
				content: <MarketReadyOverlay />,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);
		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.MARKET_READY,
			timeStamp: new Date().toISOString(),
			entityId: state.product?.id!,
			eventPayload: {
				id: state.product?.id!,
				name: state.product?.name!,
				type: state.product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					product_brand_name: state.product?.brand.name,
					user_type: userState?.user_type,
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		});
	}, [dispatch, state.product, userState?.user_type]);
	return (
		<div data-testid='product-information' className='product-information'>
			<div className='overview'>
				<div className='details'>
					<div
						className={`label-container ${
							deviceScreen === ScreenType.MOBILE ? 'mobile-wrapper' : ''
						}`}
					>
						<Link
							to={`${validateAnonymousPath(eventPublicPathName) ? '..' : ''}/brand/${
								product?.brand.id
							}`}
							className='product-brand'
							onClick={(event: any) => handleNavigateToBrand(event)}
						>
							{state.product?.brand?.name}
						</Link>
						<div>
							{state.product?.brand.boothNumber && currentEvent && currentEvent != null && (
								<>
									<BoothNumberComponent
										text={`Booth #${state.product?.brand.boothNumber}`}
										boothStyle='primary'
										icon='ri-store-3-line'
									/>
									<ExpoLabelComponent
										text={`See us at ${currentEvent?.label || currentEvent.name}!`}
										labelStyle={`large ${currentEvent?.name
											.replace(/\s+/g, '')
											.toLocaleLowerCase()
											.trim()}`}
										icon=''
										showAnimation={true}
										onClick={openExpoPopUpCallback}
									/>
								</>
							)}
						</div>
					</div>
					<h4 className='product-name'>{state.product?.name}</h4>
					<div className='product-sub-detail'>
						<div className='net'>{state.product?.netContent}</div>
						{state.product?.sku_upc_gtin ? <div>UPC: {state.product?.sku_upc_gtin}</div> : null}
					</div>
					<p className='product-description'>{state.product?.description}</p>
					<ProductBadgesComponent
						marketReady={state.product?.marketReady || false}
						nextyBadge={state.product?.isNextyFinalist || false}
						handleClickMarketReady={handleClickMarketReady}
					/>
					<div className='product-howgood-market'>
						{state.product?.howGoodScore?.show_howgood_score &&
							product?.howGoodScore?.source_retail_raiting && (
								<HowGoodScore
									score={state.product?.howGoodScore?.score}
									product={product}
									range={product?.howGoodScore?.source_retail_raiting}
									categoryAverage={product?.howGoodScore?.category_average}
									registerUserClickEvent={registerUserClickEvent}
								/>
							)}
					</div>
					<div className='links'>
						<div data-testid='go-to-brand-button' onClick={handleGoToBrand}>
							Contact Brand
						</div>
						{findUserAccessType(userState?.user_type) !== UserType.Brand && (
							<div data-testid='request-sample-button' onClick={handleRequestSample}>
								Request a sample
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
