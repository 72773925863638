import { ReactNode } from 'react';
import './ContainerForms.scss';

interface IContainerForm {
	classColumn1?: string;
	classColumn2?: string;
	FormComponent1: ReactNode;
	FormComponent2?: ReactNode;
}
export const ContainerForms: React.FC<IContainerForm> = ({
	classColumn1,
	classColumn2,
	FormComponent1,
	FormComponent2
}) => {
	return (
		<div className='form-container'>
			<div className='row-form'>
				<div className={`column-form ${classColumn1}`}>{FormComponent1}</div>
				<div className={`column-form ${classColumn2}`}>{FormComponent2}</div>
			</div>
		</div>
	);
};
