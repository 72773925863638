import { factPanelIdentifier } from '../common/constants';
import { Image } from '../common/model';
const frontSideIdentifier = 'front';

export const sortProductImages = (images: Image[]): Image[] => {
	const sortedImages = [...images].sort((a, b) => {
		let result = 0;
		if (factPanelIdentifier.test(a.side) || b.side === frontSideIdentifier) {
			result = 1;
		}
		if (factPanelIdentifier.test(b.side) || a.side === frontSideIdentifier) {
			result = -1;
		}
		return result;
	});
	return sortedImages;
};
