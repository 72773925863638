import { useEffect, useState, FC, Fragment, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Swiper as ISwiper } from 'swiper';
import { Certification } from '../../../common/model';
import { ProductVM } from '../../../common/view-model';
import { CertificationState } from './model';
import { ScreenType } from '../../../common/view-model';
import ReactCardFlip from 'react-card-flip';
import './ProductCertificationsComponent.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { useCertificationsPerScreen } from './customHooks/useCertifiactionsPerScreen';
import { useBeginingAndEnd } from './customHooks/useBeginingAndEnd';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { publicProductDetailsActions } from '../../../common/constants';

export enum CertificationCardView {
	FRONT,
	BACK
}
interface ICertificationFooterComponentProps {
	view: CertificationCardView;
	setView: (view: CertificationCardView) => void;
}
const CertificationFooterComponent: FC<ICertificationFooterComponentProps> = ({
	view,
	setView
}) => {
	const [state] = useState({ ...CertificationState });
	return (
		<div className='certification-footer'>
			<span
				className={`certification-more-info ${
					view === CertificationCardView.BACK ? 'back-wrapper' : ''
				}`}
				onClick={() =>
					view === CertificationCardView.FRONT
						? setView(CertificationCardView.BACK)
						: setView(CertificationCardView.FRONT)
				}
			>
				{view === CertificationCardView.FRONT ? state.moreInfoText : state.flipText}
				<i className='ri-repeat-line'></i>
			</span>
		</div>
	);
};
interface ICertificationFrontComponentProps {
	certification: Certification;
	view: CertificationCardView;
	setView: (view: CertificationCardView) => void;
}
export const CertificationFrontComponent: FC<ICertificationFrontComponentProps> = ({
	certification,
	view,
	setView
}) => {
	const certificationImage = require(`../../../assets/images/certifications/${certification.imageUrl}.png`);
	return (
		<div
			className={`certification-card ${
				view === CertificationCardView.FRONT ? 'front-view' : 'back-view'
			}`}
			onClick={() => setView(CertificationCardView.BACK)}
		>
			<div className='certification-front'>
				<div className='certification-image-container'>
					<img src={certificationImage} alt={certification.name} />
				</div>
				<span className='certification-name'>{certification.name}</span>
			</div>
			<CertificationFooterComponent view={view} setView={setView} />
		</div>
	);
};
interface ICertificationBackComponentProps {
	certification: Certification;
	view: CertificationCardView;
	setView: (view: CertificationCardView) => void;
}
export const CertificationBackComponent: FC<ICertificationBackComponentProps> = ({
	certification,
	view,
	setView
}) => {
	const [state] = useState({ ...CertificationState });
	const [isEdgeCase, setIsEdgeCase] = useState(state.validateEdgeCase(certification));
	const handleBackCardClick = (e: any) => {
		const element = e.target as HTMLInputElement;
		if (!element.className.includes('certifier-item')) {
			setView(CertificationCardView.FRONT);
		}
	};
	useEffect(() => {
		setIsEdgeCase(state.validateEdgeCase(certification));
	}, [certification]);
	return (
		<div
			data-testid='certification-card'
			className={`certification-card ${
				view === CertificationCardView.FRONT ? 'front-view' : 'back-view'
			}`}
			onClick={handleBackCardClick}
		>
			<div className='certification-back'>
				<span className='certification-name'>{certification.name}</span>
				<p className='certification-description'>{certification.description}</p>
				<div className='certifiers-container'>
					{!isEdgeCase && (
						<span className='certifiers-title'>
							{certification.certifiers.length > 1
								? state.certifierText.concat('s')
								: state.certifierText}
						</span>
					)}
					<div className='certifiers-list'>
						{certification.certifiers.map((certifier, index) => {
							return (
								<a
									className='certifier-item'
									key={index}
									target='_blank'
									href={certifier.learnMore}
									rel='noreferrer'
								>
									{certifier.name}
								</a>
							);
						})}
					</div>
				</div>
			</div>
			<CertificationFooterComponent view={view} setView={setView} />
		</div>
	);
};

interface ICertificationCardComponentProps {
	certification: Certification;
	registerUserClickEvent: (event: string) => void;
}
export const CertificationCardComponent: FC<ICertificationCardComponentProps> = ({
	certification,
	registerUserClickEvent
}) => {
	certification = {
		...certification,
		imageUrl: certification.certifiers.length > 1 ? 'generic_certification' : certification.imageUrl
	};
	const [view, setView] = useState<CertificationCardView>(CertificationCardView.FRONT);
	const handleCardClick = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_CERTIFICATION_CARD);
		}
	};
	return (
		<div onClick={handleCardClick}>
			<ReactCardFlip isFlipped={view === CertificationCardView.BACK} flipDirection='horizontal'>
				<CertificationFrontComponent certification={certification} view={view} setView={setView} />
				<CertificationBackComponent certification={certification} view={view} setView={setView} />
			</ReactCardFlip>
		</div>
	);
};
interface IProductCertificationsComponentProps {
	product: ProductVM | undefined;
	deviceScreen?: ScreenType;
	registerUserClickEvent: (event: string) => void;
}
export const ProductCertificationsComponent: FC<IProductCertificationsComponentProps> = ({
	product,
	deviceScreen,
	registerUserClickEvent
}) => {
	const [state] = useState({ ...CertificationState });
	const isMobile = deviceScreen === ScreenType.MOBILE;
	const isTablet = deviceScreen === ScreenType.TABLET;
	const isDesktop =
		deviceScreen === ScreenType.LARGE_DESKTOP ||
		deviceScreen === ScreenType.MEDIUM_DESKTOP ||
		deviceScreen === ScreenType.SMALL_DESKTOP;
	const swiperRef = useRef<ISwiper>();
	const initialCertificationsPerScreen = 3;
	const { certificationsPerScreen } = useCertificationsPerScreen(
		initialCertificationsPerScreen,
		deviceScreen
	);
	const [hasSlideChanged, setHasSlideChanged] = useState(false);
	const { isBeginning, isEnd } = useBeginingAndEnd(swiperRef, hasSlideChanged, setHasSlideChanged);
	return (
		<div data-testid='product-certification-component' className='certifications-container'>
			<div className='top-separator'></div>
			<div className='certifications-header'>
				<h5>{state.sectionTitle}</h5>
				<div className='line'></div>
				<div
					className={`${
						product?.certifications.length! >= 5 || !isDesktop
							? 'certifications-card-container'
							: 'certifications-card-container-no-swiper'
					}`}
				>
					{isDesktop && (
						<Fragment>
							{product?.certifications?.length! >= 5 ? (
								<Fragment>
									<Swiper
										data-testid='certifications-swiper'
										pagination={{
											clickable: true
										}}
										modules={[Pagination]}
										slidesPerView={certificationsPerScreen}
										onBeforeInit={swiper => {
											swiperRef.current = swiper;
										}}
										onSlideChange={() => setHasSlideChanged(true)}
										breakpoints={{
											1045: {
												spaceBetween: 0
											},
											1046: {
												spaceBetween: 22
											}
										}}
										className='certifications-swiper-desktop'
									>
										{product?.certifications.map((certification, index) => {
											return (
												<SwiperSlide key={index}>
													<CertificationCardComponent
														key={index}
														certification={certification}
														registerUserClickEvent={registerUserClickEvent}
													/>
												</SwiperSlide>
											);
										})}
									</Swiper>
									{product?.certifications.length! > certificationsPerScreen && (
										<Fragment>
											<div
												data-testid='desktop-swiper-arrow-prev'
												className={`swiper-button-prev ${
													isBeginning ? 'swiper-button-disabled' : ''
												}`}
												onClick={() => swiperRef.current?.slidePrev()}
											></div>
											<div
												data-testid='desktop-swiper-arrow-next'
												className={`swiper-button-next ${isEnd ? 'swiper-button-disabled' : ''}`}
												onClick={() => swiperRef.current?.slideNext()}
											></div>
										</Fragment>
									)}
								</Fragment>
							) : (
								<Fragment>
									{product?.certifications.map((certification, index) => {
										return (
											<CertificationCardComponent
												key={index}
												certification={certification}
												registerUserClickEvent={registerUserClickEvent}
											/>
										);
									})}
								</Fragment>
							)}
						</Fragment>
					)}
					{(isMobile || isTablet) && (
						<Swiper
							data-testid='certifications-swiper'
							spaceBetween={30}
							pagination={{
								clickable: true
							}}
							navigation={true}
							modules={[Pagination, Navigation]}
							slidesPerView={isTablet ? 2 : 1}
							className={product?.certifications.length === 1 ? 'certifications-swiper' : ''}
						>
							{product?.certifications.map((certification, index) => {
								return (
									<SwiperSlide key={index}>
										<CertificationCardComponent
											key={index}
											certification={certification}
											registerUserClickEvent={registerUserClickEvent}
										/>
									</SwiperSlide>
								);
							})}
						</Swiper>
					)}
				</div>
			</div>
			<div className='bottom-separator'></div>
		</div>
	);
};
