import { Dispatch, FC, useEffect } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { IrisAuthenticationState } from '../authentication/model';
import { useNavigate } from 'react-router-dom';
import { LockOverlayState as model } from './model';
import pattern from '../../assets/images/ExpoEastFlowerLock.png';
import beaconLogoLock from '../../assets/images/beacon-logo-lock.png';
import './LockOverlayComponent.scss';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import { AppActions } from '../../redux/AppActions';
import { RootState } from '../../redux/AppReducer';
import { convertUserLocalStorageKey, lastUrlLocalStorageKey } from '../../common/constants';

interface LockOverLayComponentProps {
	clearModalContent: () => void;
	hasAnonymousEnded?: boolean;
}
const LockOverLayComponent: FC<LockOverLayComponentProps> = ({
	clearModalContent,
	hasAnonymousEnded
}) => {
	const navigate = useNavigate();
	useEffect(() => {
		const closeIcon = document.getElementById('close-popup');
		if (closeIcon) closeIcon.classList.add('lock-popup-close');
		return () => {
			if (closeIcon) closeIcon.classList.remove('lock-popup-close');
		};
	}, []);

	const handleLogin = () => {
		localStorage.setItem(lastUrlLocalStorageKey, window.location.pathname);
		clearModalContent();
		navigate('/');
		document.getElementById(IrisAuthenticationState.iirisLoginButtonId)?.click();
	};
	const handleRegister = () => {
		localStorage.setItem(lastUrlLocalStorageKey, window.location.pathname);
		clearModalContent();
		localStorage.setItem(convertUserLocalStorageKey, 'true');
		navigate('/');
		document.getElementById(IrisAuthenticationState.iirisRegisterButtonId)?.click();
	};
	return (
		<div className='lock-overlay-container'>
			<div className='title-container'>
				<span className='lock-title'>{model.title}</span>
			</div>
			<div className='body-container'>
				<div className='img-container'>
					<img src={beaconLogoLock} alt='beacon-logo' />
				</div>
				<div className='description-container'>
					<i className='ri-checkbox-circle-line'></i>
					<div className='description-content'>
						<span className='description'>
							{hasAnonymousEnded ? model.anonymousEndedText : model.lockedBodyText}
						</span>
					</div>
				</div>
				<div className='buttons-container'>
					<ButtonComponent text={'Login'} onClick={handleLogin} style={'rounded-button primary'} />
					<ButtonComponent
						text={'Sign Up'}
						onClick={handleRegister}
						style={'rounded-button secondary'}
					/>
				</div>
				<img src={pattern} className='back-pattern' alt='back-pattern' />
			</div>
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	hasAnonymousEnded: state.userState?.hasAnonymousEnded
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	clearModalContent: () => dispatch(AppActions.setModalContent(undefined))
});

export default connect(mapStateToProps, mapDispatchToProps)(LockOverLayComponent);
