import { ChangeEvent, useRef, useState } from 'react';
import './CheckboxFormComponent.scss';

interface ICheckboxFormComponent {
	checkboxType: 'square' | 'toggle';
	defaultValue?: string;
	disabled?: boolean;
	error?: boolean;
	helperText?: React.ReactElement;
	onClick?: () => void;
	onChange?: any;
	checkboxText?: React.ReactElement;
	required?: boolean;
	iconStart?: any;
	iconEnd?: any;
	link?: string;
	errorLink?: boolean;
	textStart?: string;
	textEnd?: string;
	name: string;
	value: boolean;
	errorIcon?: any;
	errorHelperText?: React.ReactElement;
}

const CheckboxFormComponent: React.FC<ICheckboxFormComponent> = ({
	checkboxType,
	checkboxText,
	name,
	value,
	disabled,
	onChange,
	onClick
}) => {
	const checkRef = useRef<HTMLInputElement>(null);

	const handleRefClick = () => {
		checkRef.current?.click();
	};
	return (
		<>
			<div className={`${checkboxType} checkbox-container`} onClick={onClick}>
				<label className='checkbox' htmlFor={name}>
					<input
						type='checkbox'
						checked={value}
						name={name}
						onChange={onChange}
						disabled={disabled}
						ref={checkRef}
					/>
					<span
						className={`checkmark ${disabled ? 'disabled' : 'active'} ${
							value ? 'selected' : 'not-selected'
						}`}
						onClick={handleRefClick}></span>
				</label>
				{checkboxText ? (
					<>
						<div className='checkbox-text' onClick={handleRefClick}>
							{checkboxText}
						</div>
					</>
				) : null}
			</div>
		</>
	);
};

export default CheckboxFormComponent;
