import { FC } from 'react';
import './OnboardCardComponent.scss';
export enum underLinePosition {
	TOP_LEFT = 'top-left',
	TOP_RIGHT = 'top-right',
	BOTTOM_LEFT = 'bottom-left',
	BOTTOM_RIGHT = 'bottom-right'
}
const BrandCardUnderline: FC<{ position: underLinePosition }> = ({ position }) => {
	return (
		<div className={`onboard-brand-card-underline ${position}`}>
			{Array.from({ length: 12 }).map((_, index) => (
				<div key={index} className='onboard-brand-card-underline-dot'></div>
			))}
		</div>
	);
};

interface IOnboardCardComponentProps {
	header: JSX.Element;
	content: JSX.Element;
	title?: string;
	image?: JSX.Element;
	underlinePosition?: underLinePosition;
	titleClassName?: 'left-card-title' | 'right-card-title';
	className?: string;
}

export const OnboardCardComponent: FC<IOnboardCardComponentProps> = ({
	header,
	content,
	title,
	image,
	titleClassName,
	underlinePosition,
	className
}) => {
	return (
		<div className={`onboard-brand-card-container ${className}`}>
			{(underlinePosition === underLinePosition.TOP_LEFT ||
				underlinePosition === underLinePosition.TOP_RIGHT) && (
				<BrandCardUnderline position={underlinePosition} />
			)}
			{title && (
				<div className={`brand-onboard-card-title-container ${titleClassName}`}>
					<span className='brand-onboard-card-title'>{title}</span>
					<div className='brand-title-separator'></div>
				</div>
			)}
			<div
				className={`onboard-brand-card-header-container ${
					!image ? 'no-image-wrapper' : 'image-wrapper'
				}`}
			>
				{image && <div className='onboard-brand-card-image'>{image}</div>}
				{header}
			</div>
			<div className='onboard-brand-card-content-container'>{content}</div>
			{(underlinePosition === underLinePosition.BOTTOM_LEFT ||
				underlinePosition === underLinePosition.BOTTOM_RIGHT) && (
				<BrandCardUnderline position={underlinePosition} />
			)}
		</div>
	);
};
