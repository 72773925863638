const DEFAULT_STATE: IFilterControlPanelState = {
	showAllFiltersText: 'Show all filters',
	itemsText: 'items',
	clearAllFiltersText: 'Clear All',
	filtersText: 'Filters'
};

interface IFilterControlPanelState {
	showAllFiltersText: string;
	itemsText: string;
	clearAllFiltersText: string;
	filtersText: string;
}
export const FilterControlPanelState = {
	...DEFAULT_STATE
};
