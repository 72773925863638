import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
export const useShowSearch = (width: number) => {
	const tabletWidthBreakpoint = 744;
	const location = useLocation();
	const pagesNotUsingSearch = useMemo(() => ['/profile', '/public/getonbeacon'], []);
	const [showSearch, setShowSearch] = useState<boolean>(
		!pagesNotUsingSearch.includes(location.pathname) && width >= tabletWidthBreakpoint
	);
	useEffect(() => {
		setShowSearch(
			!pagesNotUsingSearch.includes(location.pathname) && width >= tabletWidthBreakpoint
		);
	}, [location.pathname, pagesNotUsingSearch, width]);
	return { showSearch };
};
