import React from 'react';
import nextyBadgeImage from '../../../assets/images/badges/nexty-badge.svg';
import marketReadyImg from '../../../assets/images/marketReady.png';
import './ProductBadgesComponent.scss';
interface IProductBadgesComponentProps {
	marketReady: boolean;
	nextyBadge: boolean;
	handleClickMarketReady: (event: any) => void;
}
export const ProductBadgesComponent: React.FC<IProductBadgesComponentProps> = ({
	marketReady,
	nextyBadge,
	handleClickMarketReady
}) => {
	return (
		<div className={`badges${nextyBadge && marketReady ? '-both' : ''}`}>
			{nextyBadge && (
				<div className='nexty-badge'>
					<img src={nextyBadgeImage} alt=''></img>
				</div>
			)}

			{marketReady && (
				<div className='market-ready'>
					<img src={marketReadyImg} alt='' onClick={handleClickMarketReady} />
				</div>
			)}
		</div>
	);
};
