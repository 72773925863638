import './CompanyDetailsComponent.scss';
import { useEffect, useState, Dispatch, FC } from 'react';
import { CompanyDetailsState } from './model';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
import { useForm } from '../../../hooks/useForm';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { RootState } from '../../../redux/AppReducer';
import { CompanyDetails } from '../../../common/model';
import { UserActions } from '../../../redux/UserActions';
import { AppActions } from '../../../redux/AppActions';
import { applicationRoleTypes, applicationJobTypesMap } from '../../../mock/roleTypes';
import {
	IRoleType,
	ITrackingEvent,
	TrackingEventStatus,
	TrackingEventType
} from '../../../common/view-model';
import { CompanyDetailsLoadingComponent } from './company-details-loading/CompanyDetailsLoadingComponent';
import { v4 as uuidv4 } from 'uuid';
import { IirisIdentityKey, getLocalStorageValue } from '../../../helpers/authenticationHelper';
interface ICompanyDetailsComponentProps {
	updateUserCompanyDetails: (
		companyDetails: CompanyDetails,
		trackingPayload: ITrackingEvent
	) => void;
	getUserProfileBeacon: () => void;
	hasCreatedBeaconProfile: boolean;
	createBeaconProfileProgress: number;
	beaconProfileStartProgress: () => void;
	beaconProfileEndProgress: () => void;
	clearModalContent: () => void;
	completeBrandDetailsLayout: (completeBrandDetailsLayout: boolean) => void;
	setIsCompanyLoading: (isCompanyLoading: boolean) => void;
}

const CompanyDetailsComponent: FC<ICompanyDetailsComponentProps> = ({
	updateUserCompanyDetails,
	getUserProfileBeacon,
	hasCreatedBeaconProfile,
	createBeaconProfileProgress,
	beaconProfileStartProgress,
	beaconProfileEndProgress,
	clearModalContent,
	completeBrandDetailsLayout,
	setIsCompanyLoading
}) => {
	const [state] = useState({ ...CompanyDetailsState });
	const [userType, setUserType] = useState<string>('');
	const [formValues, handleInputChange, clearValues] = useForm({
		companyName: '',
		webSite: '',
		position: applicationRoleTypes[0].name,
		jobType: applicationJobTypesMap[applicationRoleTypes[0].name][0]
	});
	const [jobTypes, setJobTypes] = useState<string[]>(
		applicationJobTypesMap[applicationRoleTypes[0].name]
	);
	const { companyName, webSite, position, jobType } = formValues;
	useEffect(() => {
		setJobTypes(applicationJobTypesMap[position]);
	}, [position]);
	const onSendFrom = (e: any) => {
		const trackingPayload: ITrackingEvent = {
			transactionId: uuidv4(),
			entityId: getLocalStorageValue(IirisIdentityKey.USER_ID)!,
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.COMPANY_DETAILS,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: getLocalStorageValue(IirisIdentityKey.USER_ID)!,
				name: companyName,
				type: position,
				url: window.location.href,
				tags: [],
				metadata: {
					companyName,
					webSite,
					position,
					jobType: jobTypes.length > 0 ? jobType : undefined,
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		};
		e.preventDefault();
		if (isFormValid) {
			setUserType(position);
			beaconProfileStartProgress();
			updateUserCompanyDetails(
				{
					name: companyName,
					website: webSite,
					userType: position,
					isEditing: false,
					jobType: jobTypes.length > 0 ? jobType : ''
				},
				trackingPayload
			);
			getUserProfileBeacon();
			clearValues();
		}
	};
	const validateForm = (): boolean => {
		return companyName.trim().length > 0 && webSite.trim().length > 0 && position.trim().length > 0;
	};
	const [isFormValid, setIsFormValid] = useState(validateForm());
	useEffect(() => {
		setIsFormValid(validateForm());
	}, [formValues]);
	useEffect(() => {
		completeBrandDetailsLayout(true);
		return () => {
			completeBrandDetailsLayout(false);
		};
	}, [completeBrandDetailsLayout]);
	return (
		<div data-testid='company-details-container' className='company-details-container'>
			{createBeaconProfileProgress === 0 ? (
				<form data-testid='company-form' onSubmit={onSendFrom}>
					<div className='company-details'>
						<span className='modal-title'>{state.modalTitleText}</span>
						<div className='welcome-message-container'>
							<p className='welcome-message'>{state.welcomeMessageText}</p>
						</div>
						<div className='form-container'>
							<div className='row'>
								<div className='col'>
									<div>
										<label className='form-company-label' htmlFor='companyName'>
											{state.companyNameText}
										</label>
										<label className='requiere-label'>*</label>
										<div>
											<input
												data-testid='company-name'
												type='text'
												name='companyName'
												value={companyName}
												className='form-control'
												onChange={handleInputChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col'>
									<div>
										<label className='form-company-label' htmlFor='webSite'>
											{state.companyWebSiteText}
										</label>
										<label className='requiere-label'>*</label>
										<div>
											<input
												data-testid='company-website'
												type='text'
												name='webSite'
												value={webSite}
												className='form-control'
												onChange={handleInputChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='options-container'>
								<div className='col-sm'>
									<div>
										<label className='form-company-label' htmlFor='position'>
											{state.companyPositionText}
										</label>
										<label className='requiere-label'>*</label>
										<div>
											<select
												data-testid='position'
												name='position'
												className='form-control'
												value={position}
												onChange={handleInputChange}
											>
												{applicationRoleTypes
													.filter(role => role.showInProfile)
													.map((role: IRoleType) => (
														<option key={role.id} value={role.name}>
															{role.name}
														</option>
													))}
											</select>
										</div>
									</div>
								</div>
								<div className='col-sm'>
									<div>
										<label className='form-company-label' htmlFor='jobType'>
											{state.companyJobTypeText}
										</label>
										<label className='requiere-label'>*</label>
										<div>
											<select
												data-testid='job-type'
												name='jobType'
												className='form-control'
												value={jobType}
												onChange={handleInputChange}
												disabled={jobTypes.length === 0}
											>
												{jobTypes.map((jobType: string, index: number) => (
													<option key={index} value={jobType}>
														{jobType}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='button-container'>
							<ButtonComponent
								style='rounded-button primary'
								onClick={() => {}}
								text={state.buttonText}
								icon={<i className='ri-arrow-right-s-line line-icon'></i>}
								type='submit'
								disabled={!isFormValid}
								preventDefault={false}
							/>
						</div>
						<div className='powered-by-container'>
							<span className='powered-by-text'>
								{state.poweredByText} <span className='iris-text'>{state.irisText}</span>
							</span>
						</div>
					</div>
				</form>
			) : (
				<CompanyDetailsLoadingComponent
					hasCreatedBeaconProfile={hasCreatedBeaconProfile}
					createBeaconProfileProgress={createBeaconProfileProgress}
					userType={userType}
					onProgressComplete={beaconProfileEndProgress}
					clearModalContent={clearModalContent}
					setIsCompanyLoading={setIsCompanyLoading}
				/>
			)}
		</div>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	userState: state.userState,
	createBeaconProfileProgress: state.createBeaconProfileProgress || 0,
	hasCreatedBeaconProfile: state.hasCreatedBeaconProfile || false
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	updateUserCompanyDetails: (details: CompanyDetails, trackingPayload: ITrackingEvent) => {
		return dispatch(
			UserActions.updateUserProfile({
				companyDetails: {
					...details
				},
				trackingPayload
			})
		);
	},
	getUserProfileBeacon: () => {
		return dispatch({ type: UserActions.GET_USER_PROFILE });
	},
	clearModalContent: () => {
		return dispatch(AppActions.setModalContent(undefined));
	},
	beaconProfileStartProgress: () => {
		return dispatch(AppActions.startBeaconProfileProgress());
	},
	beaconProfileEndProgress: () => {
		return dispatch(AppActions.finishBeaconProfileProgress());
	},
	completeBrandDetailsLayout: (isBrandDetailslayout: boolean) =>
		dispatch(AppActions.setCompleteBrandDetailsLayout(isBrandDetailslayout)),
	setIsCompanyLoading: (isLoading: boolean) => dispatch(AppActions.isCompanyLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsComponent);
