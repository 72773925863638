import './IngredientsTemplateComponent.scss';
import { IngredientsTemplateHeader } from './IngredientsTemplateHeader/IngredientsTemplateHeader';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { RootState } from '../../../redux/AppReducer';
import { FC, useState } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { UserActions } from '../../../redux/UserActions';
import { IngredientsTemplate, ScreenType } from '../../../common/view-model';
import { useEffect } from 'react';
import { ApolloActions } from '../../../redux/ApolloActions';
import { GET_INGREDIENT_TEMPLATES } from '../../../graphql/queries/ingredientTemplates';
import { getTemplateIngredientsByPurpose } from '../../../helpers/transformers';
import { LoadingIngredientsTemplateComponent } from '../LoadingIngredientsTemplate/LoadingIngredientsTemplate';
import OnboardingTemplateCards from '../OnboardingTemplateCards/OnboardingTemplateCards';
import OnboardingDetailTemplateComparison from '../OnboardingDetailTemplateComparison/OnboardingDetailTemplateComparison';
import { SaveTemplateStickyComponent } from '../SaveTemplateStickyComponent/SaveTemplateStickyComponent';
import { useNavigate } from 'react-router-dom';

export interface IIngredientsTemplateComponentProps {
	ingredientTemplates?: IngredientsTemplate[];
	ingredientsTemplateId?: number;
	setIngredientsTemplateId: (ingredientsTemplateId: number) => void;
	getTemplates: () => void;
	screenType: ScreenType | undefined;
	saveUserIngredientsTemplateId: () => void;
	saveContainerRef: React.RefObject<HTMLDivElement>;
}
const IngredientsTemplateComponent: FC<IIngredientsTemplateComponentProps> = ({
	ingredientTemplates,
	ingredientsTemplateId,
	setIngredientsTemplateId,
	getTemplates,
	screenType,
	saveUserIngredientsTemplateId,
	saveContainerRef
}) => {
	const navigate = useNavigate();
	const [hasChangeTemplate, setHasChangeTemplate] = useState(false);
	const [showStickyButton, setShowStickyButton] = useState(false);
	const [templates, setTemplates] = useState<IngredientsTemplate[]>([]);
	const [currentTabIndex, setCurrentTabIndex] = useState(1);
	const [previewTab, setPreviewTab] = useState(-1);
	const [showDetailedList, setShowDetailedList] = useState(false);
	const moveToStepHandler = (index: number) => {
		setIngredientsTemplateId(index);
		setCurrentTabIndex(index);
		setPreviewTab(currentTabIndex);
		setHasChangeTemplate(true);
	};
	const FULL_SIZE_SCREEN_WIDTH = 743;
	const { width } = useWindowSize();

	useEffect(() => {
		setIngredientsTemplateId(ingredientsTemplateId!);
	}, [ingredientsTemplateId]);

	useEffect(() => {
		if (ingredientTemplates) {
			ingredientTemplates = ingredientTemplates.map(template => ({
				...template,
				purpose: getTemplateIngredientsByPurpose(template)
			}));

			setTemplates(ingredientTemplates!);
		} else {
			getTemplates();
		}
	}, [ingredientTemplates]);
	useEffect(() => {
		if (!saveContainerRef) return;
		const handleScroll = () => {
			if (saveContainerRef.current) {
				const targetPosition = saveContainerRef.current.getBoundingClientRect().top;
				const windowHeight = window.innerHeight;
				if (targetPosition > windowHeight && (hasChangeTemplate || showDetailedList)) {
					setShowStickyButton(true);
				} else {
					setShowStickyButton(false);
				}
			}
		};
		document.addEventListener('scroll', handleScroll);
		return () => document.removeEventListener('scroll', handleScroll);
	}, [hasChangeTemplate, saveContainerRef, showDetailedList]);
	useEffect(() => {
		if (!showDetailedList) {
			setShowStickyButton(false);
		} else {
			setShowStickyButton(true);
		}
	}, [showDetailedList]);
	const handleSaveTemplate = () => {
		saveUserIngredientsTemplateId();
		navigate('/landing');
		window.scrollTo(0, 0);
	};
	return (
		<>
			{templates && templates.length > 0 ? (
				<div data-testid='ingredients-template' className='onboarding-ingredients-template'>
					<div className='col'>
						<div className='row'>
							<div className='template-header'>
								<IngredientsTemplateHeader
									isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
								/>
							</div>
						</div>
						<div className='row'>
							<div
								className={`template-cards ${
									currentTabIndex === 1
										? 'ing-current-blue'
										: currentTabIndex === 2
										? 'ing-current-green'
										: 'ing-current-yellow'
								}`}
							>
								<OnboardingTemplateCards
									currentTab={currentTabIndex}
									templates={templates}
									moveToTab={moveToStepHandler}
									setShowDetailedList={setShowDetailedList}
									showDetailedList={showDetailedList}
								/>
							</div>
						</div>
						<div className='row'>
							<div
								className={`detail-comparision ${
									currentTabIndex === 1
										? 'current-blue'
										: currentTabIndex === 2
										? 'current-green'
										: 'current-yellow'
								}`}
							>
								<OnboardingDetailTemplateComparison
									currentTab={currentTabIndex}
									templates={templates}
									moveToTab={moveToStepHandler}
									setShowDetailedList={setShowDetailedList}
									showDetailedList={showDetailedList}
									isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
									previewTab={previewTab}
									setPreviewTab={setPreviewTab}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoadingIngredientsTemplateComponent
					loadingBottomPillsCount={3}
					loadingCardsCount={3}
					loadingTopPillsCount={
						screenType === ScreenType.TABLET ||
						screenType === ScreenType.MOBILE ||
						width === FULL_SIZE_SCREEN_WIDTH
							? 5
							: 4
					}
					isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
					profilePage={false}
				/>
			)}
			{templates && templates.length > 0 && (
				<SaveTemplateStickyComponent
					currentTemplate={templates[currentTabIndex - 1]}
					saveTemplate={handleSaveTemplate}
					show={showStickyButton}
				/>
			)}
		</>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	ingredientTemplates: state.storeState?.templates,
	ingredientsTemplateId: state.userState?.ingredientsTemplateId || 1,
	screenType: state.deviceScreen
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setIngredientsTemplateId: (ingredientsTemplateId: number) =>
		dispatch(UserActions.setIngredientsTemplateId(ingredientsTemplateId)),
	getTemplates: () => {
		return dispatch(ApolloActions.query({ query: GET_INGREDIENT_TEMPLATES }));
	},
	saveUserIngredientsTemplateId: () =>
		dispatch({ type: UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID })
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsTemplateComponent);
