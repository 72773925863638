const DEFAULT_STATE: ISaveTemplateStickyModel = {
	saveBtnText: 'Save & Continue'
};

interface ISaveTemplateStickyModel {
	saveBtnText: string;
}

export const SaveTemplateStickyModel = {
	...DEFAULT_STATE
};
