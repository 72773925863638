import './WhatsNewFilterElementsComponent.scss';
import { FilterVM } from '../../../../common/view-model';
import { FC } from 'react';
import { CheckboxComponent } from '../../../UI/CheckboxComponent/CheckboxComponent';
import { FilterTooltipComponent } from '../../tooltip/FilterTooltipComponent';
import { handleToolTipClick } from '../../generic-filter/model';
import { useNavigate } from 'react-router-dom';
import { isCurrentUserAnonymous } from '../../../../helpers/authenticationHelper';

interface IFilterElementComponentProps {
	filter: FilterVM;
	selectedFilters?: FilterVM[];
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
}
const FilterElementComponent: FC<IFilterElementComponentProps> = ({
	filter,
	selectedFilters,
	setLockModalContent,
	registerUserClickEvent
}) => {
	const handleClickToolTip = (id: string, iconId: string) => {
		handleToolTipClick(id, iconId);
	};
	return (
		<>
			<div className='whats-new-filter-content'>
				<CheckboxComponent
					checked={
						selectedFilters?.find(selectedFilter => selectedFilter.id === filter.id) !== undefined
					}
					label={filter.name}
					children={filter.children}
					type={filter.type}
					parentLength={0}
					style='primary'
					isLocked={isCurrentUserAnonymous()}
					setLockModalContent={setLockModalContent}
					registerUserClickEvent={registerUserClickEvent}
					parentIndex={filter.id}
				/>
				{filter?.toolTip && (
					<i
						className='ri-question-line tooltip-icon'
						id={`toltip-icon${filter?.id || ''}`}
						onClick={() => handleClickToolTip(filter?.id || '', `toltip-icon${filter?.id || ''}`)}
					></i>
				)}
			</div>
			{filter?.toolTip?.description && (
				<FilterTooltipComponent toolTip={filter?.toolTip} id={filter?.id || ''} />
			)}
		</>
	);
};

interface IWhatsNewFilterElementsComponentProps {
	filters: FilterVM[];
	currentCategory: string;
	setCurrentCategory: (category?: string) => void;
	productsPageUrl: string;
	selectedFilters?: FilterVM[];
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
	clearAllFilters: () => void;
}

export const WhatsNewFilterElementsComponent: FC<IWhatsNewFilterElementsComponentProps> = ({
	filters,
	currentCategory,
	setCurrentCategory,
	productsPageUrl,
	selectedFilters,
	setLockModalContent,
	registerUserClickEvent,
	clearAllFilters
}) => {
	const navigate = useNavigate();
	const onCurrentCategoryClick = () => {
		setCurrentCategory(undefined);
		clearAllFilters();
		navigate(`${isCurrentUserAnonymous() ? '..' : ''}${productsPageUrl}`);
	};
	return (
		<div className='whats-new-container'>
			<div className='selected-element-container' onClick={onCurrentCategoryClick}>
				<i className='ri-arrow-left-s-line'></i>
				<span className='current-category'>{currentCategory}</span>
			</div>
			<div className='filter-elements'>
				{filters?.map(filter => (
					<FilterElementComponent
						filter={filter}
						key={filter.id}
						selectedFilters={selectedFilters}
						setLockModalContent={setLockModalContent}
						registerUserClickEvent={registerUserClickEvent}
					/>
				))}
			</div>
		</div>
	);
};
