const DEFAULT_STATE: ILockOverlayState = {
	title: 'Don’t Miss Out!',
	anonymousEndedText:
		"Ready to dive deeper? Complete your sign-up for full access to our platform's exclusive content and enhanced features!",
	lockedBodyText:
		'To access the information you’re looking for, you need to log in or create a Beacon Discovery account.'
};
interface ILockOverlayState {
	title: string;
	lockedBodyText: string;
	anonymousEndedText: string;
}
export const LockOverlayState = {
	...DEFAULT_STATE
};
