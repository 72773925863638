import { FilterType, FilterVM } from '../common/view-model';

export const whastNewFilter: FilterVM = {
	id: 'new',
	name: "What's New?",
	type: FilterType.WHATS_NEW,
	isChecked: false,
	children: [
		{
			id: 'new-to-market',
			name: 'New To Market',
			type: FilterType.NEW_TO_MARKET,
			isChecked: false,
			toolTip: {
				description: 'Products or brands that launched within the last year.',
				isPoweredByHowGood: false
			}
		},
		...(process.env.REACT_APP_USE_NEW_BEACON === 'true'
			? [
					{
						id: 'new-to-beacon',
						name: 'New To Beacon',
						type: FilterType.NEW_TO_BEACON,
						isChecked: false,
						toolTip: {
							description: 'Products added to Beacon Discovery within the last 30 days.',
							isPoweredByHowGood: false
						}
					}
			  ]
			: []),
		{
			id: 'nexty-finalists',
			name: 'NEXTY Finalists',
			type: FilterType.NEXTY_FINALISTS,
			isChecked: false,
			toolTip: {
				description:
					'The NEXTY Awards recognize the most progressive, innovative, inspiring and trustworthy products in the natural products industry.',
				isPoweredByHowGood: false
			}
		}
	]
};
