import { Action } from 'redux-actions';
import { IngredientCategory } from '../../common/view-model';

export class IngredientFilterActions {
	static readonly SET_INGREDIENT_FILTER = '@@ingredientFilter/SET_INGREDIENT_FILTER';
	static readonly SET_INGREDIENT_FILTER_OPTIONS =
		'@@ingredientFilter/SET_INGREDIENT_FILTER_OPTIONS';

	static setIngredientFilterOptions = (
		payload: IngredientCategory[]
	): Action<IngredientCategory[]> => {
		return {
			type: IngredientFilterActions.SET_INGREDIENT_FILTER_OPTIONS,
			payload
		};
	};
	static setIngredientFilter = (
		payload: IngredientCategory | undefined
	): Action<IngredientCategory | undefined> => {
		return {
			type: IngredientFilterActions.SET_INGREDIENT_FILTER,
			payload
		};
	};
}
