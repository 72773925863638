import { useEffect } from 'react';
export const useWidgetLoader = () => {
	useEffect(() => {
		let passportScript = document.createElement('script');
		passportScript.type = 'text/javascript';
		passportScript.async = true;
		passportScript.src = process.env.REACT_APP_IRIS_PASSPORT_SCRIPT!;
		passportScript.setAttribute('id', 'passport-loader');
		document.getElementsByTagName('head')[0].appendChild(passportScript);

		return () => {
			const existingPassportScript = document.querySelector(`[src="${passportScript.src}"]`);
			if (existingPassportScript) {
				existingPassportScript.parentNode?.removeChild(existingPassportScript);
			}
		};
	}, []);
};
