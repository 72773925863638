import { eventPublicPathName } from '../common/constants';

enum IirisIdentityKey {
	USER_EMAIL = 'x-iiris-identity-username',
	USER_ID = 'x-iiris-identity-userid',
	USER_IDENTITY = 'x-iiris-identity'
}

const getIirisCookie = (): string | undefined =>
	localStorage.getItem(IirisIdentityKey.USER_IDENTITY) || undefined;

const setLocalStorageValue = (key: IirisIdentityKey, value: string): void =>
	localStorage.setItem(key, value);

const getLocalStorageValue = (key: IirisIdentityKey): string | null => localStorage.getItem(key);

const clearLocalStorageValue = (key: IirisIdentityKey): void => localStorage.removeItem(key);

const removeAllAuthenticationKeysFromLocalStorage = (): void =>
	Object.values(IirisIdentityKey).forEach(value => localStorage.removeItem(value));

const validateAnonymousPath = (eventPath: string) =>
	window.location.pathname.split('/').some(path => path === eventPath);
const isCurrentUserAnonymous = () =>
	validateAnonymousPath(eventPublicPathName) &&
	getLocalStorageValue(IirisIdentityKey.USER_IDENTITY) !== null &&
	!getLocalStorageValue(IirisIdentityKey.USER_EMAIL) &&
	!getLocalStorageValue(IirisIdentityKey.USER_ID);

const isAuthenticated = () =>
	getIirisCookie() &&
	getLocalStorageValue(IirisIdentityKey.USER_EMAIL) &&
	!validateAnonymousPath(eventPublicPathName)
		? true
		: false;
const validateCanLoadAnonymousRoutes = () =>
	!getLocalStorageValue(IirisIdentityKey.USER_EMAIL) &&
	!getLocalStorageValue(IirisIdentityKey.USER_ID);

const validateHasAllAuthenticationKeys = () =>
	Object.values(IirisIdentityKey).every(value => getLocalStorageValue(value) !== null);

const validateCompanyDetails = (companyDetails: any) => {
	if (companyDetails !== undefined) {
		if (
			companyDetails.jobType === '' &&
			companyDetails.name === '' &&
			companyDetails.userType === '' &&
			companyDetails.website === ''
		) {
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};

export {
	getIirisCookie,
	setLocalStorageValue,
	getLocalStorageValue,
	clearLocalStorageValue,
	removeAllAuthenticationKeysFromLocalStorage,
	IirisIdentityKey,
	validateAnonymousPath,
	isCurrentUserAnonymous,
	isAuthenticated,
	validateCanLoadAnonymousRoutes,
	validateHasAllAuthenticationKeys,
	validateCompanyDetails
};
