import { gql } from 'graphql.macro';

export const GET_BRAND = gql`
	query GetBrand($id: String!, $expoEventId: String) {
		brand(id: $id, expoEventId: $expoEventId) {
			id
			boothNumber
			brand_attributes
			brand_country
			brand_facebook
			brand_foundation_year
			brand_instagram
			brand_linkedin
			brand_mission
			brand_state
			brand_story
			brand_tiktok
			brand_twitter
			brand_website
			brand_youtube
			brand_zip
			brokers {
				name
				phone
				email
			}
			contact_email
			contact_name
			contact_phone
			contact_title
			distributors
			holding_company
			how_its_made
			hq_city
			hq_country
			hq_state
			marketing_assets
			name
			url_logo
			uses_private_label
		}
	}
`;
