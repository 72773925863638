import { FC, useState } from 'react';
import './PrepExpoDiscovery.scss';
import { ReactComponent as Cursor } from '../../assets/images/cursor.svg';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import mapindicator from '../../assets/images/mapindicator.png';
import { AppActions } from '../../redux/AppActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/AppReducer';
import { ScreenType } from '../../common/view-model';
import GenericSVGPrepComponent, { GenericSVGComponentKey } from './GenericSVGPrepComponent';

export const PrepExpoDiscovery: FC<any> = () => {
	const [next, setNext] = useState(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState);
	const currentEvent = useSelector(
		(state: RootState) => state.appState.storeState?.getCurrentEvent
	);
	const dispatch = useDispatch();
	const handleClick = () => {
		if (next) {
			window.localStorage.setItem('discover', 'done');
			dispatch(AppActions.setModalContent(undefined));
		} else {
			setNext(true);
		}
	};
	return (
		<div className='prep-container'>
			<div className='prep-header'>
				{deviceScreen === ScreenType.MOBILE
					? `This brand will be at ${currentEvent?.name}!`
					: 'Prep for Expo on Beacon Discovery!'}
			</div>
			<div className='prep-main-body'>
				<div className='prep-step'>
					{!next ? (
						<>
							<span>Step 1</span> - Favorite products on Beacon Discovery with the{' '}
							<GenericSVGPrepComponent componentKey={GenericSVGComponentKey.LABEL} /> label!
						</>
					) : (
						<>
							<span>Step 2</span> - See those brands instantly highlighted on your{' '}
							<a className='link-show-floor' href={currentEvent?.floorMapUrl} target='blank'>
								<i className=' fa-map fa-regular'></i>{' '}
								<span className='show-map'>show floor map here!</span>
							</a>
						</>
					)}
				</div>
				<div className='prep-body'>
					{!next ? (
						<div className='prep-cards'>
							{Array.from(Array(5).keys()).map((item, index) => (
								<GenericSVGPrepComponent key={index} componentKey={GenericSVGComponentKey.CARDS} />
							))}
						</div>
					) : (
						<div className='prep-map'>
							<GenericSVGPrepComponent componentKey={GenericSVGComponentKey.MAP} />
							<img src={mapindicator} className='mapindicator' alt='map-indicator' />
						</div>
					)}
				</div>
				<div className='prep-buttons'>
					<ButtonComponent
						text={!next ? 'Next' : 'Go Discover!'}
						onClick={handleClick}
						style={'rounded-button primary'}
					/>
				</div>
				{!next && (
					<div className='prep-cursor'>
						<Cursor />
					</div>
				)}
			</div>
		</div>
	);
};
