import { gql } from 'graphql.macro';

export const GET_ONBOARDING_BRAND = gql`
	query {
		getBrandFromAdmin {
			uniqueApplicationSessionId
			salesforceAccount
			name
			status
			brandAttributes
			brandLogo
			accountPrimaryContactName
			accountPrimaryContactEmail
			submittedContactEmail
			submittedContactFirstName
			submittedContactLastName
			brandRepEmail
			brandRepName
			brandRepPhone
			brandRepTitle
			brandStory
			brandMission
			brandInstagram
			brandYoutube
			brandTwitter
			brandLinkedin
			brandFacebook
			brandTiktok
			brandVideo
			brandWebsite
			brandYearFound
			holdingCompany
			brandHQCountry
			brandHQState
			brandHQCity
			productRepEmail
			productRepName
			productRepPhone
			productRepTitle
			mapPolicy
			serviceProviderNeeds {
				contractManufacturingPrivateLabel
				fulfillmentLogistics
				packagingPlastics
				sustainablePackaging
				labelPrinting
				qualityAssurance
				certifyingAgentOrganic
				formulationClinicalResearch
				mediaMarketingAgency
				manufacturingProcessingEquipment
				legalRegulatoryConsulting
				contactlessSampling
				staffingServices
				financeAccountingPayrollServices
				financialInstitutionInvestmentBanking
				investors
				onlineBusinessSolutions
				seekDistribution
				seekBrokerWholesaler
				otherNotListedHere
				noneAbove
			}
			currentRetailChannels {
				conventionalRetailers
				naturalChain
				independent
				directConsumers
				eTailer
			}
			ownedByParentCompany
			ownedByHoldingCompany
			parentCompany
			privateLabeServices
			hasBroker
			brokers {
				name
				phone
				email
			}
			processingTechniques {
				id
				name
			}
			processingTechniques {
				id
				name
			}
			currentDistributionChannels {
				KeHe
				UNFI
				Other
				NotInDistribution
			}
			keheRegion {
				northEast
				east
				southEast
				central
				west
				none
			}
			UNFIRegion {
				northEast
				east
				southEast
				central
				west
				none
			}
			otherDistributorRegion {
				northEast
				east
				southEast
				central
				west
				none
			}
			activelyRaisingCapital
			revenueLastYear
			revenueExpectedThisYear
		}
	}
`;
