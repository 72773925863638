import { FC, useEffect, useState } from 'react';
import './CategoryBreadcrumbComponent.scss';
import { buildBreadCrumb } from '../../../helpers/buildBreadCrumbHelper';
import { FilterType, FilterVM } from '../../../common/view-model';
import { useNavigate } from 'react-router-dom';
import {
	parentCategoryIdentifier,
	secondLevelCategoryIdentifier,
	firstLevelCategoryIdentifier
} from '../../../helpers/paramsUrlIndetifiers';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
interface IBreadCrumbLevels {
	firstLevel: FilterVM | undefined;
	secondLevel: FilterVM | undefined;
	thirdLevel: FilterVM | undefined;
	fourthLevel: FilterVM | undefined;
}
interface ICategoryBreadcrumbComponentProps {
	elements?: any[];
}

export const CategoryBreadcrumbComponent: FC<ICategoryBreadcrumbComponentProps> = ({
	elements
}) => {
	const [breadcrumb, setBreadcrumb] = useState<IBreadCrumbLevels | undefined>(
		buildBreadCrumb(elements)
	);
	const allProductsText = 'All Products';
	const productsPageUrl = '/products';
	const navigate = useNavigate();
	useEffect(() => {
		setBreadcrumb(buildBreadCrumb(elements));
	}, [elements]);
	const handleClearAllClick = () => {
		if (breadcrumb?.firstLevel) {
			navigate(`${isCurrentUserAnonymous() ? '..' : ''}${productsPageUrl}`);
		}
	};
	const handleFirstLevelClick = (filter?: FilterVM) => {
		if (isCurrentUserAnonymous()) {
			return;
		}
		if (filter && breadcrumb?.secondLevel) {
			if (filter?.type === FilterType.WHATS_NEW) {
				navigate(`${productsPageUrl}?${FilterType[filter.type].toLowerCase()}=${filter.id}`);
			} else {
				navigate(`${productsPageUrl}?${parentCategoryIdentifier}=${filter.id}`);
			}
		}
	};
	const handleSecondLevelClick = (filter?: FilterVM) => {
		if (isCurrentUserAnonymous()) {
			return;
		}
		if (filter && breadcrumb?.thirdLevel) {
			navigate(
				`${productsPageUrl}?${parentCategoryIdentifier}=${breadcrumb.firstLevel?.id}&${secondLevelCategoryIdentifier}=${filter.id}`
			);
		}
	};
	const handleThirdLevelClick = (filter?: FilterVM) => {
		if (isCurrentUserAnonymous()) {
			return;
		}
		if (filter && breadcrumb?.fourthLevel) {
			navigate(
				`${productsPageUrl}?${parentCategoryIdentifier}=${breadcrumb.firstLevel?.id}&${secondLevelCategoryIdentifier}=${breadcrumb.secondLevel?.id}&${firstLevelCategoryIdentifier}=${filter.id}`
			);
		}
	};

	return (
		<div className='category-filter-breadcrumb-container'>
			<span
				className={`breadcrumb-text ${breadcrumb?.firstLevel ? 'has-more' : ''}`}
				onClick={handleClearAllClick}
			>
				{allProductsText}{' '}
			</span>
			<span
				className={`breadcrumb-text ${breadcrumb?.secondLevel ? 'has-more' : ''}`}
				onClick={() => handleFirstLevelClick(breadcrumb?.firstLevel)}
			>
				{breadcrumb?.firstLevel?.name}{' '}
			</span>
			<span
				className={`breadcrumb-text ${breadcrumb?.thirdLevel ? 'has-more' : 'last'}`}
				onClick={() => handleSecondLevelClick(breadcrumb?.secondLevel)}
			>
				{breadcrumb?.secondLevel?.name}{' '}
			</span>
			<span
				className={`breadcrumb-text ${breadcrumb?.fourthLevel ? 'has-more' : 'last'}`}
				onClick={() => handleThirdLevelClick(breadcrumb?.thirdLevel)}
			>
				{breadcrumb?.thirdLevel?.name}{' '}
			</span>
			<span className='breadcrumb-text last'>{breadcrumb?.fourthLevel?.name} </span>
		</div>
	);
};
