import { connect, useSelector } from 'react-redux';
import { RootState } from '../../redux/AppReducer';
import { Brand } from '../../common/model';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useEffect, useRef, useState } from 'react';
import { ScreenType } from '../../common/view-model';
import { Pagination, Navigation, Swiper as ISwiper, Grid } from 'swiper';
import { BrandComponent } from '../brand/BrandComponent';
import './HighlightedBrandsComponent.scss';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { HighlightedBrandsState } from './model';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import { eventPublicPathName } from '../../common/constants';
interface HighlightedBrandsProps {
	brandsHighlightedArr: Brand[];
}
const LARGE_MOBILE = 744;
const SMALL_MOBILE = 500;
export const HighlightedBrands = ({ brandsHighlightedArr }: HighlightedBrandsProps) => {
	const swiperRef = useRef<ISwiper>();
	const [slidePerView, setSlidePerView] = useState(6);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const windowsSize = useWindowSize().width;
	const navigate = useNavigate();
	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setSlidePerView(3);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setSlidePerView(3);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setSlidePerView(3);
		} else if (deviceScreen === ScreenType.TABLET) {
			setSlidePerView(2);
		} else if (LARGE_MOBILE < windowsSize && windowsSize > SMALL_MOBILE) {
			setSlidePerView(4);
		} else if (SMALL_MOBILE < windowsSize) {
			setSlidePerView(2);
		}
	}, [deviceScreen, windowsSize]);
	const handleNavigate = (brand: string) => {
		navigate(
			`${!isCurrentUserAnonymous() ? `/brand/${brand}` : `/${eventPublicPathName}/brand/${brand}`}`
		);
	};
	return (
		<div className='highlighted-brands'>
			<div className='highlighted-brands-title'>
				{HighlightedBrandsState.highlightedBrandsTitle}
			</div>
			<div className='highlighted-brands-text'>{HighlightedBrandsState.highlightedBrandsText}</div>
			<div className='divider'></div>
			<div className='brands-carousel-container'>
				{deviceScreen !== ScreenType.MOBILE ? (
					<>
						<Swiper
							slidesPerView={slidePerView}
							spaceBetween={0}
							pagination={{
								el: '.swiper-custom-pagination-highlighted-brand',
								clickable: true,
								dynamicBullets: brandsHighlightedArr.length > 20,
								dynamicMainBullets: 5
							}}
							lazy={true}
							modules={[Pagination, Navigation]}
							onBeforeInit={swiper => {
								swiperRef.current = swiper;
							}}
						>
							{brandsHighlightedArr.length > 0 &&
								brandsHighlightedArr.map((brand: Brand, index: number) => (
									<SwiperSlide key={index}>
										<BrandComponent
											brandImage={brand.url_logo || ''}
											description={brand.brand_story || ''}
											name={brand.name || ''}
											onClick={() => handleNavigate(brand.id || '')}
										/>
									</SwiperSlide>
								))}
						</Swiper>

						<div className='nav-buttons'>
							<button
								className='swiper-button-prev'
								onClick={() => swiperRef.current?.slidePrev()}
							></button>
							<button
								className='swiper-button-next'
								onClick={() => swiperRef.current?.slideNext()}
							></button>
						</div>
						{brandsHighlightedArr.length > 4 && (
							<div className='swiper-custom-pagination-highlighted-brand'></div>
						)}
					</>
				) : windowsSize < SMALL_MOBILE ? (
					<>
						{brandsHighlightedArr.length > 0 && (
							<>
								<Swiper
									observeParents={true}
									slidesPerView={1}
									spaceBetween={0}
									grid={{
										rows: 2,
										fill: 'row'
									}}
									navigation={false}
									pagination={{
										el: '.swiper-custom-pagination-highlighted-brand',
										clickable: true,
										dynamicBullets: brandsHighlightedArr.length > 20,
										dynamicMainBullets: 5
									}}
									modules={[Grid, Pagination]}
									onBeforeInit={swiper => {
										swiperRef.current = swiper;
									}}
								>
									{brandsHighlightedArr.map((brand: Brand, index: number) => (
										<SwiperSlide key={index}>
											<BrandComponent
												brandImage={brand.url_logo || ''}
												onClick={() => handleNavigate(brand.id || '')}
												description={brand.brand_story || ''}
												name={brand.name || ''}
											/>
										</SwiperSlide>
									))}
								</Swiper>
								{brandsHighlightedArr.length > 4 && (
									<>
										<div className='swiper-custom-pagination-highlighted-brand'></div>
										<div className='control-mobile-for-highlighted-brand'>
											<i
												className='bi bi-chevron-left'
												onClick={() => {
													swiperRef.current?.slidePrev();
												}}
											></i>
											<i
												className='bi bi-chevron-right'
												onClick={() => {
													swiperRef.current?.slideNext();
												}}
											></i>
										</div>
									</>
								)}
							</>
						)}
					</>
				) : (
					<>
						{brandsHighlightedArr.length > 0 && (
							<>
								<Swiper
									observeParents={true}
									slidesPerView={2}
									spaceBetween={0}
									grid={{
										rows: 2,
										fill: 'row'
									}}
									navigation={false}
									pagination={{
										el: '.swiper-custom-pagination-highlighted-brand',
										clickable: true,
										dynamicBullets: brandsHighlightedArr.length > 20,
										dynamicMainBullets: 5
									}}
									modules={[Grid, Pagination]}
									onBeforeInit={swiper => {
										swiperRef.current = swiper;
									}}
								>
									{brandsHighlightedArr.map((brand: Brand, index: number) => (
										<SwiperSlide key={index}>
											<BrandComponent
												brandImage={brand.url_logo || ''}
												onClick={() => handleNavigate(brand.id || '')}
												description={brand.brand_story || ''}
												name={brand.name || ''}
											/>
										</SwiperSlide>
									))}
								</Swiper>
								{brandsHighlightedArr.length > 2 && (
									<>
										<div className='swiper-custom-pagination-highlighted-brand'></div>
										<div className='control-mobile-for-highlighted-brand'>
											<i
												className='bi bi-chevron-left'
												onClick={() => {
													swiperRef.current?.slidePrev();
												}}
											></i>
											<i
												className='bi bi-chevron-right'
												onClick={() => {
													swiperRef.current?.slideNext();
												}}
											></i>
										</div>
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	brandsHighlightedArr: state.appState.storeState?.brandsHighlighted || []
});

export default connect(mapStateToProps)(HighlightedBrands);
